import { IEntry, IEntryRecord } from '@flight-cap/shared';
import React from 'react';
import InfoCircleIcon from '../../../components/Icon/InfoCircleIcon';

const EntryInformation: React.FunctionComponent<{ entry: IEntry | IEntryRecord }> = ({ entry }) => {
    return (
        <>
            {entry.disqualified && (
                <>
                    <div className="badge-blue badge-rounded badge-outline !whitespace-normal mb-8 !items-start !p-4">
                        <InfoCircleIcon className="!w-8 !h-8 mr-4" />
                        <div>
                            <span className="text-xl">
                                Entry was marked as ineligible for awards.
                            </span>
                            {entry.disqualified_reason && (
                                <div className="uppercase mt-2">{entry.disqualified_reason}</div>
                            )}
                            <p className="mt-2">
                                Please contact the competition coordinator with any questions.
                            </p>
                        </div>
                    </div>
                </>
            )}
            {/* <label className="inline-block border-b border-gray-500 mb-2">Entry Details</label> */}
            <p>Judging Number: {entry.judging_number}</p>
            <p>
                {entry.style_snippet.category}
                {entry.style_snippet.sub_category}
                {': '}
                {entry.style_snippet.name}
            </p>
            {entry.required_info ? <p>{entry.required_info}</p> : <></>}
            {entry.specific_info ? <p>{entry.specific_info}</p> : <></>}
            {entry.optional_info ? <p>{entry.optional_info}</p> : <></>}
            <p>Table: {entry.table_snippet.title}</p>
        </>
    );
};

export default EntryInformation;
