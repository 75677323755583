import { IEntry } from '@flight-cap/shared';
import React from 'react';

const DisqualifiedBadge: React.FunctionComponent<{
    entry: IEntry;
    condensed?: boolean;
    className?: string;
}> = ({ entry, condensed = false, className = '' }) => {
    if (!entry.disqualified) {
        return null;
    }

    return condensed ? (
        <div
            className={`text-gray-500 bg-gray-100 rounded px-2 !text-xs ${className} flex items-center w-min select-none`}
            title="This entry is not eligible for awards"
        >
            <svg className="h-3 w-3 mr-1 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} stroke="#c7aa27" strokeWidth={0.1} />
            </svg>
            <span>DQ'd</span>
        </div>
    ) : (
        <div className={`badge-red badge-rounded !text-xs ${className}`}>Award Ineligible</div>
    );
};

export default DisqualifiedBadge;
