import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { ApplicationRoutes } from '../../routes';

const Splash: React.FunctionComponent<RouteComponentProps> = () => {
    return (
        <div className="main-content-wrapper text-center space-y-4">
            <h1 className="text-2xl mb-4">Welcome to Flight Cap</h1>
            <div className="container-dashed-gray max-w-xl m-auto">
                <div className="text-left space-y-4">
                    <p>
                        We aim to be the best solution for judging beer, mead, and cider
                        competitions.
                    </p>
                </div>
            </div>
            <div className="container-dashed-gray max-w-xl m-auto">
                <div className="text-left space-y-4">
                    <p>
                        If you are here as a judge looking for your competition, please{' '}
                        <Link to={ApplicationRoutes.SignIn}>sign-in</Link> or{' '}
                        <Link to={ApplicationRoutes.SignUp}>sign-up</Link> and join your
                        competition.
                    </p>
                    <p>We're excited for you, you're about to have a great judging experience!</p>
                </div>
            </div>
            <div className="container-dashed-gray max-w-xl m-auto">
                <div className="text-left space-y-4">
                    <p>
                        If you are interested in hosting a competition on flightcap, then shoot us{' '}
                        <a
                            href="mailto:flightcap.io@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            an email
                        </a>
                        ! We are still in alpha, but are happy to help configure your competition.
                    </p>
                </div>
            </div>
            <div className="container-dashed-gray max-w-xl m-auto">
                <div className="text-left space-y-4">
                    <p>Simple and enjoyable - so you can focus on the fun part.</p>
                </div>
            </div>
        </div>
    );
};

export default Splash;
