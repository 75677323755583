import React from 'react';
import { firestore } from '../../../../firebase';
import {
    ICustomComparativeFlight,
    IEntry,
    ITableComparativeFlight,
    decorateWithEntryInfoGetters,
} from '@flight-cap/shared';

export interface IFlightEntriesContext {
    flight: ITableComparativeFlight | ICustomComparativeFlight;
    flightEntries: IEntry[];
}

export const TableComparativeFlightEntriesContext: React.Context<IFlightEntriesContext> =
    React.createContext<IFlightEntriesContext>({
        flight: null,
        flightEntries: null,
    });

const TableComparativeFlightEntriesProvider: React.FunctionComponent<{
    flight: ITableComparativeFlight;
    competitionId: string;
    children: React.ReactNode;
}> = ({ flight, competitionId, children }) => {
    const [flightEntries, setFlightEntries] = React.useState<IEntry[]>([]);

    React.useEffect(() => {
        if (competitionId && flight) {
            return firestore
                .collection(`competitions/${competitionId}/entries`)
                .where('table_snippet.uid', '==', flight.table_snippet?.uid || '')
                .where('mini_bos', '==', true)
                .onSnapshot((snapshot) => {
                    console.log('TableComparativeFlightEntriesProvider -- onSnapshot');
                    const entries = snapshot.docs
                        .map((doc) => ({ uid: doc.id, ...(doc.data() as IEntry) }))
                        .map((entry) => decorateWithEntryInfoGetters(entry))
                        .sort((a, b) => {
                            return a.judging_number > b.judging_number ? 1 : -1;
                        });

                    setFlightEntries(entries);
                });
        } else {
            setFlightEntries([]);
        }
    }, [competitionId, flight]);

    return (
        <TableComparativeFlightEntriesContext.Provider
            value={{
                flight,
                flightEntries,
            }}
        >
            {children}
        </TableComparativeFlightEntriesContext.Provider>
    );
};

export default TableComparativeFlightEntriesProvider;
