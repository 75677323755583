import React from 'react';
import { IUseStateHook } from '@flight-cap/shared';

export interface IRecoverPasswordState {
    isRecovering: boolean;
    email: string;
}

export const RecoverPasswordContext: React.Context<IUseStateHook<IRecoverPasswordState>> =
    React.createContext<IUseStateHook<IRecoverPasswordState>>([null, () => {}]);

const RecoverPasswordProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const stateHook = React.useState<IRecoverPasswordState>({
        isRecovering: false,
        email: null,
    });
    return (
        <RecoverPasswordContext.Provider value={stateHook}>
            {children}
        </RecoverPasswordContext.Provider>
    );
};

export default RecoverPasswordProvider;
