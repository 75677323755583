import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from '@reach/router';
import { ApplicationRoutes } from './routes';
import {
    ICompetitionParticiantsRolesIndex,
    IStyleGuideData,
    IUserProfile,
    StyleClassification,
    UserCompetitionRole,
} from '@flight-cap/shared';

const config = {
    environment: process.env.REACT_APP_ENVIRONMENT,
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    cloudFunctionsURL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL,
};

export enum AuthResponseCode {
    UserNotFound = 'auth/user-not-found',
    InvalidEmail = 'auth/invalid-email',
    WrongPassword = 'auth/wrong-password',
    WeakPassword = 'auth/weak-password',
    EmailInUse = 'auth/email-already-in-use',
    PasswordResetWrongEmail = 'password-reset-wrong-email',
}

const firebaseApp = app.initializeApp(config);
const googleAuthProvider = new app.auth.GoogleAuthProvider();

export const firestore = app.firestore();
export const firebaseAuth = app.auth();
export const timestamp = app.firestore.FieldValue.serverTimestamp;
export const deleteField = app.firestore.FieldValue.delete;
export const firstoreArrayRemove = app.firestore.FieldValue.arrayRemove;
export const firstoreArrayUnion = app.firestore.FieldValue.arrayUnion;
export type FirestoreFieldValue = app.firestore.FieldValue;

const { environment } = config;

if (environment === 'local') {
    console.log('runnning locally. using the emulator database');
    firestore.useEmulator('localhost', 8080);
    firebaseAuth.useEmulator('http://localhost:9099/');
}

export const signOut = async () => {
    try {
        await firebaseAuth.signOut();
        // kinda yucky, but this gets rid of the redirect param with explicitly signing out
        setTimeout(() => {
            navigate(ApplicationRoutes.SignIn, { replace: true });
        }, 1);
    } catch (err) {}
};

export const signInWithGoogle = () =>
    firebaseAuth
        .signInWithPopup(googleAuthProvider)
        .then((userAuth) => createUserProfileDocument(userAuth.user));

export const signInWithEmailAndPassword = (
    email: string,
    password: string
): Promise<void | app.auth.UserCredential> =>
    firebaseAuth.signInWithEmailAndPassword(email, password);

export const createUserWithEmailAndPassword = (
    email: string,
    password: string,
    additionalData?: Partial<IUserProfile>
) =>
    firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then((userAuth) => createUserProfileDocument(userAuth.user, additionalData));

export const createUserProfileDocument = async (
    userAuth: firebase.default.User,
    additionalData?: Partial<IUserProfile>
) => {
    if (!userAuth) return;

    const userRef = getUserProfileDocument(userAuth.uid);
    const { displayName, email, photoURL } = userAuth;
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
        const created_at = new Date();

        try {
            return userRef.set({
                display_name: displayName,
                photo_url: photoURL,
                email,
                created_at,
                ...additionalData,
            });
        } catch (error) {
            console.error('error creating a user', error);
        }
    }

    return Promise.resolve(snapshot.data());
};

export const getUserProfileDocument = (uid: string) => firestore.doc(`users/${uid}`);

export const addCompetitionParticipant = async (
    competitionId: string,
    userId: string,
    roles: UserCompetitionRole[]
) => {
    return firestore
        .doc(`competitions/${competitionId}/indexes/participants_roles`)
        .set({ [userId]: roles }, { merge: true });
};

export const getStyleDocument = async (
    styleClassification: StyleClassification,
    uid: string
): Promise<IStyleGuideData> => {
    if (!uid) {
        return Promise.resolve(null);
    }

    const guideData = await firestore
        .doc(`/styles/${styleClassification}/guide_data/${uid.toUpperCase()}`)
        .get();
    const data = guideData.data() as IStyleGuideData;
    console.log('getStyleDocument', data);
    return data;
};

/**
 * The best option for this might be to have a cloud API function that can be called
 * to update this index. That way we dont need to give non-admins read access to the
 * whole participants_roles index
 */
export const addParticipantRole = async (
    competitionId: string,
    participantId: string,
    role: UserCompetitionRole
) => {
    console.log('add role', role, participantId);

    if (!competitionId) {
        return Promise.resolve();
    }

    const indexRef = firestore.doc(`competitions/${competitionId}/indexes/participants_roles`);
    const indexData = (await indexRef.get()).data() as ICompetitionParticiantsRolesIndex;

    if (!indexData[participantId].includes(role)) {
        return indexRef.update({
            [participantId]: firstoreArrayUnion(role),
        });
    }

    return Promise.resolve();
};

export const removeParticipantRole = async (
    competitionId: string,
    participantId: string,
    role: UserCompetitionRole
) => {
    console.log('remove role', role, participantId);

    if (!competitionId) {
        return Promise.resolve();
    }

    const indexRef = firestore.doc(`competitions/${competitionId}/indexes/participants_roles`);
    const indexData = (await indexRef.get()).data() as ICompetitionParticiantsRolesIndex;

    if (indexData[participantId].includes(role)) {
        return indexRef.update({
            [participantId]: firstoreArrayRemove(role),
        });
    }

    return Promise.resolve();
};

export default firebaseApp;
