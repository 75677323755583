import React from 'react';
import { useForm } from 'react-hook-form';
import { ICompetition, parameterizeEndpoint } from '@flight-cap/shared';
import { navigate } from '@reach/router';
import { ApplicationRoutes } from '../../routes';

interface IFormData {
    competitionSlug: string;
}

const JoinComp: React.FunctionComponent<{ competitions: ICompetition[] }> = ({ competitions }) => {
    const {
        register,
        handleSubmit,
        formState,
        formState: { errors },
    } = useForm<IFormData>({
        mode: 'onChange',
    });

    const onSubmit = ({ competitionSlug }: IFormData) => {
        navigate(parameterizeEndpoint(ApplicationRoutes.JoinCompetition, { competitionSlug }));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="md:grid md:grid-cols-3">
            <div className="mb-4 col-span-3">
                <select
                    id="location"
                    {...register('competitionSlug', { required: true })}
                    aria-invalid={errors.competitionSlug ? 'true' : 'false'}
                >
                    <option value="" className="text-pink-500">
                        Choose a Competition
                    </option>
                    {competitions?.length ? (
                        competitions
                            .filter((comp) => !comp.archived)
                            .map((comp) => (
                                <option key={comp.uid} value={comp.slug}>
                                    {comp.title}
                                </option>
                            ))
                    ) : (
                        <></>
                    )}
                </select>
            </div>
            {competitions?.length ? (
                <div className="mb-4">
                    <button
                        type="submit"
                        className="btn-indigo w-full"
                        disabled={!formState.isValid}
                    >
                        Check it out
                    </button>
                </div>
            ) : (
                <> </>
            )}
        </form>
    );
};

export default JoinComp;
