import React from 'react';
import Card from '../../components/Card/Card';
import HostComp from './HostComp';
import JoinComp from './JoinComp';
import { CompetitionsContext } from './providers/CompetitionsProvider';
import { UserContext } from '../../providers/UserProvider';
import { Link } from '@reach/router';
import { parameterizeEndpoint } from '@flight-cap/shared';
import { ApplicationRoutes } from '../../routes';
import { FlagsContext } from '../../providers/FlagsProvider';

const Dashboard: React.FunctionComponent = () => {
    const { competitions } = React.useContext(CompetitionsContext);
    const { user } = React.useContext(UserContext);
    const { flags } = React.useContext(FlagsContext);

    const { notJoined } = React.useMemo(() => {
        const userComps = user?.competitions ? Object.keys(user.competitions) : [];
        return {
            notJoined: competitions?.filter((comp) => !userComps.includes(comp.uid)) || [],
        };
    }, [competitions, user?.competitions]);

    const joined = React.useMemo(
        () =>
            Object.entries(user?.competitions || []).map(([compId, compSnippet]) => ({
                uid: compId,
                ...compSnippet,
            })),
        [user]
    );

    return (
        <div className="main-content-wrapper">
            <div className="md:grid md:grid-cols-2 md:gap-6 space-y-12 md:space-y-0">
                {joined.length ? (
                    <Card className="md:col-span-1" headingText="Your Competitions">
                        <ul className="space-y-2">
                            {joined.map(({ uid, title, slug, roles }) => (
                                <li key={uid}>
                                    <Link
                                        className="btn-indigo btn-outline w-full"
                                        key={uid}
                                        to={parameterizeEndpoint(ApplicationRoutes.Competition, {
                                            competitionSlug: slug,
                                        })}
                                    >
                                        <div className="flex justify-between items-center">
                                            {title}{' '}
                                            <span className="font-normal">{roles.join(', ')}</span>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Card>
                ) : (
                    <></>
                )}

                <Card className="md:col-span-1" headingText="Host a competition">
                    {flags?.enable_comp_creation ? (
                        <HostComp />
                    ) : (
                        <div className="container-dashed-gray">
                            <p className="text-center text-sm">
                                We aren't ready for you to setup your own competitions yet, but if
                                you'd like to learn more about using Flightcap with your next
                                competition, please{' '}
                                <a
                                    href="mailto:flightcap.io@gmail.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    email us
                                </a>
                                .
                            </p>
                        </div>
                    )}
                </Card>

                {notJoined.length ? (
                    <Card className="md:col-span-1" headingText="Join a competition">
                        <JoinComp competitions={notJoined} />
                    </Card>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
