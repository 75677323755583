import {
    AwardRank,
    ICustomComparativeFlight,
    IEntry,
    ITableComparativeFlight,
} from '@flight-cap/shared';
import React from 'react';
import { FlightComparisonContext } from '../../providers/FlightComparisonProvider';
import { CustomComparativeFlightEntriesContext } from '../../providers/FlightEntries/CustomComparativeFlightEntriesProvider';
import { TableComparativeFlightEntriesContext } from '../../providers/FlightEntries/TableComparativeFlightEntriesProvider';
import ComparisonGrid from './ComparisonGrid';
import ComparisonPlaces from './ComparisonPlaces';

const MiniBosDetail: React.FunctionComponent<{
    flight: ITableComparativeFlight | ICustomComparativeFlight;
}> = ({ flight }) => {
    const { flightEntries } = React.useContext(
        flight.type === 'table_comparative'
            ? TableComparativeFlightEntriesContext
            : CustomComparativeFlightEntriesContext
    );
    const { updateAwardRank } = React.useContext(FlightComparisonContext);

    const { isReady } = React.useContext(FlightComparisonContext);

    if (!isReady) {
        return null;
    }

    if (!flightEntries.length) {
        <p>There are no qualifying entries yet.</p>;
    }

    const handlePlaceSubmit = (entry: IEntry, rank: AwardRank) => {
        updateAwardRank(rank, entry);
    };

    return (
        <>
            <h1 className="my-4">{flight.name()}</h1>
            <hr className="my-8" />
            {/* <p className="my-4 text-sm text-gray-400 text-center">
                Please use the boxes below to track your progress through the Mini Best of Show
                ranking. These are your personal notes. Only the eliminated status is shared across
                all the judges in the session.
            </p> */}

            <ComparisonPlaces rankings={flight.award_rankings} />
            <hr className="my-8" />
            <ComparisonGrid entries={flightEntries} onPlaceSubmit={handlePlaceSubmit} />
        </>
    );
};

export default MiniBosDetail;
