import {
    IFlightComparison,
    IJudgeSnippet,
    IUserProfile,
    IAwardRankings,
    AwardRank,
    IEntry,
    ITableComparativeFlight,
    ICustomComparativeFlight,
} from '@flight-cap/shared';
import React from 'react';
import { firestore, timestamp } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { UserContext } from '../../../providers/UserProvider';

type IFlightComparisonContext = {
    comparison: IFlightComparison;
    isReady: boolean;
    updateComparison: (entries: IFlightComparison['entries']) => Promise<void>;
    broadcastEliminated: (entryId: string, eliminated: boolean) => Promise<void>;
    updateAwardRank: (place: AwardRank, entry: IEntry) => Promise<void>;
};

export const FlightComparisonContext: React.Context<IFlightComparisonContext> =
    React.createContext<IFlightComparisonContext>({
        comparison: null,
        updateComparison: null,
        broadcastEliminated: null,
        isReady: false,
        updateAwardRank: null,
    });

function makeJudgeSnippet(user: IUserProfile): IJudgeSnippet {
    return {
        uid: user.uid,
        display_name: user.display_name,
        email: user.email,
        judging: user.judging || null,
    };
}

/**
 * Providers all the flights available to the currently signed in user.
 */
const FlightComparisonProvider: React.FunctionComponent<{
    flight: ITableComparativeFlight | ICustomComparativeFlight;
    children: React.ReactNode;
}> = ({ flight, children }) => {
    const [comparison, setComparison] = React.useState<IFlightComparison>(null);
    const [comparisonDoc, setComparisonDoc] =
        React.useState<firebase.default.firestore.DocumentReference>(null);
    const [isReady, setIsReady] = React.useState(false);

    const { competition } = React.useContext(CompetitionContext);
    const { user } = React.useContext(UserContext);

    React.useEffect(() => {
        if (competition?.uid && flight) {
            return firestore
                .collection(`competitions/${competition.uid}/flights/${flight.uid}/comparisons/`)
                .where('judge_snippet.uid', '==', user.uid)
                .limit(1)
                .onSnapshot((comparisons) => {
                    if (comparisons.docs.length) {
                        const comparison = comparisons.docs.shift();
                        console.log('Flight Comparison Provider - onSnapshot', comparison.data());
                        setComparisonDoc(comparison.ref);
                        setComparison({
                            uid: comparison.id,
                            ...(comparison.data() as IFlightComparison),
                        });
                        setIsReady(true);
                    } else {
                        console.log('Flight Comparison Provider - generating doc');
                        const doc = firestore
                            .collection(
                                `competitions/${competition.uid}/flights/${flight.uid}/comparisons/`
                            )
                            .doc();

                        doc.set({ judge_snippet: makeJudgeSnippet(user), entries: {} }).then(() => {
                            setComparisonDoc(doc);
                            setComparison({
                                uid: doc.id,
                                judge_snippet: makeJudgeSnippet(user),
                                saved_at: null,
                                entries: {},
                            });
                            setIsReady(true);
                        });
                    }
                });
        } else {
            setComparisonDoc(null);
            setComparison(null);
            setIsReady(true);
        }
    }, [competition?.uid, flight, user.uid, user]);

    const updateComparison = async (entries: IFlightComparison['entries']) => {
        const hasValues = entries && Object.keys(entries).length;

        if (!hasValues) {
            return Promise.reject('No entries to update');
        }

        const data: Omit<IFlightComparison, 'uid'> = {
            entries: {
                ...(comparison?.entries || {}),
                ...entries,
            },
            judge_snippet: makeJudgeSnippet(user),
            saved_at: timestamp(),
        };

        // force existing place values to be numbers or null
        Object.values(data.entries).forEach((obj) => {
            if (obj.place) {
                obj.place = Number(obj.place);
            } else {
                obj.place = null;
            }
        });

        return comparisonDoc.set(data, { merge: true });
    };

    const broadcastEliminated = async (entryId: string, eliminated: boolean) => {
        const response = await firestore
            .collection(`competitions/${competition.uid}/flights/${flight.uid}/comparisons/`)
            .where('judge_snippet.uid', '!=', user.uid)
            .get();

        if (response.size) {
            const batch = firestore.batch();
            response.docs.forEach((doc) => {
                batch.update(doc.ref, { [`entries.${entryId}.eliminated`]: eliminated });
            });
            return batch.commit();
        }

        return Promise.resolve();
    };

    const updateAwardRank = async (rank: AwardRank | null, entry: IEntry) => {
        const currentRankings: IAwardRankings = flight.award_rankings || {};

        const precise: any = {};

        // unset any place that has this entry
        for (const [place, entrySnippet] of Object.entries(currentRankings)) {
            if (entrySnippet?.uid === entry.uid) {
                precise[`award_rankings.${place}`] = null;
                break;
            }
        }

        if (rank !== null) {
            precise[`award_rankings.${rank}`] = {
                uid: entry.uid,
                judging_number: entry.judging_number,
                style_snippet: entry.style_snippet,
            };
        }

        console.log('updating the award rank (precise)', precise);

        return firestore
            .doc(`competitions/${competition.uid}/flights/${flight.uid}`)
            .update(precise);
    };

    return (
        <FlightComparisonContext.Provider
            value={{
                comparison,
                updateComparison,
                broadcastEliminated,
                isReady,
                updateAwardRank,
            }}
        >
            {children}
        </FlightComparisonContext.Provider>
    );
};

export default FlightComparisonProvider;
