import React from 'react';

const PrintableFooter: React.FunctionComponent = () => {
    return (
        <footer className="flex flex-col justify-center items-center mt-16">
            <p>
                Let us know what you like, and what we can do better:{' '}
                <a href="mailto:flightcap.io@gmail.com" target="_blank" rel="noopener noreferrer">
                    flightcap.io@gmail.com
                </a>
            </p>
            <p>
                Created with ❤️ using <a href="https://flightcap.io">flightcap.io</a>
            </p>
        </footer>
    );
};

export default PrintableFooter;
