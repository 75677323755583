import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IValueInputProps } from './QueryFormRow';

const QueryMiniBosInput: React.FunctionComponent<IValueInputProps> = ({ name }) => {
    const { register } = useFormContext();

    return (
        <div className="hidden">
            <input
                type="checkbox"
                min={0}
                max={50}
                hidden={true}
                checked={true}
                readOnly
                {...register(name)}
            />
        </div>
    );
};

export default QueryMiniBosInput;
