import React from 'react';
import { useForm } from 'react-hook-form';
import { addCompetitionParticipant, firestore, timestamp } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { navigate } from '@reach/router';
import { ApplicationRoutes } from '../../routes';
import { ICompetitionRecord, parameterizeEndpoint } from '@flight-cap/shared';

interface IFormData {
    title: string;
    slug: string;
}

const HostComp: React.FunctionComponent = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IFormData>();
    const { user } = React.useContext(UserContext);

    const onSubmit = async (data: IFormData) => {
        const { title, slug } = data;

        try {
            const compDoc = firestore.collection('competitions').doc();
            const compData: ICompetitionRecord = {
                title,
                slug,
                created_by: user.uid,
                default_sheet_schema: 'midwinter_2022',
                style_classification: 'bjcp_2021',
                created_at: timestamp(),
            };
            await compDoc.set(compData);
            await addCompetitionParticipant(compDoc.id, user.uid, ['owner', 'admin', 'judge']);
            reset();
            navigate(
                parameterizeEndpoint(ApplicationRoutes.AdminDashboard, {
                    competitionSlug: slug,
                })
            );
        } catch (e) {
            console.log('could not create the comp', e);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="md:grid md:grid-cols-3">
            <div className="mb-4 col-span-3">
                <label htmlFor="title">Competition Name</label>
                <input
                    type="text"
                    autoComplete="off"
                    placeholder="Competition Name"
                    aria-invalid={errors.title ? 'true' : 'false'}
                    {...register('title', { required: true })}
                ></input>
            </div>
            <div className="mb-4 col-span-3">
                <label htmlFor="slug">Slug</label>
                <input
                    type="text"
                    placeholder="Competition Slug"
                    aria-invalid={errors.title ? 'true' : 'false'}
                    {...register('slug', { required: true })}
                ></input>
            </div>
            <div className="mb-4">
                <button type="submit" className="btn-indigo w-full">
                    Start
                </button>
            </div>
        </form>
    );
};

export default HostComp;
