export enum ApplicationRoutes {
    Home = '/',
    Dashboard = '/dashboard',
    SignIn = '/auth/sign-in',
    SignUp = '/auth/sign-up',
    SignOut = '/auth/sign-out',
    RecoverPassword = '/auth/recover-password',
    UserProfile = '/profile',
    Competition = '/:competitionSlug',
    JudgingDashboard = '/:competitionSlug/judging/',
    StewardingDashboard = '/:competitionSlug/stewarding/',
    AdminDashboard = '/:competitionSlug/admin',
    JoinCompetition = '/:competitionSlug/join',
    CompetitionInfo = '/:competitionSlug/info',
    JudgingFlight = '/:competitionSlug/judging/flight/:flightId',
    JudgingEntry = '/:competitionSlug/judging/flight/:flightId/entry/:entryId',
}
