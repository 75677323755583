import { IEntry, IScoreSheetData } from '@flight-cap/shared';
import React from 'react';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
// import { defaultSchema } from '../../../sheet_schemas';
import PrintableScoresheet from '../../Pdfs/scoresheet/PrintableScoresheet';

const EntryPrintScoresheets: React.FunctionComponent<{
    entry: IEntry;
    scores: IScoreSheetData[];
}> = ({ entry, scores }) => {
    const { competition } = React.useContext(CompetitionContext);

    return (
        <>
            {scores?.map((s) => (
                <PrintableScoresheet
                    key={s.uid}
                    entry={entry}
                    competition={competition}
                    schema={entry.sheet_schema}
                    score={s}
                    className="printer-page"
                />
            ))}
        </>
    );
};

export default EntryPrintScoresheets;
