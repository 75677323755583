import React from 'react';
import { firestore } from '../firebase';
import { ICompetition } from '@flight-cap/shared';
import { useMatch } from '@reach/router';
import { ApplicationRoutes } from '../routes';

type ICompetitionContext = {
    competition: ICompetition;
    resolved: boolean;
};

export const CompetitionContext: React.Context<ICompetitionContext> =
    React.createContext<ICompetitionContext>({
        competition: null,
        resolved: false,
    });

/**
 * Provides the competition with the id the corresponds to the :competitionId
 * in the route.
 */
const CompetitionProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const match = useMatch(`${ApplicationRoutes.Competition}/*`);
    const [competition, setCompetition] = React.useState<ICompetition>(null);
    const [resolved, setResolved] = React.useState(false);

    React.useEffect(() => {
        if (match?.competitionSlug) {
            setResolved(false);
            return firestore
                .collection(`competitions`)
                .where('slug', '==', match.competitionSlug)
                .limit(1)
                .onSnapshot((comps) => {
                    const comp = comps.docs?.[0];
                    console.log('Competition Provider - onSnapshot');
                    setCompetition(
                        comp ? { uid: comp.id, ...(comp.data() as ICompetition) } : null
                    );
                    setResolved(true);
                });
        } else {
            setCompetition(null);
            setResolved(true);
        }
    }, [match?.competitionSlug]);

    return (
        <CompetitionContext.Provider value={{ competition, resolved }}>
            {children}
        </CompetitionContext.Provider>
    );
};

export default CompetitionProvider;
