import React from 'react';
import { firestore } from '../../../../firebase';
import {
    IEntry,
    IEntryFlightSnippet,
    ITableScoringFlight,
    decorateWithEntryInfoGetters,
} from '@flight-cap/shared';
import { FlightsContext } from '../FlightsProvider';

interface IEntryMap {
    allEntries: IEntry[];
    inFlight: IEntry[];
    anotherFlight: IEntry[];
    unclaimed: IEntry[];
}

export interface ITableScoringFlightEntriesContext {
    flight: ITableScoringFlight;
    flightEntries: IEntryMap;
    assignEntryToFlight: (flightId: string, entryId: string) => Promise<void>;
    removeEntryFromFlight: (entryId: string) => Promise<void>;
}

const defaultEntryMap: IEntryMap = {
    allEntries: [],
    inFlight: [],
    anotherFlight: [],
    unclaimed: [],
};

export const TableScoringFlightEntriesContext: React.Context<ITableScoringFlightEntriesContext> =
    React.createContext<ITableScoringFlightEntriesContext>({
        flight: null,
        flightEntries: defaultEntryMap,
        assignEntryToFlight: null,
        removeEntryFromFlight: null,
    });

const TableScoringFlightEntriesProvider: React.FunctionComponent<{
    flight: ITableScoringFlight;
    competitionId: string;
    children: React.ReactNode;
}> = ({ flight, competitionId, children }) => {
    const [entryMap, setEntryMap] = React.useState<IEntryMap>(defaultEntryMap);
    const { flights } = React.useContext(FlightsContext);

    React.useEffect(() => {
        if (competitionId && flight) {
            return firestore
                .collection(`competitions/${competitionId}/entries`)
                .where('table_snippet.uid', '==', flight.table_snippet?.uid || '')
                .onSnapshot((snapshot) => {
                    console.log('TableScoringFlightEntriesProvider -- onSnapshot');
                    const entries = snapshot.docs
                        .map((doc) => ({ uid: doc.id, ...(doc.data() as IEntry) }))
                        .map((entry) => decorateWithEntryInfoGetters(entry))
                        .sort((a, b) => {
                            return a.judging_number > b.judging_number ? 1 : -1;
                        });

                    const inFlight: IEntry[] = [];
                    const anotherFlight: IEntry[] = [];
                    const unclaimed: IEntry[] = [];

                    entries.forEach((entry) => {
                        if (entry.flight_snippet?.uid === flight.uid) {
                            return inFlight.push(entry);
                        }

                        if (entry.flight_snippet) {
                            return anotherFlight.push(entry);
                        }

                        unclaimed.push(entry);
                    });

                    setEntryMap({
                        allEntries: entries,
                        inFlight: inFlight.sort((a, b) =>
                            a.flight_snippet.claimed_at > b.flight_snippet.claimed_at ? 1 : -1
                        ),
                        anotherFlight,
                        unclaimed,
                    });
                });
        } else {
            setEntryMap(defaultEntryMap);
        }
    }, [competitionId, flight]);

    const assignEntryToFlight = async (flightId: string, entryId: string) => {
        const flight = flights.find((f) => f.uid === flightId);

        if (!flight) {
            console.log('no flight given, not assigning to flight');
            return;
        }

        const flight_snippet: IEntryFlightSnippet = {
            uid: flight.uid,
            title: flight.name(),
            judges: flight.judges,
            claimed_at: new Date(),
        };

        // ok to overwrite the whole flight snippet
        return firestore.doc(`competitions/${competitionId}/entries/${entryId}`).update({
            flight_snippet,
        });
    };

    const removeEntryFromFlight = async (entryId: string) => {
        // ok to overwrite
        return firestore
            .doc(`competitions/${competitionId}/entries/${entryId}`)
            .update({ flight_snippet: null });
    };

    return (
        <TableScoringFlightEntriesContext.Provider
            value={{
                flight,
                flightEntries: entryMap,
                assignEntryToFlight,
                removeEntryFromFlight,
            }}
        >
            {children}
        </TableScoringFlightEntriesContext.Provider>
    );
};

export default TableScoringFlightEntriesProvider;
