import { Link } from '@reach/router';
import React from 'react';
import { CellProps } from 'react-table';
import AwardRankingBadgeList from '../../../components/AwardRanking/AwardRankingBadgeList';
import DownloadIcon from '../../../components/Icon/DownloadIcon';
import PrinterIcon from '../../../components/Icon/PrinterIcon';
import StyleBadge from '../../../components/StyleBadge/StyleBadge';
import { MyColumn } from '../../../components/Table/react-table-config';
import EditDeleteCellRenderer from '../../../components/Table/renderers/EditDeleteCellRenderer';
import { TableRow } from './TablesAdmin';

export const awardTableColumns: MyColumn<TableRow>[] = [
    {
        Header: 'Name',
        accessor: 'title',
        sticky: 'left',
    },
    {
        Header: 'Styles',
        accessor: (d) => (
            <div>
                {Object.values(d.categories || [])
                    .sort((a, b) => (a.uid > b.uid ? 1 : -1))
                    .map((cat) => (
                        <StyleBadge key={cat.uid} style={cat} />
                    ))}
            </div>
        ),
        width: 300,
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Entries',
        accessor: (d) => {
            const miniBosEntries = d.entries.filter((e) => e.mini_bos);
            const scored = d.entries.filter((e) => e.consensus_score?.toString().length > 0);
            const unscoredCount = d.entries.length - scored.length;

            return (
                <div className="flex flex-col">
                    {d.entries.length > 0 ? (
                        <div className="badge-blue badge-outline badge-rounded mb-2 w-min">
                            {d.entries.length} Entries
                        </div>
                    ) : (
                        <></>
                    )}
                    {unscoredCount > 0 ? (
                        <div className="badge-red badge-outline badge-rounded mb-2 w-min">
                            {unscoredCount} Unscored
                        </div>
                    ) : (
                        <></>
                    )}

                    {miniBosEntries.length > 0 ? (
                        <button
                            className="btn-pull-sheet-print-mini-bos mb-2"
                            data-table-uid={d.uid}
                        >
                            <div className="pointer-events-none badge-green badge-outline badge-rounded w-min">
                                {miniBosEntries.length} in Mini-BOS <PrinterIcon className="ml-2" />
                            </div>
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            );
        },
    },
    {
        Header: 'Flights',
        accessor: (d) => {
            return (
                <div className="flex flex-row justify-between items-center w-full">
                    <div className="flex flex-col">
                        {d.scoringFlights?.map((f) => (
                            <Link to="../flights" key={f.uid}>
                                <div className="badge badge-gray badge-outline my-1 w-min">
                                    <p className="text-sm truncate" style={{ maxWidth: '200px' }}>
                                        {f.name()}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <button
                        className="badge badge-sm btn-green btn-add-scoring-flight w-min"
                        data-table-uid={d.uid}
                    >
                        Add
                    </button>
                </div>
            );
        },
        width: 250,
        minWidth: 150,
        maxWidth: 850,
    },
    {
        Header: 'Winners',
        accessor: (d) => {
            return <AwardRankingBadgeList rankings={d.award_rankings} />;
        },
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<TableRow>) => (
            <>
                <EditDeleteCellRenderer value={original.uid} />
                <a
                    href={original.downloadSheetsUrl}
                    className="btn-yellow btn-outline"
                    target="_blank"
                    rel="noreferrer"
                >
                    <DownloadIcon className="pointer-events-none" />
                </a>
            </>
        ),
        disableSortBy: true,
        width: 200,
        minWidth: 200,
        maxWidth: 300,
        sticky: 'right',
        className: 'unstick-large',
    },
];

export const nonAwardTableColumns: MyColumn<TableRow>[] = [
    {
        Header: 'Name',
        accessor: 'title',
        sticky: 'left',
    },
    {
        Header: 'Styles',
        accessor: (d) => (
            <div>
                {Object.values(d.categories || [])
                    .sort((a, b) => (a.uid > b.uid ? 1 : -1))
                    .map((cat) => (
                        <StyleBadge key={cat.uid} style={cat} />
                    ))}
            </div>
        ),
        width: 300,
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Entries',
        accessor: (d) => {
            const scored = d.entries.filter((e) => e.consensus_score?.toString().length > 0);
            const unscoredCount = d.entries.length - scored.length;

            return (
                <div className="flex flex-col">
                    {d.entries.length > 0 ? (
                        <div className="badge-blue badge-outline badge-rounded mb-2 w-min">
                            {d.entries.length} Entries
                        </div>
                    ) : (
                        <></>
                    )}
                    {unscoredCount > 0 ? (
                        <div className="badge-red badge-outline badge-rounded mb-2 w-min">
                            {unscoredCount} Unscored
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            );
        },
    },
    {
        Header: 'Flights',
        accessor: (d) => {
            return (
                <div className="flex flex-row justify-between items-center w-full">
                    <div className="flex flex-col">
                        {d.scoringFlights?.map((f) => (
                            <Link to="../flights" key={f.uid}>
                                <div className="badge badge-gray badge-outline my-1 w-min">
                                    <p className="text-sm truncate" style={{ maxWidth: '200px' }}>
                                        {f.name()}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <button
                        className="badge badge-sm btn-green btn-add-scoring-flight w-min"
                        data-table-uid={d.uid}
                    >
                        Add
                    </button>
                </div>
            );
        },
        width: 250,
        minWidth: 150,
        maxWidth: 850,
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<TableRow>) => (
            <>
                <EditDeleteCellRenderer value={original.uid} />
                <a
                    href={original.downloadSheetsUrl}
                    className="btn-yellow btn-outline"
                    target="_blank"
                    rel="noreferrer"
                >
                    <DownloadIcon className="pointer-events-none" />
                </a>
            </>
        ),
        disableSortBy: true,
        width: 200,
        minWidth: 200,
        maxWidth: 300,
        sticky: 'right',
        className: 'unstick-large',
    },
];
