import React from 'react';
import {
    ISheetSection,
    ISheetElementProps,
    ISliderProps,
    ITextAreaProps,
    IScoreInputProps,
    IScoreSheetData,
} from '@flight-cap/shared';
import SheetTextArea from './SheetTextArea';
import SheetScoreInput from './SheetScoreInput';
import SheetSlider from './SheetSlider';
import { SheetElementType } from '@flight-cap/shared';
import Tooltip from '../Tooltip/Tooltip';
import AnnotationIcon from '../Icon/QuestionMarkCircleIcon';

const makeElement = (elementProps: ISheetElementProps, data: IScoreSheetData): React.ReactChild => {
    const map: Record<SheetElementType, React.ReactChild> = {
        slider: <SheetSlider {...(elementProps as ISliderProps)} scoreSheetData={data} />,
        textarea: <SheetTextArea {...(elementProps as ITextAreaProps)} scoreSheetData={data} />,
        score_input: (
            <SheetScoreInput {...(elementProps as IScoreInputProps)} scoreSheetData={data} />
        ),
    };

    return map[elementProps.type] || <p>not supported</p>;
};

const SheetSection: React.FunctionComponent<
    ISheetSection & {
        scoreSheetData: IScoreSheetData;
        errors?: { [key: string]: { message?: string } };
    }
> = ({ title, columns, className, renderMode, scoreSheetData, tooltip, errors }) => {
    const cols = columns.length;

    let errorMessage = '';
    if (errors) {
        columns.forEach((col) =>
            col.elements.forEach((el) => {
                if (errors[el.element_id]) {
                    errorMessage = `${errorMessage}${errors[el.element_id].message}. `;
                }
            })
        );
    }

    return (
        <section
            className={`my-2 py-2 border-b ${
                renderMode === 'pdf' ? 'border-gray-800' : ''
            } md:grid md:grid-cols-${cols} ${className}`}
        >
            <h3 className="col-span-full text-gray-600 text-lg flex items-center justify-between mb-1">
                <div className="flex items-center">
                    <span className={`mr-4 ${renderMode === 'pdf' ? 'font-bold' : ''}`}>
                        {title}
                    </span>
                    {tooltip && renderMode === 'pdf' && (
                        <p className="text-gray-400 text-lg">{tooltip}</p>
                    )}
                    {tooltip && renderMode !== 'pdf' && (
                        <Tooltip content={tooltip}>
                            <AnnotationIcon />
                        </Tooltip>
                    )}
                </div>
                <div>
                    {errorMessage && (
                        <span className="badge badge-red badge-rounded badge-condensed">
                            <span className="text-xs flex">
                                <span className="hidden xl:inline">
                                    {errorMessage.length > 25 ? 'Required' : errorMessage}
                                </span>
                                <span className="xl:hidden">Required</span>
                            </span>
                        </span>
                    )}
                </div>
            </h3>
            {columns.map((column, indx) => (
                <div key={`col-${indx}`}>
                    {column.elements.map((element) => (
                        <div
                            key={element.element_id}
                            className={`mb-4 section-element ${errorMessage ? 'has-error' : ''}`}
                        >
                            {makeElement({ ...element, renderMode }, scoreSheetData)}
                        </div>
                    ))}
                </div>
            ))}
        </section>
    );
};

export default SheetSection;
