import React from 'react';

const CheckCircleIcon: React.FunctionComponent<{ className?: string }> = ({ className }) => (
    <svg
        className={`h-4 w-4 ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);

export default CheckCircleIcon;
