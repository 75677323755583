import React from 'react';
import { useFormContext } from 'react-hook-form';
import SelectMenu from '../../../../components/SelectMenu/SelectMenu';
import { IValueInputProps } from './QueryFormRow';

const mapItemsToValues = (
    values: string | string[],
    items: { label: string; value: string }[]
): string[] => {
    if (!values || !items?.length) {
        return [];
    }

    const mapValueToLabel = (val: string) => items.find((item) => item.value === val)?.label;
    return values instanceof Array ? values.map(mapValueToLabel) : [mapValueToLabel(values)];
};

const FormMulitSelect: React.FunctionComponent<IValueInputProps> = ({
    name,
    items,
    buttonLabel,
    required,
}) => {
    const { register, watch } = useFormContext();
    const watchValues = watch(`${name}.[]`);
    const checkboxes = items.map(({ label, value }) => (
        <label key={value} className="py-2 pr-4 max-w-xs truncate">
            <input className="mx-4" type="checkbox" value={value} {...register(`${name}.[]`)} />
            {label}
        </label>
    ));

    const selectedInfo = mapItemsToValues(watchValues, items);
    const controlLabel = selectedInfo.length ? selectedInfo.join(', ') : buttonLabel;

    return (
        <div className="w-full bg-red-50">
            <SelectMenu buttonLabel={controlLabel} className="w-full" menuClasses="flex flex-col">
                {checkboxes}
            </SelectMenu>
            {required && (
                <input
                    type="text"
                    className="w-0 h-0 p-0 m-0 border-0 opacity-0"
                    name={name}
                    value={watchValues || ''}
                    required
                    onChange={() => {}}
                />
            )}
        </div>
    );
};

export default FormMulitSelect;
