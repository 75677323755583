import { IBeverageDescriptor, IScoreSheetData } from '@flight-cap/shared';
import React from 'react';

const PrintableDescriptors: React.FunctionComponent<{
    schemaDescriptors: IBeverageDescriptor[];
    scoreDescriptors: IScoreSheetData['descriptors'];
}> = ({ schemaDescriptors, scoreDescriptors }) => {
    const positiveDescriptors = Object.entries(scoreDescriptors || {}).filter(
        ([key, value]) => !!value
    );

    const hasDescriptors = schemaDescriptors?.length && positiveDescriptors.length;

    return hasDescriptors ? (
        <div className="mb-8">
            <label className="inline-block border-b text-lg uppercase border-gray-500 mb-2">
                Entry Descriptors:
            </label>
            <div className="space-y-6">
                {schemaDescriptors
                    .filter((desc) => scoreDescriptors[desc.id] === true)
                    .map(({ title, description }) => (
                        <label className="flex" key={description}>
                            <input
                                type="checkbox"
                                className="mr-2 mt-1.5"
                                style={{ color: '#333' }}
                                checked={true}
                            />
                            <div className="text-lg">
                                <span className="font-bold">{title} </span>
                                <span>{description}</span>
                            </div>
                        </label>
                    ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default PrintableDescriptors;
