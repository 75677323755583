import React from 'react';
import { UserContext } from '../../providers/UserProvider';
import MobileMenu from './MobileMenu';
import UserProfileMenu from './UserProfileMenu';
import MobileMenuToggleButton from './MobileMenuToggleButton';
import SignInUpButton from '../../modules/Auth/SignInUpButtons';
import CompetitionSelect from './CompetitionSelect';
import { ApplicationRoutes } from '../../routes';
import { Link } from '@reach/router';
import PlaneIcon from '../Icon/PlaneIcon';

const buildLabel =
    (
        {
            local: 'Local',
            dev: 'Dev',
        } as Record<string, string>
    )[process.env.REACT_APP_ENVIRONMENT] || '';

const AppLogo: React.FunctionComponent = () => {
    return (
        <>
            <span className="text-indigo-500">
                <PlaneIcon className="h-8 w-8 inline mr-4" />
            </span>
            <span className="text-white">
                Flight Cap
                {buildLabel && (
                    <span className="text-yellow-400 uppercase text-xs mx-2">{buildLabel}</span>
                )}
            </span>
        </>
    );
};

const AppHeader: React.FunctionComponent = () => {
    const { user } = React.useContext(UserContext);
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleMobileMenuItemClicked = () => {
        setMenuOpen(false);
    };

    return (
        <nav className="bg-gray-800">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        {user ? (
                            <>
                                <Link
                                    to={ApplicationRoutes.Dashboard}
                                    className="flex-shrink-0 flex items-center"
                                >
                                    <AppLogo />
                                </Link>
                                <CompetitionSelect />
                            </>
                        ) : (
                            <AppLogo />
                        )}
                    </div>
                    <div className="hidden md:block z-10">
                        <div className="ml-4 flex items-center md:ml-6">
                            {user ? <UserProfileMenu /> : <SignInUpButton />}
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        {user ? (
                            <MobileMenuToggleButton
                                onClick={() => setMenuOpen(!menuOpen)}
                                isOpen={menuOpen}
                            />
                        ) : (
                            <SignInUpButton />
                        )}
                    </div>
                </div>
            </div>

            <MobileMenu isOpen={menuOpen} onItemClicked={handleMobileMenuItemClicked} />
        </nav>
    );
};

export default AppHeader;
