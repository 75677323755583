import { IEntry } from '@flight-cap/shared';
import React from 'react';

const EntryStyleBadge: React.FunctionComponent<{ entry: IEntry; className?: string }> = ({
    entry,
    className,
}) => (
    <span className={`badge-gray badge-sm ${className ? className : ''}`}>
        <span className="truncate">
            {entry.style_snippet.category}
            {entry.style_snippet.sub_category}: {entry.style_snippet.name}
        </span>
    </span>
);

export default EntryStyleBadge;
