import { AwardRank, IAwardRankings } from '@flight-cap/shared';
import React from 'react';

const AwardRankingBadgeList: React.FunctionComponent<{ rankings: IAwardRankings }> = ({
    rankings,
}) => {
    return Object.values(rankings || {}).length ? (
        <div className="flex flex-col">
            {rankings?.[AwardRank.First] && (
                <span className="badge-yellow badge-outline badge-rounded mb-2 w-min">
                    1st: {rankings[AwardRank.First].judging_number}
                </span>
            )}
            {rankings?.[AwardRank.Second] && (
                <span className="badge-green badge-outline badge-rounded mb-2 w-min">
                    2nd: {rankings[AwardRank.Second].judging_number}
                </span>
            )}
            {rankings?.[AwardRank.Third] && (
                <span className="badge-blue badge-outline badge-rounded mb-2 w-min">
                    3rd: {rankings[AwardRank.Third].judging_number}
                </span>
            )}
            {rankings?.[AwardRank.HonorableMention] && (
                <span className="badge-gray badge-outline badge-rounded mb-2 w-min">
                    HM: {rankings[AwardRank.HonorableMention].judging_number}
                </span>
            )}
        </div>
    ) : (
        <span className="border-dashed-gray p-2 text-center">No winners yet</span>
    );
};

export default AwardRankingBadgeList;
