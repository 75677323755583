import { AwardRank, IEntry, IFlightComparisonEntry } from '@flight-cap/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import AllergensBadge from '../../../../components/EntryDetails/AllergensBadge';
import EntryDetails from '../../../../components/EntryDetails/EntryDetails';
import BadgeCheckIcon from '../../../../components/Icon/BadgeCheckIcon';
import SentimentDissatisfiedIcon from '../../../../components/Icon/SentimentDissatisfiedIcon';
import SentimentNeutralIcon from '../../../../components/Icon/SentimentNeutralIcon';
import SentimentSatisfiedIcon from '../../../../components/Icon/SentimentSatisfiedIcon';

const EntryBox: React.FunctionComponent<{
    entry: IEntry;
    onPlaceSubmit: (entryId: IEntry, place: AwardRank) => void;
    data: IFlightComparisonEntry;
}> = ({ entry, onPlaceSubmit, data }) => {
    const { register } = useFormContext();

    const handleRankingSubmit = (evt: React.MouseEvent) => {
        evt.preventDefault();
        const placeSelect = document.getElementById(`${entry.uid}.place`) as HTMLSelectElement;
        onPlaceSubmit(entry, (Number(placeSelect.value) || null) as AwardRank);
    };

    const { eliminated, impression } = data || {};

    let conditionalStyles = '';
    let backgroundStyles = '';

    if (eliminated) {
        backgroundStyles = 'bg-black-stripes border-t-1';
        conditionalStyles = 'border-gray-600 text-gray-100 placeholder-gray-100 placeholder-italic';
    } else if (impression === 'liked') {
        backgroundStyles = 'shadow';
        conditionalStyles = 'border-green-600 text-green-700 placeholder-green-700 placeholder-i';
    } else if (impression === 'meh') {
        backgroundStyles = 'shadow';
        conditionalStyles = 'border-yellow-400 text-yellow-700 placeholder-yellow-700';
    } else if (impression === 'disliked') {
        backgroundStyles = 'shadow';
        conditionalStyles = 'border-red-700 text-red-700 placeholder-red-700';
    } else {
        conditionalStyles = 'text-gray-700';
    }

    return (
        <div
            className={`${conditionalStyles} ${backgroundStyles}  border-t-8 p-4 border rounded text-sm flex flex-col justify-between m-4`}
        >
            <div className="flex flex-row justify-between align-top mb-4">
                <div>
                    <p className="font-bold">{entry.judging_number}</p>
                    <p>
                        {entry.style_snippet.category}
                        {entry.style_snippet.sub_category}: {entry.style_snippet.name}
                    </p>
                    <div className="space-y-2">
                        <EntryDetails entry={entry} condensed />
                        <AllergensBadge entry={entry} />
                    </div>
                </div>
                <label className="flex flex-col items-end" htmlFor={`${entry.uid}.place`}>
                    <span className="mb-1">My Ranking</span>
                    <span className="relative z-0 inline-flex shadow-sm rounded-md h-min">
                        <select
                            className={`block w-full pl-3 pr-9 py-2 ${
                                eliminated ? 'rounded-md' : 'rounded-r-none rounded-l-md '
                            } ${conditionalStyles} border bg-transparent text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                            {...register(`${entry.uid}.place`)}
                            id={`${entry.uid}.place`}
                        >
                            <option></option>
                            <option value={AwardRank.First}>1st Place</option>
                            <option value={AwardRank.Second}>2nd Place</option>
                            <option value={AwardRank.Third}>3rd Place</option>
                            <option value={AwardRank.HonorableMention}>Honorable Mention</option>
                        </select>
                        <button
                            disabled={eliminated}
                            title="Push placement to table"
                            onClick={handleRankingSubmit}
                            className={`${conditionalStyles} ${
                                eliminated ? 'opacity-20' : ''
                            } -ml-px relative inline-flex items-center px-2 py-2 rounded-none rounded-r-md border bg-transparent`}
                        >
                            <BadgeCheckIcon />
                        </button>
                    </span>
                </label>
            </div>

            <div>
                <label htmlFor={`${entry.uid}.place`}>
                    <span className="mb-1">My Notes</span>
                    <textarea
                        className={`${conditionalStyles} w-full mb-4 bg-transparent`}
                        rows={2}
                        placeholder="Your Notes"
                        {...register(`${entry.uid}.notes`)}
                    ></textarea>
                </label>
                <div className="flex items-center justify-between">
                    <label className={`flex flex-col`} htmlFor={`${entry.uid}.impression`}>
                        <span className="mb-1">My Impression</span>
                        <div className="flex space-x-2">
                            <label className="px-1 flex items-center" title="Liked">
                                <input
                                    className="mr-1"
                                    type="radio"
                                    value="liked"
                                    {...register(`${entry.uid}.impression`)}
                                />
                                <SentimentSatisfiedIcon className="h-7 w-7" />
                            </label>
                            <label className="px-1 flex items-center" title="Neutral">
                                <input
                                    className="mr-1"
                                    type="radio"
                                    value="meh"
                                    {...register(`${entry.uid}.impression`)}
                                />
                                <SentimentNeutralIcon className="h-7 w-7" />
                            </label>
                            <label className="px-1 flex items-center" title="Disliked">
                                <input
                                    className="mr-1"
                                    type="radio"
                                    value="disliked"
                                    {...register(`${entry.uid}.impression`)}
                                />
                                <SentimentDissatisfiedIcon className="h-7 w-7" />
                            </label>
                        </div>
                    </label>
                    <label className="flex flex-col items-end" htmlFor={`${entry.uid}.eliminated`}>
                        <span className={`mb-1 ${eliminated ? 'bg-red-600 px-2 mb-2' : ''}`}>
                            Eliminated
                        </span>
                        <input
                            type="checkbox"
                            id={`${entry.uid}.eliminated`}
                            {...register(`${entry.uid}.eliminated`)}
                        ></input>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default EntryBox;
