import React from 'react';
import { IParticipantSnippet } from '@flight-cap/shared';

const CategoryList: React.FunctionComponent<{
    categoryIds: IParticipantSnippet['categories_entered_ids'];
}> = ({ categoryIds = [] }) => {
    if (!categoryIds.length) {
        return <span></span>; //<span className="text-sm text-gray-400 inline-block">No entries</span>;
    }

    return (
        <span className="text-xs uppercase inline-block">
            {categoryIds.map((catId, index) => (
                <span className="mr-1 inline-block" key={catId}>
                    {catId}
                    {index < categoryIds.length - 1 && <span>,</span>}
                </span>
            ))}
        </span>
    );
};

export default CategoryList;
