import { IEntry } from '@flight-cap/shared';
import React from 'react';

const RequiredSpecificInfo: React.FunctionComponent<{ entry: IEntry; className?: string }> = ({
    entry,
    className,
}) =>
    entry.requiredInfo() || entry.specificInfo() || entry.optionalInfo() ? (
        <>
            {entry.requiredInfo() ? <p>{entry.requiredInfo()}</p> : <></>}
            {entry.specificInfo() ? <p>{entry.specificInfo()}</p> : <></>}
            {entry.optionalInfo() ? <p>{entry.optionalInfo()}</p> : <></>}
        </>
    ) : (
        <></>
    );

RequiredSpecificInfo.defaultProps = {
    className: '',
};

export const EntryDetails: React.FunctionComponent<{
    entry: IEntry;
    condensed?: boolean;
}> = ({ entry, condensed }) => {
    return (
        <>
            {condensed ? (
                <>
                    {[entry.strength, entry.sweetness, entry.carbonation]
                        .filter((val) => val)
                        .join(', ')}
                </>
            ) : (
                <>
                    {entry.strength ? (
                        <p>
                            <span className="text-gray-500">Strength:</span> {entry.strength}
                        </p>
                    ) : (
                        <></>
                    )}
                    {entry.sweetness ? (
                        <p>
                            <span className="text-gray-500">Sweetness:</span> {entry.sweetness}
                        </p>
                    ) : (
                        <></>
                    )}
                    {entry.carbonation ? (
                        <p>
                            <span className="text-gray-500">Carbonation:</span> {entry.carbonation}
                        </p>
                    ) : (
                        <></>
                    )}
                </>
            )}
            <RequiredSpecificInfo entry={entry} />
        </>
    );
};

EntryDetails.defaultProps = {
    condensed: false,
};

export default EntryDetails;
