import { containsClass, getAttribute } from '@flight-cap/shared';
import React from 'react';
import { useForm } from 'react-hook-form';
import { addCompetitionParticipant } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import UsersService, { IUserSearchSnippet } from '../../../services/UsersService';
import { Popover } from '@headlessui/react';
import { ParticipantsContext } from '../providers/ParticipantsProvider';

interface ISearchFormData {
    value: string;
}

const UserService = new UsersService();

const ParticipantSearch: React.FunctionComponent = () => {
    const { register, handleSubmit } = useForm<ISearchFormData>();

    const [results, setResults] = React.useState<IUserSearchSnippet[]>([]);

    const { competition } = React.useContext(CompetitionContext);
    const { participants } = React.useContext(ParticipantsContext);

    const participantKeys = participants
        ? participants.reduce((acc, curr) => {
              acc[curr.uid] = true;
              return acc;
          }, {} as Record<string, boolean>)
        : {};

    const popoverButtonRef = React.useRef<HTMLButtonElement>();
    const openedRef = React.useRef<boolean>(false);

    const handleFormSubmit = async (data: ISearchFormData) => {
        const results = await UserService.findUsers(data.value);
        setResults(results);
        openPopover();
    };

    const handleSubmitError = () => {
        setResults([]);
        openPopover();
    };

    const openPopover = () => {
        if (!openedRef.current) {
            popoverButtonRef.current.click();
        }
    };

    const handleResultsListClick = (evt: React.MouseEvent) => {
        if (evt.target instanceof HTMLButtonElement) {
            if (containsClass(evt.target, 'add-user')) {
                const userId = String(getAttribute(evt.target, 'data-user-id'));
                addCompetitionParticipant(competition.uid, userId, []);
            }
        }
    };

    return (
        <div className="text-sm relative">
            <label>Find people to participate in your competition: </label>
            <form onSubmit={handleSubmit(handleFormSubmit, handleSubmitError)}>
                <div className="w-full flex mt-1">
                    <input
                        className="!rounded-r-none"
                        type="text"
                        {...register('value', { required: true })}
                        placeholder="Participant email, name, or BJCP ID"
                    ></input>
                    <button
                        type="submit"
                        className="btn-indigo btn-outline !rounded-none !rounded-r-md -ml-px"
                    >
                        Search
                    </button>
                </div>
            </form>
            <Popover className="relative">
                {({ open }) => {
                    openedRef.current = open;

                    return (
                        <>
                            <Popover.Button
                                className="hidden"
                                ref={popoverButtonRef}
                            ></Popover.Button>
                            <Popover.Panel>
                                <ul
                                    className="w-full mt-2 border p-4 absolute z-10 bg-white shadow-sm"
                                    onClick={handleResultsListClick}
                                >
                                    {results.length ? (
                                        results.map((r) => (
                                            <li
                                                key={r.uid}
                                                className="flex justify-between items-center"
                                            >
                                                {[r.display_name, r.bjcp_id, r.bjcp_rank]
                                                    .filter((v) => !!v)
                                                    .join(', ')}
                                                {participantKeys[r.uid] ? (
                                                    <span className="badge badge-outline badge-condensed badge-rounded">
                                                        Already joined
                                                    </span>
                                                ) : (
                                                    <button
                                                        className="add-user btn-indigo btn-condensed"
                                                        data-user-id={r.uid}
                                                    >
                                                        Add
                                                    </button>
                                                )}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="container-dashed-gray">
                                            Could not find a matching user in Flight Cap
                                        </span>
                                    )}
                                </ul>
                            </Popover.Panel>
                        </>
                    );
                }}
            </Popover>
        </div>
    );
};

export default ParticipantSearch;
