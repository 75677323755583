import { IEntry } from '@flight-cap/shared';
import React from 'react';
import DisqualifiedBadge from '../../components/EntryDetails/DisqualifiedBadge';
import EntryDetails from '../../components/EntryDetails/EntryDetails';
import EntryStyleBadge from '../../components/EntryHeader/EntryStyleBadge';
import { useEntryAllergens } from '../../hooks/useEntryAllergens';

const ClaimEntryItem: React.FunctionComponent<{ entry: IEntry; disabled?: boolean }> = ({
    entry,
    disabled,
}) => {
    const { AllergensBadge } = useEntryAllergens(entry);

    return (
        <div
            className={`flex flex-row justify-between items-center px-4 py-2 ${
                disabled ? 'italic opacity-50' : ''
            }`}
        >
            <div className="flex-grow mr-4">
                <span className="flex flex-col sm:flex-row justify-between">
                    <div className="flex space-x-2">
                        <div className="text-sm">{entry.judging_number}</div>
                        <DisqualifiedBadge entry={entry} condensed />
                    </div>
                    <EntryStyleBadge entry={entry} className="w-min badge-rounded" />
                </span>
                <span className="text-xs mt-1 mb-1">
                    <EntryDetails entry={entry} condensed />
                </span>

                <AllergensBadge />
            </div>
            {disabled ? (
                <></>
            ) : (
                <button
                    className="btn-indigo btn-outline claim-entry-button"
                    data-entry-uid={entry.uid}
                >
                    Claim
                </button>
            )}
        </div>
    );
};

export default ClaimEntryItem;
