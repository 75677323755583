import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { TablesContext } from '../../CompetitionAdmin/providers/TablesProvider';
import { EntriesContext } from '../../CompetitionAdmin/providers/EntriesProvider';
import { FlightsContext } from '../../CompetitionAdmin/providers/FlightsProvider';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import Table from '../../../components/Table/Table';
import { stewardingTableColumns, StewardingTableRow } from './StewardingTableTableColumns';
import { stewardingFlightColumns, StewardingFlightRow } from './StewardingFlightTableColumns';
import { ICustomComparativeFlight } from '@flight-cap/shared';

const StewardingDashboard: React.FunctionComponent<RouteComponentProps> = ({ navigate }) => {
    const { tables } = React.useContext(TablesContext);
    const { flights } = React.useContext(FlightsContext);
    const { entries } = React.useContext(EntriesContext); // could be replaced with an index of entry snippets
    const { competition } = React.useContext(CompetitionContext);

    const stewardingTableRows: StewardingTableRow[] = React.useMemo(
        () =>
            tables
                ? tables.map((t) => ({
                      ...t,
                      entries: entries ? entries.filter((e) => e.table_snippet?.uid === t.uid) : [],
                      downloadSheetsUrl: `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}pdfs/competition/${competition?.uid}/table/${t.uid}`,
                  }))
                : [],
        [competition, entries, tables]
    );

    const stewardingFlightRows: StewardingFlightRow[] = React.useMemo(
        () =>
            flights
                ? flights
                      .filter((f) => f.type === 'custom_comparative')
                      .map((f) => f as ICustomComparativeFlight)
                : [],
        [flights]
    );

    return (
        <>
            <div className="space-y-8">
                <div>
                    <h3 className="text-gray-700 my-2">Tables</h3>
                    <Table columns={stewardingTableColumns} data={stewardingTableRows} />
                </div>
                {stewardingFlightRows.length > 0 && (
                    <div>
                        <h3 className="text-gray-700 my-2">Custom Flights</h3>
                        <Table columns={stewardingFlightColumns} data={stewardingFlightRows} />
                    </div>
                )}
            </div>
        </>
    );
};

export default StewardingDashboard;
