import React from 'react';
import CheckmarkIcon from '../Icon/CheckmarkIcon';
import XIcon from '../Icon/XIcon';
import BaseToggleSwitch, { ToggleContext } from './BaseToggle';

const Toggle: React.FunctionComponent = () => {
    const enabled = React.useContext(ToggleContext);

    return (
        <div
            className={`${
                enabled ? 'bg-indigo-600' : 'bg-gray-200'
            } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 pointer-events-none`}
        >
            <span className="sr-only">Use setting</span>
            <span
                className={`${
                    enabled ? 'translate-x-5' : 'translate-x-0'
                } pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
            >
                <span
                    className={`${
                        enabled
                            ? 'opacity-0 ease-out duration-100'
                            : 'opacity-100 ease-in duration-200'
                    } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
                    aria-hidden="true"
                >
                    <XIcon className="text-gray-400" />
                </span>
                <span
                    className={`${
                        enabled
                            ? 'opacity-100 ease-in duration-200'
                            : 'opacity-0 ease-out duration-100'
                    } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
                    aria-hidden="true"
                >
                    <CheckmarkIcon />
                </span>
            </span>
        </div>
    );
};

const ToggleSwitch = React.forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement>>(
    (props, ref) => {
        return (
            <BaseToggleSwitch {...props} ref={ref}>
                <Toggle />
            </BaseToggleSwitch>
        );
    }
);

export default ToggleSwitch;
