import React from 'react';
import { ICompetition } from '@flight-cap/shared';
import { firestore } from '../../../firebase';

type ICompetitionContext = { competitions: ICompetition[] };

export const CompetitionsContext: React.Context<ICompetitionContext> =
    React.createContext<ICompetitionContext>({
        competitions: null,
    });

/**
 * Provides the competition with the id the corresponds to the :competitionId
 * in the route.
 */
const CompetitionsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [competitions, setCompetitions] = React.useState<ICompetition[]>(null);

    React.useEffect(() => {
        return firestore.collection(`competitions`).onSnapshot((comps) => {
            console.log('CompetitionsProvider - onSnapshot', comps.docs);
            setCompetitions(
                comps.docs.map((comp) => ({ uid: comp.id, ...(comp.data() as ICompetition) }))
            );
        });
    }, []);

    return (
        <CompetitionsContext.Provider value={{ competitions }}>
            {children}
        </CompetitionsContext.Provider>
    );
};

export default CompetitionsProvider;
