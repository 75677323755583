import React from 'react';
import { FlightsContext } from './providers/FlightsProvider';
import { Link } from '@reach/router';
import useActiveMatch from '../../hooks/useActiveMatch';
import { LocalStorageKeys, localStorageRemove } from '@flight-cap/shared';

const FlightMenu: React.FunctionComponent = () => {
    const { flights } = React.useContext(FlightsContext);

    const NavLink = useActiveMatch(Link);

    const handleFlightLinkClicked = React.useCallback(
        () => localStorageRemove(LocalStorageKeys.CustomStyleGuidelineId),
        []
    );

    return (
        <span className="button-group mx-4 md:mx-0 mb-4">
            {flights &&
                flights
                    .filter((flight) => flight.active)
                    // .sort((a, b) => (a.name() > b.name() ? 1 : -1))
                    .sort((a, b) => {
                        return a.type > b.type
                            ? -1
                            : a.type === b.type
                            ? a.name > b.name
                                ? -1
                                : 1
                            : 1;
                    })
                    .map((flight, index) => {
                        return (
                            <NavLink
                                onClick={handleFlightLinkClicked}
                                path={`flight/${flight.uid}/*`}
                                to={`flight/${flight.uid}`}
                                key={flight.uid}
                                type="button"
                                className="button-group-item text-center"
                            >
                                {flight.name()}
                            </NavLink>
                        );
                    })}
        </span>
    );
};

export default FlightMenu;
