import { ApplicationRoutes } from '../routes';

interface INavItem {
    label: string;
    to: string;
}

const navItems = [
    { label: 'Profile', to: ApplicationRoutes.UserProfile },
    { label: 'Dashboard', to: ApplicationRoutes.Dashboard },
];

export const useUserMenu = (): INavItem[] => {
    /**
     * can check permissions and filter here...
     */
    // const { user } = React.useContext(UserContext);

    return navItems;
};
