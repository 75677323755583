import React from 'react';
import { useFormContext } from 'react-hook-form';

const StyleTypeSelect: React.FunctionComponent = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="flex">
            <label htmlFor="new-style-type" className="sr-only">
                Style Type
            </label>
            <select
                className="text-sm"
                id="new-style-type"
                placeholder="Style Type"
                aria-invalid={errors.type ? 'true' : 'false'}
                {...register('type', { required: true })}
            >
                <option disabled value="">
                    Choose a type
                </option>
                <option value={'beer'}>Beer</option>
                <option value={'cider'}>Cider</option>
                <option value={'mead'}>Mead</option>
            </select>
        </div>
    );
};

export default StyleTypeSelect;
