import React from 'react';
import { signInWithGoogle } from '../../firebase';
import GoogleLogo from '../../assets/google_logo.svg';

const GoogleButton: React.FunctionComponent<{ label?: string }> = ({ label }) => {
    return (
        <button className="border p-3 w-full flex items-center" onClick={signInWithGoogle}>
            <img src={GoogleLogo} className="absolute w-6" alt="google-logo" />
            <span className="w-full text-center">{label}</span>
        </button>
    );
};

GoogleButton.defaultProps = {
    label: 'Sign In with Google',
};

export default GoogleButton;
