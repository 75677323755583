import React from 'react';
import {
    ICompetitionRecord,
    IEntryRecord,
    IScoreSheetData,
    ISheetSchema,
} from '@flight-cap/shared';
import EntryInformation from './EntryInformation';
import JudgeInformation from './JudgeInformation';
import ScoresheetBody from './ScoresheetBody';
import SummaryInformation from './SummaryInformation';
import SheetScoringGuide from '../../../components/Scoresheet/SheetScoringGuide';
import PrintableDescriptors from './PrintableDescriptors';
import PrintableFooter from './PrintableFooter';

const PrintableScoresheet = React.forwardRef<
    HTMLDivElement,
    {
        entry: IEntryRecord;
        competition: ICompetitionRecord;
        score: IScoreSheetData;
        schema: ISheetSchema;
        className?: string;
    }
>(({ entry, competition, schema, score, className }, ref) => {
    return score ? (
        <div ref={ref}>
            <div key={score.uid} className={`flex flex-col justify-between ${className} text-lg`}>
                {/* <section className="flex justify-center text-center items-center space-x-4 mb-16"> */}
                <div>
                    <section className="grid grid-cols-10 gap-x-16 mb-16">
                        <div className="col-span-3 flex justify-center">
                            {competition.logo_url ? (
                                <img
                                    alt="competition-logo"
                                    className="w-64 h-64"
                                    src={competition.logo_url}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <header className="text-6xl col-span-6 flex items-center text-center">
                            <h1 className="flex flex-col">
                                <div className="font-bold tracking-wide border-gray-800 border-2 py-1 px-12">
                                    {competition.title}
                                </div>
                                <div className="text-2xl uppercase text-white bg-gray-800 py-2 px-6">
                                    <span className="font-bold">{entry.style_snippet.type}</span>{' '}
                                    <span className="font-thin">Scoresheet</span>
                                </div>
                            </h1>
                        </header>
                    </section>
                    <section className="grid grid-cols-10 gap-x-16">
                        <div className="col-span-3 text-xl">
                            <div className="mt-4 mb-8">
                                <EntryInformation entry={entry} />
                            </div>
                            <div className="mb-8">
                                <SummaryInformation entry={entry} />
                            </div>

                            <div className="mb-8">
                                <JudgeInformation judge={score?.judge_snippet} />
                            </div>

                            <PrintableDescriptors
                                schemaDescriptors={schema.descriptors}
                                scoreDescriptors={score.descriptors}
                            />

                            {schema?.scoringGuide && (
                                <>
                                    <label className="inline-block border-b text-lg uppercase border-gray-500 mb-2">
                                        Scoring Range
                                    </label>
                                    <div className="-ml-1">
                                        <SheetScoringGuide
                                            scoringGuide={schema.scoringGuide}
                                            scoreSheetData={score}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="col-span-7">
                            <ScoresheetBody schema={schema} scoreSheetData={score} />
                        </div>
                    </section>
                </div>
                <PrintableFooter />
            </div>
        </div>
    ) : (
        <p>No scoresheet data provided</p>
    );
});

export default PrintableScoresheet;
