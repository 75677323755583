import React from 'react';

const DefaultErrorBoundaryContent: React.FunctionComponent = () => {
    return (
        <div className="flex justify-center mt-8 max-w-md">
            <div className="p-8 text-red-800 border border-red-800 border-dashed ">
                Whoops, we encountered an error!
            </div>
        </div>
    );
};

export default DefaultErrorBoundaryContent;
