import React from 'react';
import { signOut } from '../../firebase';
import { Link } from '@reach/router';
import { ApplicationRoutes } from '../../routes';
import { useUserMenu } from '../../hooks/useUserMenu';
import { UserContext } from '../../providers/UserProvider';
import DefaultUserPhoto from '../../assets/default_user_photo.svg';
import { Menu } from '@headlessui/react';
import SelectMenu from '../SelectMenu/SelectMenu';

const UserProfileMenu: React.FunctionComponent = () => {
    const menuItems = useUserMenu();

    const handleSignOutClicked = (event: React.MouseEvent) => {
        event.preventDefault();
        signOut();
    };

    const { user } = React.useContext(UserContext);

    const menuButton = (
        <Menu.Button className="flex flex-row items-center p-2">
            <span className="text-white text-sm mr-4">{user.display_name}</span>
            <img
                className="h-8 w-8 rounded-full"
                src={user.photo_url || DefaultUserPhoto}
                alt="user-profile"
            />
        </Menu.Button>
    );

    return (
        <SelectMenu button={menuButton}>
            <div className="px-4 py-3">
                <p className="text-sm leading-5">{user.display_name}</p>
                <p className="text-sm font-medium leading-5 text-gray-500 truncate">{user.email}</p>
            </div>
            {menuItems.map(({ to, label }) => (
                <Menu.Item key={to}>
                    <Link className="menu-item" to={to}>
                        {label}
                    </Link>
                </Menu.Item>
            ))}

            <Menu.Item>
                <a
                    href={ApplicationRoutes.SignOut}
                    onClick={handleSignOutClicked}
                    className="menu-item"
                >
                    Sign out
                </a>
            </Menu.Item>
        </SelectMenu>
    );
};

export default UserProfileMenu;
