import React from 'react';
import { Popover } from '@headlessui/react';

const SimplePanel: React.FunctionComponent<{ content: string }> = ({ content }) => {
    return <p className="text-sm bg-gray-800 text-white p-4 rounded-md opacity-95">{content}</p>;
};

const Tooltip: React.FunctionComponent<{ content: string; children: React.ReactNode }> = ({
    content,
    children,
}) => {
    return (
        <Popover className="flex">
            <Popover.Button className="">{children}</Popover.Button>
            <Popover.Panel className="absolute z-10">
                <SimplePanel content={content} />
            </Popover.Panel>
        </Popover>
    );
};

export default Tooltip;
