import { IFlight } from '@flight-cap/shared';
import React from 'react';
import FlightCategoryList from './FlightCateogoryList';
import FlightIcon from './FlightIcon';

const FlightDescription: React.FunctionComponent<{ flight: IFlight }> = ({ flight }) => {
    return (
        <div className="flex">
            <span className="w-4 h-4">
                <FlightIcon flightType={flight.type} />
            </span>

            <div className="flex flex-col ml-2">
                <span>{flight.name()}</span>
                <FlightCategoryList flight={flight} />
            </div>
        </div>
    );
};

export default FlightDescription;
