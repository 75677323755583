import { IStyle, StyleSummary } from '@flight-cap/shared';
import React from 'react';
import { firestore } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';

type IStylesContext = {
    styles: IStyle[];
};

export const StylesContext: React.Context<IStylesContext> = React.createContext<IStylesContext>({
    styles: null,
});

/**
 * Providers all the flights available to the currently signed in user.
 */
const StylesProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [styles, setStyles] = React.useState<IStyle[]>(null);
    const { competition } = React.useContext(CompetitionContext);

    React.useEffect(() => {
        if (competition?.uid) {
            firestore
                .doc(`competitions/${competition.uid}/styles/summary`)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        const styleSummaryData = doc.data() as StyleSummary;
                        const stylesArray = Object.values(styleSummaryData).sort((a, b) =>
                            a.uid > b.uid ? 1 : -1
                        );

                        setStyles(stylesArray);
                    } else {
                        console.warn('could not find style summary');
                    }
                });
        } else {
            console.log('setting styles to null');
            setStyles(null);
        }
    }, [competition.uid]);

    return <StylesContext.Provider value={{ styles }}>{children}</StylesContext.Provider>;
};

export default StylesProvider;
