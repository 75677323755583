import React from 'react';
import { firestore, timestamp } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import {
    IFlight,
    IFlightRecord,
    ITableScoringFlight,
    generateFlightNames,
    ICustomComparativeFlight,
    EntriesPulledMap,
    IBaseFlightRecord,
    IParticipantSnippet,
    calculateJudgeCoverage,
    getIneligibeJudgeMap,
    getFlightJudgeConflicts,
} from '@flight-cap/shared';
import { TablesContext } from './TablesProvider';
import { FieldValue } from '../flights/QueryFormList/QueryFormRow';
import { SessionsContext } from '../../../providers/SessionsProvider';
import { ParticipantsContext } from './ParticipantsProvider';

type IFlightsContext = {
    flights: IFlight[];
    updateFlight: (id: string, props: Partial<IFlightRecord>) => Promise<void>;
    deleteFlight: (id: string) => Promise<void>;
    addTableScoringFlight: (tableId: string) => Promise<ITableScoringFlight>;
    addCustomComparativeFlight: () => Promise<ICustomComparativeFlight>;
    addSessionToFlight: (flightId: string, sessionId: string) => Promise<void>;
    addJudgeToFlight: (flightId: string, judgeId: string) => Promise<void>;
    removeJudgeFromFlight: (flightId: string, judgeId: string) => Promise<void>;
};

export const FlightsContext: React.Context<IFlightsContext> = React.createContext<IFlightsContext>({
    flights: [],
    updateFlight: null,
    deleteFlight: null,
    addTableScoringFlight: null,
    addCustomComparativeFlight: null,
    addSessionToFlight: null,
    addJudgeToFlight: null,
    removeJudgeFromFlight: null,
});

/**
 * Provides the all the tables corresponding to the currently selected competition.
 */
const FlightsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [flights, setFlights] = React.useState<IFlight[]>([]);

    const { competition } = React.useContext(CompetitionContext);
    const { tables } = React.useContext(TablesContext);
    const { sessions } = React.useContext(SessionsContext);
    const { participants } = React.useContext(ParticipantsContext);

    React.useEffect(() => {
        if (competition?.uid) {
            return firestore
                .collection(`competitions/${competition.uid}/flights`)
                .orderBy('saved_at', 'asc')
                .onSnapshot((flights) => {
                    const flightDataArray = flights.docs.map((flight) => {
                        const flightData = { uid: flight.id, ...(flight.data() as IFlight) };
                        flightData.is_table_flight =
                            flightData.type === 'table_comparative' ||
                            flightData.type === 'table_scoring';

                        if (flightData.judges) {
                            const judgeArray = Object.values(
                                flightData.judges || {}
                            ) as IParticipantSnippet[];
                            flightData.coverage = calculateJudgeCoverage(judgeArray);

                            if (flightData.is_table_flight) {
                                flightData.ineligible_judge_map = getIneligibeJudgeMap(
                                    judgeArray,
                                    (flightData as ITableScoringFlight).table_snippet
                                        ?.category_ids || []
                                );
                                flightData.ineligible_judge_count = Object.keys(
                                    flightData.ineligible_judge_map
                                ).length;
                            }

                            if (flightData.session_id) {
                                flightData.unavailable_judge_ids = judgeArray
                                    .filter((j) => !j.session_ids.includes(flightData.session_id))
                                    .map((j) => j.uid);
                            } else {
                                flightData.unavailable_judge_ids = [];
                            }

                            flightData.judge_conflicts = getFlightJudgeConflicts(
                                flightData,
                                judgeArray
                            );
                            flightData.conflicted_judge_ids = flightData.judge_conflicts.map(
                                (c) => c.judge.uid
                            );
                        }

                        return flightData;
                    });

                    generateFlightNames(flightDataArray);

                    setFlights(
                        // filter out table_comparative flights for which the table is not allowing awards
                        flightDataArray.filter(
                            (f) =>
                                !(
                                    f.type === 'table_comparative' &&
                                    f.table_snippet?.allow_awards === false
                                )
                        )
                    );
                });
        } else {
            setFlights([]);
        }
    }, [competition]);

    const addTableScoringFlight = async (tableId: string) => {
        const table = tables?.find((t) => t.uid === tableId);

        if (!table) {
            console.log('table not found');
            return Promise.reject();
        }

        const flightData: IFlightRecord = {
            type: 'table_scoring',
            judge_ids: [],
            judges: {},
            active: false,
            saved_at: timestamp(),
            table_snippet: {
                ...table,
            },
        };

        const doc = firestore.collection(`competitions/${competition.uid}/flights`).doc();

        await doc.set(flightData, { merge: true });

        return Promise.resolve({ ...flightData, uid: doc.id } as ITableScoringFlight);
    };

    const addCustomComparativeFlight = async () => {
        const flightData: IFlightRecord = {
            type: 'custom_comparative',
            judge_ids: [],
            judges: {},
            active: false,
            saved_at: timestamp(),
            award_rankings: null,
            title: 'My Custom Flight',
            where: [
                {
                    field: FieldValue.Place,
                    comparator: '==',
                    value: 1,
                },
            ],
        };

        const doc = firestore.collection(`competitions/${competition.uid}/flights`).doc();

        await doc.set(flightData, { merge: true });

        const pulledRef = firestore.doc(`competitions/${competition.uid}/indexes/entries_pulled`);
        const pulledData = (await pulledRef.get()).data() as EntriesPulledMap;

        Object.values(pulledData).forEach((e) => (e[doc.id] = false));

        pulledRef.update(pulledData);

        return Promise.resolve({ ...flightData, uid: doc.id } as ICustomComparativeFlight);
    };

    const updateFlight = async (id: string, props: Partial<IFlightRecord>) => {
        if (competition.uid) {
            return firestore.doc(`competitions/${competition.uid}/flights/${id}`).update(props);
        }

        return Promise.reject('no competition id');
    };

    const deleteFlight = (id: string) => {
        return firestore.doc(`competitions/${competition.uid}/flights/${id}`).delete();
    };

    const addSessionToFlight = async (flightId: string, sessionId: string) => {
        const session = sessionId ? sessions.find((s) => s.uid === sessionId) : null;

        const session_snippet = session
            ? {
                  uid: sessionId,
                  title: session.title,
              }
            : null;

        const updates: Partial<IBaseFlightRecord> = {
            session_id: sessionId,
            session_snippet,
        };

        console.log('adding session to flight', flightId, updates);

        return firestore.doc(`competitions/${competition.uid}/flights/${flightId}`).update(updates);
    };

    const updateJudgeInFlightStatus = async (
        flightId: string,
        participantId: string,
        operation: 'add' | 'remove'
    ) => {
        const flight = flights.find((f) => f.uid === flightId);

        if (!flight) {
            console.warn('cannot judge to flight with flight id:', flightId);
            return;
        }

        const updates: Partial<IFlightRecord> = {
            judge_ids: [...flight.judge_ids],
            judges: flight.judges,
        };

        if (operation === 'add' && !flight.judge_ids.includes(participantId)) {
            const judge = participants.find((p) => p.uid === participantId);

            const snippet: IParticipantSnippet = {
                uid: judge.uid,
                display_name: judge.display_name,
                email: judge.email,
                judging: judge.judging || null,
                roles: judge.roles,
                categories_entered: judge.categories_entered,
                categories_entered_ids: judge.categories_entered_ids,
                session_snippets: judge.session_snippets,
                session_ids: judge.session_ids,
            };

            if (judge) {
                updates.judge_ids.push(participantId);
                updates.judges = {
                    ...updates.judges,
                    [participantId]: snippet,
                };
            } else {
                console.warn('cannot add judge, no participant found with id', participantId);
                return;
            }
        }

        if (operation === 'remove') {
            updates.judge_ids = updates.judge_ids.filter((id) => id !== participantId);
            delete updates.judges[participantId];
        }

        return firestore.doc(`competitions/${competition.uid}/flights/${flightId}`).update(updates);
    };

    const addJudgeToFlight = async (flightId: string, participantId: string) => {
        updateJudgeInFlightStatus(flightId, participantId, 'add');
    };

    const removeJudgeFromFlight = async (flightId: string, participantId: string) => {
        updateJudgeInFlightStatus(flightId, participantId, 'remove');
    };

    return (
        <FlightsContext.Provider
            value={{
                flights,
                addTableScoringFlight,
                updateFlight,
                deleteFlight,
                addCustomComparativeFlight,
                addSessionToFlight,
                addJudgeToFlight,
                removeJudgeFromFlight,
            }}
        >
            {children}
        </FlightsContext.Provider>
    );
};

export default FlightsProvider;
