import { IFlight } from '@flight-cap/shared';
import React from 'react';
import FlightDescription from '../../../../components/FlightDescription/FlightDescription';
import DangerIcon from '../../../../components/Icon/DangerIcon';

const FlightListItem: React.FunctionComponent<{ flight: IFlight }> = ({ flight }) => {
    const errorJoin = flight.judge_conflicts.length > 2 ? ', and' : ' and ';
    const errors = flight.judge_conflicts
        .map((c) => `${c.judge.display_name} ${c.errors.join(errorJoin)}`)
        .join('\n');

    return (
        <div
            className={`flex p-2 rounded ${errors ? 'bg-red-50 text-red-800' : ''}`}
            title={errors}
        >
            <FlightDescription flight={flight} />
            {errors.length && (
                <div className="ml-2">
                    <DangerIcon />
                </div>
            )}
        </div>
    );
};

export default FlightListItem;
