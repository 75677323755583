import React from 'react';
import { IScoreInputProps, IScoreSheetData } from '@flight-cap/shared';
import { useFormContext } from 'react-hook-form';
import FormNumberInput from '../Form/FormNumberInput';
import { useLocation } from '@reach/router';

const SheetScoreInput: React.FunctionComponent<
    IScoreInputProps & { scoreSheetData: IScoreSheetData }
> = (props) => {
    const {
        setValue,
        formState: { errors: formErrors },
    } = useFormContext();

    // in the case where we are moving from an entry with a null scoreSheetData to another null scoreSheetData, we want to reset the values
    const { pathname } = useLocation();

    React.useEffect(() => {
        setValue(
            props.element_id,
            Number(props.scoreSheetData?.values?.[props.element_id]) || null
        );
    }, [props.scoreSheetData, props.element_id, setValue, pathname]);

    return props.renderMode === 'pdf' ? (
        <div className="flex items-center mt-2">
            <div className="badge-blue badge-outline mr-2">
                <span className="text-xl text-center px-2 -ml-1">
                    {props.scoreSheetData?.values?.[props.element_id]}
                </span>
            </div>
            out of {props.max_score}
        </div>
    ) : (
        <div className="grid grid-cols-2 gap-4 items-center">
            <FormNumberInput
                name={props.element_id}
                min={0}
                max={props.max_score}
                aria-invalid={formErrors.consensus_score}
                required={props.required}
            />
            <span> out of {props.max_score}</span>
        </div>
    );
};

export default SheetScoreInput;
