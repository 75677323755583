import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IValueInputProps } from './QueryFormRow';

const QueryScoreInput: React.FunctionComponent<IValueInputProps> = ({ name }) => {
    const { register } = useFormContext();

    return (
        <div className="w-20">
            <input
                autoComplete="off"
                type="number"
                min={0}
                max={50}
                {...register(name, {
                    pattern: {
                        value: /^[0-9]+$/,
                        message: 'Please enter a number',
                    },
                    setValueAs: (v: string) => parseInt(v),
                })}
            />
        </div>
    );
};

export default QueryScoreInput;
