import React from 'react';
import { ICompetitionStyle, IStyleSnippet, StyleMigrations } from '@flight-cap/shared';
import { deleteField, firestore } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';

type AddStyleProps = Omit<
    ICompetitionStyle,
    'uid' | 'source' | 'enabled' | 'category' | 'category_name' | 'sub_category'
>;
type UpdateStyleProps = Partial<Omit<ICompetitionStyle, 'uid'>>;

type IStylesContext = {
    styles: ICompetitionStyle[];
    styleMigrations: StyleMigrations;
    addStyle: (props: AddStyleProps) => Promise<ICompetitionStyle>;
    updateStyles: (ids: string[], props: UpdateStyleProps) => Promise<void>;
    deleteStyle: (id: string) => Promise<void>;
    addStyleMigration: (fromStyleId: string, toStyleId: string) => Promise<void>;
    styleSnippets: Map<string, IStyleSnippet>;
};

export const StylesContext: React.Context<IStylesContext> = React.createContext<IStylesContext>({
    styles: null,
    addStyle: null,
    updateStyles: null,
    deleteStyle: null,
    addStyleMigration: null,
    styleMigrations: null,
    styleSnippets: null,
});

/**
 * Provides the all the styles corresponding to the currently selected competition.
 */
const StylesProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [styles, setStyles] = React.useState<ICompetitionStyle[]>(null);
    const [styleMigrations, setStyleMigrations] = React.useState<StyleMigrations>(null);
    const [styleSnippets, setStyleSnippets] = React.useState<Map<string, IStyleSnippet>>(null);

    const { competition } = React.useContext(CompetitionContext);

    React.useEffect(() => {
        firestore
            .doc(`competitions/${competition.uid}/indexes/style_migrations`)
            .onSnapshot((results) => {
                setStyleMigrations({ ...(results.data() as StyleMigrations) });
            });

        return firestore.doc(`competitions/${competition.uid}/styles/summary`).onSnapshot((doc) => {
            const styleSummary = doc.data() as Map<string, ICompetitionStyle>;
            if (!styleSummary) {
                console.log('no styles found for competition');
                return;
            }

            const competitionStyles = Object.values(styleSummary)
                .filter((s) => s.uid) // filter out invalid styles - migrateStyleEntryCount migration script for entry_count added some invalid values in the styles/summary map
                .sort((a, b) => (a.uid > b.uid ? 1 : -1));

            setStyles(competitionStyles);

            const snippetMap = new Map();
            for (const style of competitionStyles) {
                snippetMap.set(style.uid, {
                    uid: style.uid,
                    name: style.name,
                    type: style.type,
                    category: style.category,
                    sub_category: style.sub_category,
                });
            }
            setStyleSnippets(snippetMap);
        });
    }, [competition]);

    const addStyle = async (props: AddStyleProps) => {
        if (competition.uid) {
            // get the custom_styles map, and total up all the custom styles that have been created for this comp
            const customStylesDoc = await firestore
                .doc(`competitions/${competition.uid}/indexes/custom_styles`)
                .get();

            const customCount = Object.keys(customStylesDoc.data() || {}).length;
            const category = 'XCS';
            const sub_category = `0${String(customCount + 1)}`.slice(-2);
            const uid = `${category}${sub_category}`;

            const data = {
                ...props,
                source: 'custom',
                category,
                sub_category,
                uid,
                category_name: 'Custom Style',
                enabled: true,
                deleted: false,
            } as ICompetitionStyle;

            await firestore
                .doc(`competitions/${competition.uid}/styles/summary`)
                .set({ [uid]: data }, { merge: true });

            await customStylesDoc.ref.set({ [uid]: true }, { merge: true });

            return data;
        }
        return Promise.reject('no competition id');
    };

    const updateStyles = async (ids: string[], props: UpdateStyleProps) => {
        if (competition.uid) {
            const updates: Record<string, Partial<ICompetitionStyle>> = {};
            ids.forEach((id) => {
                updates[id] = props;
            });

            firestore
                .doc(`competitions/${competition.uid}/styles/summary`)
                .set(updates, { merge: true });
        }

        return Promise.reject('no competition id');
    };

    const deleteStyle = async (id: string) => {
        return firestore.doc(`competitions/${competition.uid}/styles/summary`).update({
            [id]: deleteField(),
        });
    };

    const addStyleMigration = async (fromStyleId: string, toStyleId: string) => {
        const updates: Record<string, string> = { [fromStyleId]: toStyleId };

        // update any existing entries that point to the 'from' to point to the 'to'
        for (const [from, to] of Object.entries(styleMigrations)) {
            if (to === fromStyleId) {
                updates[from] = toStyleId;
            }
        }

        return firestore
            .doc(`competitions/${competition.uid}/indexes/style_migrations`)
            .set(updates, { merge: true });
    };

    return (
        <StylesContext.Provider
            value={{
                styles,
                addStyle,
                updateStyles,
                deleteStyle,
                styleMigrations,
                addStyleMigration,
                styleSnippets,
            }}
        >
            {children}
        </StylesContext.Provider>
    );
};

export default StylesProvider;
