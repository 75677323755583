import React from 'react';
import { TablesContext } from './providers/TablesProvider';
import TwoColumnCard, {
    LeftColumn,
    LeftColumnTitle,
    RightColumn,
} from '../../components/Card/TwoColumnCard';
import { CompetitionContext } from '../../providers/CompetitionProvider';
import { Link, RouteComponentProps } from '@reach/router';
import { EntriesContext } from './providers/EntriesProvider';
import { FlightsContext } from './providers/FlightsProvider';

const CompetitionAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const { tables } = React.useContext(TablesContext);
    const { entries } = React.useContext(EntriesContext);
    const { flights } = React.useContext(FlightsContext);
    const { competition, resolved } = React.useContext(CompetitionContext);

    const entryListItems = React.useMemo(() => {
        const counts = {
            unassigned: 0,
            assigned: 0,
            total: entries?.length || 0,
            scored: 0,
            unscored: 0,
        };
        entries?.forEach((entry) => {
            counts[entry.table_snippet ? 'assigned' : 'unassigned']++;
            counts[Object.keys(entry?.score_snippets || {}).length ? 'scored' : 'unscored']++;
        });
        return counts;
    }, [entries]);

    const flightListItems = React.useMemo(() => {
        const counts = {
            tableScoring: 0,
            tableScoringActive: 0,
            miniBos: 0,
            miniBosActive: 0,
            custom: 0,
            customActive: 0,
        };

        flights?.forEach((flight) => {
            if (flight.type === 'table_scoring') {
                counts.tableScoring++;
                if (flight.active) {
                    counts.tableScoringActive++;
                }
            }

            if (flight.type === 'table_comparative') {
                counts.miniBos++;
                if (flight.active) {
                    counts.miniBosActive++;
                }
            }

            if (flight.type === 'custom_comparative') {
                counts.custom++;
                if (flight.active) {
                    counts.customActive++;
                }
            }
        });
        return counts;
    }, [flights]);

    return resolved ? (
        <>
            <h1 className="text-indigo-700 text-2xl mb-8">{competition?.title} Adminsitration</h1>
            <div className="space-y-12">
                <TwoColumnCard>
                    <LeftColumn>
                        <LeftColumnTitle
                            title="Participants"
                            description="People involved in this competition."
                        />
                    </LeftColumn>
                    <RightColumn>
                        <div className="sm:text-right">
                            <Link to={'./participants'} className="w-full sm:w-auto btn-indigo">
                                Manage Participants
                            </Link>
                        </div>
                    </RightColumn>
                </TwoColumnCard>
                <TwoColumnCard>
                    <LeftColumn>
                        <LeftColumnTitle
                            title="Tables"
                            description="Award categories for entries."
                        />
                    </LeftColumn>
                    <RightColumn>
                        <div className="md:grid md:grid-cols-1">
                            <ul className="space-y-1" aria-label="table summary list">
                                {tables?.map((table) => (
                                    <li
                                        key={table.uid}
                                        className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                                    >
                                        <span className="truncate">{table?.title}</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {
                                                entries?.filter(
                                                    (entry) =>
                                                        entry.table_snippet?.uid === table.uid
                                                ).length
                                            }{' '}
                                            Entries
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="sm:text-right">
                            <Link to={'./tables'} className="w-full sm:w-auto btn-indigo">
                                Manage Tables
                            </Link>
                        </div>
                    </RightColumn>
                </TwoColumnCard>

                <TwoColumnCard>
                    <LeftColumn>
                        <LeftColumnTitle
                            title="Entries"
                            description="Submissions to be judged and ranked."
                        />
                    </LeftColumn>
                    <RightColumn>
                        <div className="md:grid md:grid-cols-1">
                            <div className="flex flex-col space-y-8">
                                <ul className="space-y-1" aria-label="table summary list">
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Assigned</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {entryListItems.assigned} Entries
                                        </span>
                                    </li>
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Unassigned</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {entryListItems.unassigned} Entries
                                        </span>
                                    </li>
                                    <hr />
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Total</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {entryListItems.total} Entries
                                        </span>
                                    </li>
                                </ul>
                                <ul className="space-y-1" aria-label="table summary list">
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Scored</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {entryListItems.scored} Entries
                                        </span>
                                    </li>
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Unscored</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {entryListItems.unscored} Entries
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="sm:text-right">
                            <Link to={'./entries'} className="w-full sm:w-auto btn-indigo">
                                Manage Entries
                            </Link>
                        </div>
                    </RightColumn>
                </TwoColumnCard>

                <TwoColumnCard>
                    <LeftColumn>
                        <LeftColumnTitle
                            title="Flights"
                            description="Judge groupings containing an assignment of entries."
                        />
                    </LeftColumn>
                    <RightColumn>
                        <div className="space-y-12">
                            <div className="flex flex-col space-y-8">
                                <ul className="space-y-1" aria-label="table summary list">
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Active Table Scoring</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.tableScoringActive} Flights
                                        </span>
                                    </li>
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Inactive Table Scoring</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.tableScoring -
                                                flightListItems.tableScoringActive}{' '}
                                            Flights
                                        </span>
                                    </li>
                                    <hr />
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Total</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.tableScoring} Table Scoring Flights
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col space-y-8">
                                <ul className="space-y-1" aria-label="table summary list">
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Active Mini-Bos</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.miniBosActive} Flights
                                        </span>
                                    </li>
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Inactive Mini-Bos</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.miniBos -
                                                flightListItems.miniBosActive}{' '}
                                            Flights
                                        </span>
                                    </li>
                                    <hr />
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Total</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.miniBos} Mini-Bos Flights
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex flex-col space-y-8">
                                <ul className="space-y-1" aria-label="table summary list">
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Active Custom Flights</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.customActive} Flights
                                        </span>
                                    </li>
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Inactive Custom Flights</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.custom - flightListItems.customActive}{' '}
                                            Flights
                                        </span>
                                    </li>
                                    <hr />
                                    <li className="text-gray-600 w-full justify-between hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md">
                                        <span className="truncate">Total</span>
                                        <span className="bg-gray-100 group-hover:bg-gray-200 ml-auto inline-block py-0.5 px-3 text-xs rounded-full text-gray-600">
                                            {flightListItems.custom} Custom Flights
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="sm:text-right">
                                <Link to={'./flights'} className="w-full sm:w-auto btn-indigo">
                                    Manage Flights
                                </Link>
                            </div>
                        </div>
                    </RightColumn>
                </TwoColumnCard>

                <TwoColumnCard>
                    <LeftColumn>
                        <LeftColumnTitle
                            title="Styles"
                            description="A grouping for entries with a shared set of traits. Styles are assigned to tables, and
                        the collective entries for a table's styles are judging against each
                        other for medals."
                        />
                    </LeftColumn>
                    <RightColumn>
                        <div className="sm:text-right">
                            <Link to={'./styles'} className="w-full sm:w-auto btn-indigo">
                                Manage Styles
                            </Link>
                        </div>
                    </RightColumn>
                </TwoColumnCard>

                <TwoColumnCard>
                    <LeftColumn>
                        <LeftColumnTitle
                            title="Sessions"
                            description="A grouping of flights and available judges for organizing differnt judging events"
                        />
                    </LeftColumn>
                    <RightColumn>
                        <div className="sm:text-right">
                            <Link to={'./sessions'} className="w-full sm:w-auto btn-indigo">
                                Manage Sessions
                            </Link>
                        </div>
                    </RightColumn>
                </TwoColumnCard>
            </div>
        </>
    ) : (
        <></>
    );
};

export default CompetitionAdmin;
