import { IFlight, ISession } from '@flight-cap/shared';
import React from 'react';
import FlightDescription from '../../../components/FlightDescription/FlightDescription';
import { MyColumn } from '../../../components/Table/react-table-config';
import ToggleSwitch from '../../../components/Toggle/ToggleSwitch';

export interface ISessionAvailabilityRow extends ISession {
    isAvailable: boolean;
    flightsAssigned: IFlight[];
}

export const sessionsTableColumns: MyColumn<ISessionAvailabilityRow>[] = [
    {
        Header: 'Session',
        accessor: (d) => d.name(),
        sortType: (a, b) => {
            const titleA = a.original.name()?.toLocaleLowerCase();
            const titleB = b.original.name()?.toLocaleLowerCase();
            return titleA > titleB ? 1 : -1;
        },
        sticky: 'left',
    },
    {
        Header: 'Location',
        accessor: (d) =>
            d.map_link ? (
                <a href={d.map_link} target="_map" key={d.uid}>
                    {d.location}
                </a>
            ) : (
                d.location
            ),
        minWidth: 150,
        maxWidth: 250,
    },
    {
        Header: 'Time',
        accessor: (d) =>
            d.start_date ? new Date(d.start_date).toLocaleString('en-US') : 'No Time Set',
    },
    {
        Header: 'Available',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-switch toggle-session-availablity"
                data-session-id={d.uid}
                checked={d.isAvailable}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
    {
        Header: 'Flights Assigned',
        accessor: (d) => {
            if (d.flightsAssigned.length === 0) {
                return <p className="text-gray-600">No flights assigned</p>;
            }

            return (
                <ul className="space-y-4">
                    {d.flightsAssigned.map((f) => {
                        return (
                            <li className="text-xs flex" key={f.uid}>
                                <FlightDescription flight={f} />
                            </li>
                        );
                    })}
                </ul>
            );
        },
        minWidth: 150,
        maxWidth: 200,
    },
];
