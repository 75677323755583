import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { EntriesContext } from '../providers/EntriesProvider';
import { ScoresheetsContext } from '../providers/ScoresheetsProvider';
import AdminScoresheet from './AdminScoresheet';

const EntryDetail: React.FunctionComponent<RouteComponentProps<{ entryId: string }>> = ({
    entryId,
}) => {
    const { competition } = React.useContext(CompetitionContext);
    const { entries } = React.useContext(EntriesContext);
    const { scoresheets } = React.useContext(ScoresheetsContext);

    const { entry, scores } = React.useMemo(
        () => ({
            entry: entries?.find((e) => e.uid === entryId),
            scores: scoresheets?.filter((s) => s.entry_id === entryId),
        }),
        [entries, entryId, scoresheets]
    );

    return entry && scores.length ? (
        <>
            <h1 className="text-xl mb-4">Scoresheets</h1>
            {scores.map((score) => (
                <AdminScoresheet
                    key={score.uid}
                    entry={entry}
                    score={score}
                    competition={competition}
                />
            ))}
        </>
    ) : (
        <>no score sheets</>
    );
};

export default EntryDetail;
