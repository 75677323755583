import React from 'react';
import { firestore } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import {
    IEntry,
    IEntryRecord,
    chunkArray,
    ITable,
    decorateWithEntryInfoGetters,
    EntriesPulledMap,
} from '@flight-cap/shared';
import { SheetSchemasContext } from '../../Competition/providers/SheetSchemasProvider';
import { StylesContext } from './StylesProvider';

type IEntriesContext = {
    entries: IEntry[];
    entriesPulled: EntriesPulledMap;
    updateEntryPulledStatus: (entryId: string, key: string, pulled: boolean) => Promise<void>;
    addEntries: (records: IEntryRecord[], tables: ITable[]) => Promise<void[]>;
    updateEntries: (ids: string[], updates: Partial<IEntryRecord>[]) => Promise<void>;
    deleteEntry: (id: string) => Promise<void>;
    deleteEntries: (ids: string[]) => Promise<void[]>;
    getEntryDoc: (id: string) => firebase.default.firestore.DocumentReference<IEntryRecord>;
};

export const EntriesContext: React.Context<IEntriesContext> = React.createContext<IEntriesContext>({
    entries: null,
    entriesPulled: null,
    updateEntryPulledStatus: null,
    addEntries: null,
    updateEntries: null,
    deleteEntry: null,
    deleteEntries: null,
    getEntryDoc: null,
});

/**
 * Provides the all the tables corresponding to the currently selected competition.
 */
const EntriesProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [entries, setEntries] = React.useState<IEntry[]>(null);
    const [entriesPulled, setEntriesPulled] = React.useState<EntriesPulledMap>(null);

    const { competition } = React.useContext(CompetitionContext);
    const { schemas } = React.useContext(SheetSchemasContext);
    const { styleSnippets, styleMigrations } = React.useContext(StylesContext);

    React.useEffect(() => {
        if (competition?.uid) {
            const pulledUnsubscribe = firestore
                .doc(`competitions/${competition.uid}/indexes/entries_pulled`)
                .onSnapshot((doc) => {
                    const pulled = doc.data() as EntriesPulledMap;
                    setEntriesPulled(pulled);
                });

            const entriesUnsubscribe = firestore
                .collection(`competitions/${competition.uid}/entries`)
                .orderBy('judging_number', 'asc')
                .onSnapshot((entries) => {
                    setEntries(
                        entries.docs.map((entryDoc) => {
                            const entryData = entryDoc.data() as IEntryRecord;

                            // abstract this
                            const sheet_schema =
                                schemas?.find(
                                    (schema) => schema.uid === entryData.sheet_schema_id
                                ) ||
                                schemas?.find(
                                    (schema) => schema.uid === competition?.default_sheet_schema
                                ) ||
                                schemas?.find((schema) => schema.uid === 'default_schema');

                            const entry = {
                                uid: entryDoc.id,
                                ...entryData,
                                sheet_schema,
                                disqualified: entryData.disqualified === true,
                                table_override_id: entryData.table_override_id
                                    ? entryData.table_override_id
                                    : null,
                            } as IEntry;

                            return decorateWithEntryInfoGetters(entry);
                        })
                    );
                });

            return () => {
                entriesUnsubscribe();
                pulledUnsubscribe();
            };
        } else {
            setEntries([]);
        }
    }, [competition, schemas]);

    const addEntries = async (entries: IEntryRecord[], tables: ITable[]): Promise<void[]> => {
        const entriesRef = firestore.collection(`competitions/${competition.uid}/entries`);
        const chunks = chunkArray(entries, 500);

        const promises = chunks.map((entryChunk) => {
            const batch = firestore.batch();
            entryChunk.forEach((entry) => {
                let newStyleId = styleMigrations[entry.style_snippet.uid];
                if (newStyleId) {
                    const migratedSnippet = styleSnippets.get(newStyleId);
                    if (migratedSnippet) {
                        entry.style_snippet = migratedSnippet;
                        newStyleId = styleMigrations[migratedSnippet.uid];
                    } else {
                        console.log('could not find migration snippet');
                    }
                }

                const table = tables.find((t) => t.categories[entry.style_snippet.uid]);
                if (table) {
                    entry.table_snippet = {
                        ...table,
                    };
                }
                const doc = entriesRef.doc();
                batch.set(doc, entry);
            });
            return batch.commit();
        });

        return Promise.all(promises);
    };

    const deleteEntries = async (ids: string[]): Promise<void[]> => {
        const entriesRef = firestore.collection(`competitions/${competition.uid}/entries`);
        const chunks = chunkArray(ids, 500);
        const promises = chunks.map((entryChunk) => {
            const batch = firestore.batch();
            entryChunk.forEach((entryId) => {
                batch.delete(entriesRef.doc(entryId));
            });
            return batch.commit();
        });
        return Promise.all(promises);
    };

    const updateEntries = async (ids: string[], updates: Partial<IEntryRecord>[]) => {
        if (ids.length !== updates.length) {
            throw new Error('length mismatch in ids and updates array');
        }

        const batch = firestore.batch();

        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            const update = updates[i];
            const entryDocRef = firestore.doc(`competitions/${competition.uid}/entries/${id}`);
            batch.update(entryDocRef, update);
        }

        return batch.commit();
    };

    const deleteEntry = (id: string) => {
        return firestore.doc(`competitions/${competition.uid}/entries/${id}`).delete();
    };

    const getEntryDoc = (id: string) => {
        return firestore.doc(
            `competitions/${competition.uid}/entries/${id}`
        ) as firebase.default.firestore.DocumentReference<IEntryRecord>;
    };

    const updateEntryPulledStatus = (entryId: string, key: string, pulled: boolean) => {
        const curr = entriesPulled?.[entryId] || {};

        return firestore
            .doc(`competitions/${competition.uid}/indexes/entries_pulled`)
            .set({ [entryId]: { ...curr, [key]: pulled } }, { merge: true });
    };

    return (
        <EntriesContext.Provider
            value={{
                entries,
                entriesPulled,
                updateEntryPulledStatus,
                updateEntries,
                deleteEntry,
                addEntries,
                deleteEntries,
                getEntryDoc,
            }}
        >
            {children}
        </EntriesContext.Provider>
    );
};

export default EntriesProvider;
