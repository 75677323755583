import React from 'react';
import { IParticipantSnippet } from '@flight-cap/shared';

const JudgeCertifications: React.FunctionComponent<{
    judging: IParticipantSnippet['judging'];
    asDots?: boolean;
}> = ({ judging, asDots = false }) => {
    if (!judging || !judging.other_status) {
        return null;
    }

    let mead, cider, pro, other;

    if (judging.other_status.mead_judge) {
        mead = (
            <span title="BJCP Mead Judge">
                {asDots ? (
                    <svg
                        className="h-3 w-3 mr-1 text-yellow-300"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                    >
                        <circle cx={4} cy={4} r={3} stroke="#c7aa27" strokeWidth={0.1} />
                    </svg>
                ) : (
                    <span className="badge-yellow badge-rounded !text-xs !px-1 !py-0">Mead</span>
                )}
            </span>
        );
    }

    if (judging.other_status.cider_judge) {
        cider = (
            <span title="BJCP Cider Judge">
                {asDots ? (
                    <svg
                        className="h-3 w-3 mr-1 text-green-400"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                    >
                        <circle cx={4} cy={4} r={3} stroke="#0d570e" strokeWidth={0.1} />
                    </svg>
                ) : (
                    <span className="badge-green badge-rounded !text-xs !px-1 !py-0">Cider</span>
                )}
            </span>
        );
    }

    const proTitles = [];
    if (judging.other_status.professional_brewer) {
        proTitles.push('Professional Brewer');
    }
    if (judging.other_status.professional_meadmaker) {
        proTitles.push('Professional Meadmaker');
    }
    if (judging.other_status.professional_cidermaker) {
        proTitles.push('Professional Cidermaker');
    }

    if (proTitles.length) {
        pro = (
            <span title={proTitles.join(', ')}>
                {asDots ? (
                    <svg
                        className="h-3 w-3 mr-1 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                    >
                        <circle cx={4} cy={4} r={3} stroke="#555" strokeWidth={0.1} />
                    </svg>
                ) : (
                    <span className="badge-gray badge-rounded !text-xs !px-1 !py-0">Pro</span>
                )}
            </span>
        );
    }

    const otherTitles = [];

    if (judging.other_status.cicerone) {
        otherTitles.push('Cicerone');
    }
    if (judging.other_status.sommelier) {
        otherTitles.push('Sommelier');
    }
    if (judging.other_status.sensory_training) {
        otherTitles.push('Sensory Training');
    }

    if (otherTitles.length) {
        other = (
            <span title={otherTitles.join(', ')}>
                <svg
                    className={`h-3 w-3 mr-1 text-gray-100 ${asDots ? '' : 'mt-[2px]'}`}
                    fill="currentColor"
                    viewBox="0 0 8 8"
                >
                    <circle cx={4} cy={4} r={3} stroke="#888" strokeWidth={0.1} />
                </svg>
            </span>
        );
    }

    return (
        <span className="flex items-center">
            {mead}
            {cider}
            {pro}
            {other}
        </span>
    );
};

export default JudgeCertifications;
