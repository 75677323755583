import { useDroppable } from '@dnd-kit/core';
import { IFlight, isJudgeAllowedInFlight } from '@flight-cap/shared';
import React from 'react';
import { SessionInspectionContext } from '../SessionInspectionProvider';
import {
    DraggableStatusVariant,
    SessionEditorDragType,
    SessionEditorDropActions,
} from '../sessions/SessionEditor';
import { IDraggableJudgeData } from './DraggableJudge';

export interface IJudgeDropZoneData {
    accepts: SessionEditorDragType.Participant;
    action: SessionEditorDropActions.AddJudgeToFlight;
    flight: IFlight;
}

const JudgeDropZone: React.FunctionComponent<{
    flight: IFlight;
    children: React.ReactNode;
    className?: string;
}> = ({ flight, children, className = '' }) => {
    const flightId = flight?.uid || null;
    const { isOver, setNodeRef, active } = useDroppable({
        id: `dropzone-judge-${flightId}`,
        data: {
            accepts: SessionEditorDragType.Participant,
            action: SessionEditorDropActions.AddJudgeToFlight,
            flight,
        } as IJudgeDropZoneData,
    });

    const { inspectedParticipant } = React.useContext(SessionInspectionContext);

    const variant: DraggableStatusVariant =
        inspectedParticipant && !isJudgeAllowedInFlight(inspectedParticipant, flight)
            ? 'warning'
            : 'idle';

    const participant = (active?.data.current as IDraggableJudgeData)?.participant;

    // if no participant, disable drop zone
    let ref = participant ? setNodeRef : null;

    // if not accepted drag data type, disabled drop zone
    if (ref && active?.data.current.type !== SessionEditorDragType.Participant) {
        ref = null;
    }

    // if participant is not in the session; canceling a drop of a currently assigned, but unavailble judge will not move them
    if (
        ref &&
        flight.session_id &&
        participant.session_ids &&
        !participant.session_ids?.includes(flight.session_id)
    ) {
        ref = null;
    }

    // if already in flight, disable drop zone
    if (ref && flight.judge_ids.includes(participant?.uid)) {
        ref = null;
    }

    return (
        <div
            ref={ref}
            className={`${className} transition-all ${isOver ? 'border drop-zone-allowed' : ''} ${
                variant === 'warning' ? 'bg-yellow-stripes !border-yellow-800 !text-yellow-800' : ''
            }`}
        >
            {children}
        </div>
    );
};

export default JudgeDropZone;
