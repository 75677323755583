import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { StylesContext } from '../providers/StylesProvider';

const QuickEnable: React.FunctionComponent<RouteComponentProps> = () => {
    const { styles, updateStyles } = React.useContext(StylesContext);

    const handleEnableAllClicked = React.useCallback(() => {
        const type = (document.getElementById('enable-type-select') as HTMLSelectElement)?.value;
        const ids = styles.filter((s) => s.type === type && !s.enabled).map((s) => s.uid);
        updateStyles(ids, { enabled: true });
    }, [styles, updateStyles]);

    const handleDisableAllClicked = React.useCallback(() => {
        const type = (document.getElementById('enable-type-select') as HTMLSelectElement)?.value;
        const ids = styles.filter((s) => s.type === type && s.enabled).map((s) => s.uid);
        updateStyles(ids, { enabled: false });
    }, [styles, updateStyles]);

    return (
        <div className="relative z-0 inline-flex shadow-sm rounded-md h-min w-full">
            <select className="rounded-r-none" id="enable-type-select">
                <option value={'beer'}>Beer</option>
                <option value={'cider'}>Cider</option>
                <option value={'mead'}>Mead</option>
            </select>
            <button
                onClick={handleEnableAllClicked}
                className="-ml-px text-indigo-700 relative inline-flex items-center justify-center px-2 py-2 rounded-none border border-gray-300 bg-white w-48"
            >
                Enable All
            </button>
            <button
                onClick={handleDisableAllClicked}
                className="-ml-px text-indigo-700 relative inline-flex items-center justify-center px-2 py-2 rounded-none rounded-r-md border border-gray-300 bg-white w-48"
            >
                Disable All
            </button>
        </div>
    );
};

export default QuickEnable;
