import React from 'react';
import { RouteComponentProps, Router, Redirect, Link } from '@reach/router';
import FlightsProvider from './providers/FlightsProvider';
import JudgingDashboard from './JudgingDashboard';
import SheetSchemasProvider from './providers/SheetSchemasProvider';
import EntryProvider from './providers/EntryProvider';
import CompetitionAdminRoute from '../CompetitionAdmin/CompetitionAdminRoute';
import { parameterizeEndpoint } from '@flight-cap/shared';
import { ApplicationRoutes } from '../../routes';
import CompetitionAdminWrapper from '../CompetitionAdmin/CompetitionAdminWrapper';
import { UserContext } from '../../providers/UserProvider';
import CompetitionJudgingRoute from '../CompetitionAdmin/CompetitionJudgingRoute';
import CompetitionJoin from './CompetitionJoin';
import { CompetitionContext } from '../../providers/CompetitionProvider';
import useActiveMatch from '../../hooks/useActiveMatch';
import FlightEntriesProvider from './providers/FlightEntries/FlightEntriesProvider';
import StylesProvider from './providers/StylesProvider';
import CompetitionInformation from './CompetitionInformation/CompetitionInformation';
import CompetitionStewardingRoute from '../CompetitionAdmin/CompetitionStewardingRoute';
import CompetitionStewardingWrapper from './Stewarding/StewardingWrapper';
import SessionsProvider from '../../providers/SessionsProvider';
import CompetitionParticipantProvider from '../../providers/CompetitionParticipantProvider';

const Competition: React.FunctionComponent<
    RouteComponentProps<{ competitionSlug: string } & { children: React.ReactNode }>
> = ({ competitionSlug }) => {
    const { user } = React.useContext(UserContext);
    const { competition, resolved: competitionResolved } = React.useContext(CompetitionContext);

    const hasJoinedCompetition = !!user?.competitions?.[competition?.uid];

    const isCompetitionAdmin = user?.competitions?.[competition?.uid]?.roles?.includes('admin');

    const isJudge = user?.competitions?.[competition?.uid]?.roles?.includes('judge');

    const isSteward = user?.competitions?.[competition?.uid]?.roles?.includes('steward');

    const NavLink = useActiveMatch(Link);

    const menuItems = [
        <NavLink
            key="./info/*"
            path="./info/*"
            to="./info"
            className="text-sm font-medium px-4 py-2 text-green-700 active-underline border-green-700"
        >
            Competition Info
        </NavLink>,
    ];

    if (isCompetitionAdmin) {
        menuItems.push(
            <NavLink
                key="./admin/*"
                path="./admin/*"
                to="./admin"
                className="text-sm font-medium px-4 py-2 text-green-700 active-underline border-green-700"
            >
                Admin
            </NavLink>
        );
    }

    if (isSteward) {
        menuItems.push(
            <NavLink
                key="./stewarding/*"
                path="./stewarding/*"
                to="./stewarding"
                className="text-sm font-medium px-4 py-2 text-green-700 active-underline border-green-700"
            >
                Stewarding
            </NavLink>
        );
    }

    if (isJudge) {
        menuItems.push(
            <NavLink
                key="./judging/*"
                path="./judging/*"
                to="./judging"
                className="text-sm font-medium px-4 py-2 text-green-700 active-underline border-green-700"
            >
                Judging
            </NavLink>
        );
    }

    if (competition?.archived && competition?.created_by !== user.uid) {
        return (
            <div className="flex justify-center">
                <div className="container-dashed-gray mt-12 max-w-md">
                    <div className="space-y-4">
                        <p>
                            Hi there! <span className="font-bold">{competition.title}</span> has
                            been archived by the owner!{' '}
                        </p>
                        <p>
                            Head over the <Link to={ApplicationRoutes.Dashboard}>dashboard</Link>{' '}
                            and join another competition.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return competition && competitionResolved ? (
        <>
            {competition.archived && (
                <p className="bg-yellow-300 text-yellow-800 text-center p-4 sticky top-0">
                    This competition is archived
                </p>
            )}
            <StylesProvider>
                <SheetSchemasProvider>
                    <FlightsProvider>
                        <EntryProvider>
                            <CompetitionParticipantProvider>
                                <SessionsProvider>
                                    <FlightEntriesProvider competitionId={competition.uid}>
                                        {menuItems.length > 0 && (
                                            <div className="bg-green-100 px-4">{menuItems}</div>
                                        )}
                                        <Router className="main-content-wrapper">
                                            <CompetitionAdminRoute
                                                as={CompetitionAdminWrapper}
                                                path="/admin/*"
                                            />
                                            <CompetitionJudgingRoute
                                                as={JudgingDashboard}
                                                path="/judging/*"
                                            />
                                            <CompetitionStewardingRoute
                                                as={CompetitionStewardingWrapper}
                                                path="/stewarding/*"
                                            />
                                            <CompetitionJoin path="/join/*" />
                                            <CompetitionInformation path="/info/*" />
                                            <Redirect
                                                from="/"
                                                to={parameterizeEndpoint(
                                                    isCompetitionAdmin
                                                        ? ApplicationRoutes.AdminDashboard
                                                        : hasJoinedCompetition
                                                        ? ApplicationRoutes.CompetitionInfo
                                                        : ApplicationRoutes.JoinCompetition,
                                                    {
                                                        competitionSlug,
                                                    }
                                                )}
                                                default
                                                noThrow
                                            />
                                        </Router>
                                    </FlightEntriesProvider>
                                </SessionsProvider>
                            </CompetitionParticipantProvider>
                        </EntryProvider>
                    </FlightsProvider>
                </SheetSchemasProvider>
            </StylesProvider>
        </>
    ) : (
        <div className="flex justify-center">
            <p className="container-dashed-gray mt-12 max-w-md">Loading Competition...</p>
        </div>
    );
};

export default Competition;
