import React from 'react';
import { ISession } from '@flight-cap/shared';

const SessionLocation: React.FunctionComponent<{ session: ISession }> = ({
    session: { map_link, location },
}) => {
    if (!location && !map_link) {
        return <span className="text-gray-400">Location</span>;
    }

    if (!map_link) {
        return <>{location}</>;
    }

    return (
        <span>
            <span>{location ? `${location} ` : null}</span>
            {map_link && (
                <a target={'_map'} href={map_link}>
                    Map
                </a>
            )}
        </span>
    );
};

export default SessionLocation;
