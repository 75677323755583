import React from 'react';
import { useForm } from 'react-hook-form';
import { DescriptorChangedHandler } from './FlightDetail/FlightDetail';
import { EntryContext } from './providers/EntryProvider';

const DescriptorForm: React.FunctionComponent<{
    onDescriptorChanged: DescriptorChangedHandler;
}> = ({ onDescriptorChanged }) => {
    const { entry, scoreSheetData } = React.useContext(EntryContext);
    const items = entry?.sheet_schema?.descriptors || [];

    const { register } = useForm({
        defaultValues: scoreSheetData?.descriptors,
    });

    if (!items.length) {
        return (
            <div className="container-dashed-gray text-center">
                No descriptors available. Try selecting an entry from the Flight section, and then
                check back here.
            </div>
        );
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        onDescriptorChanged(evt.target.name, evt.target.checked);
    };

    return (
        <form className="pl-1">
            <p className="text-sm text-center mb-4">
                Addtional descriptors that may apply to the entry
            </p>
            <div className="space-y-6 text-sm">
                {items.map(({ id, title, description }) => (
                    <div key={id}>
                        <label className="flex">
                            <input
                                type="checkbox"
                                className="mr-4 mt-0.5"
                                {...register(id, { onChange: handleChange })}
                            />
                            <div>
                                <span className="font-bold">{title} </span>
                                <span>{description}</span>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
        </form>
    );
};

export default DescriptorForm;
