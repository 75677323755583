import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Table from '../../../components/Table/Table';
import { ParticipantsContext } from '../providers/ParticipantsProvider';
import {
    containsClass,
    getAttribute,
    ICompetitionParticipant,
    IFlight,
    ISession,
} from '@flight-cap/shared';
import { columns } from './ParticipantsTableColumns';
import { SessionsContext } from '../../../providers/SessionsProvider';
import ParticipantSearch from './ParticipantSearch';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { FlightsContext } from '../providers/FlightsProvider';

export type ParticipantRow = ICompetitionParticipant & {
    isSteward: boolean;
    isJudge: boolean;
    isAdmin: boolean;
    isOwner: boolean;
    enteredIn: Record<string, boolean>;
    sessionsAvailable: Record<string, boolean>;
    sessions: ISession[];
};

const ParticipantsAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const { participants, addRole, removeRole, updateCategoryEnteredIn, enteredCategoriesMap } =
        React.useContext(ParticipantsContext);

    const { sessions, participantSessionsMap, setParticipantAvailability } =
        React.useContext(SessionsContext);

    const { flights } = React.useContext(FlightsContext);

    const { competition } = React.useContext(CompetitionContext);

    const rows = React.useMemo(
        () =>
            participants
                ? participants.map((p) => {
                      return {
                          ...p,
                          isSteward: p.roles.includes('steward'),
                          isJudge: p.roles.includes('judge'),
                          isOwner: p.roles.includes('owner'),
                          isAdmin: p.roles.includes('admin'),
                          enteredIn: enteredCategoriesMap?.[p.uid] || {},
                          sessionsAvailable: participantSessionsMap?.[p.uid] || {},
                          sessions,
                      };
                  })
                : [],
        [participants, enteredCategoriesMap, sessions, participantSessionsMap]
    );

    const handleTableClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            if (containsClass(evt.target, 'toggle-role')) {
                const participantId = String(getAttribute(evt.target, 'data-participant-id'));
                const participant = rows.find((p) => p.uid === participantId);

                if (containsClass(evt.target, 'toggle-is-steward')) {
                    participant.isSteward
                        ? removeRole(participantId, 'steward')
                        : addRole(participantId, 'steward');
                }

                if (containsClass(evt.target, 'toggle-is-judge')) {
                    participant.isJudge
                        ? removeRole(participantId, 'judge')
                        : addRole(participantId, 'judge');
                }

                if (containsClass(evt.target, 'toggle-is-admin')) {
                    participant.isAdmin
                        ? removeRole(participantId, 'admin')
                        : addRole(participantId, 'admin');
                }
            }

            if (containsClass(evt.target, 'remove-style')) {
                const participantId = String(getAttribute(evt.target, 'data-participant-id'));
                const styleId = String(getAttribute(evt.target, 'data-style-id'));

                evt.stopPropagation();
                evt.preventDefault();

                updateCategoryEnteredIn(participantId, styleId, false);
            }
        },
        [rows, addRole, removeRole, updateCategoryEnteredIn]
    );

    const handleTableChangeEvent = (evt: React.FormEvent) => {
        if (evt.target instanceof HTMLInputElement) {
            const type = String(getAttribute(evt.target, 'data-type'));
            const participantId = String(getAttribute(evt.target, 'data-participant-id'));

            if (type === 'toggle-entered-in') {
                updateCategoryEnteredIn(participantId, evt.target.value, evt.target.checked);
            }

            if (type === 'toggle-session-available') {
                setParticipantAvailability(participantId, evt.target.value, evt.target.checked);
            }
        }
    };

    const handleDownloadJudgeReportClicked = React.useCallback(() => {
        const participantFlights: Record<string, IFlight[]> = {};
        const participantSessions: Record<string, ISession[]> = {};

        flights.forEach((f) => {
            const flightSession = sessions.find((s) => s.uid === f.session_id);

            f.judge_ids.forEach((id) => {
                if (!participantFlights[id]) {
                    participantFlights[id] = [];
                }

                participantFlights[id].push(f);

                if (!participantSessions[id]) {
                    participantSessions[id] = [];
                }

                if (flightSession && !participantSessions[id].includes(flightSession)) {
                    participantSessions[id].push(flightSession);
                }
            });
        });

        const csv = participants
            .filter((p) => p.roles.includes('judge'))
            .reduce((csv, participant) => {
                const pSessions = participantSessions[participant.uid] || [];
                const pFlights = participantFlights[participant.uid] || [];

                return `${csv}${participant.judging?.bjcp_id || ''},${participant.display_name},${
                    participant.email
                },${pSessions.length},"${pSessions
                    .map((s) => s.name())
                    .sort()
                    .join('\r')}",${pFlights.length},"${pFlights
                    .map((f) => f.name())
                    .sort()
                    .join('\r')}"\r\n`;
            }, 'data:text/csv;charset=utf-8,BJCP ID,Display Name,Email,Number of Active Sessions,Active Sessions,Total Number of Flights,Flights\r\n');

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', `${competition.slug}_judge_report.csv`);
        document.body.appendChild(link); // Required for FF
        link.click();
    }, [competition.slug, flights, participants, sessions]);

    return (
        <>
            <div className="mb-8 w-full px-2 md:px-0 md:w-128">
                <ParticipantSearch />
            </div>
            <div onClick={handleTableClicked} onChange={handleTableChangeEvent}>
                <Table columns={columns} data={rows || []} />
            </div>
            {rows.length > 0 && (
                <button
                    className="btn btn-indigo btn-outline mt-4"
                    onClick={handleDownloadJudgeReportClicked}
                >
                    Download Judge Report
                </button>
            )}
        </>
    );
};

export default ParticipantsAdmin;
