import React from 'react';
import SheetSection from './SheetSection';
import { UserContext } from '../../providers/UserProvider';
import { IEntry, IScoreSheetData } from '@flight-cap/shared';
import { Link } from '@reach/router';
import { useEntryAllergens } from '../../hooks/useEntryAllergens';
import ScoresheetEntryInformation from './ScoresheetEntryInformation';
import ScoresheetJudgeInformation from './ScoresheetJudgeInformation';
import SheetScoringGuide from './SheetScoringGuide';
import { FieldErrors, useFormContext } from 'react-hook-form';

const Scoresheet: React.FunctionComponent<{
    entry: IEntry;
    scoreSheetData: IScoreSheetData;
    onFormSubmit: VoidFunction;
    hasChanges: boolean;
    toggleStickyEntryInfo: (show: boolean) => void;
    errors: FieldErrors;
}> = ({ onFormSubmit, entry, scoreSheetData, errors, toggleStickyEntryInfo }) => {
    const { user } = React.useContext(UserContext);
    const { AllergensBadge } = useEntryAllergens(entry);

    const { handleSubmit } = useFormContext();

    const schema = entry?.sheet_schema;
    const sections = schema?.sections || [];

    const showUserInfoWarning =
        !user.email ||
        !user.judging ||
        !user.judging.bjcp_rank ||
        (user.judging.bjcp_rank && !user.judging.bjcp_id);

    const onSubmit = () => {
        onFormSubmit();
    };

    const observableTopPositionRef = React.useRef<HTMLDivElement>();

    React.useLayoutEffect(() => {
        if (
            'IntersectionObserver' in window &&
            'IntersectionObserverEntry' in window &&
            'intersectionRatio' in window.IntersectionObserverEntry.prototype
        ) {
            const topObserver = new IntersectionObserver((entries) => {
                toggleStickyEntryInfo(entries[0].boundingClientRect.y < 0);
            });
            topObserver.observe(observableTopPositionRef.current);
        }
    }, [toggleStickyEntryInfo]);

    return entry ? (
        <>
            {entry.allergens && (
                <div className="text-center mb-4">
                    <AllergensBadge />
                </div>
            )}
            <div className="flex flex-initial justify-between">
                <ScoresheetEntryInformation entry={entry} />
                <section className="flex flex-col items-end">
                    <ScoresheetJudgeInformation judge={user} />
                    {showUserInfoWarning ? (
                        <Link
                            to="/profile"
                            className="font-medium flex text-right items-center text-xs text-yellow-600 mt-2"
                        >
                            Please update your profile information
                            <svg
                                className="h-6 w-6 ml-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                            </svg>
                        </Link>
                    ) : (
                        <></>
                    )}
                </section>
            </div>
            <div ref={observableTopPositionRef} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 mt-8">
                    {sections.map((section, index) => (
                        <SheetSection
                            key={`${section.title}-${index}`}
                            {...section}
                            scoreSheetData={scoreSheetData}
                            className={section.className}
                            errors={errors}
                        ></SheetSection>
                    ))}
                </div>
                {schema?.scoringGuide && (
                    <>
                        <label className="text-md">Scoring Range Guide</label>
                        <div className="text-sm">
                            <SheetScoringGuide scoringGuide={schema.scoringGuide} />
                        </div>
                    </>
                )}
            </form>
        </>
    ) : (
        <p>no entry</p>
    );
};

export default Scoresheet;
