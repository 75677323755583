import React from 'react';
import {
    ICompetitionStylesTableIndex,
    IEntryRecord,
    IFlightRecord,
    ITable,
    ITableRecord,
} from '@flight-cap/shared';
import { firestore, timestamp } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';

type ITablesContext = {
    tables: ITable[];
    addTable: (props: ITableRecord) => Promise<ITable>;
    updateTable: (
        id: string,
        props: Partial<Omit<ITableRecord, 'categories' | 'category_ids'>>
    ) => Promise<void>;
    updateTableStylesMap: (id: string, map: Record<string, boolean>) => Promise<void>;
    deleteTable: (id: string) => Promise<void>;
};

export const TablesContext: React.Context<ITablesContext> = React.createContext<ITablesContext>({
    tables: [],
    addTable: null,
    updateTable: null,
    deleteTable: null,
    updateTableStylesMap: null,
});

/**
 * Provides the all the tables corresponding to the currently selected competition.
 */
const TablesProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [tables, setTables] = React.useState<ITable[]>([]);

    const { competition } = React.useContext(CompetitionContext);

    React.useEffect(() => {
        if (competition?.uid) {
            return firestore
                .collection(`competitions/${competition.uid}/tables`)
                .orderBy('title', 'asc')
                .onSnapshot((tables) => {
                    setTables(
                        tables.docs.map((table) => {
                            const tableData = table.data() as ITable;
                            return {
                                uid: table.id,
                                ...tableData,
                                allow_awards: tableData.allow_awards !== false,
                            };
                        })
                    );
                });
        } else {
            setTables([]);
        }
    }, [competition]);

    const addTable = async (props: Omit<ITableRecord, 'uid'>) => {
        if (competition.uid) {
            const batch = firestore.batch();

            // create the table
            const tableDoc = firestore.collection(`competitions/${competition.uid}/tables`).doc();
            batch.set(tableDoc, props, { merge: true });

            // create an initial flight
            const flightData: IFlightRecord = {
                type: 'table_scoring',
                judge_ids: [],
                judges: {},
                active: false,
                saved_at: timestamp(),
                table_snippet: {
                    uid: tableDoc.id,
                    ...props,
                },
            };
            const flightDoc = firestore.collection(`competitions/${competition.uid}/flights`).doc();
            batch.set(flightDoc, flightData, { merge: true });

            // create a mini-bos flight
            const miniBosFlightData = {
                ...flightData,
                type: 'table_comparative',
            };

            const miniBosFlightDoc = firestore
                .collection(`competitions/${competition.uid}/flights`)
                .doc();
            batch.set(miniBosFlightDoc, miniBosFlightData, { merge: true });

            await batch.commit();

            const tableData = {
                ...(await tableDoc.get()).data(),
                uid: tableDoc.id,
            } as ITable;

            return tableData;
        }

        return Promise.reject('no competition id');
    };

    const updateTable = async (id: string, props: Partial<Omit<ITableRecord, 'uid'>>) => {
        if (competition.uid) {
            return firestore.doc(`competitions/${competition.uid}/tables/${id}`).update(props);
        }

        return Promise.reject('no competition id');
    };

    const updateTableStylesMap = async (tableId: string, categoryMap: Record<string, boolean>) => {
        if (competition.uid) {
            const indexesRef = firestore.doc(
                `competitions/${competition.uid}/indexes/styles_table`
            );
            const indexesSnap = await indexesRef.get();
            const indexes = (indexesSnap.data() || {}) as ICompetitionStylesTableIndex;
            const updateKeys = {} as Record<string, string | null>;

            for (const key in categoryMap) {
                if (categoryMap[key] === true) {
                    updateKeys[key] = tableId;
                } else {
                    if (indexes?.[key] === tableId) {
                        updateKeys[key] = null;
                    }
                }
            }

            const updatedIndexes = {
                ...indexes,
                ...updateKeys,
            };

            return indexesRef.set(updatedIndexes);
        }
    };

    const deleteTable = async (id: string) => {
        const overrideEntries = await firestore
            .collection(`competitions/${competition.uid}/entries`)
            .where('table_override_id', '==', id)
            .get();

        for (const doc of overrideEntries.docs) {
            const data = doc.data() as IEntryRecord;
            const categoryTable = tables.find((t) =>
                t.category_ids.includes(data.style_snippet.uid)
            );
            if (categoryTable) {
                const update: Partial<IEntryRecord> = {
                    table_snippet: categoryTable,
                    table_override_id: null,
                };
                doc.ref.update(update);
            }
        }

        return firestore.doc(`competitions/${competition.uid}/tables/${id}`).delete();
    };

    return (
        <TablesContext.Provider
            value={{
                tables,
                addTable,
                updateTable,
                updateTableStylesMap,
                deleteTable,
            }}
        >
            {children}
        </TablesContext.Provider>
    );
};

export default TablesProvider;
