import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { SessionsContext } from '../../../providers/SessionsProvider';
import { containsClass, getAttribute, ISession } from '@flight-cap/shared';
import SessionEditor, { SessionEditorDragType } from './sessions/SessionEditor';
import { FlightsContext } from '../providers/FlightsProvider';
import { SessionEditorDropActions } from './sessions/SessionEditor';
import {
    Active,
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    Over,
    pointerWithin,
    TouchSensor,
    useDndMonitor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import DropZoneFlights, { IFlightDropZoneData } from './dnd/DropZoneFlights';
import { DraggableFlight, IDraggableFlightData } from './dnd/DraggableFlight';
import { IJudgeDropZoneData } from './dnd/DropZoneJudges';
import { IDraggableJudgeData } from './dnd/DraggableJudge';
import SessionThumbnail from './sessions/SessionThumbnail';
import FlightThumbnail from './flights/FlightThumbnail';
import { MouseSensor } from '../../../dndkit/PreventableSensors';
import SessionInspectionContextProvider, {
    SessionInspectionContext,
} from './SessionInspectionProvider';
import DragGhost from './dnd/DragGhost';

export type SessionUpdateField = 'title' | 'location' | 'start_date' | 'map_link';

const SessionsAdminUI: React.FunctionComponent = () => {
    const [activeDragTarget, setActiveDragTarget] = React.useState<Active>(null);
    const [activeDropTarget, setActiveDropTarget] = React.useState<Over>(null);
    const [selectedSessionIds, setSelectedSessionIds] = React.useState<string[]>([]);

    const { inspectFlight, inspectParticipant, clearInspection } =
        React.useContext(SessionInspectionContext);

    const { sessions, addSession, updateSession } = React.useContext(SessionsContext);

    const { flights, addSessionToFlight, addJudgeToFlight, removeJudgeFromFlight, updateFlight } =
        React.useContext(FlightsContext);

    const handleDragStart = (evt: DragStartEvent) => {
        setActiveDragTarget(evt.active);

        switch (evt.active.data.current.type) {
            case SessionEditorDragType.Flight: {
                inspectFlight(evt.active.data.current.flight);
                break;
            }
            case SessionEditorDragType.Participant: {
                inspectParticipant(evt.active.data.current.participant);
                break;
            }
        }
    };

    const handleDragOver = (evt: DragOverEvent) => {
        setActiveDropTarget(evt.over);
    };

    const handleDragEnd = (evt: DragEndEvent) => {
        const { over, active } = evt;
        setActiveDragTarget(null);
        setActiveDropTarget(null);
        clearInspection();

        if (over) {
            switch (over.data.current.action) {
                case SessionEditorDropActions.AddSessionToFlight: {
                    const overData = over.data.current as IFlightDropZoneData;
                    const activeData = active.data.current as IDraggableFlightData;
                    addSessionToFlight(activeData.flight.uid, overData.sessionId);
                    break;
                }

                case SessionEditorDropActions.AddJudgeToFlight: {
                    const overData = over.data.current as IJudgeDropZoneData;
                    const activeData = active.data.current as IDraggableJudgeData;
                    if (activeData.flight) {
                        removeJudgeFromFlight(activeData.flight.uid, activeData.participant.uid);
                    }
                    addJudgeToFlight(overData.flight.uid, activeData.participant.uid);
                    break;
                }

                default: {
                    console.log('unrecognized over action', over.data.current.action);
                }
            }
        }
    };

    useDndMonitor({
        onDragStart: handleDragStart,
        // onDragMove(event) {},
        onDragOver: handleDragOver,
        onDragEnd: handleDragEnd,
    });

    const handleAddSession = () => {
        addSession();
    };

    const handleUpdateSession = (uid: string, data: Partial<ISession>) => {
        updateSession(uid, data);
    };

    const handleSessionSelected = (uid: string, selected: boolean) => {
        if (selected && !selectedSessionIds.includes(uid)) {
            setSelectedSessionIds([...selectedSessionIds, uid]);
            return;
        }

        if (!selected && selectedSessionIds.includes(uid)) {
            setSelectedSessionIds(selectedSessionIds.filter((id) => id !== uid));
            return;
        }
    };

    const unassignedFlights = React.useMemo(
        () => flights.filter((f) => !f.session_id).sort((a, b) => (a.name() > b.name() ? 1 : -1)),
        [flights]
    );

    const selectedSessions = React.useMemo(
        () =>
            sessions
                .filter((s) => selectedSessionIds.includes(s.uid))
                .sort((a, b) => (a.name() > b.name() ? 1 : -1)),
        [selectedSessionIds, sessions]
    );

    const handleClick = (evt: React.MouseEvent) => {
        if (
            evt.target instanceof HTMLButtonElement &&
            containsClass(evt.target, 'remove-participant-from-flight')
        ) {
            const participantId = getAttribute(evt.target, 'data-participant-id');
            const flightId = getAttribute(evt.target, 'data-flight-id');
            removeJudgeFromFlight(String(flightId), String(participantId));
            return;
        }

        if (evt.target instanceof HTMLInputElement) {
            const dataRole = getAttribute(evt.target, 'data-role');

            switch (dataRole) {
                case 'toggle-flight-active': {
                    const flightId = getAttribute(evt.target, 'data-flight-id');
                    const flight = flights.find((f) => f.uid === flightId);
                    if (flight) {
                        updateFlight(flight.uid, { active: !flight.active });
                    }
                    return;
                }
                case 'toggle-flights-active': {
                    const flightIds = getAttribute(evt.target, 'data-flight-ids');
                    if (typeof flightIds === 'string' && flightIds.length) {
                        console.log('dooooin it', (evt.target as HTMLInputElement).checked);
                        flights
                            .filter((f) => flightIds.includes(f.uid))
                            .forEach((f) => {
                                updateFlight(f.uid, {
                                    active: (evt.target as HTMLInputElement).checked,
                                });
                            });
                    }
                    return;
                }
            }
        }
    };

    const scrollHeightClass = 'md:max-h-[calc(100%-24px)]';

    return (
        <div className="md:h-[calc(100vh-104px-3em)] md:overflow-hidden">
            <div onClick={handleClick} className="md:h-full flex flex-col md:flex-row space-x-4">
                {selectedSessions.length > 0 && (
                    <div className={`grow`}>
                        <label>Session Detail</label>
                        <div
                            className={`grid ${scrollHeightClass} md:overflow-scroll border-t border-gray-100`}
                        >
                            {selectedSessions.map((s) => (
                                <div
                                    key={s.uid}
                                    className="shadow-sm border m-4 rounded border-gray-200"
                                >
                                    <SessionEditor
                                        session={s}
                                        onUpdateSession={handleUpdateSession}
                                    ></SessionEditor>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className="grow">
                    <label>Sessions</label>
                    <div
                        className={`${scrollHeightClass} md:overflow-scroll border-t border-gray-100`}
                    >
                        {sessions ? (
                            <ul className="grid grid-cols-1 4xl:grid-cols-2 gap-4 overflow-x-hidden p-2">
                                {sessions.map((s) => (
                                    <li
                                        key={s.uid}
                                        className="w-full h-full"
                                        // style={{ minWidth: '350px' }}
                                    >
                                        <SessionThumbnail
                                            session={s}
                                            onUpdateSession={handleUpdateSession}
                                            onSessionSelected={handleSessionSelected}
                                            selected={selectedSessions.includes(s)}
                                        ></SessionThumbnail>
                                    </li>
                                ))}
                                <button
                                    className="btn-indigo btn-outline"
                                    onClick={handleAddSession}
                                >
                                    + Add Session
                                </button>
                            </ul>
                        ) : null}
                    </div>
                </div>
                <div className="">
                    <label>Unassigned Flights</label>
                    <div
                        className={`${scrollHeightClass} h-full md:overflow-scroll border-dashed-gray p-2`}
                    >
                        <DropZoneFlights session={null} id={`unassigned-sessions`}>
                            <div
                                className="flex h-full flex-col space-y-2"
                                style={{ minWidth: '300px' }}
                            >
                                {unassignedFlights.map((f) => (
                                    <DraggableFlight key={f.uid} flight={f}>
                                        <FlightThumbnail flight={f} />
                                    </DraggableFlight>
                                ))}
                            </div>
                        </DropZoneFlights>
                    </div>
                </div>
            </div>
            <DragOverlay className="z-50" dropAnimation={null}>
                {!!activeDragTarget ? (
                    <DragGhost active={activeDragTarget} over={activeDropTarget} />
                ) : null}
            </DragOverlay>
        </div>
    );
};

const SessionsAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: { delay: 0, tolerance: 0 },
    });

    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: { delay: 350, tolerance: 0 },
    });

    const sensors = useSensors(mouseSensor, touchSensor);

    return (
        <SessionInspectionContextProvider>
            <DndContext sensors={sensors} collisionDetection={pointerWithin}>
                <SessionsAdminUI />
            </DndContext>
        </SessionInspectionContextProvider>
    );
};

export default SessionsAdmin;
