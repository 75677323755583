import React from 'react';
import { createUserWithEmailAndPassword } from '../../firebase';
import { RouteComponentProps } from '@reach/router';
import { getErrorMessage } from '../../hooks/useFirebaseAuthError';
import Card from '../../components/Card/Card';
import GoogleButton from './GoogleButton';
import Divider from '../../components/Divider/Divider';
import { FormProvider, useForm } from 'react-hook-form';
import FormErrorToast from '../../components/Form/FormErrorToast';
import { useToasts } from 'react-toast-notifications';
import { SimpleToastContent } from '../../components/Toasts/ToastMessage';

interface IFormData {
    name: string;
    email: string;
    password: string;
}

const SignUp: React.FunctionComponent<RouteComponentProps> = () => {
    const methods = useForm<IFormData>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;
    const { addToast, removeAllToasts } = useToasts();

    const onSubmit = (data: IFormData) => {
        const { email, password, name } = data;
        console.log('onSubmit', data);
        createUserWithEmailAndPassword(email, password, { display_name: name }).catch((error) => {
            removeAllToasts();
            addToast(
                <SimpleToastContent title="There was an error" subTitle={getErrorMessage(error)} />,
                { appearance: 'error' }
            );
        });
    };

    return (
        <>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-600">
                Create an account
            </h2>
            <Card>
                <div className="mt-6">
                    <GoogleButton />
                    <Divider>or continue with</Divider>
                </div>
                <FormProvider {...methods}>
                    <FormErrorToast errors={errors}>
                        <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                            <div className="mb-4">
                                <label htmlFor="email">Name</label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    aria-invalid={errors.name ? 'true' : 'false'}
                                    {...register('name', { required: 'Please supply a name' })}
                                ></input>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                    {...register('email', {
                                        required: 'Please supply a valid email address',
                                    })}
                                ></input>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email">Password</label>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    aria-invalid={errors.password ? 'true' : 'false'}
                                    {...register('password', {
                                        required:
                                            'Please supply a password with at least 8 characters.',
                                        minLength: {
                                            value: 8,
                                            message:
                                                'Please supply a password with at least 8 characters.',
                                        },
                                    })}
                                    autoComplete="new-password"
                                ></input>
                            </div>
                            <button className="btn-indigo w-full text-center my-4" type="submit">
                                Create Account
                            </button>
                        </form>
                    </FormErrorToast>
                </FormProvider>
            </Card>
        </>
    );
};

export default SignUp;
