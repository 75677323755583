import React from 'react';
import { StylesContext } from '../providers/StylesProvider';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { MetaStyleType } from '@flight-cap/shared';
import StyleTypeSelect from './StyleTypeSelect';

const QuickAddCustomStyle: React.FunctionComponent = () => {
    const { addStyle } = React.useContext(StylesContext);

    interface IFormData {
        name: string;
        type: MetaStyleType;
    }

    const methods = useForm<IFormData>();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = methods;

    const onSubmit: SubmitHandler<IFormData> = async (data) => {
        await addStyle({
            ...data,
        });
        reset({ type: data.type, name: null });
    };

    return (
        <div className="px-4 sm:px-0">
            <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Add a Custom Style</h3>
                <div className="mt-2 text-sm text-gray-500">
                    <p>Define a custom non-BJCP style that entries can use for categorization</p>
                </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="mt-5 sm:flex sm:items-centers space-y-2 sm:space-x-2 sm:space-y-0"
                >
                    <div className="w-full bg-gray-100 flex">
                        <label htmlFor="new-style-title" className="sr-only">
                            Style Name
                        </label>
                        <input
                            type="text"
                            id="new-style-title"
                            autoComplete="off"
                            {...register('name', { required: true })}
                            aria-invalid={errors.name ? 'true' : 'false'}
                            placeholder="Custom Style Name"
                        />
                    </div>
                    <FormProvider {...methods}>
                        <StyleTypeSelect />
                    </FormProvider>
                    <button
                        type="submit"
                        className="mt-3 w-full btn-indigo items-center justify-center sm:mt-0 sm:w-64 sm:text-sm"
                    >
                        Add Custom Style
                    </button>
                </form>
            </div>
        </div>
    );
};

export default QuickAddCustomStyle;
