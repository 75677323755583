import React from 'react';
import { IEntry } from '@flight-cap/shared';
import AllergensBadgeComponent from '../components/EntryDetails/AllergensBadge';

export const useEntryAllergens = (
    entry: IEntry
): { AllergensBadge: React.FunctionComponent; hasAllergens: boolean } => {
    const allergens = entry?.allergens?.join(', ') || '';
    const hasAllergens = allergens.length > 0;

    const AllergensBadge: React.FunctionComponent = () =>
        hasAllergens ? <AllergensBadgeComponent entry={entry} /> : <></>;
    return {
        AllergensBadge,
        hasAllergens,
    };
};
