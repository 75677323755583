import { AwardRank, IAwardEntrySnippet, IAwardRankings } from '@flight-cap/shared';
import React from 'react';

const EntryRenderer: React.FunctionComponent<{
    snip: IAwardEntrySnippet;
    children: React.ReactNode;
}> = ({ snip, children }) => {
    return (
        <div
            className={`${
                snip ? '' : 'border-dashed'
            } border rounded-md p-4 h-full mx-8 flex align-top`}
        >
            <span className="w-24">{children}</span>
            {snip ? (
                <>
                    {snip.judging_number}
                    {': '}
                    {snip.style_snippet.name} ({snip.style_snippet.category}
                    {snip.style_snippet.sub_category})
                </>
            ) : (
                <div className="flex justify-center text-gray-400">Undecided</div>
            )}
        </div>
    );
};

const ComparisonPlaces: React.FunctionComponent<{ rankings: IAwardRankings }> = ({ rankings }) => {
    return (
        <div
            className={`grid justify-between grid-cols-1 gap-y-4 ${
                rankings?.[AwardRank.HonorableMention] ? 'md:grid-cols-2' : 'md:grid-cols-3'
            }`}
        >
            <EntryRenderer snip={rankings?.[AwardRank.First]}>1st</EntryRenderer>
            <EntryRenderer snip={rankings?.[AwardRank.Second]}>2nd</EntryRenderer>
            <EntryRenderer snip={rankings?.[AwardRank.Third]}>3rd</EntryRenderer>
            {rankings?.[AwardRank.HonorableMention] && (
                <EntryRenderer snip={rankings?.[AwardRank.HonorableMention]}>HM</EntryRenderer>
            )}
        </div>
    );
};

export default ComparisonPlaces;
