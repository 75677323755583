import { IFlight } from '@flight-cap/shared';
import React from 'react';

const FlightCategoryList: React.FunctionComponent<{ flight: IFlight }> = ({ flight }) => {
    return flight.type === 'table_comparative' || flight.type === 'table_scoring' ? (
        <>{flight.table_snippet.category_ids.join(', ')}</>
    ) : null;
};

export default FlightCategoryList;
