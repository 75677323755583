import React from 'react';
import { Match } from '@reach/router';

/**
 * wraps a component with the Match that will apply and `active` class
 * if the route url matches. accepts an option `matchProps` array that will
 * apply boolean properties to the wrapped component if there is a match
 */
function useActiveMatch<T>(Component: React.FunctionComponent<T>) {
    return (
        props: T & {
            path: string;
            className?: string;
            children?: React.ReactNode;
            matchProps?: string[];
        }
    ) => (
        <Match path={props.path}>
            {({ match }) => {
                const matchPropsObject: Record<string, boolean> = {};

                if (match) {
                    props.matchProps?.forEach((flag) => {
                        matchPropsObject[flag] = true;
                    });
                }

                return (
                    <Component
                        {...props}
                        {...matchPropsObject}
                        className={`${props.className || ''} ${match ? ' active' : ''}`}
                    >
                        {props.children}
                    </Component>
                );
            }}
        </Match>
    );
}

export default useActiveMatch;
