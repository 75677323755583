import React from 'react';
import { RouteComponentProps } from '@reach/router';
import CompetitionsProvider from './providers/CompetitionsProvider';
import Dashboard from './Dashboard';

const DashboardWrapper: React.FunctionComponent<
    RouteComponentProps & { children?: React.ReactNode }
> = () => {
    return (
        <CompetitionsProvider>
            <Dashboard />
        </CompetitionsProvider>
    );
};

export default DashboardWrapper;
