import React from 'react';
import { getJudgeQualifications, IJudgeSnippet } from '@flight-cap/shared';

const JudgeInformation: React.FunctionComponent<{ judge: IJudgeSnippet }> = ({ judge }) => {
    return (
        <>
            <label className="inline-block border-b border-gray-500 mb-2 text-lg uppercase">
                Judged by
            </label>
            <p>{judge.display_name}</p>
            {judge.email && (
                <p>
                    <a
                        className="font-normal text-xl text-gray-900"
                        href={`mailto:${judge.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {judge.email}
                    </a>
                </p>
            )}
            <p>BJCP Id: {judge?.judging?.bjcp_id || 'not provided'}</p>
            <p>BJCP Rank: {judge?.judging?.bjcp_rank || 'not provided'}</p>
            <div className="mt-1 text-lg">
                {getJudgeQualifications(judge?.judging).map((value: string) => (
                    <div key={value}>{value}</div>
                ))}
            </div>
        </>
    );
};

export default JudgeInformation;
