import {
    IStyleGuideData,
    localStorageGet,
    LocalStorageKeys,
    localStorageSet,
    MetaStyleType,
} from '@flight-cap/shared';
import React from 'react';
import { getStyleDocument } from '../../firebase';
import { CompetitionContext } from '../../providers/CompetitionProvider';
import { StylesContext } from './providers/StylesProvider';

const Section: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
    <div className="my-4">{children}</div>
);

const SectionHeading: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
    <h3 className="mt-2 font-bold inline mr-2">{children}</h3>
);

const StyleInformation: React.FunctionComponent<{
    category: string;
    subCategory: string;
    type: MetaStyleType;
}> = ({ category, subCategory, type }) => {
    const [style, setStyle] = React.useState<IStyleGuideData>();
    const { styles } = React.useContext(StylesContext);
    const { competition } = React.useContext(CompetitionContext);

    const [selectedStyleId, setSelectedStyleId] = React.useState<string>(
        category === 'XCS'
            ? localStorageGet(LocalStorageKeys.CustomStyleGuidelineId)
            : `${category}${subCategory}`
    );

    React.useEffect(() => {
        getStyleDocument(competition.style_classification, selectedStyleId).then(setStyle);
    }, [competition.style_classification, selectedStyleId]);

    const handleStyleSelectChange = React.useCallback(
        (evt: React.ChangeEvent<HTMLSelectElement>) => {
            localStorageSet(LocalStorageKeys.CustomStyleGuidelineId, evt.target.value);
            setSelectedStyleId(evt.target.value);
        },
        []
    );

    const bjcpStylesForType = React.useMemo(
        () => (styles || []).filter((s) => s.type === type && s.source === 'bjcp'),
        [type, styles]
    );

    if (!category || !subCategory) {
        return (
            <div className="container-dashed-gray text-center">
                No style information available. Try selecting an entry from the Flight section, and
                then check back here.
            </div>
        );
    }

    return (
        <>
            {category === 'XCS' && (
                <div className="container-dashed-gray text-center flex flex-col mb-4">
                    <div>
                        This is a custom {type} style without specific guidelines. You can select a
                        BJCP {type} style to view.
                    </div>
                    <select
                        className="my-4"
                        placeholder="Choose a style"
                        value={style ? selectedStyleId : ''}
                        onChange={handleStyleSelectChange}
                    >
                        <option disabled value={''}>
                            {' '}
                            Choose a style
                        </option>
                        {bjcpStylesForType.map(({ uid, name, category, sub_category }) => (
                            <option key={uid} value={uid}>
                                {category}
                                {sub_category}: {name}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {style && (
                <div className="text-sm">
                    <h1 className="font-bold text-lg">
                        {style?.id}: {style?.name}
                    </h1>
                    {style?.impression && <p className="mb-4">{style.impression}</p>}
                    {style?.comments && <p className="mb-4">{style.comments}</p>}
                    <div className="grid grid-cols-3 gap-2">
                        {style.stats?.og && (
                            <p>
                                OG: {style.stats.og.low} - {style.stats.og.high}
                            </p>
                        )}
                        {style.stats?.fg && (
                            <p>
                                FG: {style.stats.fg.low} - {style.stats.fg.high}
                            </p>
                        )}
                        {style.stats?.ibu && (
                            <p>
                                IBU: {style.stats.ibu.low} - {style.stats.ibu.high}
                            </p>
                        )}
                        {style.stats?.abv && (
                            <p>
                                ABV: {style.stats.abv.low} - {style.stats.abv.high}
                            </p>
                        )}
                        {style.stats?.srm && (
                            <p>
                                SRM: {style.stats.srm.low} - {style.stats.srm.high}
                            </p>
                        )}
                    </div>
                    {style?.appearance && (
                        <Section>
                            <SectionHeading>Appearance</SectionHeading>
                            <p className="inline">{style.appearance}</p>
                        </Section>
                    )}
                    {style?.aroma && (
                        <Section>
                            <SectionHeading>Aroma</SectionHeading>
                            <p className="inline">{style.aroma}</p>
                        </Section>
                    )}
                    {style?.flavor && (
                        <Section>
                            <div>
                                <SectionHeading>Flavor</SectionHeading>
                                <p className="inline">{style.flavor}</p>
                            </div>
                        </Section>
                    )}
                    {style?.mouthfeel && (
                        <Section>
                            <div>
                                <SectionHeading>Mouthfeel</SectionHeading>
                                <p className="inline">{style.mouthfeel}</p>
                            </div>
                        </Section>
                    )}
                    {style?.comparison && (
                        <Section>
                            <SectionHeading>Comparison</SectionHeading>
                            <p className="inline">{style.comparison}</p>
                        </Section>
                    )}
                    {style?.history && (
                        <Section>
                            <SectionHeading>History</SectionHeading>
                            <p className="inline">{style.history}</p>
                        </Section>
                    )}
                    {style?.entryinstructions && (
                        <Section>
                            <SectionHeading>Entry Instructions</SectionHeading>
                            <p className="inline">{style.entryinstructions}</p>
                        </Section>
                    )}
                    {style?.examples && (
                        <Section>
                            <SectionHeading>Examples</SectionHeading>
                            {style?.examples?.length ? (
                                <p className="inline">{style.examples.join(', ')}</p>
                            ) : (
                                <p>No examples available</p>
                            )}
                        </Section>
                    )}
                </div>
            )}
        </>
    );
};

export default StyleInformation;
