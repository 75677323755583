import { IEntry } from '@flight-cap/shared';
import React from 'react';
import DisqualifiedBadge from '../EntryDetails/DisqualifiedBadge';
import EntryDetails from '../EntryDetails/EntryDetails';

const EntryAdditionalInformation: React.FunctionComponent<{ entry: IEntry }> = ({ entry }) => {
    return (
        <>
            <span className="flex mt-1 mb-2 space-x-4 items-center">
                <p className="">Entry: {entry.judging_number}</p>
                <DisqualifiedBadge entry={entry} />
            </span>
            <span className="text-sm">
                <EntryDetails entry={entry} />
            </span>
        </>
    );
};

const ScoresheetEntryInformation: React.FunctionComponent<{
    entry: IEntry;
}> = ({ entry }) => {
    return (
        <div>
            <h1 className="mr-4">
                <p className="text-2xl">
                    {entry.style_snippet?.category}
                    {entry.style_snippet?.sub_category} - {entry.style_snippet?.name}
                </p>
                <div className="text-sm">
                    <EntryAdditionalInformation entry={entry} />
                </div>
            </h1>
        </div>
    );
};

export default ScoresheetEntryInformation;
