import React from 'react';
import Drawer from '../../../components/Drawer/Drawer';
import { IStyle } from '@flight-cap/shared';
import { useForm, FormProvider } from 'react-hook-form';
import { StylesContext } from '../providers/StylesProvider';
import StyleTypeSelect from './StyleTypeSelect';

interface IProps {
    style: IStyle;
    onCancel: React.MouseEventHandler;
}

const StylesEditDrawer: React.FunctionComponent<IProps> = ({ style, onCancel }) => {
    const methods = useForm();

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = methods;

    React.useEffect(() => {
        reset({
            ...style,
        });
    }, [reset, style]);

    const { updateStyles } = React.useContext(StylesContext);

    const onSubmit = async (data: Partial<IStyle>) => {
        if (!style) {
            return;
        }

        updateStyles([style.uid], data);

        onCancel(null);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Drawer
                title="Edit Style"
                show={!!style}
                onCancel={onCancel}
                actions={
                    <>
                        <button
                            onClick={onCancel}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Save
                        </button>
                    </>
                }
            >
                <div className="p-4">
                    <div className="mb-4 flex flex-col">
                        <label htmlFor="style-title">Style Name</label>
                        <input
                            className="w-full"
                            id="style-title"
                            type="text"
                            placeholder="Name"
                            aria-invalid={errors.name ? 'true' : 'false'}
                            {...register('name', { required: true })}
                        ></input>
                    </div>
                    <div className="mb-4 flex flex-col">
                        <label>Style Type</label>
                        <FormProvider {...methods}>
                            <StyleTypeSelect />
                        </FormProvider>
                    </div>
                    <div className="mb-4 flex flex-col">
                        <label className="block">
                            <input type="checkbox" className="m-2" {...register('enabled')} />
                            Enabled
                        </label>
                    </div>
                </div>
            </Drawer>
        </form>
    );
};

export default StylesEditDrawer;
