import { IFlight, IParticipantSnippet } from '@flight-cap/shared';
import React from 'react';
import DangerIcon from '../../../../components/Icon/DangerIcon';
import CategoryList from '../CategoryList';
import DraggableJudge from '../dnd/DraggableJudge';
import JudgeDropZone from '../dnd/DropZoneJudges';
import FlightActiveToggle from './FlightActiveToggle';
import FlightLoad from './FlightLoad';
import FlightMeatballMenu from './FlightMeatballMenu';
import JudgeCoverage from '../JudgeCoverage';

const JudgeList: React.FunctionComponent<{
    judges: IParticipantSnippet[];
    flight: IFlight;
}> = ({ judges, flight }) => {
    return (
        <div className="border-dashed-gray p-2 grid grid-cols-1 gap-2">
            {judges.length > 0 ? (
                judges.map((j) => (
                    <DraggableJudge
                        key={j.uid}
                        participant={j}
                        flight={flight}
                        sessionId={flight.session_snippet?.uid}
                    />
                ))
            ) : (
                <span className="text-sm text-gray-400 italic">No Judges Assigned</span>
            )}
        </div>
    );
};

const JudgeConflicts: React.FunctionComponent<{
    flight: IFlight;
}> = ({ flight }) => {
    return (
        flight.judge_conflicts.length > 0 && (
            <div className="mt-2 flex items-center bg-yellow-stripes border rounded border-yellow-800 text-yellow-800 p-2">
                <div className="flex flex-col space-y-1">
                    {flight.judge_conflicts.map(({ judge, errors }) => (
                        <div key={judge.uid} className="flex items-center">
                            <DangerIcon className="mr-1" />
                            <span className="text-xs">
                                {judge.display_name}{' '}
                                {errors.join(errors.length > 2 ? ', and ' : ' and ')}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        )
    );
};

const FlightEditor: React.FunctionComponent<{
    flight: IFlight;
    className?: string;
}> = ({ flight, className = '' }) => {
    const judges = Object.values(flight.judges || {}) as IParticipantSnippet[];

    const isTableScoring = flight.type === 'table_scoring';
    const isTableFlight = isTableScoring || flight.type === 'table_comparative';
    const flightError = flight.judge_conflicts.length > 0;

    return (
        <JudgeDropZone
            flight={flight}
            className={`${className} border border-gray-500 h-full bg-white flex transition-colors `}
        >
            <div className="flex border-r border-gray-150">
                <JudgeCoverage coverage={flight.coverage} error={flightError} />
            </div>

            <div className="p-2 grow flex flex-col justify-between">
                <div>
                    <header className="text-sm flex justify-between items-start space-x-5">
                        <div>{flight.name()}</div>
                        <span className="flex items-center space-x-1">
                            <FlightLoad flight={flight} />
                            <FlightMeatballMenu flight={flight} />
                        </span>
                    </header>
                    {isTableFlight && (
                        <section>
                            <CategoryList categoryIds={flight.table_snippet?.category_ids} />
                        </section>
                    )}
                    <section className="mt-2">
                        <JudgeList judges={judges} flight={flight} />
                        {flightError && <JudgeConflicts flight={flight} />}
                    </section>
                </div>

                <FlightActiveToggle flight={flight} />
            </div>
        </JudgeDropZone>
    );
};

export default FlightEditor;
