import React from 'react';

const BoltIcon: React.FunctionComponent<{ className?: string; fillClasses?: string }> = ({
    className = '',
}) => (
    <svg
        className={`h-4 w-4 ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
        />
    </svg>
);

export default BoltIcon;
