import React from 'react';
import FormMulitSelect from './QueryMulitSelect';
import { IValueInputProps } from './QueryFormRow';

const QueryMetaTypeSelect: React.FunctionComponent<IValueInputProps> = ({ name }) => {
    const items = [
        { label: 'Beer', value: 'beer' },
        { label: 'Mead', value: 'mead' },
        { label: 'Cider', value: 'cider' },
    ];
    return <FormMulitSelect name={name} items={items} buttonLabel="Choose Types" />;
};

export default QueryMetaTypeSelect;
