import { generateFlightNames, IFlight } from '@flight-cap/shared';
import React from 'react';
import { firestore } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { UserContext } from '../../../providers/UserProvider';

type IFlightsContext = {
    flights: IFlight[];
};

export const FlightsContext: React.Context<IFlightsContext> = React.createContext<IFlightsContext>({
    flights: [],
});

/**
 * Providers all the flights available to the currently signed in user.
 */
const FlightsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [flights, setFlights] = React.useState<IFlight[]>([]);
    const { competition } = React.useContext(CompetitionContext);
    const { user } = React.useContext(UserContext);

    React.useEffect(() => {
        if (competition?.uid) {
            return firestore
                .collection(`competitions/${competition.uid}/flights`)
                .where(`judge_ids`, 'array-contains', user.uid)
                .orderBy('saved_at', 'asc')
                .onSnapshot((flights) => {
                    console.log('Competition Flights Provider - onSnapshot');

                    const flightDataArray = flights.docs.map((flight) => ({
                        uid: flight.id,
                        ...(flight.data() as IFlight),
                    }));

                    generateFlightNames(flightDataArray);

                    setFlights(flightDataArray);
                });
        } else {
            console.log('setting flights to null');
            setFlights(null);
        }
    }, [competition?.uid, user.uid]);

    return <FlightsContext.Provider value={{ flights }}>{children}</FlightsContext.Provider>;
};

export default FlightsProvider;
