import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import TablesProvider from '../../CompetitionAdmin/providers/TablesProvider';
import EntriesProvider from '../../CompetitionAdmin/providers/EntriesProvider';
import FlightsProvider from '../../CompetitionAdmin/providers/FlightsProvider';
import ParticipantsProvider from '../../CompetitionAdmin/providers/ParticipantsProvider';
import StylesProvider from '../../CompetitionAdmin/providers/StylesProvider';
import StewardingDashboard from './StewardingDashboard';
import TablePullSheets from './TablePullSheets';
import CustomCompatativeFlightPullSheet from './FlightPullSheets';

const CompetitionStewardingWrapper: React.FunctionComponent<
    RouteComponentProps & { children?: React.ReactNode }
> = () => {
    return (
        <>
            <StylesProvider>
                <EntriesProvider>
                    <TablesProvider>
                        <FlightsProvider>
                            <ParticipantsProvider>
                                <Router>
                                    <StewardingDashboard path="/" />
                                    <TablePullSheets path="/table/:tableId/pullsheets" />
                                    <CustomCompatativeFlightPullSheet path="/flight/:flightId/pullsheets" />
                                </Router>
                            </ParticipantsProvider>
                        </FlightsProvider>
                    </TablesProvider>
                </EntriesProvider>
            </StylesProvider>
        </>
    );
};

export default CompetitionStewardingWrapper;
