import React from 'react';
import Card from './Card';

export const LeftColumn: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    return <div className="md:col-span-1">{children}</div>;
};

export const LeftColumnTitle: React.FunctionComponent<{ title: string; description?: string }> = ({
    title,
    description,
}) => (
    <>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        {description ? <p className="mt-1 text-sm text-gray-500">{description}</p> : <></>}
    </>
);

export const RightColumn: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => <div className="mt-5 md:mt-0 col-span-1 sm:col-span-2">{children}</div>;

const TwoColumnCard: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <Card>
            <div className="md:grid md:grid-cols-3 md:gap-6">{children}</div>
        </Card>
    );
};

export default TwoColumnCard;
