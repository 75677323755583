import React from 'react';
import { useFieldArray } from 'react-hook-form';
import PlusCircleIcon from '../../../../components/Icon/PlusCircleIcon';
import QueryFormRow, { FieldValue } from './QueryFormRow';

const QueryFormList: React.FunctionComponent = () => {
    const { fields, append, remove } = useFieldArray({ name: 'where' });

    const handleAddClicked = () => {
        append({ field: FieldValue.Table, comparator: undefined, value: undefined });
    };

    return (
        <>
            {fields.map((field, index) => (
                <QueryFormRow
                    key={index}
                    index={index}
                    onRemoveClick={remove}
                    showRemove={fields.length > 1}
                />
            ))}

            {fields.length < 3 && (
                <button
                    type="button"
                    onClick={handleAddClicked}
                    className="text-green-600 hover:text-green-700"
                >
                    <PlusCircleIcon className="w-5 h-5 pointer-events-none" />
                </button>
            )}
        </>
    );
};

export default QueryFormList;
