/* eslint-disable react/jsx-no-undef */

import React from 'react';
import { DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

const FormErrorList: React.FunctionComponent<{
    errors: DeepMap<Record<string, any>, FieldError>;
}> = ({ errors }) => {
    const errorsArr = Object.entries(errors);
    return (
        <>
            <h3 className="text-sm text-red-800">There were problems with your submission</h3>
            <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc pl-5 space-y-1">
                    {errorsArr.map(([key, error]) => (
                        <li key={key}>{error.message}</li>
                    ))}
                </ul>
            </div>
        </>
    );
};

const FormErrorToast: React.FunctionComponent<{
    errors: DeepMap<Record<string, any>, FieldError>;
    children: React.ReactNode;
}> = ({ children }) => {
    const {
        formState: { errors: stateErrors },
    } = useFormContext();
    const { addToast, removeAllToasts } = useToasts();
    const [displayErrors, setDisplayErrors] = React.useState(stateErrors);

    React.useEffect(() => {
        const errorsArr = Object.entries(stateErrors || {});
        removeAllToasts();
        if (errorsArr.length > 0) {
            addToast(<FormErrorList errors={stateErrors} />, {
                appearance: 'error',
            });
        }
    }, [displayErrors, stateErrors, addToast, removeAllToasts]);

    const handleSubmit = async () => {
        setDisplayErrors({ ...stateErrors });
    };

    return <span onSubmitCapture={handleSubmit}>{children}</span>;
};

export default FormErrorToast;
