import React from 'react';
import { IScoreSheetData, ITextAreaProps } from '@flight-cap/shared';
import { useFormContext } from 'react-hook-form';
import { useLocation } from '@reach/router';

const SheetTextArea: React.FunctionComponent<
    ITextAreaProps & { scoreSheetData: IScoreSheetData }
> = (props) => {
    const {
        setValue,
        register,
        watch,
        formState: { touchedFields },
    } = useFormContext();

    // in the case where we are moving from an entry with a null scoreSheetData to another null scoreSheetData, we want to reset the values
    const { pathname } = useLocation();

    const watchFields = React.useRef(props.watch?.map(({ field }) => field));

    const watchValues = watchFields.current ? watch(watchFields.current) : [];

    const warnings: string[] = [];

    watchValues.forEach((currentValue: number, index: number) => {
        const { field, value, warning } = props.watch[index];
        if (currentValue < value && (touchedFields[field] || currentValue > 0)) {
            warnings.push(warning);
        }
    });

    React.useEffect(() => {
        setValue(props.element_id, String(props.scoreSheetData?.values?.[props.element_id] || ''));
    }, [props.scoreSheetData, props.element_id, setValue, pathname]);

    const value = String(props.scoreSheetData?.values?.[props.element_id] || '');

    return props.renderMode === 'pdf' ? (
        <div className="text-lg border-2 border-gray-300 border-dashed rounded p-2 scoresheet-textarea">
            {props.title ? (
                <span className="uppercase mr-2 font-bold text-grey-800">{props.title}:</span>
            ) : (
                <></>
            )}
            {value ? (
                <span className="space-y-2">
                    {value.split('\n').map((p, indx) => (
                        <p key={indx}>{p}</p>
                    ))}
                </span>
            ) : (
                <span className="text-gray-300 italic">
                    No {props.title ? `specific ${props.title}` : 'additonal comments'} provided
                </span>
            )}
        </div>
    ) : (
        <>
            <textarea
                className="mb-1"
                id={props.element_id}
                placeholder={props.placeholder}
                rows={props.rows || 3}
                // @TODO this needs to be part of the schema, or defined in yup?
                {...register(props.element_id, { required: props.required })}
            ></textarea>

            <div className="flex flex-col lg:flex-row space-x-1 items-end lg:justify-end lg:h-5">
                {warnings.map((warning, index) => (
                    <p
                        key={index}
                        className="badge-gray badge-rounded badge-condensed mb-1 lg:mb-0 text-xs"
                    >
                        <svg
                            className="h-2 w-2 mr-1 text-blue-600"
                            fill="currentColor"
                            viewBox="0 0 8 8"
                        >
                            <circle cx={4} cy={4} r={3} />
                        </svg>
                        <span className="text-xs text-blue-600">{warning}</span>
                    </p>
                ))}
            </div>
        </>
    );
};

SheetTextArea.defaultProps = {
    renderMode: 'judging',
};

export default SheetTextArea;
