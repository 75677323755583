import { ICompetitionStyle } from '@flight-cap/shared';
import React from 'react';
import Confirm, { ConfirmTheme } from '../../../components/Confirm/Confirm';
import { ItemGetSet } from '../../../components/Table/renderers/EditDeleteCellRenderer';
import { StylesContext } from '../providers/StylesProvider';

interface IProps {
    itemForDeletion: ItemGetSet<ICompetitionStyle>;
}

const StylesAdmin: React.FunctionComponent<IProps> = ({ itemForDeletion }) => {
    const { deleteStyle } = React.useContext(StylesContext);

    const handleDeleteConfirm = () => {
        deleteStyle(itemForDeletion.get().uid);
        itemForDeletion.set(null);
    };

    const handleDeleteCancel = () => {
        itemForDeletion.set(null);
    };

    return (
        <Confirm
            theme={ConfirmTheme.Danger}
            title={`Delete Style`}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
            show={itemForDeletion.get() ? true : false}
        >
            <p className="text-sm text-gray-500">
                Are you sure you want to delete the style:{' '}
                <span className="text-red-700">{itemForDeletion.get()?.uid}</span>?
            </p>
        </Confirm>
    );
};

export default StylesAdmin;
