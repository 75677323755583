import React from 'react';
import { navigate } from '@reach/router';
import { UserContext } from '../../providers/UserProvider';
import { IUserCompetitionSnippet, parameterizeEndpoint } from '@flight-cap/shared';
import { ApplicationRoutes } from '../../routes';
import { CompetitionContext } from '../../providers/CompetitionProvider';

const CompetitionSelect: React.FunctionComponent = () => {
    const { user } = React.useContext(UserContext);
    const { competition } = React.useContext(CompetitionContext);

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        navigate(
            parameterizeEndpoint(ApplicationRoutes.Competition, {
                competitionSlug: event.target.value,
            })
        );
    };

    const competitionsArray = Object.values(user?.competitions || {}).sort((a, b) =>
        a.title > b.title ? 1 : -1
    );

    const activeComps: IUserCompetitionSnippet[] = [];
    const archivedComps: IUserCompetitionSnippet[] = [];

    competitionsArray.forEach((comp) =>
        comp.is_competition_archived ? archivedComps.push(comp) : activeComps.push(comp)
    );

    // return user && competitionsArray?.length > 1 ? (
    return user && competitionsArray?.length ? (
        <select
            id="competition_select"
            onChange={handleSelect}
            className="app-header-btn border-0 bg-transparent ml-8 w-30 sm:w-60"
            value={competition?.slug || 'DEFAULT'}
        >
            <option disabled value="DEFAULT">
                Choose a Competition
            </option>
            {activeComps.map(({ slug, title }) => (
                <option key={slug} value={slug}>
                    {title}
                </option>
            ))}

            {archivedComps.length > 0 && (
                <optgroup label="Archived Competitions">
                    {archivedComps.map(({ slug, title }) => (
                        <option key={slug} value={slug}>
                            {title}
                        </option>
                    ))}
                </optgroup>
            )}
        </select>
    ) : competition?.title ? (
        <div className="text-gray-200 mx-12 text-sm">{competition.title}</div>
    ) : (
        <></>
    );
};

export default CompetitionSelect;
