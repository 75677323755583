import React from 'react';
import { firestore } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { IScoreSheetData } from '@flight-cap/shared';
import { sheetNeedsAttention } from '../../../sheet_schemas';

type IScoresheetsContext = {
    scoresheets: IScoreSheetData[];
};

export const ScoresheetsContext: React.Context<IScoresheetsContext> =
    React.createContext<IScoresheetsContext>({
        scoresheets: null,
    });

/**
 * Provides the all the scoresheets corresponding to the currently selected competition.
 */
const ScoresheetsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [scoresheets, setScoresheets] = React.useState<IScoreSheetData[]>(null);

    const { competition } = React.useContext(CompetitionContext);

    React.useEffect(() => {
        if (competition?.uid) {
            return firestore
                .collectionGroup(`scoresheets`)
                .where('competition_id', '==', competition.uid)
                .onSnapshot((snap) => {
                    console.log('Scoresheets Provider - onSnapshot');
                    const sheets = snap.docs
                        .map((sheet) => ({
                            uid: sheet.id,
                            ...(sheet.data() as IScoreSheetData),
                        }))
                        .map((sheet) => {
                            return {
                                ...sheet,
                                needs_attention: sheetNeedsAttention(sheet, null),
                            };
                        });
                    setScoresheets(sheets);
                });
        } else {
            setScoresheets([]);
        }
    }, [competition]);

    return (
        <ScoresheetsContext.Provider value={{ scoresheets }}>
            {children}
        </ScoresheetsContext.Provider>
    );
};

export default ScoresheetsProvider;
