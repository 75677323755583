import React from 'react';
import { Transition } from '@headlessui/react';
import { containsClass } from '@flight-cap/shared';

export interface IDrawerProps {
    show: boolean;
    title?: string;
    maxWidth?:
        | 'max-w-md'
        | 'max-w-lg'
        | 'max-w-xl'
        | 'max-w-2xl'
        | 'max-w-3xl'
        | 'max-w-4xl'
        | 'max-w-5xl'
        | 'max-w-6xl'
        | 'max-w-7xl';
    actions?: React.ReactChild;
    onCancel: React.MouseEventHandler;
    className?: string;
    children: React.ReactNode;
}

const Drawer: React.FunctionComponent<IDrawerProps> = ({
    show,
    title,
    maxWidth,
    actions,
    children,
    className = '',
    onCancel,
}) => {
    const handleClick = (evt: React.MouseEvent) => {
        if (containsClass(evt.target, 'bg-blocker')) {
            onCancel(null);
        }
    };

    return (
        <Transition show={show}>
            <div className={`fixed inset-0 overflow-hidden z-10 ${className}`}>
                <div
                    className="absolute inset-0 overflow-hidden bg-gray-800 bg-opacity-60 bg-blocker"
                    onClick={handleClick}
                >
                    <section
                        className="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16"
                        aria-labelledby="slide-over-heading"
                    >
                        <Transition.Child
                            enter="transform transition ease-in-out duration-300 sm:duration-400"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-50"
                            leave="transform transition ease-in-out duration-300 sm:duration-400"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className={`h-full w-90vw sm:w-screen ${maxWidth}`}>
                                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                    <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <h2
                                                    id="slide-over-heading"
                                                    className="text-lg font-medium text-gray-900"
                                                >
                                                    {title ? title : ''}
                                                </h2>
                                                <div className="ml-3 h-7 flex items-center">
                                                    <button
                                                        type="button"
                                                        onClick={onCancel}
                                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        {/* <!-- Heroicon name: x --> */}
                                                        <svg
                                                            className="h-6 w-6"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M6 18L18 6M6 6l12 12"
                                                            />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 relative flex-1">
                                            <div className="h-full" aria-hidden="true">
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                        {actions ? actions : <></>}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </section>
                </div>
            </div>
        </Transition>
    );
};

Drawer.defaultProps = {
    show: false,
    title: '',
    maxWidth: 'max-w-md',
};

export default Drawer;
