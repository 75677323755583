import React from 'react';
import { ICompetitionParticipant, IStyleSnippet, UserCompetitionRole } from '@flight-cap/shared';
import { CompetitionContext } from './CompetitionProvider';
import { firestore } from '../firebase';
import { UserContext } from './UserProvider';

type ICompetitionParticipantContext = {
    roles: UserCompetitionRole[];
    categories_entered: IStyleSnippet[];
};

export const CompetitionParticipantContext: React.Context<ICompetitionParticipantContext> =
    React.createContext<ICompetitionParticipantContext>({
        roles: null,
        categories_entered: null,
    });

/**
 * Provides the all the tables corresponding to the currently selected competition.
 */
const CompetitionParticipantProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [participant, setParticipant] = React.useState<ICompetitionParticipantContext>(null);

    const { competition } = React.useContext(CompetitionContext);
    const { user } = React.useContext(UserContext);

    React.useEffect(() => {
        if (competition?.uid) {
            return firestore
                .doc(`competitions/${competition.uid}/participants/${user.uid}`)
                .onSnapshot((participant) => {
                    const data = participant.data() as ICompetitionParticipant;
                    setParticipant({
                        roles: data.roles,
                        categories_entered: Object.values(data.categories_entered || {}).sort(
                            (a, b) => {
                                if (a.type === b.type) {
                                    return a.uid > b.uid ? 1 : -1;
                                } else {
                                    return a.type > b.type ? 1 : -1;
                                }
                            }
                        ),
                    });
                });
        } else {
            setParticipant(null);
        }
    }, [competition, user.uid]);

    return (
        <CompetitionParticipantContext.Provider
            value={{
                roles: participant?.roles,
                categories_entered: participant?.categories_entered,
            }}
        >
            {children}
        </CompetitionParticipantContext.Provider>
    );
};

export default CompetitionParticipantProvider;
