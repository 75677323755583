var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { judgingQualificationsMap, judgingQualificationsMapCondensed } from '.';
import { CSVToArray } from './CSVToArray';
export var parameterizeString = function (str, params) {
    return str.replace(RegExp(Object.keys(params).join('|'), 'gi'), function (matched) {
        return params[matched] || matched;
    });
};
export var parameterizeEndpoint = function (str, params) {
    var parameterizedKeys = Object.keys(params).map(function (key) {
        var _a;
        return (_a = {},
            _a[":".concat(key)] = params[key],
            _a);
    });
    var replace = parameterizedKeys.reduce(function (a, b) { return (__assign(__assign({}, a), b)); });
    return parameterizeString(str, replace);
};
export var parseCSV = function (csv) {
    var arr = CSVToArray(String(csv), ',');
    var header = arr.shift().map(function (str) { return trimQuotes(str).replaceAll(' ', ''); });
    var parsed = arr
        .filter(function (data) { return data.length === header.length; })
        .map(function (data) {
        return data.reduce(function (data, key, indx) {
            var _a;
            return (__assign(__assign({}, data), (_a = {}, _a[header[indx]] = trimQuotes(key), _a)));
        }, {});
    });
    return parsed;
};
export var trimQuotes = function (str) { return str.replaceAll(/^"|"$/g, ''); };
export var parseBCOEMEntry = function (entry) {
    var _a;
    var categoryZeroPad = entry.Category.length === 1 ? '0' : '';
    var meta = 'beer';
    if (entry.Category.toUpperCase().startsWith('M')) {
        meta = 'mead';
    }
    else if (entry.Category.toUpperCase().startsWith('C')) {
        meta = 'cider';
    }
    // bocoem changed the column name
    var subCategory = entry.SubCategory || entry.Subcategory;
    return {
        bcoem_id: Number(entry.EntryNumber),
        allergens: ((_a = entry.PossAllergens) === null || _a === void 0 ? void 0 : _a.split(',').filter(function (a) { return a === null || a === void 0 ? void 0 : a.length; })) || [],
        consensus_score: null,
        entry_number: entry.EntryNumber,
        judging_number: entry.JudgingNumber,
        mini_bos: null,
        notes: '',
        required_info: entry.RequiredInfo,
        // title: entry.EntryName || entry.BrewName,
        sheet_schema_id: null,
        specific_info: entry.BrewerSpecifics || null,
        paid: entry.Paid === '1' ? true : false,
        recieved: entry.Received === '1' ? true : false,
        optional_info: entry.OptionalInfo || null,
        strength: entry.Strength || null,
        carbonation: entry.Carbonation || null,
        sweetness: entry.Sweetness || null,
        entrant_snippet: {
            bcoem_id: Number(entry.BrewerID),
            // first_name: entry.BrewerFirstName || null,
            // last_name: entry.BrewerLastName || null,
            // email: entry.Email || null,
        },
        style_snippet: {
            type: meta,
            category: "".concat(categoryZeroPad).concat(entry.Category),
            name: entry.Style,
            sub_category: subCategory.toUpperCase(),
            uid: "".concat(categoryZeroPad).concat(entry.Category).concat(subCategory.toUpperCase()),
        },
        table_snippet: null,
    };
};
export function chunkArray(arr, len) {
    var chunks = [];
    var n = arr.length;
    var i = 0;
    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
}
export function getMapDiff(before, after) {
    return Object.entries(after).reduce(function (diff, _a) {
        var k = _a[0], v = _a[1];
        if (before[k] !== v) {
            diff[k] = v;
        }
        return diff;
    }, {});
}
export var containsClass = function (target, className) {
    return target instanceof HTMLElement && target && target.classList.contains(className);
};
export var getAttribute = function (target, attributeName) {
    return target instanceof HTMLElement && target && target.getAttribute(attributeName);
};
export var getDataAttributes = function (target) {
    return target instanceof HTMLElement &&
        target &&
        target.getAttributeNames().reduce(function (obj, att) {
            var _a;
            return (__assign(__assign({}, obj), (_a = {}, _a[att] = target.getAttribute(att), _a)));
        }, {});
};
export function generateFlightNames(flights) {
    var letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var scoringMap = {};
    for (var _i = 0, flights_1 = flights; _i < flights_1.length; _i++) {
        var flight = flights_1[_i];
        if (flight.type === 'table_comparative') {
            flight.generated_name = "".concat(flight.table_snippet.title, " [Mini-BOS]");
        }
        if (flight.type === 'table_scoring') {
            if (scoringMap[flight.table_snippet.uid] === undefined) {
                scoringMap[flight.table_snippet.uid] = 0;
            }
            else {
                scoringMap[flight.table_snippet.uid] = scoringMap[flight.table_snippet.uid] + 1;
            }
            flight.generated_name = "".concat(flight.table_snippet.title, " [").concat(letters[scoringMap[flight.table_snippet.uid]], "]");
        }
        flight.name = function () {
            return this.title || this.generated_name;
        };
    }
    return flights;
}
export function generateSessionNames(sessions) {
    var count = 0;
    for (var _i = 0, sessions_1 = sessions; _i < sessions_1.length; _i++) {
        var session = sessions_1[_i];
        count++;
        session.generated_name = "Session ".concat(count);
        session.name = function () {
            return this.title || this.generated_name;
        };
    }
    return sessions;
}
export function decorateWithEntryInfoGetters(entry) {
    entry.requiredInfo = function () {
        return this.required_info_override || this.required_info || null;
    };
    entry.optionalInfo = function () {
        return this.optional_info_override || this.optional_info || null;
    };
    entry.specificInfo = function () {
        return this.specific_info_override || this.specific_info || null;
    };
    return entry;
}
export function timeSince(date1, date2) {
    if (date2 === void 0) { date2 = new Date(); }
    var seconds = Math.floor((date2.getTime() - date1.getTime()) / 1000);
    var interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return interval + " year".concat(interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " month".concat(interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " day".concat(interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hour".concat(interval > 1 ? 's' : '');
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return interval + " minute".concat(interval > 1 ? 's' : '');
    }
    if (seconds > 1) {
        return Math.floor(seconds) + ' seconds';
    }
    return 'just now';
}
export function localStorageGet(key) {
    try {
        return localStorage.getItem(key);
    }
    catch (_a) {
        console.warn('could not read from local storage');
        return null;
    }
}
export function localStorageSet(key, value) {
    try {
        localStorage.setItem(key, value);
    }
    catch (_a) {
        console.warn('could not write to local storage');
    }
}
export function localStorageRemove(key) {
    try {
        localStorage.removeItem(key);
    }
    catch (_a) {
        console.warn('could not remove from local storage');
    }
}
export function localStorageClear() {
    try {
        localStorage.clear();
    }
    catch (_a) {
        console.warn('could not clear local storage');
    }
}
export function arraysIntersect(array1, array2) {
    return array1.some(function (val) { return array2.includes(val); });
}
export function isJudgeAllowedInFlight(judge, flight) {
    var _a, _b;
    // if no categories entered, then return true
    if (!((_a = judge.categories_entered_ids) === null || _a === void 0 ? void 0 : _a.length)) {
        return true;
    }
    if (flight.type === 'table_scoring' || flight.type === 'table_comparative') {
        // if the flights table has no categories (perhaps still being planned?)
        if (!((_b = flight.table_snippet.category_ids) === null || _b === void 0 ? void 0 : _b.length)) {
            return true;
        }
        // if there is an intersection, then return false
        return !arraysIntersect(flight.table_snippet.category_ids, judge.categories_entered_ids);
    }
    return true;
}
export function getIneligibeJudgeMap(judges, flightCategrories) {
    if (flightCategrories.length === 0) {
        return {};
    }
    return judges.reduce(function (acc, cur) {
        // while categories_entered_ids is required on the type, some legacy snippets may not have it set
        var conflicted = (cur.categories_entered_ids || []).filter(function (cat) {
            return flightCategrories.includes(cat);
        });
        if (conflicted.length) {
            acc[cur.uid] = conflicted;
        }
        return acc;
    }, {});
}
export function getFlightJudgeConflicts(flight, judges) {
    var isTableScoring = flight.type === 'table_scoring';
    var isTableFlight = isTableScoring || flight.type === 'table_comparative';
    var conflicts = [];
    judges.forEach(function (j) {
        var _a, _b;
        var errors = [];
        if (!((_a = j.roles) === null || _a === void 0 ? void 0 : _a.includes('judge'))) {
            errors.push('is not available as a judge');
        }
        else {
            // judge has entries in a category assigned to this flight
            if (isTableFlight && flight.ineligible_judge_map[j.uid]) {
                var catIds = j.categories_entered_ids.filter(function (id) { var _a; return (_a = flight.table_snippet) === null || _a === void 0 ? void 0 : _a.category_ids.includes(id); });
                errors.push("has entries in ".concat(catIds.join(', ')));
            }
            // judge is not available for the flight
            if (flight.session_id && !((_b = j.session_ids) === null || _b === void 0 ? void 0 : _b.includes(flight.session_id))) {
                errors.push('is not available for the session');
            }
        }
        if (errors.length) {
            conflicts.push({ judge: j, errors: errors });
        }
    });
    return conflicts;
}
export function getJudgeQualifications(judging, condensed) {
    if (condensed === void 0) { condensed = false; }
    if (!judging) {
        return [];
    }
    var map = condensed ? judgingQualificationsMapCondensed : judgingQualificationsMap;
    // use a set to remove dupes
    return Array.from(new Set(Object.keys(map)
        .filter(function (key) { var _a; return ((_a = judging === null || judging === void 0 ? void 0 : judging.other_status) === null || _a === void 0 ? void 0 : _a[key]) === true; })
        .map(function (key) { return map[key]; })));
}
export function getCondensedJudgeRank(rank) {
    switch (rank) {
        case 'Non-BJCP':
            return '';
        case 'Rank Pending':
            return 'Pend.';
        case 'Provisional':
            return 'Prvis.';
        case 'Apprentice':
            return 'Appr.';
        case 'Recognized':
            return 'Rec.';
        case 'Certified':
            return 'Cert.';
        case 'National':
            return 'Nat.';
        case 'Master':
            return 'Mas.';
        case 'Grand Master':
            return 'GM';
        case 'Grand Master+':
            return 'GM+';
        case 'Honorary Master':
            return 'HGM';
    }
}
export function calculateJudgeCoverage(judges) {
    return judges.reduce(function (acc, judge) {
        var rank = 0.15;
        if (!judge.judging) {
            return acc + rank;
        }
        switch (judge === null || judge === void 0 ? void 0 : judge.judging.bjcp_rank) {
            case 'Grand Master+':
            case 'Grand Master':
            case 'Master':
            case 'Honorary Master':
                rank = 0.8;
                break;
            case 'National':
                rank = 0.75;
                break;
            case 'Certified':
                rank = 0.4;
                break;
            case 'Rank Pending':
            case 'Provisional':
            case 'Recognized':
                rank = 0.2;
                break;
        }
        var modifier = 0;
        if (judge.judging.other_status) {
            if (judge.judging.other_status.sensory_training) {
                modifier = Math.max(modifier, 0.1);
            }
            if (judge.judging.other_status.professional_brewer ||
                judge.judging.other_status.professional_cidermaker ||
                judge.judging.other_status.professional_meadmaker ||
                judge.judging.other_status.sommelier ||
                judge.judging.other_status.cicerone) {
                modifier = Math.max(modifier, 0.25);
            }
        }
        return acc + Math.min(0.75, rank + modifier);
    }, 0);
}
export function validateEmail(email) {
    return email
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}
export function validateBJCPID(id) {
    return id.toLowerCase().match(/^[A-Za-z]{1}\d{4}$/);
}
export function calculateJudgingLoad(flights) {
    return flights.reduce(function (acc, f) {
        if (f.type === 'table_scoring') {
            if (f.table_snippet.scoring_flight_count > 1) {
                acc.approximate = true;
            }
            acc.scoring_entries += f.table_snippet.entry_count;
            acc.approximate_scoring_entries +=
                f.table_snippet.entry_count / f.table_snippet.scoring_flight_count;
            acc.scoring += 1;
            if (f.active) {
                acc.scoring_active += 1;
            }
        }
        else {
            acc.comparative += 1;
            if (f.active) {
                acc.comparative_active += 1;
            }
        }
        return acc;
    }, {
        approximate_scoring_entries: 0,
        scoring_entries: 0,
        comparative: 0,
        comparative_active: 0,
        scoring: 0,
        scoring_active: 0,
        approximate: false,
    });
}
export function isTouchDevice() {
    return ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0);
}
