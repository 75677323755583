import React from 'react';
import { IHasClassName, IMenuState } from '@flight-cap/shared';
import { ApplicationRoutes } from '../../routes';
import { Link } from '@reach/router';
// import { useHeaderMenu } from '../../hooks/useHeaderMenu';
import { useUserMenu } from '../../hooks/useUserMenu';
import { UserContext } from '../../providers/UserProvider';
import { signOut } from '../../firebase';
import DefaultUserPhoto from '../../assets/default_user_photo.svg';

interface IProps extends IMenuState, IHasClassName {
    onItemClicked: React.MouseEventHandler;
}

const MobileMenu: React.FunctionComponent<IProps> = ({ className, isOpen, onItemClicked }) => {
    const openClass = isOpen ? 'block' : 'hidden';
    // const menuItems = useHeaderMenu();
    const userMenuItems = useUserMenu();
    const { user } = React.useContext(UserContext);

    const handleSignOutClicked = (event: React.MouseEvent) => {
        event.preventDefault();
        signOut();
        onItemClicked(event);
    };

    return (
        <div className={`${openClass} md:hidden ${className}`}>
            {/* <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {menuItems.map(({ to, label }) => (
                    <Link key={to} to={to} onClick={onItemClicked} className="mobile-menu-item">
                        {label}
                    </Link>
                ))}
            </div> */}
            {user ? (
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5 space-x-3">
                        <div className="flex-shrink-0">
                            <img
                                className="h-10 w-10 rounded-full"
                                src={user.photo_url || DefaultUserPhoto}
                                alt="user-profile"
                            />
                        </div>
                        <div className="space-y-1">
                            <div className="text-base font-medium leading-none text-white">
                                {user.display_name}
                            </div>
                            <div className="text-sm font-medium leading-none text-gray-400">
                                {user.email}
                            </div>
                        </div>
                    </div>
                    <div
                        className="mt-3 px-2 space-y-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                    >
                        {userMenuItems.map(({ to, label }) => (
                            <Link
                                key={to}
                                to={to}
                                onClick={onItemClicked}
                                className="mobile-menu-item"
                            >
                                {label}
                            </Link>
                        ))}

                        <a
                            href={ApplicationRoutes.SignOut}
                            className="mobile-menu-item"
                            role="menuitem"
                            onClick={handleSignOutClicked}
                        >
                            Sign Out
                        </a>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default MobileMenu;
