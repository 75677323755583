import React from 'react';
import DeleteIcon from '../../Icon/DeleteIcon';
import EditIcon from '../../Icon/EditIcon';

export interface IEditDeleteCellRendererProps {
    value: string;
    hideEdit?: boolean;
    hideDelete?: boolean;
}

export type ItemGetSet<T> = { get: () => T; set: (item: T) => void };

export function useEditDeleteCell<T extends { uid: string }>(
    items: T[]
): [React.MouseEventHandler, ItemGetSet<T>, ItemGetSet<T>] {
    const [itemForDeletion, setItemForDeletion] = React.useState<T>(null);
    const [itemForUpdate, setItemForUpdate] = React.useState<T>(null);

    const tableClicked = React.useMemo(() => {
        const handleDeleteClick = (id: string) => {
            setItemForDeletion(items.find((item) => item.uid === id));
        };

        const handleEditClick = (id: string) => {
            setItemForUpdate(items.find((item) => item.uid === id));
        };

        return (evt: React.MouseEvent) => {
            if (evt.target instanceof HTMLButtonElement) {
                if (evt.target.classList.contains('cell-delete-btn')) {
                    handleDeleteClick(evt.target.value);
                    return;
                }

                if (evt.target.classList.contains('cell-edit-btn')) {
                    handleEditClick(evt.target.value);
                }
            }
        };
    }, [items]);

    return [
        tableClicked,
        {
            get: () => itemForDeletion,
            set: setItemForDeletion,
        },
        {
            get: () => itemForUpdate,
            set: setItemForUpdate,
        },
    ];
}

const EditDeleteCellRenderer: React.FunctionComponent<IEditDeleteCellRendererProps> = ({
    value,
    hideEdit,
    hideDelete,
}) => {
    return (
        <>
            {!hideEdit && (
                <button className="btn-indigo btn-outline mr-4 cell-edit-btn" value={value}>
                    <EditIcon />
                </button>
            )}
            {!hideDelete && (
                <button className="btn-red btn-outline mr-4 cell-delete-btn" value={value}>
                    <DeleteIcon />
                </button>
            )}
        </>
    );
};

export default EditDeleteCellRenderer;
