import React from 'react';
import { signInWithEmailAndPassword } from '../../firebase';
import { RouteComponentProps, Link } from '@reach/router';
import { getErrorMessage, useFirebaseAuthError } from '../../hooks/useFirebaseAuthError';
import { ApplicationRoutes } from '../../routes';
import { RecoverPasswordContext } from '../../providers/RecoverPasswordProvider';
import Alert, { AlertType } from '../../components/Alert/Alert';
import Card from '../../components/Card/Card';
import GoogleButton from './GoogleButton';
import Divider from '../../components/Divider/Divider';
import { FormProvider, useForm } from 'react-hook-form';
import FormErrorToast from '../../components/Form/FormErrorToast';
import { SimpleToastContent } from '../../components/Toasts/ToastMessage';
import { useToasts } from 'react-toast-notifications';

interface IFormData {
    email: string;
    password: string;
}

const SignIn: React.FunctionComponent<RouteComponentProps> = () => {
    const [AuthErrorDisplay, setAuthError] = useFirebaseAuthError();
    const [{ isRecovering, email: recoveryEmail }] = React.useContext(RecoverPasswordContext);
    const methods = useForm<IFormData>({
        defaultValues: {
            email: isRecovering ? recoveryEmail : '',
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = methods;

    const { addToast, removeAllToasts } = useToasts();

    const onSubmit = (data: IFormData) => {
        const { email, password } = data;
        setAuthError(null);
        signInWithEmailAndPassword(email, password).catch((error) => {
            removeAllToasts();
            addToast(
                <SimpleToastContent title="There was an error" subTitle={getErrorMessage(error)} />,
                { appearance: 'error' }
            );
        });
    };

    return (
        <>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-600">
                Sign in to your account
            </h2>
            <Card>
                {!isRecovering ? (
                    <div className="mt-6">
                        <GoogleButton />
                        <Divider>or continue with</Divider>
                    </div>
                ) : (
                    <></>
                )}
                <FormProvider {...methods}>
                    <FormErrorToast errors={errors}>
                        <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                            <div className="mb-4">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    aria-invalid={errors.email ? 'true' : 'false'}
                                    {...register('email', {
                                        required: 'Please enter your email address',
                                    })}
                                ></input>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email">Password</label>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    aria-invalid={errors.password ? 'true' : 'false'}
                                    {...register('password', {
                                        required: 'Please enter your password',
                                    })}
                                    autoComplete="current-password"
                                ></input>
                            </div>
                            <div>
                                <button
                                    className="btn-indigo w-full text-center my-4"
                                    type="submit"
                                >
                                    Sign In
                                </button>

                                <div className="text-sm">
                                    <Link
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                        to={ApplicationRoutes.RecoverPassword}
                                    >
                                        Forgot Password?
                                    </Link>
                                </div>
                            </div>
                            {isRecovering ? (
                                <Alert type={AlertType.Success} className="my-4">
                                    We've sent you an email with instructions for resetting your
                                    password!
                                </Alert>
                            ) : (
                                <></>
                            )}
                        </form>
                    </FormErrorToast>
                </FormProvider>
                <AuthErrorDisplay />
            </Card>
        </>
    );
};

export default SignIn;
