import { IEntry, IScoreSheetData } from '@flight-cap/shared';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import Confirm, { ConfirmTheme } from '../../../components/Confirm/Confirm';
import FormErrorToast from '../../../components/Form/FormErrorToast';
import ScoresheetEntryInformation from '../../../components/Scoresheet/ScoresheetEntryInformation';
import ScoresheetJudgeInformation from '../../../components/Scoresheet/ScoresheetJudgeInformation';
import SheetSection from '../../../components/Scoresheet/SheetSection';
import { SimpleToastContent } from '../../../components/Toasts/ToastMessage';
import { firestore } from '../../../firebase';
import { useHasFormChanges } from '../../../hooks/useHasFormChanges';
import { CompetitionContext } from '../../../providers/CompetitionProvider';

const AdminEditableScoresheet: React.FunctionComponent<{
    entry: IEntry;
    score: IScoreSheetData;
    className: string;
}> = ({ entry, score, className }) => {
    const [FormChangeWrapper, hasChanges, setHasChanges] = useHasFormChanges();

    const { competition } = React.useContext(CompetitionContext);
    const sections = entry?.sheet_schema?.sections || [];
    const methods = useForm<IScoreSheetData['values']>();
    const { addToast, removeAllToasts } = useToasts();
    const [showUpdateConfirmation, setShowUpdateConfirmation] = React.useState<boolean>(false);
    const [isValid, setIsValid] = React.useState(null);

    /**
     * Using this submit handler to trigger validation without preventing the submit event
     */
    const handleOnSubmit_ReactFormBypass: React.FormEventHandler<HTMLFormElement> = async (evt) => {
        evt.preventDefault();
        const valid = await methods.trigger();
        setIsValid(valid);
        methods.clearErrors();
        setShowUpdateConfirmation(true);
    };

    const handleUpdateCancel = () => {
        setShowUpdateConfirmation(false);
    };

    const handleUpdateConfirm = async () => {
        const values = methods.getValues();
        setShowUpdateConfirmation(false);
        removeAllToasts();
        try {
            await firestore
                .doc(
                    `competitions/${competition.uid}/entries/${entry.uid}/scoresheets/${score.uid}`
                )
                .update({ is_valid: isValid, values });

            addToast(
                <SimpleToastContent
                    title="Saved Successfully"
                    subTitle="Way to use your admin powers for good!"
                />,
                {
                    appearance: 'success',
                }
            );

            setHasChanges(false);
        } catch {
            addToast(
                <SimpleToastContent
                    title="Saving Failed"
                    subTitle="We're sorry, but we couldn't save your scoresheet"
                />,
                {
                    appearance: 'error',
                }
            );
        }
    };

    return (
        <div className={className}>
            <div className="flex flex-initial justify-between mb-8 opacity-50">
                <ScoresheetEntryInformation entry={entry} />
                <section className="flex flex-col items-end">
                    <ScoresheetJudgeInformation judge={score.judge_snippet} />
                </section>
            </div>
            <FormProvider {...methods}>
                <FormErrorToast errors={methods.formState.errors}>
                    <form onSubmit={handleOnSubmit_ReactFormBypass}>
                        <FormChangeWrapper className="lg:grid lg:grid-cols-2 lg:gap-x-8">
                            {sections.map((section, index) => (
                                <SheetSection
                                    key={`${section.title}-${index}`}
                                    {...section}
                                    scoreSheetData={score}
                                    className={section.className}
                                ></SheetSection>
                            ))}
                        </FormChangeWrapper>
                        <button
                            type="submit"
                            className={`btn-yellow w-full my-4 ${hasChanges ? '' : 'btn-outline'}`}
                            disabled={!hasChanges}
                        >
                            Save Changes
                        </button>
                    </form>
                </FormErrorToast>
            </FormProvider>
            <Confirm
                theme={ConfirmTheme.Warning}
                title={`Edit Scoresheet`}
                onConfirm={handleUpdateConfirm}
                onCancel={handleUpdateCancel}
                show={showUpdateConfirmation}
            >
                <div className="text-sm text-gray-500 space-y-2">
                    <p>Are you sure you want to apply these changes?</p>
                    <p>
                        This action will make a permenant change to the scoresheet, and appear to
                        the entrant as the judge's original feedback.
                    </p>
                </div>
            </Confirm>
        </div>
    );
};

AdminEditableScoresheet.defaultProps = {
    className: '',
};

export default AdminEditableScoresheet;
