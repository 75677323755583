import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../providers/UserProvider';
import { CompetitionContext } from '../../providers/CompetitionProvider';
import { ApplicationRoutes } from '../../routes';
import { parameterizeEndpoint, UserCompetitionRole } from '@flight-cap/shared';
import { addCompetitionParticipant } from '../../firebase';
import { LeftColumnTitle } from '../../components/Card/TwoColumnCard';
import Card from '../../components/Card/Card';

const CompetitionJoin: React.FunctionComponent<RouteComponentProps> = () => {
    const { competition } = React.useContext(CompetitionContext);
    const { user } = React.useContext(UserContext);

    const navgiateToDashboard = (route: ApplicationRoutes) => {
        navigate(
            parameterizeEndpoint(route, {
                competitionSlug: competition.slug,
            })
        );
    };

    const roles = user?.competitions?.[competition?.uid]?.roles;
    if (roles) {
        if (roles.includes('judge')) {
            navgiateToDashboard(ApplicationRoutes.JudgingDashboard);
        } else if (roles.includes('steward')) {
            navgiateToDashboard(ApplicationRoutes.StewardingDashboard);
        } else {
            navgiateToDashboard(ApplicationRoutes.CompetitionInfo);
        }
    }

    interface IFormData {
        role: UserCompetitionRole[];
    }
    const defaultRoleSelection: UserCompetitionRole[] = ['judge'];

    const { handleSubmit, register, watch } = useForm<IFormData>({
        defaultValues: { role: defaultRoleSelection },
    });

    const onSubmit = async (data: IFormData) => {
        addCompetitionParticipant(competition.uid, user.uid, data.role);
    };

    const watchRole = watch('role', defaultRoleSelection);
    const roleSelected = watchRole.length > 0;

    return competition ? (
        <Card>
            <LeftColumnTitle title={`Join Competition: ${competition.title}`} />
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="md:flex md:items-center md:justify-center h-full flex-col"
            >
                <div className="mt-4 text-gray-700 text-sm">
                    <p className="my-2">This competition could use your help!</p>
                    <div className="py-4">
                        <p className="my-2">
                            Joining as a judge will allow the organizers to add you to flights. Once
                            you are in an active flight, you'll be able to access the judging tools.
                        </p>
                        <label>
                            I'd like to Judge
                            <input
                                className="ml-2"
                                type="checkbox"
                                {...register('role')}
                                value="judge"
                            ></input>
                        </label>
                    </div>
                    {/* <div className="py-4">
                        <p className="my-2">
                            Joining as a steward will allow you to see the pullsheets for flights.
                        </p>
                        <label>
                            I'd like to Steward
                            <input
                                className="ml-2"
                                type="checkbox"
                                {...register('role')}
                                value="steward"
                            ></input>
                        </label>
                    </div> */}
                    <p className="my-2">Thanks for helpling!</p>
                </div>

                <button type="submit" className="btn-indigo w-full md:w-max">
                    {roleSelected
                        ? `Join as ${watchRole
                              .map((r: string) => r[0].toUpperCase() + r.substring(1))
                              .join(' and ')}!`
                        : 'Join!'}
                </button>
            </form>
        </Card>
    ) : (
        <></>
    );
};

export default CompetitionJoin;
