import React, { useContext } from 'react';
import Editable from '../../../../components/Editable/Editable';
import { calculateJudgingLoad, ISession } from '@flight-cap/shared';
import { useForm } from 'react-hook-form';

import { FlightsContext } from '../../providers/FlightsProvider';
import DropZoneFlights from '../dnd/DropZoneFlights';
import SessionLoad from './SessionLoad';
import { SessionUpdateField } from '../SessionsAdmin';
import SessionMeatballMenu from './SessionMeatballMenu';
import JudgeCoverage from '../JudgeCoverage';
import FlightListItem from '../flights/FlightListItem';

export enum SessionEditorDragType {
    Participant = 'participant',
    Flight = 'flight',
}

export enum SessionEditorDropActions {
    AddSessionToFlight = 'add-session-to-flight',
    AddJudgeToFlight = 'add-judge-to-flight',
}

interface Props {
    session: ISession;
    onUpdateSession: (uid: string, updates: Partial<ISession>) => void;
    onSessionSelected: (uid: string, checked: boolean) => void;
    selected?: boolean;
}

const SessionThumbnail: React.FunctionComponent<Props> = ({
    session,
    onUpdateSession,
    onSessionSelected,
    selected = false,
}) => {
    const { location, title, start_date, map_link } = session;
    const { flights } = useContext(FlightsContext);

    const flightsInSession = React.useMemo(
        () =>
            flights
                .filter((f) => f.session_id === session.uid)
                .sort((a, b) => (a.name() > b.name() ? 1 : -1)),
        [flights, session]
    );

    // const tablesInSession = React.useMemo(() => {
    //     const map = new Map<string, ITableSnippet>();
    //     flightsInSession.forEach((f) => {
    //         if (f.type === 'table_scoring' || f.type === 'table_comparative') {
    //             map.set(f.table_snippet.uid, f.table_snippet);
    //         }
    //     });
    //     return Array.from(map.values()).sort((a, b) => (a.title > b.title ? 1 : -1));
    // }, [flightsInSession]);

    const {
        handleSubmit,
        register,
        resetField,
        formState: { errors },
    } = useForm<Partial<ISession>>({
        defaultValues: {
            title,
            location,
            start_date,
            map_link,
        },
    });

    const onSubmit = (fields: SessionUpdateField[]) => async (data: Partial<ISession>) => {
        const updates = fields.reduce((obj, curr) => {
            obj[curr] = data[curr];
            return obj;
        }, {} as Record<SessionUpdateField, any>);

        onUpdateSession(session.uid, updates);
    };

    const availableJudges = Object.values(session.participant_snippets || {}).filter((p) =>
        p.roles.includes('judge')
    );

    const sessionLoad = React.useMemo(() => {
        return calculateJudgingLoad(flightsInSession);
    }, [flightsInSession]);

    const sessionCoverage =
        flightsInSession.reduce((acc, cur) => (acc += cur.coverage), 0) / flightsInSession.length;

    const ineligibleFlight = !!flightsInSession.find((f) => f.judge_conflicts.length > 0);

    return (
        <DropZoneFlights session={session} id={`session-${session.uid}-thumbnail`}>
            <div
                className={`flex w-full h-full text-start border rounded overflow-hidden ${
                    selected ? 'border-indigo-600 shadow-lg' : ''
                }`}
            >
                <div className="flex border-r border-gray-150">
                    <div className="flex">
                        {ineligibleFlight && (
                            <JudgeCoverage coverage={1.0} error={ineligibleFlight} />
                        )}
                        <JudgeCoverage coverage={sessionCoverage} error={false} />
                    </div>
                </div>

                <div className="flex flex-col w-full h-full justify-between">
                    <div className="flex items-center justify-between p-2 pl-4 border-b border-gray-100">
                        <label className="flex items-center space-x-2 text-gray-400 hover:text-indigo-600 cursor-pointer">
                            <input
                                readOnly
                                type="checkbox"
                                className=""
                                onChange={(e) => onSessionSelected(session.uid, e.target.checked)}
                            />
                            <span>Open session detail</span>
                        </label>
                        <SessionMeatballMenu session={session} />
                    </div>
                    <section className="flex flex-col h-full w-full">
                        <div className="w-full flex flex-col justify-between h-full p-2 pl-4">
                            <div className="flex items-baseline justify-between">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <>
                                        <div className="w-full">
                                            <Editable
                                                onConfirm={handleSubmit(onSubmit(['title']))}
                                                onCancel={() => resetField('title')}
                                                className="flex"
                                                staticNode={
                                                    <h1 className="font-medium inline">
                                                        {session.name()}
                                                    </h1>
                                                }
                                                editorNode={
                                                    <input
                                                        autoFocus
                                                        type="text"
                                                        placeholder="Session Title"
                                                        aria-invalid={
                                                            errors.title ? 'true' : 'false'
                                                        }
                                                        {...register('title')}
                                                    ></input>
                                                }
                                            />

                                            <Editable
                                                className="text-sm"
                                                onConfirm={handleSubmit(onSubmit(['start_date']))}
                                                onCancel={() => resetField('start_date')}
                                                staticNode={
                                                    <span>
                                                        {start_date ? (
                                                            new Date(start_date).toLocaleString(
                                                                'en-US'
                                                            )
                                                        ) : (
                                                            <span className="text-gray-400">
                                                                Time
                                                            </span>
                                                        )}
                                                    </span>
                                                }
                                                editorNode={
                                                    <input
                                                        autoFocus
                                                        type="datetime-local"
                                                        placeholder="Session Location"
                                                        aria-invalid={
                                                            errors.location ? 'true' : 'false'
                                                        }
                                                        {...register('start_date')}
                                                    ></input>
                                                }
                                            />
                                        </div>
                                    </>
                                </form>
                            </div>
                            {/* flights */}
                            {flightsInSession.length > 0 && (
                                <ul className="text-xs text-gray-600 inline-block">
                                    {flightsInSession.map((f) => (
                                        <li className="flex" key={f.uid}>
                                            <FlightListItem flight={f} />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <section className="text-sm mt-2 flex justify-between items-center border-t  border-gray-100 p-2 pl-4 overflow-y-scroll">
                            <span className="mr-8">
                                <SessionLoad load={sessionLoad} judges={availableJudges} />
                            </span>
                        </section>
                    </section>
                </div>
            </div>
        </DropZoneFlights>
    );
};

export default SessionThumbnail;
