import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FlightsContext } from '../providers/FlightsProvider';
import {
    containsClass,
    getDataAttributes,
    ICustomComparativeFlight,
    IEntry,
    IFlight,
    ITableComparativeFlight,
    ITableScoringFlight,
} from '@flight-cap/shared';
import Confirm, { ConfirmTheme } from '../../../components/Confirm/Confirm';
import FlightEditDrawer from './FlightEditDrawer';
import { useEditDeleteCell } from '../../../components/Table/renderers/EditDeleteCellRenderer';
import { EntriesContext } from '../providers/EntriesProvider';
import Table from '../../../components/Table/Table';
import {
    tableScoringFlightColumns,
    miniBosFlightColumns,
    customFlightColumns,
} from './FlightsTable.columns';
import { TablesContext } from '../providers/TablesProvider';
import { CompetitionContext } from '../../../providers/CompetitionProvider';

export type TableScoringFlightRow = ITableScoringFlight & {
    table_entries: IEntry[];
    claimed_entries: IEntry[];
    mini_bos_entries: IEntry[];
};

export type MiniBosFlightRow = ITableComparativeFlight & {
    mini_bos_entries: IEntry[];
    table_entries: IEntry[];
};

export type CustomCompareFlightRow = ICustomComparativeFlight & { competitionId: string };

const FlightsAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const {
        flights,
        deleteFlight,
        updateFlight,
        addTableScoringFlight,
        addCustomComparativeFlight,
    } = React.useContext(FlightsContext);
    const { entries } = React.useContext(EntriesContext);
    const [handleEditDeleteTableClicked, flightForDeletion, flightForUpdate] =
        useEditDeleteCell<IFlight>(flights);
    const { tables } = React.useContext(TablesContext);
    const { competition } = React.useContext(CompetitionContext);

    const standardRows = React.useMemo(
        () =>
            flights
                ?.filter((f) => f.type === 'table_scoring')
                .sort((a, b) => (a.name() > b.name() ? 1 : -1))
                .map((f) => {
                    const claimed_entries =
                        entries?.filter((e) => e.flight_snippet?.uid === f.uid) || [];

                    return {
                        ...f,
                        table_entries:
                            entries?.filter(
                                (e) =>
                                    e.table_snippet?.uid ===
                                    (f as ITableScoringFlight).table_snippet?.uid
                            ) || [],
                        claimed_entries,
                        mini_bos_entries: claimed_entries.filter((e) => e.mini_bos),
                    };
                }) || [],
        [flights, entries]
    );

    const miniBosRows = React.useMemo(
        () =>
            flights
                ?.filter((f) => f.type === 'table_comparative')
                .sort((a, b) => (a.name() > b.name() ? 1 : -1))
                .map((f) => {
                    const table_entries =
                        entries?.filter(
                            (e) =>
                                e.table_snippet?.uid ===
                                (f as ITableComparativeFlight).table_snippet?.uid
                        ) || [];

                    return {
                        ...f,
                        table_entries,
                        mini_bos_entries: table_entries?.filter((e) => e.mini_bos) || [],
                    };
                }) || [],
        [flights, entries]
    );

    const customRows = React.useMemo(
        () =>
            flights
                ?.filter((f) => f.type === 'custom_comparative')
                .sort((a, b) => (a.name() > b.name() ? 1 : -1))
                .map((f) => {
                    return {
                        ...f,
                        competitionId: competition.uid,
                    };
                }) || [],
        [flights, competition?.uid]
    );

    const handleTableClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            handleEditDeleteTableClicked(evt);
            if (containsClass(evt.target, 'toggle-flight-active')) {
                const data = getDataAttributes(evt.target);
                const flight = [...miniBosRows, ...standardRows, ...customRows].find(
                    (f) => f.uid === data['data-flight-id']
                );
                updateFlight(flight.uid, { active: !flight.active });
            }
        },
        [handleEditDeleteTableClicked, standardRows, updateFlight, miniBosRows, customRows]
    );

    const handleDeleteConfirm = () => {
        deleteFlight(flightForDeletion.get().uid);
        flightForDeletion.set(null);
    };

    const handleDeleteCancel = () => {
        flightForDeletion.set(null);
    };

    const handleDrawerClose = () => {
        flightForUpdate.set(null);
    };

    const handleAddScoringFlightClicked = async () => {
        const tableId = (document.getElementById('add-scoring-table-select') as HTMLSelectElement)
            .value;
        addTableScoringFlight(tableId);
    };

    const handleAddCustomComparativeFlightClicked = async () => {
        addCustomComparativeFlight();
    };

    return (
        <>
            <div onClick={handleTableClicked}>
                <div className="flex justify-between items-center mb-2">
                    <h3 className="my-4">Table Scoring Flights</h3>
                    <label className="flex flex-col items-end" htmlFor="add-scoring-table-select">
                        <span className="relative z-0 inline-flex shadow-sm rounded-md h-min">
                            <select className="rounded-r-none" id="add-scoring-table-select">
                                <optgroup label="Choose a table">
                                    {tables?.map((t) => (
                                        <option key={t.uid} value={t.uid}>
                                            {t.title}
                                        </option>
                                    ))}
                                </optgroup>
                            </select>
                            <button
                                onClick={handleAddScoringFlightClicked}
                                className="-ml-px text-indigo-700 relative inline-flex items-center px-2 py-2 rounded-none rounded-r-md border border-gray-300 bg-white"
                            >
                                Add
                            </button>
                        </span>
                    </label>
                </div>
                <Table
                    columns={tableScoringFlightColumns}
                    data={standardRows || []}
                    maxHeight="95vh"
                    rowSelection={false}
                />
                <hr className="my-8" />
                <div className="flex justify-between items-center mb-2">
                    <h3 className="my-4">Mini-BOS Flights</h3>
                </div>
                <Table
                    columns={miniBosFlightColumns}
                    data={miniBosRows || []}
                    maxHeight="95vh"
                    rowSelection={false}
                />
                <hr className="my-8" />
                <div className="flex justify-between items-center mb-2">
                    <h3 className="my-4">Other Flights</h3>
                    <button
                        onClick={handleAddCustomComparativeFlightClicked}
                        className="-ml-px text-indigo-700 relative inline-flex items-center px-2 py-2 rounded border border-gray-300 bg-white"
                    >
                        Add Custom Flight
                    </button>
                </div>
                <Table
                    columns={customFlightColumns}
                    data={customRows || []}
                    maxHeight="95vh"
                    rowSelection={false}
                />
            </div>
            <Confirm
                theme={ConfirmTheme.Danger}
                title={`Delete Flight`}
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                show={flightForDeletion.get() ? true : false}
            >
                <p className="text-sm text-gray-500">
                    Are you sure you want to delete the flight:{' '}
                    <span className="text-red-700">{flightForDeletion.get()?.name()}</span>?
                </p>
            </Confirm>
            {flightForUpdate.get() && (
                <FlightEditDrawer onCancel={handleDrawerClose} flight={flightForUpdate.get()} />
            )}
        </>
    );
};

export default FlightsAdmin;
