import { firestore } from '../firebase';

export function updateDocImperative(path: string, data: any): Promise<void> {
    return new Promise(async (resolve, reject) => {
        try {
            await firestore.doc(path).update(data);
            resolve();
        } catch (err) {
            reject(err);
        }
    });
}
