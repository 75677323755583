import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { IParticipantSnippet, IFlight, getFlightJudgeConflicts } from '@flight-cap/shared';
import { DraggableStatusVariant, SessionEditorDragType } from '../sessions/SessionEditor';
import SessionJudge from '../sessions/SessionJudge';
import { SessionInspectionContext } from '../SessionInspectionProvider';

export interface IDraggableJudgeData {
    type: SessionEditorDragType.Participant;
    participant: IParticipantSnippet;
    sessionId: string;
    flight?: IFlight;
}

const DraggableJudge: React.FunctionComponent<{
    sessionId: string;
    flight?: IFlight;
    participant: IParticipantSnippet;
}> = ({ participant, sessionId, flight }) => {
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: `draggable-session-participant-${sessionId}-${flight?.uid || ''}-${participant.uid}`,
        data: {
            type: SessionEditorDragType.Participant,
            participant: participant,
            sessionId: sessionId,
            flight: flight,
        } as IDraggableJudgeData,
    });

    let variant: DraggableStatusVariant = 'idle';
    const { inspectedFlight } = React.useContext(SessionInspectionContext);

    if (flight) {
        const conflicts = getFlightJudgeConflicts(flight || inspectedFlight, [participant]);
        if (conflicts.length > 0) {
            variant = 'error';
        }
    } else if (inspectedFlight) {
        const conflicts = getFlightJudgeConflicts(flight || inspectedFlight, [participant]);
        if (conflicts.length > 0) {
            variant = 'warning';
        }
    }

    return (
        <span
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            className={`touch-manipulation inline-block transition-all !select-none ${
                isDragging ? 'opacity-30' : ''
            } w-full`}
        >
            <SessionJudge
                participant={participant}
                sessionId={sessionId}
                variant={variant}
                condensed={!!flight}
                showRemove={!!flight}
                flight={flight}
            />
        </span>
    );
};

export default DraggableJudge;
