import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { EntriesContext } from '../providers/EntriesProvider';
import {
    parseCSV,
    parseBCOEMEntry,
    IScoreSheetData,
    containsClass,
    getAttribute,
    IEntry,
} from '@flight-cap/shared';
import Confirm, { ConfirmTheme } from '../../../components/Confirm/Confirm';
import EntryEditDrawer from './EntryEditDrawer';
import { useEditDeleteCell } from '../../../components/Table/renderers/EditDeleteCellRenderer';
import { entryColumns, IEntryRow } from './EntriesTable.columns';
import Table, { useSelectedRows } from '../../../components/Table/Table';
import DeleteIcon from '../../../components/Icon/DeleteIcon';
import EditIcon from '../../../components/Icon/EditIcon';
import { TablesContext } from '../providers/TablesProvider';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { StylesContext } from '../providers/StylesProvider';
import { usePrinter } from '../../../hooks/usePrinter';
import { firestore } from '../../../firebase';
import EntryPrintScoresheets from './EntryPrintScoresheets';
import { ScoresheetsContext } from '../providers/ScoresheetsProvider';
import UploadIcon from '../../../components/Icon/UploadIcon';

const EntriesAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const { entries, addEntries, deleteEntries } = React.useContext(EntriesContext);
    const { tables } = React.useContext(TablesContext);
    const { styles } = React.useContext(StylesContext);
    const { competition } = React.useContext(CompetitionContext);
    const { scoresheets } = React.useContext(ScoresheetsContext);

    const [PrintPortal, printPage] = usePrinter();

    const [selectedRows, handleSelectedRowsChange] = useSelectedRows<IEntryRow>();

    const [entryForPrint, setEntryForPrint] = React.useState<{
        entry: IEntry;
        scores: IScoreSheetData[];
    }>(null);
    const [itemsForUpdate, setItemsForUpdate] = React.useState<IEntryRow[]>(null);
    const [itemsForDeletion, setItemsForDeletion] = React.useState<IEntryRow[]>(null);

    const handleDeleteConfirm = () => {
        const entryIds = itemsForDeletion.map((item) => item.uid);

        if (itemForDeletion.get()) {
            entryIds.push(itemForDeletion.get().uid);
        }
        deleteEntries(entryIds);
        itemForDeletion.set(null);
        setItemsForDeletion(null);
    };

    const rows: IEntryRow[] = React.useMemo(() => {
        return (
            entries?.map((e) => {
                return {
                    ...e,
                    unrecognizedStyle: !styles?.find((s) => s.uid === e.style_snippet.uid),
                    downloadSheetsUrl: `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}pdfs/competition/${competition?.uid}/entry/${e.uid}`,
                    sheetCount: Object.keys(e.score_snippets || {}).length,
                    sheetsNeedingAttention: scoresheets
                        .filter((sheet) => sheet.entry_id === e.uid && sheet.needs_attention)
                        .map((s) => s.needs_attention),
                };
            }) || []
        );
    }, [competition, entries, scoresheets, styles]);

    const [handleTableClicked, itemForDeletion, itemForUpdate] = useEditDeleteCell<IEntryRow>(rows);

    const handleDeleteCancel = () => {
        itemForDeletion.set(null);
        setItemsForDeletion(null);
    };

    const handleDrawerClose = () => {
        itemForUpdate.set(null);
        setItemsForUpdate(null);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const file = files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', ({ target: { result } }) => {
                event.target.value = null;
                addEntries(parseCSV(String(result)).map(parseBCOEMEntry), tables);
            });
            reader.readAsText(file, 'utf-8');
        }
    };

    const handleBulkEditClick = React.useCallback(
        (evt: React.MouseEvent) => {
            setItemsForUpdate(selectedRows);
        },
        [selectedRows]
    );

    const handleBulkDeleteClick = React.useCallback(
        (evt: React.MouseEvent) => {
            setItemsForDeletion(selectedRows);
        },
        [selectedRows]
    );

    const handleExportScoreClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            const csv = entries.reduce(
                (csv, entry) =>
                    `${csv}${entry.entry_number},${entry.judging_number},${
                        entry.entrant_snippet.bcoem_id
                    },${entry.consensus_score},${entry.mini_bos ? 1 : 0},${
                        entry.table_snippet?.title || ''
                    },${parseInt(entry.table_snippet?.title.match(/^\d*/)[0]) || ''},${
                        entry.table_place ? entry.table_place : ''
                    },${{ beer: 1, cider: 2, mead: 3 }[entry.style_snippet.type]},\r\n`,
                'data:text/csv;charset=utf-8,Entry Number,Judging Number,BCOEM Brewer Id,Consensus Score,Mini Bos,Table Name,Table Number,Table Place, Score Type\r\n'
            );
            const link = document.createElement('a');
            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', `${competition.slug}_scores.csv`);
            document.body.appendChild(link); // Required for FF
            link.click();
        },
        [entries, competition.slug]
    );

    const handleEntryTableClicked = async (evt: React.MouseEvent) => {
        handleTableClicked(evt);

        if (containsClass(evt.target, 'btn-score-sheets-print')) {
            const entryId = getAttribute(evt.target, 'data-entry-uid');
            const entry = entries.find((e) => e.uid === entryId);
            const sheetsRef = await firestore
                .collection(`competitions/${competition.uid}/entries/${entryId}/scoresheets`)
                .get();
            const scores = sheetsRef.docs.map(
                (doc) => ({ uid: doc.id, ...doc.data() } as IScoreSheetData)
            );
            setEntryForPrint({ entry, scores });
            printPage(`${entry.judging_number} Scoresheets`);
        }
    };

    return (
        <>
            <div className="flex justify-between items-center mb-8">
                <span className="flex space-x-2">
                    {selectedRows.length ? (
                        <>
                            <button
                                className="btn-indigo h-min btn-outline mx-2 cell-delete-btn"
                                onClick={handleBulkEditClick}
                            >
                                <EditIcon />
                            </button>

                            <button
                                className="btn-red h-min btn-outline mx-2 cell-delete-btn"
                                onClick={handleBulkDeleteClick}
                            >
                                <DeleteIcon />
                            </button>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
                <span className="flex space-x-2">
                    <label
                        className="btn btn-indigo btn-outline flex items-center px-4 py-6 m-0 cursor-pointer w-auto"
                        title="import entries from a BCOEM CVS file"
                    >
                        <UploadIcon className="w-2 h-2" />
                        <span className="ml-2">Import entries from BCOEM</span>
                        <input
                            type="file"
                            className="hidden"
                            accept="text/csv"
                            onChange={handleFileChange}
                        />
                    </label>
                    <button
                        className="btn-indigo btn-outline h-min text-sm flex items-center px-4 cursor-pointer w-auto"
                        onClick={handleExportScoreClicked}
                    >
                        Export Entry Scores
                    </button>
                </span>
            </div>
            <div onClick={handleEntryTableClicked}>
                <Table
                    columns={entryColumns}
                    data={rows || []}
                    maxHeight="95vh"
                    rowSelection={true}
                    onSelectedChange={handleSelectedRowsChange}
                />
            </div>
            <Confirm
                theme={ConfirmTheme.Danger}
                title={`Delete Entry`}
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
                show={itemForDeletion.get() || itemsForDeletion?.length > 0 ? true : false}
            >
                <p className="text-sm text-gray-500">
                    {itemsForDeletion?.length > 0 ? (
                        `Are you sure you want to delete ${itemsForDeletion?.length} entries`
                    ) : (
                        <>
                            Are you sure you want to delete the entry:{' '}
                            <span className="text-red-700">
                                {itemForDeletion.get()?.judging_number}
                            </span>
                            ?
                        </>
                    )}
                </p>
            </Confirm>
            <EntryEditDrawer
                entries={itemsForUpdate || (itemForUpdate.get() ? [itemForUpdate.get()] : [])}
                onCancel={handleDrawerClose}
            ></EntryEditDrawer>
            <PrintPortal>
                <EntryPrintScoresheets {...entryForPrint} />
            </PrintPortal>
        </>
    );
};

export default EntriesAdmin;
