import { Active, useDroppable } from '@dnd-kit/core';
import { ISession } from '@flight-cap/shared';
import React from 'react';
import { SessionEditorDragType, SessionEditorDropActions } from '../sessions/SessionEditor';

export interface IFlightDropZoneData {
    accepts: SessionEditorDragType.Flight;
    action: SessionEditorDropActions.AddSessionToFlight;
    sessionId: string;
}

function canDrop(active: Active, sessionId: string): boolean {
    let valid = active?.data.current.type === SessionEditorDragType.Flight;

    if (valid) {
        valid = active?.data.current.flightSessionId !== sessionId;
    }

    return valid;
}

const DropZoneFlights: React.FunctionComponent<{
    session: ISession;
    children: React.ReactNode;
    id: string;
}> = ({ session, children, id }) => {
    const sessionId = session?.uid || null;
    const { isOver, setNodeRef, active } = useDroppable({
        id: `dropzone-flight-${id}`,
        data: {
            accepts: SessionEditorDragType.Flight,
            action: SessionEditorDropActions.AddSessionToFlight,
            sessionId,
        } as IFlightDropZoneData,
    });

    let ref = canDrop(active, session?.uid) ? setNodeRef : null;

    return (
        <div
            ref={ref}
            className={`flex h-full transition-all ${isOver ? 'drop-zone-allowed' : ''}`}
        >
            {children}
        </div>
    );
};

export default DropZoneFlights;
