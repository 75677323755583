import React from 'react';

const DisabledWrapper: React.FunctionComponent<{
    disabled: boolean;
    children: React.ReactNode;
}> = ({ disabled, children }) =>
    disabled ? <div className={'cursor-not-allowed opacity-30'}>{children}</div> : <>{children}</>;

export const ToggleContext = React.createContext<boolean>(false);

const BaseToggle = React.forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement>>(
    ({ className, checked, onChange, onClick, children, ...props }, ref) => {
        const [enabled, setEnabled] = React.useState(checked || false);
        const labelRef = React.useRef<HTMLLabelElement>();

        React.useEffect(() => {
            if (labelRef.current) {
                const initailChecked = (
                    labelRef.current?.querySelector('.hidden-checkbox') as HTMLInputElement
                )?.checked;

                if (initailChecked === false || initailChecked === true) {
                    setEnabled(initailChecked);
                }
            }
        }, [setEnabled]);

        React.useEffect(() => {
            if (checked === false || checked === true) {
                setEnabled(checked);
            }
        }, [checked]);

        const controlled = checked === false || checked === true;

        const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
            if (controlled) {
                return;
            }
            setEnabled(evt.target.checked);
        };

        return (
            <label ref={labelRef} className={`${className}`} {...(props as any)}>
                <input
                    ref={ref}
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    onClick={onClick}
                    {...props}
                    className={`hidden hidden-checkbox pointer-events-none`}
                />

                <DisabledWrapper disabled={props.disabled}>
                    <ToggleContext.Provider value={enabled}>{children}</ToggleContext.Provider>
                </DisabledWrapper>
            </label>
        );
    }
);

export default BaseToggle;
