import { IEntry } from '@flight-cap/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type OverrideProps =
    | { field: 'required_info' }
    | { field: 'specific_info' }
    | { field: 'optional_info' };
const EntryInforOverride: React.FunctionComponent<
    {
        entry: IEntry;
        label: string;
    } & OverrideProps
> = ({ field, label, entry }) => {
    const { register, setValue } = useFormContext();

    type OverrideKey =
        | 'required_info_override'
        | 'specific_info_override'
        | 'optional_info_override';

    const key = (
        {
            required_info: 'required_info_override',
            specific_info: 'specific_info_override',
            optional_info: 'optional_info_override',
        } as Record<string, OverrideKey>
    )[field];
    const [hasEdits, setHasEdits] = React.useState<boolean>(!!entry[key]);

    const handleRevertClicked = (evt: React.MouseEvent) => {
        evt.preventDefault();
        setValue(`${field}_temp`, entry[field]);
        setHasEdits(false);
    };

    const handleChange = () => {
        if (hasEdits !== true) {
            setHasEdits(true);
        }
    };

    return (
        <div className="flex flex-col">
            <div className="flex items-center justify-between">
                <label>{label}</label>
                {hasEdits && (
                    <button
                        className="text-xs text-gray-500 flex item-center"
                        onClick={handleRevertClicked}
                    >
                        <svg
                            className="-ml-0.5 h-4 w-4 mr-1 text-yellow-400"
                            fill="currentColor"
                            viewBox="0 0 8 8"
                        >
                            <circle cx={4} cy={4} r={3} />
                        </svg>
                        Revert to original
                    </button>
                )}
            </div>
            <textarea {...register(`${field}_temp`, { onChange: handleChange })} />
        </div>
    );
};

export default EntryInforOverride;
