import { IEntry } from '@flight-cap/shared';
import React from 'react';
import Confirm, { ConfirmTheme } from '../../components/Confirm/Confirm';
import Drawer, { IDrawerProps } from '../../components/Drawer/Drawer';
import ClaimEntryItem from './ClaimEntryItem';
import { TableScoringFlightEntriesContext } from './providers/FlightEntries/TableScoringFlightEntriesProvider';

const ClaimEntryDrawer: React.FunctionComponent<{
    show: boolean;
    closeDrawer: IDrawerProps['onCancel'];
    onEntryClaimed: (entry: IEntry) => void;
}> = ({ show, closeDrawer, onEntryClaimed }) => {
    const { flight, flightEntries, assignEntryToFlight } = React.useContext(
        TableScoringFlightEntriesContext
    );
    const [entryForClaim, setEntryForClaim] = React.useState<IEntry>(null);
    const [isValidConfirm, setIsValidConfirm] = React.useState<boolean>(false);

    const handleAvailableEntriesClicked = (evt: React.MouseEvent) => {
        if (evt.target instanceof HTMLButtonElement) {
            if (evt.target.classList.contains('claim-entry-button')) {
                const entryId = evt.target.getAttribute('data-entry-uid');
                const entry = flightEntries.unclaimed.find((entry) => entry.uid === entryId);
                setEntryForClaim(entry);
            }
        }
    };

    const handleClaimConfirm = async () => {
        await assignEntryToFlight(flight.uid, entryForClaim.uid);
        onEntryClaimed(entryForClaim);
        setEntryForClaim(null);
        setIsValidConfirm(false);
    };

    const handleClaimCancel = () => {
        setEntryForClaim(null);
        setIsValidConfirm(false);
    };

    const handleEntryInputChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const isValid = entryForClaim && evt.target.value === String(entryForClaim.judging_number);
        if (isValid !== isValidConfirm) {
            setIsValidConfirm(isValid);
        }
    };

    return (
        <>
            <Drawer title="Entries" show={show} onCancel={closeDrawer}>
                <div onClick={handleAvailableEntriesClicked}>
                    <div className="divide-y divide-gray-200 overflow-y-auto">
                        {flightEntries?.unclaimed?.length > 0 ? (
                            <>
                                <div className="pl-2 py-2 text-sm text-gray-500">
                                    Available Entries
                                </div>
                                {flightEntries?.unclaimed.map((entry) => (
                                    <ClaimEntryItem key={entry.uid} entry={entry} />
                                ))}
                            </>
                        ) : (
                            <p className="container-dashed-gray m-8 p-16">
                                There are no available entries!
                            </p>
                        )}

                        {flightEntries?.anotherFlight?.length > 0 ? (
                            <>
                                <div className="pl-2 py-2 text-sm text-gray-500">
                                    Entries claimed in another flight
                                </div>
                                {flightEntries?.anotherFlight.map((entry) => (
                                    <ClaimEntryItem key={entry.uid} disabled entry={entry} />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </Drawer>
            <Confirm
                theme={ConfirmTheme.Neutral}
                title={`Claim Entry ${entryForClaim?.judging_number}`}
                onConfirm={handleClaimConfirm}
                onCancel={handleClaimCancel}
                show={entryForClaim ? true : false}
                confirmLabel="Claim Entry"
                disableConfirm={!isValidConfirm}
            >
                <div className="text-sm text-gray-500">
                    <p className="my-2">
                        Claiming this entry will add it to your flight and will prevent another
                        flight of judges from claiming it. Before you can claim this entry, you must
                        confirm the entry number in the text field below:
                    </p>
                    <div className="flex justify-center">
                        <div className="w-40">
                            <input type="text" onChange={handleEntryInputChanged}></input>
                        </div>
                    </div>
                </div>
            </Confirm>
        </>
    );
};

export default ClaimEntryDrawer;
