import { IScoreSheetData, ISheetSchema } from '@flight-cap/shared';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SheetSection from '../../../components/Scoresheet/SheetSection';

const ScoresheetBody: React.FunctionComponent<{
    schema: ISheetSchema;
    scoreSheetData: IScoreSheetData;
}> = ({ schema, scoreSheetData }) => {
    const sections = schema?.sections || [];
    const methods = useForm();

    return (
        <FormProvider {...methods}>
            <div className="space-y-4">
                {sections.map((section, index) => (
                    <SheetSection
                        key={`${section.title}-${index}`}
                        {...section}
                        scoreSheetData={scoreSheetData}
                        renderMode="pdf"
                        errors={null}
                    ></SheetSection>
                ))}
            </div>
        </FormProvider>
    );
};

export default ScoresheetBody;
