import React from 'react';
import { firestore } from '../../../../firebase';
import {
    ICustomComparativeFlight,
    IEntry,
    ITableComparativeFlight,
    decorateWithEntryInfoGetters,
} from '@flight-cap/shared';

export interface IFlightEntriesContext {
    flight: ITableComparativeFlight | ICustomComparativeFlight;
    flightEntries: IEntry[];
}

export const CustomComparativeFlightEntriesContext: React.Context<IFlightEntriesContext> =
    React.createContext<IFlightEntriesContext>({
        flight: null,
        flightEntries: null,
    });

const CustomComparativeFlightEntriesProvider: React.FunctionComponent<{
    flight: ICustomComparativeFlight;
    competitionId: string;
    children: React.ReactNode;
}> = ({ flight, competitionId, children }) => {
    const [flightEntries, setFlightEntries] = React.useState<IEntry[]>([]);

    React.useEffect(() => {
        if (competitionId && flight) {
            let query;

            query = firestore.collection(`competitions/${competitionId}/entries`);

            for (const { field, comparator, value } of flight.where) {
                console.log('added query', field, comparator, value);
                query = query.where(field, comparator, value);
            }

            return query.onSnapshot((snapshot) => {
                console.log('CustomComparativeFlightEntriesProvider -- onSnapshot');
                const entries = snapshot.docs
                    .map((doc) => ({ uid: doc.id, ...(doc.data() as IEntry) }))
                    .map((entry) => decorateWithEntryInfoGetters(entry))
                    .sort((a, b) => {
                        return a.judging_number > b.judging_number ? 1 : -1;
                    });
                console.log(entries);
                setFlightEntries(entries);
            });
        } else {
            setFlightEntries([]);
        }
    }, [competitionId, flight]);

    return (
        <CustomComparativeFlightEntriesContext.Provider
            value={{
                flight,
                flightEntries,
            }}
        >
            {children}
        </CustomComparativeFlightEntriesContext.Provider>
    );
};

export default CustomComparativeFlightEntriesProvider;
