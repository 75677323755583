import React from 'react';
import { ICustomComparativeFlight } from '@flight-cap/shared';
import { MyColumn } from '../../../components/Table/react-table-config';
import { CellProps } from 'react-table';
import { Link } from '@reach/router';
import ChevronRightIcon from '../../../components/Icon/ChevronRightIcon';
import {
    fieldLabelMap,
    FieldValue,
} from '../../CompetitionAdmin/flights/QueryFormList/QueryFormRow';

export type StewardingFlightRow = ICustomComparativeFlight;

export const stewardingFlightColumns: MyColumn<ICustomComparativeFlight>[] = [
    {
        Header: 'Name',
        accessor: 'title',
        sticky: 'left',
    },
    {
        Header: 'Query',
        accessor: (d) => (
            <div className="flex flex-col space-y-2">
                {d.where.map(({ field, comparator, value }) => (
                    <pre
                        key={`${field}-${comparator}-${value}`}
                        className="text-xs rounded bg-gray-200 p-2 "
                    >
                        {fieldLabelMap[field as FieldValue]}{' '}
                        {value instanceof Array ? value.join(', ') : value}
                    </pre>
                ))}
            </div>
        ),
        width: 300,
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<StewardingFlightRow>) => (
            <>
                <Link
                    to={`./flight/${original.uid}/pullsheets`}
                    className="btn-outline btn-gray flex items-center font-normal"
                >
                    Pull Sheets
                    <ChevronRightIcon />
                </Link>
            </>
        ),
        disableSortBy: true,
        width: 200,
        minWidth: 200,
        maxWidth: 300,
        sticky: 'right',
        className: 'unstick-large',
    },
];
