import { IFlight } from '@flight-cap/shared';
import { Menu } from '@headlessui/react';
import React from 'react';
import Confirm, { ConfirmTheme } from '../../../../components/Confirm/Confirm';
import VerticalDotsIcon from '../../../../components/Icon/VerticalDotsIcon';
import SelectMenu from '../../../../components/SelectMenu/SelectMenu';
import { SessionsContext } from '../../../../providers/SessionsProvider';
import { FlightsContext } from '../../providers/FlightsProvider';

const FlightMeatballMenu: React.FunctionComponent<{ flight: IFlight }> = ({ flight }) => {
    const menuButton = (
        <Menu.Button className="flex">
            <VerticalDotsIcon />
        </Menu.Button>
    );

    const { sessions } = React.useContext(SessionsContext);

    const { deleteFlight, addSessionToFlight, updateFlight } = React.useContext(FlightsContext);

    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    const [showConfirmAssign, setShowConfirmAssign] = React.useState(false);

    const handleToggleActive = (active: boolean) => () => {
        updateFlight(flight.uid, { active });
    };

    const handleRemoveFromSessionClicked = () => {
        addSessionToFlight(flight.uid, null);
    };

    // ---- assign confirm handlers ---- //
    const handleAssignToSessionClicked = () => {
        setShowConfirmAssign(true);
    };

    const handleConfirmAssign = (sessionId: string) => async () => {
        await addSessionToFlight(flight.uid, sessionId);
        setShowConfirmAssign(false);
    };

    const handleCancelAssign = () => {
        setShowConfirmAssign(false);
    };

    // ---- delete confirm handlers ---- //
    const handleFlightDeleteClicked = () => {
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = async () => {
        await deleteFlight(flight.uid);
        setShowConfirmDelete(false);
    };

    const handleCancelDelete = () => {
        setShowConfirmDelete(false);
    };

    const activateFlight = !flight.active && (
        <Menu.Item>
            <li
                className="menu-item cursor-pointer primary"
                role="button"
                onClick={handleToggleActive(true)}
            >
                Activate flight
            </li>
        </Menu.Item>
    );

    const deactivateFlight = flight.active && (
        <Menu.Item>
            <li
                className="menu-item cursor-pointer primary"
                role="button"
                onClick={handleToggleActive(false)}
            >
                Deactivate flight
            </li>
        </Menu.Item>
    );

    const removeFromSession = !!flight.session_id && (
        <Menu.Item>
            <li
                className="menu-item cursor-pointer"
                role="button"
                onClick={handleRemoveFromSessionClicked}
            >
                Remove from session
            </li>
        </Menu.Item>
    );

    const assignToSession = !flight.session_id && (
        <Menu.Item>
            <li
                className="menu-item cursor-pointer"
                role="button"
                onClick={handleAssignToSessionClicked}
            >
                Assign to session...
            </li>
        </Menu.Item>
    );

    return (
        <div className="flex" data-no-dnd="true">
            <SelectMenu button={menuButton}>
                {activateFlight}
                {deactivateFlight}
                {assignToSession}
                {removeFromSession}
                <hr />
                <Menu.Item>
                    <li
                        className="menu-item cursor-pointer danger"
                        role="button"
                        onClick={handleFlightDeleteClicked}
                    >
                        Delete Flight
                    </li>
                </Menu.Item>
            </SelectMenu>
            <Confirm
                theme={ConfirmTheme.Danger}
                title={`Are you sure want to delete ${flight?.name()}`}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                show={showConfirmDelete}
                confirmLabel="Delete Flight"
            >
                <div className="text-sm text-gray-500">
                    <p className="my-2">
                        Deleting the flight will remove it from the session and remove the assigned
                        judges.
                    </p>
                    <p className="my-2">
                        It will not remove entries or the and of the entries scoresheets.
                    </p>
                </div>
            </Confirm>
            <Confirm
                theme={ConfirmTheme.Neutral}
                title={`Assign ${flight?.name()} to a session...`}
                onCancel={handleCancelAssign}
                show={showConfirmAssign}
                confirmLabel="Delete Flight"
            >
                <div className="text-sm text-gray-500">
                    <p>Choose a session</p>
                    <ul className="border rounded max-h-3/4 overflow-scroll mt-2 w-full">
                        {sessions.map((s) => (
                            <li
                                role="button"
                                className="menu-item border-b border-gray-100"
                                key={s.uid}
                                onClick={handleConfirmAssign(s.uid)}
                            >
                                {s.name()}
                            </li>
                        ))}
                    </ul>
                </div>
            </Confirm>
        </div>
    );
};

export default FlightMeatballMenu;
