import React from 'react';

const CheckmarkIcon: React.FunctionComponent<{ className?: string }> = ({ className }) => (
    <svg
        className={`h-4 w-4 ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
);

export default CheckmarkIcon;
