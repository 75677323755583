import { IJudgeSnippet, IUserProfile } from '@flight-cap/shared';
import React from 'react';

const ScoresheetJudgeInformation: React.FunctionComponent<{
    judge: IJudgeSnippet | IUserProfile;
}> = ({ judge }) => {
    return (
        <>
            <p>{judge.display_name}</p>
            <p className="w-full truncate text-right">{judge.email}</p>
            {judge.judging?.bjcp_rank ? (
                <p className="badge-gray">
                    {judge.judging.bjcp_id ? `${judge.judging.bjcp_id},` : ''}{' '}
                    {judge.judging.bjcp_rank}
                </p>
            ) : (
                <></>
            )}
        </>
    );
};

export default ScoresheetJudgeInformation;
