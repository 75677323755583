import React, { useContext } from 'react';
import Editable from '../../../../components/Editable/Editable';
import { ISession } from '@flight-cap/shared';
import { useForm } from 'react-hook-form';

import { FlightsContext } from '../../providers/FlightsProvider';
import DropZoneFlights from '../dnd/DropZoneFlights';
import DraggableJudge from '../dnd/DraggableJudge';
import { DraggableFlight } from '../dnd/DraggableFlight';
import SessionLocation from './SessionLocation';
import FlightEditor from '../flights/FlightEditor';
import { SessionUpdateField } from '../SessionsAdmin';
import SessionMeatballMenu from './SessionMeatballMenu';
import IconValue from '../IconValue';
import UserIcon from '../../../../components/Icon/UserIcon';

export enum SessionEditorDragType {
    Participant = 'participant',
    Flight = 'flight',
}
export type DraggableStatusVariant = 'idle' | 'success' | 'warning' | 'error';

export enum SessionEditorDropActions {
    AddSessionToFlight = 'add-session-to-flight',
    AddJudgeToFlight = 'add-judge-to-flight',
}

interface Props {
    session: ISession;
    onUpdateSession: (uid: string, updates: Partial<ISession>) => void;
}

const SessionsEditor: React.FunctionComponent<Props> = ({ session, onUpdateSession }) => {
    const { location, title, start_date, map_link } = session;
    const { flights } = useContext(FlightsContext);

    const flightsInSession = flights
        .filter((f) => f.session_id === session.uid)
        .sort((a, b) => (a.name() > b.name() ? 1 : -1));

    const {
        handleSubmit,
        register,
        resetField,
        formState: { errors },
    } = useForm<Partial<ISession>>({
        defaultValues: {
            title,
            location,
            start_date,
            map_link,
        },
    });

    const onSubmit = (fields: SessionUpdateField[]) => async (data: Partial<ISession>) => {
        const updates = fields.reduce((obj, curr) => {
            obj[curr] = data[curr];
            return obj;
        }, {} as Record<SessionUpdateField, any>);

        onUpdateSession(session.uid, updates);
    };

    const availableJudges = Object.values(session.participant_snippets || {})
        .filter((p) => p.roles.includes('judge'))
        .sort((a, b) => (a.display_name > b.display_name ? 1 : -1));

    return (
        <DropZoneFlights session={session} id={`session-${session.uid}-editor`}>
            <div className="p-2 md:p-4 relative w-full">
                <section className="flex justify-between items-baseline">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="w-full md:w-2/3">
                            <Editable
                                onConfirm={handleSubmit(onSubmit(['title']))}
                                onCancel={() => resetField('title')}
                                staticNode={<h1 className="font-medium">{session.name()}</h1>}
                                editorNode={
                                    <input
                                        autoFocus
                                        type="text"
                                        placeholder="Session Title"
                                        aria-invalid={errors.title ? 'true' : 'false'}
                                        {...register('title')}
                                    ></input>
                                }
                            />

                            <Editable
                                className="text-sm"
                                onConfirm={handleSubmit(onSubmit(['location', 'map_link']))}
                                onCancel={() => {
                                    resetField('location');
                                    resetField('map_link');
                                }}
                                staticNode={<SessionLocation session={session} />}
                                editorNode={
                                    <>
                                        <input
                                            autoFocus
                                            type="text"
                                            placeholder="Session Location"
                                            aria-invalid={errors.location ? 'true' : 'false'}
                                            {...register('location')}
                                        ></input>
                                        <input
                                            autoFocus
                                            type="text"
                                            placeholder="Map Link"
                                            aria-invalid={errors.map_link ? 'true' : 'false'}
                                            {...register('map_link')}
                                        ></input>
                                    </>
                                }
                            />

                            <Editable
                                className="text-sm w-60"
                                onConfirm={handleSubmit(onSubmit(['start_date']))}
                                onCancel={() => resetField('start_date')}
                                staticNode={
                                    <span>
                                        {start_date
                                            ? new Date(start_date).toLocaleString('en-US')
                                            : 'No Time Set'}
                                    </span>
                                }
                                editorNode={
                                    <input
                                        autoFocus
                                        type="datetime-local"
                                        placeholder="Session Location"
                                        aria-invalid={errors.location ? 'true' : 'false'}
                                        {...register('start_date')}
                                    ></input>
                                }
                            />
                        </div>
                    </form>
                    <SessionMeatballMenu session={session} />
                </section>

                <div className="space-y-4 my-4">
                    <hr />

                    <div>
                        <span className="flex justify-between">
                            <label>Available Judges</label>
                            <span className="text-xs">
                                <IconValue
                                    value={availableJudges.length}
                                    itemName="Judges available"
                                    icon={<UserIcon />}
                                />
                            </span>
                        </span>
                        <div className="border-dashed-gray p-2 grid grid-cols-1 xl:grid-cols-2 4xl:grid-cols-3 gap-2">
                            {availableJudges.length > 0 ? (
                                availableJudges.map((p) => (
                                    <DraggableJudge
                                        key={p.uid}
                                        sessionId={session.uid}
                                        participant={p}
                                    />
                                ))
                            ) : (
                                <div className="container-dashed-gray">
                                    No judges available for session
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <label>Flights</label>
                        <div className="grid grid-cols-1 xl:grid-cols-2 4xl:grid-cols-3 gap-2">
                            {flightsInSession.length > 0 ? (
                                flightsInSession.map((f) => (
                                    <DraggableFlight key={f.uid} flight={f}>
                                        <FlightEditor flight={f} />
                                    </DraggableFlight>
                                ))
                            ) : (
                                <div className="container-dashed-gray">No flights in session</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DropZoneFlights>
    );
};

export default SessionsEditor;
