import { IStyleSnippet, MetaStyleType } from '@flight-cap/shared';
import React from 'react';
import XIcon from '../Icon/XIcon';

const StyleBadge: React.FunctionComponent<{
    style: IStyleSnippet;
    onCancel?: (uid: string) => void;
}> = ({ style, onCancel }) => {
    const badgeClass = (
        {
            beer: 'badge-yellow',
            mead: 'badge-blue',
            cider: 'badge-green',
        } as Record<MetaStyleType, string>
    )[style.type];

    const handleCloseClick = () => {
        onCancel(style.uid);
    };

    return (
        <span key={style.uid} className={`${badgeClass} badge-rounded m-1`}>
            {style.category}
            {style.sub_category}: {style.name}
            {onCancel && (
                <span
                    className="p-1 ml-1 hover:text-red-700 hover:bg-red-200 rounded-full"
                    onClick={handleCloseClick}
                >
                    <XIcon />
                </span>
            )}
        </span>
    );
};

export default StyleBadge;
