import { Transition } from '@headlessui/react';
import React from 'react';
import { AppearanceTypes, ToastProps } from 'react-toast-notifications';
import CheckCircleIcon from '../Icon/CheckCircleIcon';
import DangerIcon from '../Icon/DangerIcon';
import InfoCircleIcon from '../Icon/InfoCircleIcon';
import XIcon from '../Icon/XIcon';

const themeProps: Record<
    AppearanceTypes,
    { icon: React.ReactChild; bgColor: string; dismissIconClass: string }
> = {
    error: {
        icon: <DangerIcon className="text-red-400 w-6 h-6" />,
        bgColor: 'bg-red-50 text-red-800',
        dismissIconClass:
            'text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
    },
    warning: {
        icon: <DangerIcon className="text-yellow-400 w-6 h-6" />,
        bgColor: 'bg-yellow-50 text-yellow-800',
        dismissIconClass:
            'text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600',
    },
    success: {
        icon: <CheckCircleIcon className="text-green-400 w-6 h-6" />,
        bgColor: 'bg-green-50 text-green-800',
        dismissIconClass:
            'text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
    },
    info: {
        icon: <InfoCircleIcon className="text-blue-400 w-6 h-6" />,
        bgColor: 'bg-blue-50 text-blue-800',
        dismissIconClass:
            'text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600',
    },
};

export const SimpleToastContent: React.FunctionComponent<{
    title: React.ReactChild;
    subTitle?: React.ReactChild;
}> = ({ title, subTitle }) => (
    <>
        <p className="text-sm font-bold">{title}</p>
        {subTitle ? <p className="mt-1 text-sm opacity-90">{subTitle}</p> : <></>}
    </>
);
const ToastMessage: React.FunctionComponent<ToastProps> = ({
    children,
    appearance,
    transitionState,
    onDismiss,
}) => {
    const handleCloseClick = () => {
        onDismiss();
    };

    const theme = themeProps[appearance];

    return (
        <Transition
            className="max-w-sm w-full"
            show={transitionState === 'entered' || transitionState === 'entering'}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className={`rounded-md ${theme.bgColor} p-4 pointer-events-auto`}>
                <div className="flex">
                    <div className="flex-shrink-0">{theme.icon}</div>
                    <div className="ml-3">{children}</div>
                    <div className="ml-auto pl-3">
                        <div className="-mx-1.5 -my-1.5">
                            <button
                                onClick={handleCloseClick}
                                className={`inline-flex ${theme.bgColor} rounded-md p-1.5  focus:outline-none focus:ring-2 focus:ring-offset-2 ${theme.dismissIconClass}`}
                            >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
};

export default ToastMessage;
