import { IEntry } from '@flight-cap/shared';
import React from 'react';

const StickyEntryInfo: React.FunctionComponent<{ entry: IEntry }> = ({ entry }) => {
    return entry ? (
        <div className="sticky top-0 bg-yellow-50 border-b px-2 py-1 flex justify-center text-center text-xs space-x-2 shadow-sm">
            <div className="space-x-2">
                <div className="inline-block">Entry {entry.judging_number}</div>
                <div className="mr-2 font-bold inline-block">
                    {entry.style_snippet?.category}
                    {entry.style_snippet?.sub_category} - {entry.style_snippet?.name}
                </div>
                <div className="inline-block">
                    {[entry.strength, entry.sweetness, entry.carbonation]
                        .filter((val) => val)
                        .join(', ')}
                </div>
                <div className="inline-block">
                    {[entry.requiredInfo(), entry.specificInfo(), entry.optionalInfo()]
                        .filter((val) => val)
                        .join('; ')}
                </div>
            </div>
        </div>
    ) : null;
};

export default StickyEntryInfo;
