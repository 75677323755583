export var AwardRank;
(function (AwardRank) {
    AwardRank[AwardRank["First"] = 1] = "First";
    AwardRank[AwardRank["Second"] = 2] = "Second";
    AwardRank[AwardRank["Third"] = 3] = "Third";
    AwardRank[AwardRank["HonorableMention"] = 4] = "HonorableMention";
})(AwardRank || (AwardRank = {}));
export var LocalStorageKeys;
(function (LocalStorageKeys) {
    LocalStorageKeys["CustomStyleGuidelineId"] = "custom-guideline-id";
})(LocalStorageKeys || (LocalStorageKeys = {}));
