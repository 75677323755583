import React from 'react';

const Divider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <div className="mt-6 mb-4">
            <div className="relative">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">{children}</span>
                </div>
            </div>
        </div>
    );
};

export default Divider;
