import React from 'react';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { FlightsContext } from '../providers/FlightsProvider';
import ComparativeFlightDetail from './ComparativeFlight/ComparativeFlightDetail';
import FlightComparisonProvider from '../providers/FlightComparisonProvider';
import ScoringFlightDetail from './ScoringFlight/ScoringFlightDetail';
import { ITableScoringFlight, parameterizeEndpoint } from '@flight-cap/shared';
import { ApplicationRoutes } from '../../../routes';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';

export type DescriptorChangedHandler = (key: string, value: boolean) => void;

const FlightDetail: React.FunctionComponent<RouteComponentProps<{ flightId: string }>> = ({
    flightId,
}) => {
    const { flights } = React.useContext(FlightsContext);
    const flight = flights?.find((flight) => flight.uid === flightId);
    const { competition } = React.useContext(CompetitionContext);

    if (flights && (!flight || !flight?.active)) {
        navigate(
            parameterizeEndpoint(ApplicationRoutes.JudgingDashboard, {
                competitionSlug: competition.slug,
            }),
            { replace: true }
        );
    }

    return (
        <ErrorBoundary type="flight-detail">
            {flight ? (
                flight.active &&
                (flight.type === 'table_comparative' || flight.type === 'custom_comparative' ? (
                    <FlightComparisonProvider flight={flight}>
                        <ComparativeFlightDetail flight={flight} />
                    </FlightComparisonProvider>
                ) : (
                    <ScoringFlightDetail flight={flight as ITableScoringFlight} />
                ))
            ) : (
                <div className="container-dashed-gray text-center">
                    <p>
                        Whoops. This flight cannot be found. Head over the to your{' '}
                        <Link
                            to={parameterizeEndpoint(ApplicationRoutes.JudgingDashboard, {
                                competitionSlug: competition.slug,
                            })}
                        >
                            judging dashboard
                        </Link>{' '}
                        to join an active flight.
                    </p>
                </div>
            )}{' '}
        </ErrorBoundary>
    );
};

export default FlightDetail;
