import React, { Dispatch, SetStateAction } from 'react';

export const useHasFormChanges = (): [
    React.FunctionComponent<{ className?: string; children: React.ReactNode }>,
    boolean,
    Dispatch<SetStateAction<boolean>>
] => {
    const [hasChanges, setHasChanges] = React.useState<boolean>(false);
    const hasChangesRef = React.useRef(hasChanges);

    const handleFormChange = React.useCallback(() => {
        if (hasChangesRef.current !== true) {
            setHasChanges(true);
            hasChangesRef.current = true;
        }
    }, []);

    React.useEffect(() => {
        hasChangesRef.current = hasChanges;
    }, [hasChanges]);

    const Wrapper = React.useMemo(
        (): React.FunctionComponent<{ className?: string; children: React.ReactNode }> =>
            ({ children, className }) =>
                (
                    <span className={className || ''} onChange={handleFormChange}>
                        {children}
                    </span>
                ),
        [handleFormChange]
    );

    return [Wrapper, hasChanges, setHasChanges];
};
