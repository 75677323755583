import React from 'react';

export enum AlertType {
    Error = 'error',
    Success = 'success',
}

const alertClasses: Record<AlertType, string> = {
    [AlertType.Error]: 'border-red-400 bg-red-100 text-red-700',
    [AlertType.Success]: 'border-green-400 bg-green-100 text-green-700',
};

const Alert: React.FunctionComponent<{
    type?: AlertType;
    className?: string;
    children: React.ReactNode;
}> = ({ type, className, children }) => {
    return (
        <div role="alert" className={className}>
            <div className={`border rounded px-4 py-3 ${alertClasses[type]}`}>{children}</div>
        </div>
    );
};

Alert.defaultProps = {
    type: AlertType.Error,
};

export default Alert;
