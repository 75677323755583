export var judgingQualificationsMap = {
    mead_judge: 'BJCP Mead Judge',
    cider_judge: 'BJCP Cider Judge',
    professional_brewer: 'Professional Brewer',
    professional_cidermaker: 'Professional Cidermaker',
    professional_meadmaker: 'Professional Meadmaker',
    cicerone: 'Cicerone',
    sommelier: 'Sommelier',
    sensory_training: 'Other Sensory Training',
};
export var judgingQualificationsMapCondensed = {
    mead_judge: 'Mead',
    cider_judge: 'Cider',
    professional_brewer: 'Pro',
    professional_cidermaker: 'Pro',
    professional_meadmaker: 'Pro',
    cicerone: 'Cicerone',
    sommelier: 'Sommelier',
    sensory_training: 'Sensory',
};
