import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import TablesProvider from './providers/TablesProvider';
import CompetitionAdmin from './CompetitionAdmin';
import TablesAdmin from './tables/TablesAdmin';
import EntriesAdmin from './entries/EntriesAdmin';
import EntriesProvider from './providers/EntriesProvider';
import FlightsAdmin from './flights/FlightsAdmin';
import FlightsProvider from './providers/FlightsProvider';
import ParticipantsProvider from './providers/ParticipantsProvider';
import StylesAdmin from './styles/StylesAdmin';
import StylesProvider from './providers/StylesProvider';
import ParticipantsAdmin from './participants/ParticipantsAdmin';
import FlightAdminDetail from './flights/FlightAdminDetail';
import EntryDetail from './entries/EntryDetail';
import ScoresheetsProvider from './providers/ScoresheetsProvider';
import SheetSchemasProvider from '../Competition/providers/SheetSchemasProvider';
import SessionsAdmin from './sessions/SessionsAdmin';

const CompetitionAdminWrapper: React.FunctionComponent<
    RouteComponentProps & { children?: React.ReactNode }
> = () => {
    return (
        <>
            {/* <p className="mb-8">admin breadcrumb menu here....</p> */}
            <SheetSchemasProvider>
                <StylesProvider>
                    <ParticipantsProvider>
                        <EntriesProvider>
                            <TablesProvider>
                                <FlightsProvider>
                                    <ScoresheetsProvider>
                                        <Router>
                                            <CompetitionAdmin path="/" />
                                            <TablesAdmin path="/tables/*" />
                                            <EntriesAdmin path="/entries/*" />
                                            <FlightsAdmin path="/flights/*" />
                                            <FlightAdminDetail path="/flights/:flightId/*" />
                                            <EntryDetail path="/flights/:flightId/entries/:entryId" />
                                            <EntryDetail path="/entries/:entryId" />
                                            <StylesAdmin path="/styles/*" />
                                            <SessionsAdmin path="/sessions/*" />
                                            <ParticipantsAdmin path="/participants/*" />
                                        </Router>
                                    </ScoresheetsProvider>
                                </FlightsProvider>
                            </TablesProvider>
                        </EntriesProvider>
                    </ParticipantsProvider>
                </StylesProvider>
            </SheetSchemasProvider>
        </>
    );
};

export default CompetitionAdminWrapper;
