/**
 * This component is for the competition specific information for a participant. Right now,
 * it's only for judges to see some general info and the session information.
 */

import { containsClass, getAttribute } from '@flight-cap/shared';
import React from 'react';
import Table from '../../../components/Table/Table';
import { SessionsContext } from '../../../providers/SessionsProvider';
import { UserContext } from '../../../providers/UserProvider';
import { FlightsContext } from '../providers/FlightsProvider';
import { ISessionAvailabilityRow, sessionsTableColumns } from './SessionsTableColumns';

const SessionAvailablity: React.FunctionComponent = () => {
    const { sessions, setParticipantAvailability, getParticipantAvailability } =
        React.useContext(SessionsContext);

    const { flights } = React.useContext(FlightsContext);

    const { user } = React.useContext(UserContext);

    const rows: ISessionAvailabilityRow[] = sessions
        ? sessions.map((s) => ({
              ...s,
              isAvailable: getParticipantAvailability(user.uid, s.uid),
              flightsAssigned: flights.filter((f) => f.session_id === s.uid),
          }))
        : [];

    const handleTableClicked = (evt: React.MouseEvent) => {
        if (containsClass(evt.target, 'toggle-session-availablity')) {
            const sessionId = String(getAttribute(evt.target, 'data-session-id'));
            setParticipantAvailability(
                user.uid,
                sessionId,
                !getParticipantAvailability(user.uid, sessionId)
            );
            console.log('session availability toggled', sessionId, user.uid);
        }
    };

    return rows.length ? (
        <>
            <h3 className="font-semibold my-4">Your Availibilty</h3>
            <div onClick={handleTableClicked}>
                <Table columns={sessionsTableColumns} data={rows || []} />
            </div>
        </>
    ) : (
        <div className="container-dashed-gray my-4">No Sessions have been created yet.</div>
    );
};

export default SessionAvailablity;
