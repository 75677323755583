import React from 'react';
import { useFormContext } from 'react-hook-form';
import { IValueInputProps } from './QueryFormRow';

const QueryPlaceSelect: React.FunctionComponent<IValueInputProps> = ({ name }) => {
    const { register } = useFormContext();

    return (
        <select {...register(name)}>
            <option value={null}></option>
            <option value="1">1st</option>
            <option value="2">2nd</option>
            <option value="3">3rd</option>
            <option value="4">Honorable Mention</option>
        </select>
    );
};

export default QueryPlaceSelect;
