import {
    IScoreSheetData,
    IScoringGuideProps,
    ISheetSchema,
    SheetSchemaId,
    IBeverageDescriptor,
} from '@flight-cap/shared';

// TODO abstract this
export const sheetNeedsAttention = (
    sheet: IScoreSheetData,
    schema: ISheetSchema
): IScoreSheetData['needs_attention'] => {
    const sheetInvalid = sheet.is_valid === false;
    const scoreIsLow = sheet.values?.final_score < 20;
    const assementIsShort = sheet.values?.overall_assessment.toString().length < 50;

    const reasons = [];

    sheetInvalid && reasons.push('Sheet is invalid');
    scoreIsLow && reasons.push('Score is low');
    assementIsShort && reasons.push('Overall is short');

    return reasons.length ? reasons : null;
};

const beerDescriptors: IBeverageDescriptor[] = [
    {
        id: 'acetaldehyde',
        title: 'Acetaldehyde',
        description: 'Green apple-like aroma and flavor.',
    },
    {
        id: 'alcoholic',
        title: 'Alcoholic',
        description:
            'The aroma, flavor, and warming effect of ethanol and higher alcohols. Sometimes described as hot.',
    },
    {
        id: 'astringent',
        title: 'Astringent',
        description:
            'Puckering, lingering harshness and/or dryness in the finish/aftertaste; harsh graininess; huskiness.',
    },
    {
        id: 'diacetyl',
        title: 'Diacetyl',
        description:
            'Artificial butter, butterscotch, or toffee aroma and flavor. Sometimes perceived as a slickness on the tongue.',
    },
    {
        id: 'dms',
        title: 'DMS (dimethyl sulfide)',
        description: 'At low levels a sweet, cooked or canned corn-like aroma and flavor.',
    },
    {
        id: 'estery',
        title: 'Estery',
        description: 'Aroma and/or flavor of any ester (fruits, fruit flavorings, or roses).',
    },
    {
        id: 'grassy',
        title: 'Grassy',
        description: 'Aroma/flavor of fresh-cut grass or green leaves.',
    },
    {
        id: 'light_struck',
        title: 'Light-Struck',
        description: 'Similar to the aroma of a skunk.',
    },
    {
        id: 'metallic',
        title: 'Metallic',
        description: 'Tinny, coiny, copper, iron, or blood-like flavor.',
    },
    {
        id: 'musty',
        title: 'Musty',
        description: 'Stale, musty, or moldy aromas/flavors.',
    },
    {
        id: 'oxidized',
        title: 'Oxidized',
        description:
            'Any one or combination of stale, winy/vinous, cardboard, papery, or sherry-like aromas and flavors.',
    },
    {
        id: 'phenolic',
        title: 'Phenolic',
        description:
            'Spicy (clove, pepper), smoky, plastic, plastic adhesive strip, and/or medicinal (chlorophenolic).',
    },
    {
        id: 'solvent',
        title: 'Solvent',
        description:
            'Aromas and flavors of higher alcohols (fusel alcohols). Similar to acetone or lacquer thinner aromas.',
    },
    {
        id: 'sour_acidic',
        title: 'Sour/Acidic',
        description:
            'Tartness in aroma and flavor. Can be sharp and clean (lactic acid), or vinegar-like (acetic acid).',
    },
    {
        id: 'sulfur',
        title: 'Sulfur',
        description: 'The aroma of rotten eggs or burning matches.',
    },
    {
        id: 'vegetal',
        title: 'Vegetal',
        description:
            'Cooked, canned, or rotten vegetable aroma and flavor (cabbage, onion, celery, asparagus, etc.)',
    },
    {
        id: 'yeasty',
        title: 'Yeasty',
        description: 'A bready, sulfury or yeast-like aroma or flavor.',
    },
];

const ciderDescriptors: IBeverageDescriptor[] = [
    {
        id: 'acetaldehyde',
        title: 'Acetaldehyde',
        description: 'Green apple-like aroma and flavor.',
    },
    {
        id: 'acetified',
        title: 'Acetified (Volatile Acidity, VA)',
        description:
            'Ethyl acetate (solvent, nail polish) or acetic acid (vinegar, harsh in back of throat).',
    },
    {
        id: 'acidic',
        title: 'Acidic',
        description:
            'Clean, sour flavor/aroma from low pH. Typically from one of several acids: malic, lactic, gluconic, or citric',
    },
    {
        id: 'alcoholic',
        title: 'Alcoholic',
        description: 'The effect of ethanol. Warming. Hot.',
    },
    {
        id: 'astringent',
        title: 'Astringent',
        description:
            'Puckering, lingering harshness and/or dryness in the finish/aftertaste; harsh graininess; huskiness.',
    },
    {
        id: 'bitter',
        title: 'Bitter',
        description: 'A sharp taste that is unpleasant at higher levels',
    },
    {
        id: 'diacetyl',
        title: 'Diacetyl',
        description:
            'Artificial butter, butterscotch, or toffee aroma and flavor. Sometimes perceived as a slickness on the tongue.',
    },
    {
        id: 'farmyard',
        title: 'Farmyard',
        description: '– Manure-like (cow or pig) or barnyard (horse stall on a warm day).',
    },
    {
        id: 'fruity',
        title: 'Fruity',
        description:
            'The aroma and flavor of fresh fruits that may be appropriate in some styles and not others.',
    },
    {
        id: 'metallic',
        title: 'Metallic',
        description:
            'Tinny, coiny, copper, iron, or blood-like flavor. Cider may turn green (copper) or black (iron).',
    },
    {
        id: 'mousy',
        title: 'Mousy',
        description: 'Taste evocative of the smell of a rodent’s den/cage.',
    },
    {
        id: 'oaky',
        title: 'Oaky',
        description:
            'A taste or aroma due to an extended length of time in a barrel or on wood chips. “Barrel character.”',
    },
    {
        id: 'oily_ropy',
        title: 'Oily/Ropy',
        description:
            '– A sheen in visual appearance, as an unpleasant viscous character proceeding to a ropy character.',
    },
    {
        id: 'oxidized',
        title: 'Oxidized',
        description: 'Staleness, the aroma/flavor of sherry, raisins, or bruised fruit.',
    },
    {
        id: 'phenolic',
        title: 'Phenolic',
        description: 'Plastic, band-aid, and/or medicinal.',
    },
    {
        id: 'spicy_smoky',
        title: 'Spicy/Smoky',
        description: 'Spice, cloves, smoky, ham. ',
    },
    {
        id: 'sulfide',
        title: 'Sulfide',
        description: 'Rotten eggs, from fermentation problems',
    },
    {
        id: 'sulfite',
        title: 'Sulfite',
        description: 'Burning matches, from excessive/recent sulfiting.',
    },
    {
        id: 'sweet',
        title: 'Sweet',
        description: 'Basic taste of sugar. Must be in balance if present.',
    },
    {
        id: 'thin',
        title: 'Thin',
        description: 'Watery. Lacking body or “stuffing.”',
    },
    {
        id: 'vegetal',
        title: 'Vegetal',
        description:
            '– Cooked, canned, or rotten vegetable aroma and flavor (cabbage, onion, celery, asparagus, etc.)',
    },
];

const meadDescriptors: IBeverageDescriptor[] = [
    {
        id: 'acetic',
        title: 'Acetic',
        description: 'Vinegary, acetic acid, sharp.',
    },
    {
        id: 'acidic',
        title: 'Acidic',
        description:
            'Clean, sour flavor/aroma from low pH. Typically from one of several acids: malic, lactic, gluconic, or citric',
    },
    {
        id: 'alcoholic',
        title: 'Alcoholic',
        description: 'The effect of ethanol. Warming. Hot.',
    },
    {
        id: 'chemical',
        title: 'Chemical',
        description: 'Vitamin, nutrient or chemical taste.',
    },
    {
        id: 'cloying',
        title: 'Cloying (dimethyl sulfide)',
        description: 'Syrupy, overly sweet, unbalanced by acid/tannin.',
    },
    {
        id: 'floral',
        title: 'Floral',
        description: 'The aroma of flower blossoms or perfume.',
    },
    {
        id: 'fruity',
        title: 'Fruity',
        description:
            '– Flavor & aroma esters often derived from fruits in a melomel. Banana & pineapple are often off-flavors.',
    },
    {
        id: 'metallic',
        title: 'Metallic-Struck',
        description: 'Tinny, coiny, copper, iron, or blood-like flavor.',
    },
    {
        id: 'moldy',
        title: 'Moldy',
        description: 'Stale, musty, moldy or corked aromas/flavors.',
    },
    {
        id: 'oxidized',
        title: 'Oxidized',
        description:
            'Any one or combination of stale, winy/vinous, cardboard, papery, or sherry-like aromas and flavors.',
    },
    {
        id: 'phenolic',
        title: 'Phenolic',
        description:
            'Spicy (clove, pepper), smoky, plastic, plastic adhesive strip, and/or medicinal (chlorophenolic).',
    },
    {
        id: 'solvent',
        title: 'Solvent',
        description:
            'Aromas and flavors of higher alcohols (fusel alcohols). Similar to acetone or lacquer thinner aromas.',
    },
    {
        id: 'sulfur',
        title: 'Sulfur',
        description: 'The aroma of rotten eggs or burning matches.',
    },
    {
        id: 'tannic',
        title: 'Tannic',
        description:
            'Drying, astringent puckering mouthfeel, similar to bitterness flavor. Taste of strong unsweetened tea or chewing on a grape skin.',
    },
    {
        id: 'vegetal',
        title: 'Vegetal',
        description:
            'Cooked, canned, or rotten vegetable aroma and flavor (cabbage, onion, celery, asparagus, etc.)',
    },
    {
        id: 'waxy',
        title: 'Waxy',
        description: 'Wax-like, tallow, fatty.',
    },
    {
        id: 'yeasty',
        title: 'Yeasty',
        description: 'A bready, sulfury or yeast-like aroma or flavor.',
    },
];

const scoringRangeGuide: IScoringGuideProps = {
    ranges: [
        { label: 'Outstanding', min: 45, max: 50, description: 'World-class example of style.' },
        {
            label: 'Excellent',
            min: 38,
            max: 44,
            description: 'Exemplifies style well, requires minor fine-tuning.',
        },
        {
            label: 'Very Good',
            min: 30,
            max: 37,
            description: 'Generally within style parameters, some minor flaws.',
        },
        {
            label: 'Good',
            min: 21,
            max: 29,
            description: 'Misses the mark on style and/or minor flaws.',
        },
        {
            label: 'Fair',
            min: 14,
            max: 20,
            description: 'Off flavors/aromas or major style deficiences. Unpleasant.',
        },
        {
            label: 'Problematic',
            min: 0,
            max: 13,
            description: 'Major off flavors and aroma dominate. Hard to drink.',
        },
    ],
};

export const defaultSchema: ISheetSchema = {
    uid: 'default_schema',
    title: 'Default Scoresheet Schema',
    minAllowedScore: 0,
    maxAllowedScore: 50,
    sections: [
        {
            title: 'Aroma',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'aroma_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Appearance',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'appearance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Flavor',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'flavor_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Mouthfeel',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'mouthfeel_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Balance/Drinkability',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'balance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Style Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'style_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Technical Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'technical_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Overall Assessment',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'overall_assessment',
                            required: 'Overall Assessment is required',
                            placeholder: 'Overall Assessment',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Final Score',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'score_input',
                            element_id: 'final_score',
                            required: 'Final Score is required',
                            max_score: 50,
                        },
                    ],
                },
            ],
        },
    ],
};

export const expandedSchema: ISheetSchema = {
    uid: 'expanded_schema',
    title: 'Expanded Schema 1',
    minAllowedScore: 0,
    maxAllowedScore: 50,
    sections: [
        {
            title: 'Aroma',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'aroma_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Flavor',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'flavor_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'aroma_flavor_notes',
                            placeholder: 'Aroma and Flavor Notes',
                            rows: 2,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Balance/Drinkability',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'balance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'balanace_notes',
                            placeholder: 'Balance and Drinkability Notes',
                            rows: 2,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Finish/Aftertaste',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'finish_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'finish_notes',
                            placeholder: 'Finish and Aftertaste Notes',
                            rows: 2,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Appearance',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'appearance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'appearance_notes',
                            placeholder: 'Appearance Notes',
                            rows: 2,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Mouthfeel',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'mouthfeel_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'mouthfeel_notes',
                            placeholder: 'Mouthfeel Notes',
                            rows: 2,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Style Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'style_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Technical Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'technical_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Overall Assessment',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'overall_assessment',
                            required: 'Overall Assessment is required',
                            placeholder: 'Overall Assessment',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Final Score',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'score_input',
                            element_id: 'final_score',
                            required: 'Final Score is required',
                            max_score: 50,
                        },
                    ],
                },
            ],
        },
    ],
};

export const midwinter2022: ISheetSchema = {
    uid: 'midwinter_2022',
    title: 'Midwinter 2022: Beer',
    minAllowedScore: 0,
    maxAllowedScore: 50,
    scoringGuide: scoringRangeGuide,
    descriptors: beerDescriptors,
    sections: [
        {
            title: 'Aroma',
            className: 'no-y-seperation',
            tooltip: 'Malt, hops, esters, and other aromatics as appropriate for style.',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Aroma is required',
                            element_id: 'aroma_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Flavor',
            className: 'no-y-seperation',
            tooltip:
                'Malt, hops, fermentation characteristics, and other flavors as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Flavor is required',
                            element_id: 'flavor_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'aroma_flavor_notes',
                            placeholder: 'Please comment on your Aroma and Flavor scores here',
                            title: 'Aroma and Flavor notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'aroma_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Aroma score',
                                },
                                {
                                    field: 'flavor_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Flavor score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Appearance',
            tooltip: 'Color, clarity, head (retention, color, texture) as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Appearance is required',
                            element_id: 'appearance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'appearance_notes',
                            placeholder: 'Please comment on your Appearance score here',
                            title: 'Appearance notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'appearance_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Appearance score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Mouthfeel',
            tooltip:
                'Body, carbonation, warmth, creaminess, astringency, and other palate sensations as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Mouthfeel is required',
                            element_id: 'mouthfeel_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'mouthfeel_notes',
                            placeholder: 'Please comment on your Mouthfeel score here',
                            title: 'Mouthfeel notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'mouthfeel_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Mouthfeel score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Balance/Drinkability',
            tooltip: 'How well the elements of the beverage compliment each other',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Balance/Drinkability is required',
                            element_id: 'balance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Finish/Aftertaste',
            tooltip: 'Attenuation, residual sweetness, and lingering flavors/impression',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Finish/Aftertaste is required',
                            element_id: 'finish_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'balance_drink_notes',
                            placeholder:
                                'Please comment on your Balance, Drinkability, Finish, and Aftertaste scores here',
                            title: 'Balance, Drinkability, Finish, and Aftertaste notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'balance_slider',
                                    value: 10,
                                    warning:
                                        'Be sure to address the low Balance/Drinkability score',
                                },
                                {
                                    field: 'finish_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Finish/Aftertaste score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Style Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Style Accuracy is required',
                            element_id: 'style_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Technical Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            required: 'Technical Accuracy is required',
                            element_id: 'technical_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Overall Assessment',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'overall_assessment',
                            required: 'Overall Assessment is required',
                            placeholder:
                                'Please comment on the overall impression and drinking pleasure, give any suggestions for improvement, and add needed context for your Final Score',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Final Score',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'score_input',
                            element_id: 'final_score',
                            required: 'Final Score is required',
                            max_score: 50,
                        },
                    ],
                },
            ],
        },
    ],
};

export const midwinter2022Cider: ISheetSchema = {
    uid: 'midwinter_2022_cider',
    title: 'Midwinter 2022: Cider',
    minAllowedScore: 0,
    maxAllowedScore: 50,
    scoringGuide: scoringRangeGuide,
    descriptors: ciderDescriptors,
    sections: [
        {
            title: 'Bouquet/Aroma',
            className: 'no-y-seperation',
            tooltip:
                'Aromatic expression of apples and other ingredients as appropriate for style.',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'aroma_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Aroma is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Flavor',
            className: 'no-y-seperation',
            tooltip: 'Flavor of apples and other characteristics as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'flavor_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Flavor is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'aroma_flavor_notes',
                            placeholder: 'Please comment on your Aroma and Flavor scores here',
                            title: 'Bouquet/Aroma and Flavor notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'aroma_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Bouquet/Aroma score',
                                },
                                {
                                    field: 'flavor_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Flavor score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Appearance',
            className: 'col-span-full',
            tooltip: 'Color, clarity, and carbonation level as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'appearance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Appearance is required',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'appearance_notes',
                            placeholder: 'Please comment on your Appearance scores here',
                            title: 'Appearance notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'appearance_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Appearance score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Balance/Drinkability',
            tooltip:
                'Balance of the acidity, sweetness, tannins, and alcohol as appropritate for style',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'balance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Balance/Drinkability is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Finish/Aftertaste',
            tooltip: 'The lingering flavors and impression',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'finish_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Finish/Aftertaste is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'balance_drink_notes',
                            placeholder:
                                'Please comment on your Balance, Drinkability, Finish, and Aftertaste scores here',
                            title: 'Balance, Drinkability, Finish, and Aftertaste notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'balance_slider',
                                    value: 10,
                                    warning:
                                        'Be sure to address the low Balance/Drinkability score',
                                },
                                {
                                    field: 'finish_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Finish/Aftertaste score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Style Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'style_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Style Accuracy is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Technical Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'technical_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Technical Accuracy is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Overall Assessment',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'overall_assessment',
                            required: 'Overall Assessment is required',
                            placeholder:
                                'Please comment on the overall impression and drinking pleasure, give any suggestions for improvement, and add needed context for your Final Score',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Final Score',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'score_input',
                            element_id: 'final_score',
                            required: 'Final Score is required',
                            max_score: 50,
                        },
                    ],
                },
            ],
        },
    ],
};

export const midwinter2022Mead: ISheetSchema = {
    uid: 'midwinter_2022_mead',
    title: 'Midwinter 2022: Mead',
    minAllowedScore: 0,
    maxAllowedScore: 50,
    scoringGuide: scoringRangeGuide,
    descriptors: meadDescriptors,
    sections: [
        {
            title: 'Bouquet/Aroma',
            className: 'no-y-seperation',
            tooltip: 'Aromatic expression of honey and other ingredients as appropriate for style.',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'aroma_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Bouquet/Aroma is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Flavor',
            className: 'no-y-seperation',
            tooltip: 'Flavor expression of honey and other ingredients as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'flavor_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Flavor is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'aroma_flavor_notes',
                            placeholder: 'Please comment on your Aroma and Flavor scores here',
                            title: 'Bouquet/Aroma and Flavor notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'aroma_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Bouquet/Aroma score',
                                },
                                {
                                    field: 'flavor_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Flavor score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Appearance',
            className: 'col-span-full',
            tooltip: 'Color, clarity, carbonation level, and legs  as appropriate for style',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'appearance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Appearance is required',
                            steps: 20,
                        },
                        {
                            type: 'textarea',
                            element_id: 'appearance_notes',
                            placeholder: 'Please comment on your Appearance scores here',
                            title: 'Appearance notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'appearance_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Appearance score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Balance/Drinkability',
            tooltip:
                'Balance of the sweetness, acidity, tannins, and alcohol as appropritate for style',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'balance_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Balance/Drinkability is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Finish/Aftertaste',
            tooltip: 'The lingering flavors and impression',
            className: 'no-y-seperation',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'finish_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Finish/Aftertaste is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: '',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'balance_drink_notes',
                            placeholder:
                                'Please comment on your Balance, Drinkability, Finish, and Aftertaste scores here',
                            title: 'Balance, Drinkability, Finish, and Aftertaste notes',
                            rows: 2,
                            watch: [
                                {
                                    field: 'balance_slider',
                                    value: 10,
                                    warning:
                                        'Be sure to address the low Balance/Drinkability score',
                                },
                                {
                                    field: 'finish_slider',
                                    value: 10,
                                    warning: 'Be sure to address the low Finish/Aftertaste score',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'Style Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'style_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Style Accuracy is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Technical Accuracy',
            columns: [
                {
                    elements: [
                        {
                            type: 'slider',
                            element_id: 'technical_slider',
                            min_label: 'Inappropriate',
                            max_label: 'Appropriate',
                            required: 'Technical Accuracy is required',
                            steps: 20,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Overall Assessment',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'textarea',
                            element_id: 'overall_assessment',
                            required: 'Overall Assessment is required',
                            placeholder:
                                'Please comment on the overall impression and drinking pleasure, give any suggestions for improvement, and add needed context for your Final Score',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Final Score',
            className: 'col-span-full',
            columns: [
                {
                    elements: [
                        {
                            type: 'score_input',
                            element_id: 'final_score',
                            required: 'Final Score is required',
                            max_score: 50,
                        },
                    ],
                },
            ],
        },
    ],
};

export const schemas: Record<SheetSchemaId, ISheetSchema> = {
    default_schema: defaultSchema,
    expanded_schema: expandedSchema,
    midwinter_2022: midwinter2022,
    midwinter_2022_cider: midwinter2022Cider,
    midwinter_2022_mead: midwinter2022Mead,
};

export const getSheetSchema = (schema_id: SheetSchemaId): ISheetSchema => {
    return schemas[schema_id] || defaultSchema;
};
