import React from 'react';
import { RouteComponentProps, Redirect, useMatch } from '@reach/router';
import { UserContext } from '../../providers/UserProvider';
import { ApplicationRoutes } from '../../routes';
import { parameterizeEndpoint } from '@flight-cap/shared';
import { CompetitionContext } from '../../providers/CompetitionProvider';

const CompetitionAdminRoute: React.FunctionComponent<
    {
        as:
            | React.ComponentClass<{ children?: React.ReactNode }>
            | React.FunctionComponent<{ children?: React.ReactNode }>;
    } & RouteComponentProps & { children?: React.ReactNode }
> = ({ as: Comp, ...rest }) => {
    const { user, resolved: userResolved } = React.useContext(UserContext);
    const { competition, resolved: competitionResolved } = React.useContext(CompetitionContext);

    const match = useMatch(`${ApplicationRoutes.Competition}/*`);
    const competitionSlug = match?.competitionSlug;

    return userResolved && competitionResolved ? (
        competitionSlug &&
        competition &&
        user &&
        (user.competitions?.[competition.uid]?.roles?.includes('admin') ||
            user.competitions?.[competition.uid]?.roles?.includes('owner')) ? (
            <Comp {...rest} />
        ) : (
            <Redirect
                to={parameterizeEndpoint(ApplicationRoutes.JudgingDashboard, { competitionSlug })}
                noThrow
            />
        )
    ) : (
        <></>
    );
};

export default CompetitionAdminRoute;
