import React from 'react';
import { ICompetitionRecord, IEntryRecord, ISheetSchema } from '@flight-cap/shared';
import { schemas as schemaMap } from '../../../sheet_schemas';

type ISheetSchemasContext = {
    schemas: ISheetSchema[];
    getSchemaForEntry: (competition: ICompetitionRecord, entry: IEntryRecord) => ISheetSchema;
};

export const SheetSchemasContext: React.Context<ISheetSchemasContext> =
    React.createContext<ISheetSchemasContext>({
        schemas: null,
        getSchemaForEntry: null,
    });

const SheetSchemasProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [schemas, setSchemas] = React.useState<ISheetSchema[]>(null);

    /**
     * dont manage the schemas in firestore now, but leaving provider plumbing in place
     * for future handling in firestore
     **/

    React.useEffect(() => {
        setSchemas(Object.values(schemaMap));
    }, []);

    // React.useEffect(() => {
    //     if (competition?.uid) {
    //         return firestore
    //             .collection(`competitions/${competition.uid}/sheet_schemas`)
    //             .onSnapshot((schemas) => {
    //                 console.log('Sheet Schemas Provider - onSnapshot');
    //                 setSchemas(
    //                     schemas.docs.map((doc) => ({
    //                         uid: doc.id,
    //                         ...(doc.data() as ISheetSchema),
    //                     }))
    //                 );
    //             });
    //     } else {
    //         setSchemas(null);
    //     }
    // }, [competition?.uid]);

    const getSchemaForEntry = (
        competition: ICompetitionRecord,
        entry: IEntryRecord
    ): ISheetSchema => {
        if (!competition || !entry) {
            return null;
        }

        return (
            schemas?.find((schema) => schema.uid === entry.sheet_schema_id) ||
            schemas?.find(
                (schema) =>
                    schema.uid ===
                    `${competition?.default_sheet_schema}_${entry.style_snippet.type}`
            ) ||
            schemas?.find((schema) => schema.uid === competition?.default_sheet_schema) ||
            schemas?.find((schema) => schema.uid === 'default_schema')
        );
    };

    return (
        <SheetSchemasContext.Provider value={{ schemas, getSchemaForEntry }}>
            {children}
        </SheetSchemasContext.Provider>
    );
};

export default SheetSchemasProvider;
