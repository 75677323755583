import React from 'react';

const PlaneIcon: React.FunctionComponent<{ className?: string }> = ({ className }) => (
    <svg
        className={`h-4 w-4 ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 512 433"
        stroke="currentColor"
        aria-hidden="true"
    >
        <g fill="currentColor" fillRule="nonzero">
            <path d="M404.258 432.515c-6.86 0-14.387-2.253-22.528-6.741L197.36 289.65a8.567 8.567 0 0 1-3.465-6.451 8.547 8.547 0 0 1 2.816-6.758L415.095 79.918a8.515 8.515 0 0 1 12.05.631c3.157 3.499 2.867 8.892-.632 12.05L215.91 282.14l175.002 129.297c4.625 2.475 12.442 5.615 18.005 3.038 5.666-2.594 8.465-10.872 9.83-17.357l74.667-351.864c2.902-11.622 1.502-20.974-3.669-25.275-3.942-3.277-9.813-3.516-16.162-.649L34.697 188.598c-13.466 5.41-17.699 10.974-17.63 12.817.05 1.212 2.952 4.557 11.605 7.203l108.8 33.962 119.45-75.366c3.993-2.492 9.25-1.314 11.759 2.662 2.508 3.977 1.33 9.25-2.663 11.76L143.241 259.1a8.606 8.606 0 0 1-7.1.938L23.637 224.916C3.67 218.806.256 208.02.017 202.132c-.341-8.072 4.318-19.746 28.45-29.406L466.995 3.63c11.896-5.427 24.662-4.267 33.69 3.26 10.462 8.72 13.875 24.115 9.37 42.223l-74.599 351.539c-3.14 14.984-9.66 24.866-19.405 29.337-3.67 1.69-7.594 2.526-11.793 2.526Z" />
            <path d="M404.258 432.515c-6.86 0-14.387-2.253-22.528-6.741L267.042 341.14c-3.789-2.799-4.608-8.158-1.792-11.93 2.8-3.788 8.158-4.607 11.93-1.791l113.732 84.019c4.625 2.475 12.442 5.615 18.005 3.038 5.666-2.594 8.465-10.872 9.83-17.357l74.667-351.864c2.902-11.622 1.502-20.974-3.669-25.275-3.942-3.277-9.813-3.516-16.162-.649L112.749 158.496l-78.052 30.102c-13.466 5.41-17.699 10.974-17.63 12.817.05 1.212 2.952 4.557 11.605 7.203L141.21 243.74c2.696.836 4.795 2.97 5.632 5.666l38.69 127.334c1.04 2.85 1.843 5.684 2.543 8.141.546 1.946 1.228 4.37 1.69 5.086-.069-.085 1.296.324 4.932.341 7.628 0 10.53-2.85 16.332-8.499l28.331-27.545c3.38-3.311 8.772-3.209 12.066.17 3.277 3.38 3.209 8.79-.17 12.066l-27.853 27.051c-6.861 6.707-14.132 13.807-28.706 13.807-16.726 0-20.105-7.407-23.058-17.852-.597-2.116-1.28-4.557-2.304-7.373l-37.512-123.443-108.186-33.775C3.67 218.806.256 208.02.017 202.132c-.341-8.072 4.318-19.746 28.45-29.406L466.995 3.63c11.896-5.427 24.662-4.267 33.69 3.26 10.462 8.72 13.875 24.115 9.37 42.223l-74.599 351.539c-3.14 14.984-9.66 24.866-19.405 29.337-3.67 1.69-7.594 2.526-11.793 2.526Z" />
            <path d="M298.667 156.667c0 4.693-3.84 8.533-8.534 8.533-4.693 0-8.533-3.84-8.533-8.533 0-4.694 3.84-8.534 8.533-8.534 4.694 0 8.534 3.84 8.534 8.534Z" />
        </g>
    </svg>
);

export default PlaneIcon;
