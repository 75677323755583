import React from 'react';
import { RouteComponentProps, Router, Redirect } from '@reach/router';
import FlightDetail from './FlightDetail/FlightDetail';
import FlightMenu from './FlightMenu';
import { FlightsContext } from './providers/FlightsProvider';

const NoFlights: React.FunctionComponent = () => (
    <p className="container-dashed-gray text-sm">
        You aren't assigned to any active flights. Sit tight. Once the administrator activates one
        of your flights, you'll be redirected.
    </p>
);

const JudgingDashboard: React.FunctionComponent<
    RouteComponentProps & { children?: React.ReactNode }
> = ({ navigate }) => {
    const { flights } = React.useContext(FlightsContext);
    const activeFlights = flights?.filter((f) => f.active);

    return (
        <>
            {activeFlights?.length > 1 ? <FlightMenu /> : <></>}
            {activeFlights?.length === 0 ? <NoFlights /> : <></>}
            <Router>
                <FlightDetail path="/flight/:flightId/*" />
                {activeFlights?.length ? (
                    <Redirect from="/*" to={`flight/${activeFlights?.[0].uid}/`} noThrow />
                ) : null}
            </Router>
        </>
    );
};

export default JudgingDashboard;
