import {
    IEntry,
    IScoreSheetData,
    ITableScoringFlight,
    parameterizeEndpoint,
} from '@flight-cap/shared';
import { navigate } from '@reach/router';
import React from 'react';
import { useForm, FormProvider, FieldErrors } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import Card from '../../../../components/Card/Card';
import LastSavedIndicator from '../../../../components/LastSaved/LastSavedIndicator';
import Scoresheet from '../../../../components/Scoresheet/Scoresheet';
import { SimpleToastContent } from '../../../../components/Toasts/ToastMessage';
import { useEntryAllergens } from '../../../../hooks/useEntryAllergens';
import { useHasFormChanges } from '../../../../hooks/useHasFormChanges';
import { usePreventWindowUnload } from '../../../../hooks/usePreventWindowUnload';
import { CompetitionContext } from '../../../../providers/CompetitionProvider';
import { UserContext } from '../../../../providers/UserProvider';
import { ApplicationRoutes } from '../../../../routes';
import ClaimEntryDrawer from '../../ClaimEntryDrawer';
import { EntryContext } from '../../providers/EntryProvider';
import { DetailsSidebar } from '../DetailsSidebar';
import { DescriptorChangedHandler } from '../FlightDetail';
import { OtherJudges } from '../OtherJudges';
import StickyEntryInfo from './StickyEntryInfo';

let autosaveTimerId: NodeJS.Timeout;
const AUTOSAVE_TIMEOUT_DURATION = 2000;

const ScoringFlightDetail: React.FunctionComponent<{ flight: ITableScoringFlight }> = ({
    flight,
}) => {
    const { entry, scoreSheetData, updateScore } = React.useContext(EntryContext);
    const { user } = React.useContext(UserContext);
    const { competition } = React.useContext(CompetitionContext);
    const { hasAllergens } = useEntryAllergens(entry);
    const { addToast, removeAllToasts } = useToasts();
    const [FormChangeWrapper, sheetHasChanges, setSheetHasChanges] = useHasFormChanges();
    const [AutoSaveChangeWrapper, shouldAutoSave, setShouldAutosave] = useHasFormChanges();
    const sheetFormMethods = useForm({ mode: 'all' });
    const {
        getValues: getSheetFormValues,
        formState,
        trigger: triggerSheetValidation,
        reset: resetSheetForm,
    } = sheetFormMethods;
    const [lastSaved, setLastSaved] = React.useState<Date>(null);
    // const [showSheet, setShowSheet] = React.useState(false);

    const sheetId = React.useRef<string>(scoreSheetData?.uid);
    React.useEffect(() => {
        if (sheetId.current !== scoreSheetData?.uid) {
            resetSheetForm(scoreSheetData?.values || {});
            triggerSheetValidation();
            sheetId.current = scoreSheetData?.uid;
        }
    }, [scoreSheetData?.uid, scoreSheetData, resetSheetForm, triggerSheetValidation]);

    React.useEffect(() => {
        const savedTime = scoreSheetData?.saved_at?.seconds;
        const autoSavedTime = scoreSheetData?.auto_saved_at?.seconds;
        const lastSaved = Math.max(savedTime || 0, autoSavedTime || 0) || NaN;

        setLastSaved(new Date(lastSaved * 1000));
    }, [setLastSaved, scoreSheetData]);

    const scrollTarget = React.useRef<HTMLDivElement>(null);
    const leftColumn = React.useRef<HTMLDivElement>(null);
    const leftColumTopOffest = React.useRef(200);

    React.useEffect(() => {
        const handleScroll = () => {
            if (leftColumn.current) {
                let offset = leftColumn.current?.getBoundingClientRect()?.top;
                if (!isNaN(offset)) {
                    leftColumTopOffest.current = offset;
                }
                leftColumn.current.style.maxHeight = `calc(100vh - ${leftColumTopOffest.current}px)`;
            }
        };
        document.addEventListener('scroll', handleScroll);

        return () => document.removeEventListener('scroll', handleScroll);
    }, []);

    let displayedErrors: FieldErrors = {};
    if (formState.errors) {
        if (formState.isSubmitted || formState.isSubmitting) {
            displayedErrors = formState.errors;
        } else {
            Object.keys(formState.errors).forEach((key) => {
                if (formState.dirtyFields[key] || formState.touchedFields[key]) {
                    displayedErrors[key] = formState.errors[key];
                }
            });
        }
    }

    const handleEntryClaimed = (entry: IEntry) => {
        closeDrawer();
        navigateToEntry(entry.uid);
    };

    const navigateToEntry = async (entryId: string) => {
        // setShowSheet(false);

        if (sheetHasChanges) {
            const isValid = await triggerSheetValidation();
            await updateScore({
                values: getSheetFormValues() as IScoreSheetData['values'],
                options: { autosave: true },
                isValid,
            });
        }

        navigate(
            parameterizeEndpoint(ApplicationRoutes.JudgingEntry, {
                competitionSlug: competition.slug,
                flightId: flight.uid,
                entryId,
            })
        );

        scrollTarget.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    // const currentEntryId = React.useRef(entry?.uid);
    // const currentScoresheetId = React.useRef<string>(null);
    // React.useEffect(() => {
    //     if (scoreSheetData?.uid !== currentScoresheetId.current) {
    //         setShowSheet(true);
    //         currentScoresheetId.current = scoreSheetData?.uid;
    //     }
    // }, [scoreSheetData?.uid]);

    if (entry && entry?.flight_snippet?.uid !== flight.uid) {
        navigate(
            parameterizeEndpoint(ApplicationRoutes.JudgingFlight, {
                competitionSlug: competition.slug,
                flightId: flight.uid,
            }),
            { replace: true }
        );
    }

    if (flight?.active === false) {
        navigate(
            parameterizeEndpoint(ApplicationRoutes.JudgingDashboard, {
                competitionSlug: competition.slug,
            }),
            { replace: true }
        );
    }

    const [showEntryDrawer, setShowEntryDrawer] = React.useState(false);

    const closeDrawer = () => {
        setShowEntryDrawer(false);
    };

    const openDrawer = () => {
        setShowEntryDrawer(true);
    };

    const otherJudges = React.useMemo(() => {
        return flight
            ? Object.keys(flight.judges)
                  .filter((uid) => user.uid !== uid)
                  .map((key) => flight.judges[key])
            : [];
    }, [flight, user.uid]);

    React.useEffect(() => {
        setSheetHasChanges(false);
        setShouldAutosave(false);
    }, [entry?.uid, setSheetHasChanges, setShouldAutosave]);

    React.useEffect(() => {
        clearTimeout(autosaveTimerId);
        if (shouldAutoSave === true) {
            autosaveTimerId = setTimeout(async () => {
                if (shouldAutoSave) {
                    const isValid = await triggerSheetValidation();
                    console.log('autosaving... isValid', isValid);
                    await updateScore({
                        values: getSheetFormValues() as IScoreSheetData['values'],
                        options: { autosave: true },
                        isValid,
                    });
                    setLastSaved(new Date());
                    setShouldAutosave(false);
                }
            }, AUTOSAVE_TIMEOUT_DURATION);
        }
        return () => clearTimeout(autosaveTimerId);
    }, [
        shouldAutoSave,
        setShouldAutosave,
        updateScore,
        getSheetFormValues,
        triggerSheetValidation,
        setLastSaved,
    ]);

    usePreventWindowUnload(sheetHasChanges);

    const handleDescriptorChanged: DescriptorChangedHandler = async (
        key: string,
        value: boolean
    ) => {
        const isValid = await triggerSheetValidation();

        await updateScore({
            values: getSheetFormValues() as IScoreSheetData['values'],
            descriptors: { [key]: value },
            isValid,
        });

        setLastSaved(new Date());

        // update the local state, since we are no longer subscribing to sheet updates in real-time
        scoreSheetData.descriptors[key] = value;
    };

    const handleScoreSheetFormSubmit = () => {
        removeAllToasts();

        updateScore({
            values: getSheetFormValues() as IScoreSheetData['values'],
            isValid: true,
        });

        addToast(
            <SimpleToastContent
                title="Scoresheet Submitted!"
                subTitle="Thanks for judging. You're the best!"
            />,
            { appearance: 'success' }
        );
        setSheetHasChanges(false);
    };

    const [showStickyEntryInfo, setShowStickyEntryInfo] = React.useState(false);

    const handleToggleStickyEntryInfo = (show: boolean) => {
        setShowStickyEntryInfo(show);
    };

    return (
        <>
            <ClaimEntryDrawer
                show={showEntryDrawer}
                closeDrawer={closeDrawer}
                onEntryClaimed={handleEntryClaimed}
            />
            <div className="flex flex-col-reverse md:flex-row mb-24" ref={scrollTarget}>
                <div
                    ref={leftColumn}
                    className="md:sticky md:top-4 md:flex-initial md:w-1/3 md:mr-4 w-full h-fit relative scoring-flight-left-column"
                    style={{
                        maxHeight: `calc(100vh - ${leftColumTopOffest.current}px)`,
                    }}
                >
                    <DetailsSidebar
                        flight={flight}
                        navigateToEntry={navigateToEntry}
                        openDrawer={openDrawer}
                        onDescriptorChanged={handleDescriptorChanged}
                    />
                </div>
                <div className="md:w-2/3 mb-12">
                    {otherJudges.length > 0 && (
                        <div className="flex items-center justify-center bg-gray-50 rounded-md p-2 mb-2">
                            <OtherJudges otherJudges={otherJudges} />
                        </div>
                    )}
                    {showStickyEntryInfo && <StickyEntryInfo entry={entry} />}
                    <Card className={`${hasAllergens ? 'bg-yellow-stripes' : ''}`}>
                        {entry ? (
                            <FormProvider {...sheetFormMethods}>
                                <FormChangeWrapper>
                                    <AutoSaveChangeWrapper>
                                        <Scoresheet
                                            entry={entry}
                                            scoreSheetData={scoreSheetData}
                                            onFormSubmit={handleScoreSheetFormSubmit}
                                            hasChanges={sheetHasChanges}
                                            errors={displayedErrors}
                                            toggleStickyEntryInfo={handleToggleStickyEntryInfo}
                                        />
                                    </AutoSaveChangeWrapper>
                                </FormChangeWrapper>
                            </FormProvider>
                        ) : (
                            <div className="container-dashed-gray h-40">No Entry Selected</div>
                        )}
                    </Card>
                    {entry && lastSaved && (
                        <div className="md:mb-0 text-center">
                            <LastSavedIndicator savedAt={lastSaved} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ScoringFlightDetail;
