import React, { ReactNode } from 'react';
import CheckmarkIcon from '../Icon/CheckmarkIcon';
import XIcon from '../Icon/XIcon';

interface Props {
    editorNode: ReactNode;
    staticNode: React.ReactNode;
    className?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    closeOnBlur?: boolean;
    showCancel?: boolean;
}

const Editable: React.FunctionComponent<Props> = ({
    editorNode,
    staticNode,
    className = '',
    closeOnBlur = true,
    showCancel = true,
    onConfirm,
    onCancel,
}) => {
    const [isEditing, setEditing] = React.useState(false);

    const editorRef = React.useRef<HTMLDivElement>();

    // Event handler while pressing any key while editing
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && onConfirm) {
            handleConfirm();
        }

        if (event.key === 'Escape') {
            handleCancel();
        }
    };

    const handleConfirm = () => {
        onConfirm && onConfirm();
        setEditing(false);
    };

    const handleCancel = () => {
        onCancel && onCancel();
        setEditing(false);
    };

    const handleBlur = () => {
        if (!closeOnBlur) {
            return;
        }

        setTimeout(() => {
            if (isEditing && !editorRef.current?.contains(document.activeElement)) {
                handleCancel();
            }
        }, 0);
    };

    const handleTriggerClicked = (evt: React.MouseEvent) => {
        evt.stopPropagation();
        evt.preventDefault();
        setEditing(true);
    };

    return (
        <section className={className}>
            {isEditing ? (
                <div ref={editorRef} className="flex flex-col relative" onBlur={handleBlur}>
                    <div onKeyDown={(e) => handleKeyDown(e)}>
                        <>{editorNode}</>
                    </div>
                    <div className="flex justify-end space-x-1 absolute right-0 top-full">
                        <button className="btn-white btn-shadow" onClick={handleConfirm}>
                            <CheckmarkIcon className="text-green-800" />
                        </button>
                        {showCancel && (
                            <button className="btn-white btn-shadow" onClick={handleCancel}>
                                <XIcon className="text-red-800" />
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <div className="hover:bg-gray-100 w-max" onClick={handleTriggerClicked}>
                    <>{staticNode}</>
                </div>
            )}
        </section>
    );
};

export default Editable;
