import React from 'react';
import { StylesContext } from '../../providers/StylesProvider';
import FormMulitSelect from './QueryMulitSelect';
import { IValueInputProps } from './QueryFormRow';

const QueryStyleSelect: React.FunctionComponent<IValueInputProps> = ({ name }) => {
    const { styles } = React.useContext(StylesContext);
    const items = styles
        .filter((s) => s.enabled)
        .map((s) => ({ label: `${s.category}${s.sub_category} ${s.name}`, value: s.uid }));

    return <FormMulitSelect name={name} items={items} buttonLabel="Choose Styles" />;
};

export default QueryStyleSelect;
