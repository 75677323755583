import React from 'react';
import {
    deleteField,
    firestore,
    FirestoreFieldValue,
    firstoreArrayRemove,
    firstoreArrayUnion,
    timestamp,
} from '../firebase';
import { CompetitionContext } from '../providers/CompetitionProvider';
import {
    generateSessionNames,
    ISession,
    ISessionRecord,
    ISessionSnippet,
    ParticipantSessionsMap,
} from '@flight-cap/shared';

type ISessionsContext = {
    sessions: ISession[];
    addSession: (name?: string) => Promise<ISession>;
    updateSession: (id: string, updates: Partial<ISessionRecord>) => Promise<void>;
    deleteSession: (id: string) => Promise<void>;
    participantSessionsMap: ParticipantSessionsMap;
    setParticipantAvailability: (
        participantId: string,
        sessionId: string,
        available: boolean
    ) => Promise<void>;
    getParticipantAvailability: (sessionId: string, participantId: string) => boolean;
};

export const SessionsContext: React.Context<ISessionsContext> =
    React.createContext<ISessionsContext>({
        sessions: undefined,
        addSession: undefined,
        updateSession: undefined,
        deleteSession: undefined,
        participantSessionsMap: undefined,
        setParticipantAvailability: undefined,
        getParticipantAvailability: undefined,
    });

/**
 * Provides the all the tables corresponding to the currently selected competition.
 */
const SessionsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [sessions, setSessions] = React.useState<ISession[]>([]);
    const [participantSessionsMap, setParticipantSessionsMap] =
        React.useState<ParticipantSessionsMap>({});

    const { competition } = React.useContext(CompetitionContext);

    React.useEffect(() => {
        if (competition?.uid) {
            const participantsSessionsUnsubscribe = firestore
                .doc(`competitions/${competition.uid}/indexes/participants_sessions`)
                .onSnapshot((result) => {
                    console.log('Admin Sessions - participantsSessions snapshot');
                    setParticipantSessionsMap(result.data() as ParticipantSessionsMap);
                });

            const sessionsUnsubscribe = firestore
                .collection(`competitions/${competition.uid}/sessions`)
                .orderBy('created_at', 'asc')
                .onSnapshot((sessions) => {
                    const array = sessions.docs.map((session) => ({
                        uid: session.id,
                        ...(session.data() as ISession),
                    }));

                    console.log('Admin Sessions Provider - onSnapShot');

                    generateSessionNames(array);

                    setSessions(array);
                });

            return () => {
                sessionsUnsubscribe();
                participantsSessionsUnsubscribe();
            };
        } else {
            setSessions([]);
        }
    }, [competition]);

    const addSession = async (title: string = '') => {
        const doc = firestore.collection(`competitions/${competition.uid}/sessions/`).doc();
        const sessionData: ISessionRecord = {
            title,
            location: null,
            start_date: null,
            map_link: null,
            created_at: timestamp(),
            saved_at: timestamp(),
            participant_snippets: {},
            participant_ids: [],
        };

        await doc.set(sessionData);

        return Promise.resolve({ ...sessionData, uid: doc.id } as ISession);
    };

    const deleteSession = (id: string) => {
        return firestore.doc(`competitions/${competition.uid}/sessions/${id}`).delete();
    };

    const updateSession = (id: string, updates: Partial<ISessionRecord>) => {
        const doc = firestore.doc(`competitions/${competition.uid}/sessions/${id}`);
        return doc.set(updates, { merge: true });
    };

    const setParticipantAvailability = async (
        participantId: string,
        sessionId: string,
        available: boolean
    ) => {
        // update the index
        const indexRef = firestore.doc(
            `competitions/${competition.uid}/indexes/participants_sessions`
        );

        const indexUpdate = {
            [participantId]: {
                ...(participantSessionsMap?.[sessionId] || {}),
                [sessionId]: available,
            },
        };

        await indexRef.set(indexUpdate, { merge: true });

        const session = sessions.find((s) => s.uid === sessionId);

        // update the participants session_snippet
        const participantRef = firestore.doc(
            `competitions/${competition.uid}/participants/${participantId}`
        );

        let snippet_update: ISessionSnippet | FirestoreFieldValue;
        let ids_update: FirestoreFieldValue;

        if (available) {
            snippet_update = {
                uid: session.uid,
                title: session.title,
                start_date: session.start_date,
                location: session.location,
                map_link: session.map_link,
            };

            ids_update = firstoreArrayUnion(sessionId);
        } else {
            snippet_update = deleteField();
            ids_update = firstoreArrayRemove(sessionId);
        }

        const preciseUpdate = {
            [`session_snippets.${sessionId}`]: snippet_update,
            session_ids: ids_update,
        };

        await participantRef.update(preciseUpdate);
    };

    const getParticipantAvailability = (participantId: string, sessionId: string) => {
        return participantSessionsMap?.[participantId]?.[sessionId];
    };

    return (
        <SessionsContext.Provider
            value={{
                sessions,
                addSession,
                deleteSession,
                updateSession,
                participantSessionsMap,
                setParticipantAvailability,
                getParticipantAvailability,
            }}
        >
            {children}
        </SessionsContext.Provider>
    );
};

export default SessionsProvider;
