import React from 'react';
import Drawer from '../../../components/Drawer/Drawer';
import { ICompetitionStyle, IEntryRecord } from '@flight-cap/shared';
import { FormProvider, useForm } from 'react-hook-form';
import { EntriesContext } from '../providers/EntriesProvider';
import { StylesContext } from '../providers/StylesProvider';
import { IEntryRow } from './EntriesTable.columns';
import { TablesContext } from '../providers/TablesProvider';
import DangerIcon from '../../../components/Icon/DangerIcon';
import EntryInforOverride from './EntryInfoOverride';

interface IProps {
    entries: IEntryRow[];
    onCancel: React.MouseEventHandler;
}

type FormData = {
    style_uid: string;
    override_table_uid: string;
    required_info_temp: string;
    optional_info_temp: string;
    specific_info_temp: string;
    disqualified: boolean;
    disqualified_reason: string;
};

const EntryEditDrawer: React.FunctionComponent<IProps> = ({ entries, onCancel }) => {
    const methods = useForm<FormData>({
        mode: 'onChange',
    });
    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
        watch,
    } = methods;
    const { updateEntries } = React.useContext(EntriesContext);
    const { styles } = React.useContext(StylesContext);
    const { tables } = React.useContext(TablesContext);

    const watchDisqualified = watch('disqualified', false);

    const singleEntry = entries?.length === 1;

    const selectedIds = React.useRef<string>(entries?.map((e) => e.uid).join(','));

    React.useEffect(() => {
        const ids = entries?.map((e) => e.uid).join(',');

        if (selectedIds.current !== ids) {
            selectedIds.current = ids;
            const entry = entries?.length === 1 ? entries[0] : null;

            if (entry === null) {
                reset();
            } else {
                reset({
                    style_uid:
                        entries.length === 1
                            ? entry.unrecognizedStyle
                                ? ''
                                : entry?.style_snippet?.uid
                            : '',
                    override_table_uid: entry.table_override_id,
                    required_info_temp: entry.requiredInfo(),
                    optional_info_temp: entry.optionalInfo(),
                    specific_info_temp: entry.specificInfo(),
                    disqualified: entry.disqualified,
                    disqualified_reason: entry.disqualified_reason,
                });
            }
        }
    }, [reset, entries]);

    const onSubmit = async (data: FormData) => {
        if (!entries) {
            return;
        }

        const {
            optional_info_temp,
            required_info_temp,
            specific_info_temp,
            disqualified,
            disqualified_reason,
            style_uid,
            override_table_uid,
        } = data;

        const styleId = style_uid;

        let selectedStyleSnippet: ICompetitionStyle;

        if (styleId) {
            const style = styles.find((s) => s.uid === styleId);
            selectedStyleSnippet = {
                ...style,
            };
        }

        const entryUpdateIds: string[] = [];
        const entryUpdates: Partial<IEntryRecord>[] = [];

        for (const entry of entries) {
            const update: Partial<IEntryRecord> = {};

            const styleChanged =
                selectedStyleSnippet?.uid && selectedStyleSnippet.uid !== entry?.style_snippet?.uid;
            const requiredInfoChanged = required_info_temp !== entry.requiredInfo();
            const optionalInfoChanged = optional_info_temp !== entry.optionalInfo();
            const specificInfoChanged = specific_info_temp !== entry.specificInfo();
            const dqChanged = disqualified !== entry.disqualified;
            const dqReasonChanged = disqualified_reason !== entry.disqualified_reason;
            const overrideTableChanged = override_table_uid !== entry?.table_override_id;

            if (styleChanged) {
                // assign the style_snippet to the one pertaining to the newly selected style
                update.style_snippet = selectedStyleSnippet;

                if (!entry.table_override_id) {
                    // update the table_snippet based on the new style. NOTE(jgreene): should this be done in a cloud function instead (when the entry style changes?)
                    const table = tables.find((t) => t.categories[selectedStyleSnippet.uid]);
                    if (table) {
                        if (table.uid !== entry.table_snippet?.uid) {
                            update.table_snippet = {
                                ...table,
                            };
                        } else {
                            console.log('found table, but its the same as current');
                        }
                    } else {
                        update.table_snippet = null;
                        console.log(
                            'could not find table for this style id - cannot update table_snippet'
                        );
                    }
                }
            }

            if (overrideTableChanged) {
                let table =
                    tables.find((t) => t.uid === override_table_uid) ||
                    tables.find((t) => t.categories[selectedStyleSnippet.uid]);
                update.table_override_id = override_table_uid;
                update.table_snippet = table
                    ? {
                          ...table,
                      }
                    : null;
                update.flight_snippet = null;
            }

            if (requiredInfoChanged) {
                update.required_info_override =
                    required_info_temp === entry.required_info ? null : required_info_temp;
            }

            if (optionalInfoChanged) {
                update.optional_info_override =
                    optional_info_temp === entry.optional_info ? null : optional_info_temp;
            }

            if (specificInfoChanged) {
                update.specific_info_override =
                    specific_info_temp === entry.specific_info ? null : specific_info_temp;
            }

            if (dqChanged) {
                update.disqualified = disqualified;
            }

            if (dqReasonChanged) {
                update.disqualified_reason = disqualified_reason;
            }

            if (update.table_snippet !== undefined && entry.flight_snippet?.uid) {
                const confirmed = window.confirm(
                    'This entry has been claimed in a flight within its current table. Changing its table will unset its consensus score, its mini-bos status, and any award rank. Are you sure you want to do this?'
                );

                if (!confirmed) {
                    return;
                }
            }

            if (Object.keys(update).length) {
                entryUpdateIds.push(entry.uid);
                entryUpdates.push(update);
                console.log('update', update);
            } else {
                console.log(`nothing to update for entry [${entry.uid}]`);
            }
        }

        // should create a plural function and have it batch
        await updateEntries(entryUpdateIds, entryUpdates);

        onCancel(null);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Drawer
                    title={singleEntry ? 'Edit Entry' : 'Edit Entries'}
                    show={entries?.length > 0 ? true : false}
                    onCancel={onCancel}
                    actions={
                        <>
                            <button
                                onClick={onCancel}
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Save
                            </button>
                        </>
                    }
                >
                    <div className="p-4">
                        {/* style */}
                        <div className="mb-4">
                            <label
                                htmlFor="entry-style"
                                className="flex justify-between items-center"
                            >
                                Style
                                {singleEntry && entries[0].unrecognizedStyle ? (
                                    <div className="badge-red">
                                        <DangerIcon className="mr-1" />
                                        Unrecognized Style: {entries[0]?.style_snippet.uid}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </label>
                            <select
                                id="entry-style"
                                placeholder="Style"
                                aria-invalid={errors['style_uid'] ? 'true' : 'false'}
                                {...register('style_uid', { required: singleEntry })}
                            >
                                <option disabled value="">
                                    Choose a style
                                </option>
                                {styles?.map((s) => (
                                    <option key={s.uid} value={s.uid}>
                                        {s.uid}: {s.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* info ovverides */}
                    {singleEntry && (
                        <>
                            <div className="px-4 space-y-4">
                                <EntryInforOverride
                                    entry={entries[0]}
                                    field="required_info"
                                    label="Required Info"
                                />
                                <EntryInforOverride
                                    entry={entries[0]}
                                    field="specific_info"
                                    label="Specific Info"
                                />
                                <EntryInforOverride
                                    entry={entries[0]}
                                    field="optional_info"
                                    label="Optional Info"
                                />
                            </div>

                            <hr className="mt-8" />
                            {/* disquailifed */}
                            <div
                                className={`${
                                    watchDisqualified ? 'bg-redder-stripes' : ''
                                } p-4 space-y-2`}
                            >
                                <div>
                                    <label className="flex items-center">
                                        <span
                                            className={`${
                                                watchDisqualified
                                                    ? 'bg-yellow-300 text-red-800'
                                                    : ''
                                            } mr-2 p-1 rounded`}
                                        >
                                            Disqualified
                                        </span>
                                        <input type="checkbox" {...register('disqualified')} />
                                    </label>
                                </div>
                                {watchDisqualified && (
                                    <div>
                                        <label>
                                            <input
                                                type="text"
                                                placeholder="Disqualification Reason"
                                                {...register('disqualified_reason', {
                                                    required: watchDisqualified,
                                                })}
                                            />
                                        </label>
                                    </div>
                                )}
                            </div>

                            <hr />
                            {/* table_override */}
                            <div className="p-4">
                                <label>
                                    <span className="mr-2">Table Override</span>
                                    <select {...register('override_table_uid')}>
                                        <option value={''}>No override</option>
                                        {tables.map((t) => (
                                            <option key={t.uid} value={t.uid}>
                                                {t.title}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                        </>
                    )}
                </Drawer>
            </form>
        </FormProvider>
    );
};

export default EntryEditDrawer;
