import { IFlight } from '@flight-cap/shared';
import React from 'react';
import BoltIcon from '../../../../components/Icon/BoltIcon';

const FlightActiveToggle: React.FunctionComponent<{ flight: IFlight }> = ({ flight }) => {
    return (
        <>
            <div
                className={`flex items-center justify-end mt-4 text-xs transition-colors ${
                    flight.active ? 'text-indigo-800' : 'text-gray-400'
                }`}
            >
                <span className="space-x-1 mr-2 flex">
                    <BoltIcon
                        className={
                            flight.active ? 'fill-indigo-500 border-indigo-800' : 'fill-white'
                        }
                    />
                    <span>Flight is {flight.active ? 'active' : 'inactive'}</span>
                </span>

                <input
                    readOnly
                    type="checkbox"
                    title={`flight ${flight.active}`}
                    checked={flight.active}
                    data-flight-id={flight.uid}
                    data-role="toggle-flight-active"
                    data-no-dnd="true"
                />
            </div>
        </>
    );
};

export default FlightActiveToggle;
