import React from 'react';
import { Menu, Transition } from '@headlessui/react';

type HasButtonLabel = { buttonLabel: string | React.ReactChild };
type HasButton = { button: React.ReactChild };

type SelectMenuProps = (HasButton | HasButtonLabel) & {
    className?: string;
    menuClasses?: string;
    id?: string;
    children: React.ReactNode;
};

const SelectMenu: React.FunctionComponent<SelectMenuProps> = (props) => {
    const { className = '', menuClasses = '', id = '' } = props;

    const menuButton = (props as HasButtonLabel).buttonLabel ? (
        <Menu.Button className="select transition duration-150 ease-in-out justify-between">
            <span className="truncate">{(props as HasButtonLabel).buttonLabel}</span>
            <svg
                className="w-5 h-5 ml-2 -mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
                strokeWidth={1}
            >
                <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </Menu.Button>
    ) : (
        (props as HasButton).button
    );

    return (
        <div id={id} className={className}>
            <Menu>
                {({ open }) => (
                    <>
                        <span>{menuButton}</span>

                        <Transition
                            show={open}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                static
                                className={`absolute z-50 right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none ${menuClasses}`}
                            >
                                {props.children}
                            </Menu.Items>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    );
};

export default SelectMenu;
