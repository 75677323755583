import { AwardRank, IEntry, IFlightComparison, IFlightComparisonEntry } from '@flight-cap/shared';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FlightComparisonContext } from '../../providers/FlightComparisonProvider';
import EntryBox from './EntryBox';
import { useDebouncedCallback } from 'use-debounce';

const getEntryDiff = (
    entryId: string,
    prev: IFlightComparisonEntry,
    next: IFlightComparisonEntry
) => {
    const diff: any = {};
    for (const [prop, value] of Object.entries(next)) {
        const key = prop as keyof IFlightComparisonEntry;
        if (prev?.[key] !== value) {
            diff[`${entryId}.${key}`] = value;
        }
    }
    return diff;
};

const diffEntries = (
    prev: IFlightComparison['entries'],
    next: IFlightComparison['entries']
): Partial<IFlightComparison['entries']> => {
    let changes = {};
    for (const [entryId, entryValues] of Object.entries(next)) {
        const diff = getEntryDiff(entryId, prev[entryId], entryValues);
        changes = { ...changes, ...diff };
    }
    return changes;
};

const ComparisonGrid: React.FunctionComponent<{
    entries: IEntry[];
    onPlaceSubmit: (entry: IEntry, rank: AwardRank) => void;
}> = ({ entries, onPlaceSubmit }) => {
    const { comparison, updateComparison, broadcastEliminated } =
        React.useContext(FlightComparisonContext);

    const methods = useForm({
        defaultValues: comparison.entries,
    });

    React.useEffect(() => {
        const formValues = methods.getValues();
        if (Object.keys(formValues).length && Object.keys(comparison.entries).length) {
            const diff = diffEntries(formValues, comparison.entries);
            Object.keys(diff).forEach((key) => {
                methods.setValue(key, diff[key]);
            });
        }
    }, [comparison.entries, comparison.uid]); /* eslint-disable-line */

    const submitChanges = async () => {
        const data = methods.getValues() as IFlightComparison['entries'];

        try {
            await updateComparison(data);
            console.log('updated comparison successfully');
        } catch (e) {
            console.log('failed to updateComparison', e);
        }
    };

    const debouncedSubmit = useDebouncedCallback(submitChanges, 1000);

    const handleFormChange = (evt: React.FormEvent) => {
        if (evt.target instanceof HTMLTextAreaElement) {
            debouncedSubmit();
        } else {
            /**
             * if a 'place' was set, unset any other comparison entry that previously has this place value
             */
            // if (evt.target instanceof HTMLSelectElement) {
            //     const [entryId, prop] = evt.target.name.split('.');
            //     if (prop === 'place') {
            //         const data = methods.getValues() as IFlightComparison['entries'];
            //         for (const [key, value] of Object.entries(data)) {
            //             if (key !== entryId && Number(value.place) === Number(evt.target.value)) {
            //                 methods.setValue(`${key}.place`, null);
            //             }
            //         }
            //     }
            // }

            if (evt.target instanceof HTMLInputElement && evt.target.name.endsWith('eliminated')) {
                const [entryId] = evt.target.name.split('.');
                broadcastEliminated(entryId, evt.target.checked);
            }
            submitChanges();
        }
    };

    return (
        <FormProvider {...methods}>
            <form onChange={handleFormChange}>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                    {entries.map((entry) => {
                        return (
                            <EntryBox
                                key={entry.uid}
                                entry={entry}
                                onPlaceSubmit={onPlaceSubmit}
                                data={comparison.entries[entry.uid]}
                            />
                        );
                    })}
                </div>
            </form>
        </FormProvider>
    );
};

export default ComparisonGrid;
