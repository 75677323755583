import { IScoreSheetData } from '@flight-cap/shared';
import React from 'react';
import DangerIcon from '../Icon/DangerIcon';

interface Props {
    reasons: IScoreSheetData['needs_attention'];
    condensed?: boolean;
}
const SheetNeedsAttention: React.FunctionComponent<Props> = ({ reasons, condensed = false }) => {
    const tooltipText = reasons.join(',');

    return (
        <span className="badge-yellow badge-rounded ml-4" title={tooltipText}>
            <DangerIcon />
            {!condensed && (
                <ul className="ml-2 p-1">
                    {reasons.map((r) => (
                        <li key={r} className="text-xs">
                            {r}
                        </li>
                    ))}
                </ul>
            )}
        </span>
    );
};

export default SheetNeedsAttention;
