import * as React from 'react';
import FlightDetailBoundaryContent from './FlightDetailBoundaryContent';
import DefaultErrorBoundaryContent from './DefaultErrorBoundaryContent';

const config = {
    logglyUrl: process.env.REACT_APP_LOGGLY_URL,
    environment: process.env.ENVIRONMENT,
};

interface IState {
    hasError: boolean;
}

type ErrorBoundaryType = 'default' | 'flight-detail';

const contentMap: Record<ErrorBoundaryType, React.ReactNode> = {
    'flight-detail': <FlightDetailBoundaryContent />,
    default: <DefaultErrorBoundaryContent />,
};

interface IProps {
    children: React.ReactNode;
    type?: ErrorBoundaryType;
}

const { environment } = config;
export function logError(error: Error, errorInfo: React.ErrorInfo) {
    if (config.environment === 'local') {
        console.log('caught error', error, errorInfo);
    } else {
        fetch(config.logglyUrl, {
            method: 'POST',
            body: JSON.stringify({ errorInfo, error, environment }),
        });
    }
}

class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return contentMap[this.props.type] || <DefaultErrorBoundaryContent />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
