import React, { Dispatch, SetStateAction } from 'react';
import { AuthResponseCode } from '../firebase';
import { Link } from '@reach/router';
import Alert from '../components/Alert/Alert';

export const getErrorMessage = (error: firebase.default.auth.AuthError): React.ReactChild => {
    let message: React.ReactChild = '';

    switch (error.code) {
        case AuthResponseCode.UserNotFound:
        case AuthResponseCode.WrongPassword:
            message =
                'Sorry, that email address and password did not match anything we have on file.';
            break;
        case AuthResponseCode.PasswordResetWrongEmail:
            message = 'Sorry, there is no account associated with that email address';
            break;
        case AuthResponseCode.InvalidEmail:
            message = 'Sorry, that email address is not valid.';
            break;
        case AuthResponseCode.EmailInUse:
            message = (
                <span>
                    The email address is already in use by another account. Would you like to{' '}
                    <Link to="/sign-in">sign-in</Link>?
                </span>
            );
            break;

        default:
            message = error.message;
    }

    return message;
};

export const useFirebaseAuthError = (): [
    React.FunctionComponent,
    Dispatch<SetStateAction<firebase.default.auth.AuthError>>
] => {
    const [error, setError] = React.useState<firebase.default.auth.AuthError>(null);

    if (error) {
        console.log('useFirebaseAuthError', error);
    }

    return [() => (error ? <Alert>{getErrorMessage(error)}</Alert> : <></>), setError];
};
