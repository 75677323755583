import React from 'react';
import Editable from '../../../components/Editable/Editable';
import JudgeCertifications from '../../../components/JudgeInfo/JudgeCertifications';
import JudgeRankBagde from '../../../components/JudgeInfo/JudgeRankBagde';
import { MyColumn } from '../../../components/Table/react-table-config';
import ToggleSwitch from '../../../components/Toggle/ToggleSwitch';
import { StylesContext } from '../providers/StylesProvider';
import { ParticipantRow } from './ParticipantsAdmin';

const StyleSelectMenu: React.FunctionComponent<ParticipantRow> = ({ uid, enteredIn }) => {
    const { styles } = React.useContext(StylesContext);
    const items = styles
        ? styles
              .filter((s) => s.enabled)
              .map((s) => ({
                  label: s.uid,
                  value: s.uid,
                  checked: enteredIn[s.uid],
              }))
        : [];

    return (
        <div className="grid w-40 md:w-80 grid-cols-2 md:grid-cols-4 h-40 overflow-y-scroll border overflow-x-hidden">
            {items.map((i) => (
                <label key={`${uid}-${i.value}`} className="flex space-x-1 p-2 items-center">
                    <input
                        type="checkbox"
                        data-participant-id={uid}
                        data-type="toggle-entered-in"
                        value={i.value}
                        defaultChecked={i.checked}
                    />
                    <span>{i.label}</span>
                </label>
            ))}
        </div>
    );
};

const SessionsSelectMenu: React.FunctionComponent<ParticipantRow> = ({
    uid,
    sessionsAvailable,
    sessions,
}) => {
    const items = sessions
        ? sessions.map((s) => ({
              label: `${s.title}`,
              value: s.uid,
              checked: sessionsAvailable[s.uid] || false,
          }))
        : [];

    return (
        <div className="w-full overflow-y-scroll border overflow-x-hidden">
            {items.map((i) => (
                <label key={i.value} className="flex space-x-1 p-2 items-center">
                    <input
                        type="checkbox"
                        data-participant-id={uid}
                        data-type="toggle-session-available"
                        value={i.value}
                        defaultChecked={i.checked}
                    />
                    <span>{i.label}</span>
                </label>
            ))}
        </div>
    );
};

export const columns: MyColumn<ParticipantRow>[] = [
    {
        Header: 'Name',
        accessor: (d) => d.display_name,
        sortType: (a, b) => {
            const titleA = a.original.display_name?.toLocaleLowerCase();
            const titleB = b.original.display_name?.toLocaleLowerCase();
            return titleA > titleB ? 1 : -1;
        },
        sticky: 'left',
    },
    {
        Header: 'Email',
        accessor: (d) => d.email,
        minWidth: 250,
    },
    {
        Header: 'BJCP Info',
        accessor: (d) =>
            d.judging ? (
                <div className="flex space-x-2">
                    <JudgeRankBagde judging={d.judging} />
                    <JudgeCertifications judging={d.judging} />
                </div>
            ) : null,
        minWidth: 200,
    },
    {
        Header: 'Steward',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-role toggle-is-steward"
                data-participant-id={d.uid}
                checked={d.isSteward}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
    {
        Header: 'Judge',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-role toggle-is-judge"
                data-participant-id={d.uid}
                checked={d.isJudge}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
    {
        Header: 'Admin',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-role toggle-is-admin"
                data-participant-id={d.uid}
                checked={d.isAdmin}
                disabled={d.isOwner}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
    {
        Header: 'Owner',
        accessor: (d) => (
            <ToggleSwitch
                disabled
                className="toggle-role toggle-is-owner"
                data-participant-id={d.uid}
                checked={d.isOwner}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
    {
        Header: 'Sessions Available',
        accessor: (d) => {
            return (
                <div className="w-full">
                    <Editable
                        className="py-1 flex w-full"
                        editorNode={<SessionsSelectMenu {...d} />}
                        staticNode={
                            <div className="pr-2 border h-full empty:text-gray-500 empty:after:content-['Edit_Available_Sessions']">
                                {d.sessions
                                    .filter((s) => d.sessionsAvailable[s.uid])
                                    .map((s) => (
                                        <span
                                            key={s.uid}
                                            className="badge badge-rounded badge-gray m-0.5"
                                        >
                                            {s.title}
                                        </span>
                                    ))}
                            </div>
                        }
                        closeOnBlur={false}
                        showCancel={false}
                    />
                </div>
            );
        },
        width: 400,
    },
    {
        Header: 'Entries In',
        accessor: (d) => {
            return (
                <>
                    <Editable
                        className="py-4"
                        editorNode={<StyleSelectMenu {...d} />}
                        staticNode={
                            <div className="mr-2 w-full h-full empty:text-gray-500 empty:after:content-['Edit_Entered_Categories']">
                                {Object.keys(d.enteredIn)
                                    .filter((k) => d.enteredIn[k])
                                    .map((k) => (
                                        <span
                                            key={k}
                                            className="badge badge-rounded badge-gray m-0.5"
                                        >
                                            {k}
                                        </span>
                                    ))}
                            </div>
                        }
                        closeOnBlur={false}
                        showCancel={false}
                    />
                </>
            );
        },
        width: 400,
    },
];
