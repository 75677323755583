import { ICompetitionRecord, IEntry, IScoreSheetData } from '@flight-cap/shared';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import EditIcon from '../../../components/Icon/EditIcon';
import { SheetSchemasContext } from '../../Competition/providers/SheetSchemasProvider';
import PrintableScoresheet from '../../Pdfs/scoresheet/PrintableScoresheet';
import AdminEditableScoresheet from './AdminEditableScoresheet';

const AdminScoresheet: React.FunctionComponent<
    RouteComponentProps<{ entry: IEntry; score: IScoreSheetData; competition: ICompetitionRecord }>
> = ({ entry, score, competition }) => {
    const [editMode, setEditMode] = React.useState<boolean>(false);

    const { getSchemaForEntry } = React.useContext(SheetSchemasContext);

    const handleModeButtonClick = () => {
        setEditMode(!editMode);
    };

    return entry && score ? (
        <div
            className={`border-dashed-gray relative mb-12 p-8 hover-show-child ${
                editMode ? 'bg-yellow-stripes' : ''
            }`}
            key={score.judge_snippet.uid}
        >
            <div className={`${editMode ? '' : 'hover-show-me'}`}>
                <button
                    className="btn-yellow btn-outline absolute flex items-center right-8"
                    onClick={handleModeButtonClick}
                >
                    {editMode ? (
                        <>Cancel</>
                    ) : (
                        <>
                            <EditIcon className="mr-2" />
                            Edit Scoresheet
                        </>
                    )}
                </button>
            </div>
            {editMode ? (
                <AdminEditableScoresheet entry={entry} score={score} className="mt-16" />
            ) : (
                <PrintableScoresheet
                    className="pointer-events-none cursor-not-allowed flight-admin-entry-detail__sheet"
                    entry={entry}
                    score={score}
                    schema={getSchemaForEntry(competition, entry)}
                    competition={competition}
                />
            )}
        </div>
    ) : (
        <>no score sheets</>
    );
};

export default AdminScoresheet;
