import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useFirebaseAuthError } from '../../hooks/useFirebaseAuthError';
import { firebaseAuth, AuthResponseCode } from '../../firebase';
import { RecoverPasswordContext } from '../../providers/RecoverPasswordProvider';
import { ApplicationRoutes } from '../../routes';
import Card from '../../components/Card/Card';
import { useForm } from 'react-hook-form';

interface IFormData {
    email: string;
}

const RecoverPassword: React.FunctionComponent<RouteComponentProps> = () => {
    const [AuthErrorDisplay, setAuthError] = useFirebaseAuthError();
    const recoverContext = React.useContext(RecoverPasswordContext);
    const setRecoverState = recoverContext[1];
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormData>();

    const onSubmit = async ({ email }: IFormData) => {
        setAuthError(null);
        try {
            await firebaseAuth.sendPasswordResetEmail(email);
            setRecoverState({
                email,
                isRecovering: true,
            });
            navigate(ApplicationRoutes.SignIn);
        } catch (error: any) {
            if (error.code === AuthResponseCode.UserNotFound) {
                setAuthError({ ...error, code: AuthResponseCode.PasswordResetWrongEmail });
            } else {
                setAuthError(error);
            }
        }
    };

    return (
        <section>
            <h2 className="mt-6 mb-4 text-center text-2xl font-extrabold text-gray-600">
                Recover your account
            </h2>
            <Card>
                <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                    <div className="mb-4">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            placeholder="Email"
                            aria-invalid={errors.email ? 'true' : 'false'}
                            {...register('email', { required: true })}
                        ></input>
                    </div>
                    <button className="btn-indigo" type="submit">
                        Send Reset Email
                    </button>
                </form>
                <AuthErrorDisplay />
            </Card>
        </section>
    );
};

export default RecoverPassword;
