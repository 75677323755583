import React from 'react';
import { IScoringGuideProps, IScoreSheetData } from '@flight-cap/shared';

const getRangeColors = (label: string): string => {
    if (label === 'Outstanding') {
        return 'border border-green-800 text-green-800';
    }

    if (label === 'Excellent') {
        return 'border border-green-800 text-green-800';
    }

    if (label === 'Very Good') {
        return 'border border-green-800 text-green-800';
    }

    if (label === 'Good') {
        return 'border border-yellow-600 text-yellow-600';
    }

    if (label === 'Fair') {
        return 'border border-red-600 text-red-600';
    }

    if (label === 'Problematic') {
        return 'border border-red-800 text-red-800';
    }

    return 'bg-grey-50';
};

const isScoreInRange = (score: number, min: number, max: number): boolean =>
    score >= min && score <= max;

const SheetScoringGuide: React.FunctionComponent<{
    scoringGuide: IScoringGuideProps;
    scoreSheetData?: IScoreSheetData;
}> = ({ scoringGuide, scoreSheetData }) => {
    const finalScore = scoreSheetData?.values.final_score;

    // overloading hasScore to determine renderming mode (hasScore is for printable sheets, otherwise judging)
    const hasScore = finalScore !== undefined;

    return (
        <div className={`grid grid-col-2 ${hasScore ? '' : 'grid-cols-2'}`}>
            {scoringGuide.ranges.map(({ min, max, label, description }) => (
                <div
                    key={label}
                    className={`flex items-center p-2 my-1 ${
                        hasScore
                            ? isScoreInRange(finalScore, min, max)
                                ? getRangeColors(label)
                                : 'bg-gray-100 opacity-50'
                            : `${getRangeColors(label)} mx-1`
                    }`}
                >
                    <span className="">
                        <strong>
                            {label} ({min}-{max}):
                        </strong>{' '}
                        {description}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default SheetScoringGuide;
