import React from 'react';
import { ITable, MetaStyleType } from '@flight-cap/shared';
import { MyColumn } from '../../../components/Table/react-table-config';
import { CellProps } from 'react-table';
import { Link } from '@reach/router';
import ChevronRightIcon from '../../../components/Icon/ChevronRightIcon';

export type StewardingTableRow = ITable;

export const stewardingTableColumns: MyColumn<StewardingTableRow>[] = [
    {
        Header: 'Name',
        accessor: 'title',
        sticky: 'left',
    },
    {
        Header: 'Styles',
        accessor: (d) => (
            <div>
                {Object.values(d.categories || [])
                    .sort((a, b) => (a.uid > b.uid ? 1 : -1))
                    .map((cat) => {
                        const badgeClass = (
                            {
                                beer: 'badge-yellow',
                                mead: 'badge-blue',
                                cider: 'badge-green',
                            } as Record<MetaStyleType, string>
                        )[cat.type];
                        return (
                            <span key={cat.uid} className={`${badgeClass} badge-rounded m-1`}>
                                {cat.category}
                                {cat.sub_category}: {cat.name}
                            </span>
                        );
                    })}
            </div>
        ),
        width: 300,
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<StewardingTableRow>) => (
            <>
                <Link
                    to={`./table/${original.uid}/pullsheets`}
                    className="btn-outline btn-gray flex items-center font-normal"
                >
                    Pull Sheets
                    <ChevronRightIcon />
                </Link>
            </>
        ),
        disableSortBy: true,
        width: 200,
        minWidth: 200,
        maxWidth: 300,
        sticky: 'right',
        className: 'unstick-large',
    },
];
