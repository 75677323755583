import React from 'react';

const JudgeCoverage: React.FunctionComponent<{
    coverage: number;
    error: boolean;
}> = ({ coverage, error }) => {
    let percent = coverage;

    let barColor = '#f4f4f5';
    let bgColor = '#f4f4f5';

    if (error) {
        barColor = '#ef4444';
        bgColor = '#fee2e2';
        percent = 1;
    } else if (percent >= 0.8) {
        barColor = '#15803d';
        bgColor = '#f0fdf4';
    } else if (percent >= 0.4) {
        barColor = '#f4cc15';
        bgColor = '#fefce8';
    } else if (percent > 0) {
        barColor = '#ef4444';
        bgColor = '#fee2e2';
    }

    return (
        <div className="w-2 relative">
            <svg className="absolute w-full h-full">
                <rect width="100%" fill={bgColor} height="100%" />
                <rect
                    width="100%"
                    fill={barColor}
                    height="100%"
                    style={{
                        transformOrigin: '50% 100%',
                        transform: `scale(1, ${percent * 100}%)`,
                        transition:
                            'transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1), fill 0.5s ease-out',
                    }}
                />
            </svg>
        </div>
    );
};

export default JudgeCoverage;
