import { IUserProfile, validateBJCPID, validateEmail } from '@flight-cap/shared';
import { firestore } from '../firebase';

type SearchableFields = 'display_name' | 'email' | 'judging.bjcp_id';

export interface IUserSearchSnippet {
    uid: string;
    display_name: string;
    email: string;
    bjcp_id: string;
    bjcp_rank: string;
}

class UsersService {
    async findUsers(
        searchTerm: string,
        fields: SearchableFields[] = ['display_name', 'email', 'judging.bjcp_id']
    ): Promise<IUserSearchSnippet[]> {
        const results: Record<string, IUserSearchSnippet> = {};

        for (const field of fields) {
            if (field === 'email' && !validateEmail(searchTerm)) {
                continue;
            }

            if (field === 'judging.bjcp_id' && !validateBJCPID(searchTerm)) {
                continue;
            }

            const docs = await firestore.collection('users').where(field, '==', searchTerm).get();

            console.log('query', field, '==', searchTerm);

            if (docs.size) {
                docs.forEach((doc) => {
                    const data = doc.data() as IUserProfile;
                    const snippet: IUserSearchSnippet = {
                        uid: doc.id,
                        display_name: data.display_name,
                        email: data.email,
                        bjcp_id: data.judging?.bjcp_id,
                        bjcp_rank: data.judging?.bjcp_rank,
                    };
                    results[doc.id] = snippet;
                });
            }
        }

        return Object.values(results) as IUserSearchSnippet[];
    }
}

export default UsersService;
