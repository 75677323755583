/**
 * This component is for the competition specific information for a participant. Right now,
 * it's only for judges to see some general info and the session information.
 */

import React from 'react';
import StyleBadge from '../../../components/StyleBadge/StyleBadge';
import { CompetitionParticipantContext } from '../../../providers/CompetitionParticipantProvider';

const CategoriesEntered: React.FunctionComponent = () => {
    const { categories_entered } = React.useContext(CompetitionParticipantContext);

    if (!categories_entered?.length) {
        return null;
    }

    return (
        <>
            <h3 className="font-semibold my-4">Your entered categories</h3>
            <p>
                The categories in which you have enteries. These are the categories you are
                inelligable to judge.
            </p>
            <div className="my-4 grid grid-cols-1 md:grid-cols-3">
                {categories_entered.map((c) => (
                    <div key={c.uid}>
                        <StyleBadge style={c} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default CategoriesEntered;
