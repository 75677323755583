import { IEntry, IFlight } from '@flight-cap/shared';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CellProps } from 'react-table';
import Confirm, { ConfirmTheme } from '../../../components/Confirm/Confirm';
import DisqualifiedBadge from '../../../components/EntryDetails/DisqualifiedBadge';
import FormNumberInput from '../../../components/Form/FormNumberInput';
import MinusCircleIcon from '../../../components/Icon/MinusCircleIcon';
import { MyColumn } from '../../../components/Table/react-table-config';
import Table from '../../../components/Table/Table';
import { firestore } from '../../../firebase';
import useActiveMatch from '../../../hooks/useActiveMatch';
import { useEntryAllergens } from '../../../hooks/useEntryAllergens';
import { useFlightSummaryData } from '../../../hooks/useFlightSummaryData';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { TableScoringFlightEntriesContext } from '../providers/FlightEntries/TableScoringFlightEntriesProvider';

const ConsensusScoreRenderer: React.FunctionComponent<CellProps<IEntry>> = (props) => {
    const { minAllowedScore, maxAllowedScore } = useFlightSummaryData(props.row.original);
    const {
        setValue,
        formState: { errors },
    } = useFormContext();

    React.useEffect(() => {
        setValue('consensus_score', props.row.original.consensus_score);
    }, [setValue, props.row.original.consensus_score]);

    return (
        <FormNumberInput
            className="p-1"
            name="consensus_score"
            min={minAllowedScore}
            max={maxAllowedScore}
            aria-invalid={errors.consensus_score}
            required={true}
        />
    );
};

const MiniBosRenderer: React.FunctionComponent<CellProps<IEntry>> = (props) => {
    const { register, setValue, formState } = useFormContext();
    const { isValid, isDirty } = formState;

    React.useEffect(() => {
        setValue('mini_bos', props.row.original.mini_bos);
    }, [setValue, props.row.original.mini_bos]);

    const disqualified = props.row.original.disqualified;
    return (
        <>
            {disqualified ? (
                <input
                    type="checkbox"
                    className="p-2 opacity-40 cursor-not-allowed"
                    {...register('mini_bos')}
                    disabled
                    checked={false}
                    title="Entry is ineligible to go to Mini-BOS"
                />
            ) : (
                <input type="checkbox" className="p-2" {...register('mini_bos')} />
            )}

            {isValid && isDirty ? (
                <>
                    <input type="hidden" value={props.row.original.uid} {...register('uid')} />
                    <button type="submit" className="btn-indigo btn-outline btn-condensed ml-4">
                        Save
                    </button>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const SaveRenderer: React.FunctionComponent<CellProps<IEntry>> = (props) => {
    const { register, setValue, formState } = useFormContext();
    const { isValid, isDirty } = formState;

    React.useEffect(() => {
        setValue('mini_bos', props.row.original.mini_bos);
    }, [setValue, props.row.original.mini_bos]);

    return (
        <>
            {isValid && isDirty ? (
                <>
                    <input type="hidden" value={props.row.original.uid} {...register('uid')} />
                    <button type="submit" className="btn-indigo btn-outline btn-condensed ml-4">
                        Save
                    </button>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const Div: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = ({ children, ...props }) => (
    <div {...props}>{children}</div>
);

const EntryRenderer: React.FunctionComponent<CellProps<IEntry>> = (props) => {
    const entry = props.row.original;
    const { myScore, isValid } = useFlightSummaryData(props.row.original);
    const ActiveLink = useActiveMatch(Div);
    const { AllergensBadge } = useEntryAllergens(entry);

    // this should go back to using a Link (not Div) once moved over to React-Router with Prompt in place
    return (
        <ActiveLink
            role="button"
            as="button"
            tabIndex={0}
            path={`./entry/${entry.uid}/*`}
            className="flex flex-col w-full font-normal flight-summary__entry-cell cursor-pointer"
            data-entry-id={entry.uid}
        >
            {/* pointer-event-none is allow triggering of click in the FlightDetail component */}
            <span className="pointer-events-none">
                <div className="flex space-x-2 items-center">
                    <p className="text-black">{entry.judging_number}</p>
                    <DisqualifiedBadge entry={entry} condensed />
                </div>
                <p className="text-xs text-gray-500">
                    {entry.style_snippet.category}
                    {entry.style_snippet.sub_category}: {entry.style_snippet.name}
                </p>
                {isValid ? (
                    <p className="w-min mt-1 badge-green badge-sm badge-rounded badge-condensed mb-1">
                        My Score: {myScore}
                    </p>
                ) : (
                    <p className="w-min mt-1 badge-yellow badge-sm badge-rounded badge-condensed mb-1">
                        In Progress
                    </p>
                )}
                <AllergensBadge />
            </span>
        </ActiveLink>
    );
};

const MenuButtonRenderer: React.FunctionComponent<CellProps<IEntry>> = (props) => {
    return (
        <button
            type="button"
            className="remove-from-flight-btn text-gray-300 hover:text-red-700"
            data-entry-id={props.row.original.uid}
        >
            <MinusCircleIcon className="w-5 h-5 pointer-events-none" />
        </button>
    );
};

const awardEligibleColumns: MyColumn<IEntry>[] = [
    {
        Header: 'Entry',
        Cell: EntryRenderer,
    },
    {
        Header: 'Consensus',
        Cell: ConsensusScoreRenderer,
        width: 80,
    },
    {
        Header: 'Mini-BOS',
        Cell: MiniBosRenderer,
        width: 80,
    },
    {
        Header: ' ',
        Cell: MenuButtonRenderer,
        maxWidth: 32,
    },
];

const awardIneligibleColumns: MyColumn<IEntry>[] = [
    {
        Header: 'Entry',
        Cell: EntryRenderer,
    },
    {
        Header: 'Consensus',
        Cell: ConsensusScoreRenderer,
        width: 80,
    },
    {
        Header: '  ',
        Cell: SaveRenderer,
        maxWidth: 64,
    },
    {
        Header: ' ',
        Cell: MenuButtonRenderer,
        maxWidth: 32,
    },
];

interface IFormData {
    consensus_score: number;
    mini_bos: boolean;
    uid: string;
}

const FormRow: React.FunctionComponent<React.HTMLProps<HTMLFormElement>> = ({
    children,
    ...rest
}) => {
    const methods = useForm<IFormData>({ mode: 'onChange' });
    const { competition } = React.useContext(CompetitionContext);
    // const ActiveFormProvider = useActiveMatch(FormProvider);

    const onSubmit = async (data: IFormData) => {
        const { uid, ...rest } = data;
        try {
            // precise enough, just updating the mini_bos and consensus_score
            await firestore.doc(`competitions/${competition.uid}/entries/${uid}`).update(rest);
            methods.reset(rest);
        } catch (err) {
            console.log('error updating summary info', err);
        }
    };

    return (
        <FormProvider {...methods}>
            <form {...rest} onSubmit={methods.handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
};

const FlightSummary: React.FunctionComponent<{
    flight: IFlight;
    onEntryRemoved: VoidFunction;
    onAddEntryClicked: VoidFunction;
    onClick: React.MouseEventHandler;
}> = ({ onEntryRemoved, onAddEntryClicked, onClick }) => {
    const { flightEntries, removeEntryFromFlight, flight } = React.useContext(
        TableScoringFlightEntriesContext
    );
    const entries = flightEntries.inFlight || [];

    const [entryForRemoval, setEntryForRemoval] = React.useState<IEntry>(null);

    const [isValidConfirm, setIsValidConfirm] = React.useState<boolean>(false);

    const addToFlightButton = (
        <button className="btn-indigo btn-outline w-full" onClick={onAddEntryClicked}>
            Add entries to flight
        </button>
    );

    const handleTableClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        if (evt.target instanceof HTMLButtonElement) {
            if (evt.target.classList.contains('remove-from-flight-btn')) {
                const entryId = evt.target.getAttribute('data-entry-id');
                setEntryForRemoval(entries.find((entry) => entry.uid === entryId));
                return;
            }
        }

        onClick(evt);
    };

    const handleDeleteConfirm = async () => {
        await removeEntryFromFlight(entryForRemoval.uid);
        setEntryForRemoval(null);
        onEntryRemoved();
    };

    const handleDeleteCancel = () => {
        setEntryForRemoval(null);
    };

    const handleEntryInputChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const isValid =
            entryForRemoval && evt.target.value === String(entryForRemoval.judging_number);
        if (isValid !== isValidConfirm) {
            setIsValidConfirm(isValid);
        }
    };

    const awardsAllowed = flight.table_snippet?.allow_awards !== false;

    return (
        <>
            <h1 className="text-2xl text-gray-900 text-center mb-4">
                <div className="text-sm text-gray-500 block">{flight.table_snippet?.title}</div>
                <div>{flight.name()}</div>
            </h1>
            {entries.length > 0 ? (
                <div className="mb-4">
                    <div onClick={handleTableClick}>
                        <Table
                            columns={awardsAllowed ? awardEligibleColumns : awardIneligibleColumns}
                            data={entries}
                            Row={FormRow}
                            className="condensed-table"
                        />
                    </div>
                    <p className="text-sm text-gray-400 my-4">
                        Click on an entry's juding number to access its scoresheet.
                    </p>

                    <Confirm
                        theme={ConfirmTheme.Danger}
                        title={`Remove entry from flight`}
                        onConfirm={handleDeleteConfirm}
                        onCancel={handleDeleteCancel}
                        show={entryForRemoval ? true : false}
                        disableConfirm={!isValidConfirm}
                    >
                        <div className="text-sm text-gray-500">
                            <p className="mb-4">
                                Are you sure you want to remove entry{' '}
                                <span className="text-red-700">
                                    #{entryForRemoval?.judging_number}
                                </span>{' '}
                                from this flight?
                            </p>
                            <p className="mb-4">
                                Doing this will reset its consensus score and mini-bos status.
                            </p>
                            <p className="mb-4">
                                If so, please enter the entry id in the box below and click
                                'Confirm'.
                            </p>
                            <div className="">
                                <div className="w-40">
                                    <input type="text" onChange={handleEntryInputChanged}></input>
                                </div>
                            </div>
                        </div>
                    </Confirm>
                </div>
            ) : (
                <div className="container-dashed-gray mr-4 my-4">
                    <p>There aren't any entries in your flight. </p>
                </div>
            )}
            {addToFlightButton}
        </>
    );
};

export default FlightSummary;
