import React from 'react';
import { IParticipantSnippet } from '@flight-cap/shared';

const JudgeRankBagde: React.FunctionComponent<{
    judging: IParticipantSnippet['judging'];
    asDots?: boolean;
}> = ({ judging }) => {
    return (
        <span className="ml-1 badge bg-white badge-rounded badge-outline !text-xs">
            {judging?.bjcp_rank || 'Non-BJCP'}
        </span>
    );
};

export default JudgeRankBagde;
