import React from 'react';
import { timeSince } from '@flight-cap/shared';

const LastSavedIndicator: React.FunctionComponent<{ savedAt: Date }> = ({ savedAt }) => {
    const [since, setTimeSince] = React.useState<string>(timeSince(savedAt));
    const last = React.useRef<string>(since);

    React.useEffect(() => {
        function setSince() {
            const sinceString = timeSince(savedAt);
            if (last.current !== sinceString) {
                setTimeSince(sinceString);
                last.current = sinceString;
            }
        }

        setSince();
        const intervalId = setInterval(() => {
            setSince();
        }, 1000);

        return () => clearInterval(intervalId);
    }, [setTimeSince, savedAt, last]);

    const validDate = isFinite(savedAt.getTime());

    return (
        <p className="text-gray-500 text-sm p-2">
            {validDate ? `Saved ${since} ${since === 'just now' ? '' : 'ago'}` : 'Not yet saved'}
        </p>
    );
};

export default LastSavedIndicator;
