import { IJudgingLoad, IParticipantSnippet } from '@flight-cap/shared';
import React from 'react';
import BoltIcon from '../../../../components/Icon/BoltIcon';
import DocumentTextIcon from '../../../../components/Icon/DocumentTextIcon';
import PaperAirplaneIcon from '../../../../components/Icon/PaperAirplaneIcon';
import TrophyIcon from '../../../../components/Icon/TrophyIcon';
import UserIcon from '../../../../components/Icon/UserIcon';
import IconValue from '../IconValue';

const SessionLoad: React.FunctionComponent<{
    load: IJudgingLoad;
    judges: IParticipantSnippet[];
}> = ({ load, judges }) => {
    return (
        <div className="flex space-x-1 sm:space-x-3 items-center !text-xs">
            {/* {load.scoring > 0 && (
                <span className="flex">
                    <span className="border rounded-l-full px-1.5">
                        <IconValue
                            value={load.scoring}
                            itemName={load.scoring === 1 ? 'scoring flight' : 'scoring flights'}
                            icon={<PaperAirplaneIcon />}
                        />
                    </span>
                    <span className="border rounded-r-full border-indigo-600 bg-indigo-100 px-1.5 -ml-px ">
                        <IconValue
                            value={load.scoring_active}
                            itemName={
                                load.scoring === 1
                                    ? 'scoring flight active'
                                    : 'scoring flights active'
                            }
                            icon={<BoltIcon className="fill-white text-indigo-600" />}
                        />
                    </span>
                </span>
            )} */}
            {load.scoring > 0 && (
                <div className="flex rounded-full border pl-1 items-center">
                    <IconValue
                        value={load.scoring}
                        itemName={load.scoring === 1 ? 'scoring flight' : 'scoring flights'}
                        icon={<PaperAirplaneIcon />}
                    />
                    <div
                        className={`flex rounded-full border px-2 ml-2 transition-colors ${
                            load.scoring_active > 0
                                ? 'border-indigo-600 text-indigo-600 bg-indigo-100'
                                : ''
                        }`}
                    >
                        <IconValue
                            value={load.scoring_active}
                            itemName={
                                load.scoring === 1
                                    ? 'scoring flight active'
                                    : 'scoring flights active'
                            }
                            icon={<BoltIcon />}
                        />
                    </div>
                </div>
            )}
            {load.approximate_scoring_entries > 0 && (
                <IconValue
                    value={load.approximate_scoring_entries}
                    itemName={load.approximate_scoring_entries === 1 ? 'entry' : 'entries'}
                    icon={<DocumentTextIcon />}
                />
            )}
            {load.comparative > 0 && (
                <div className="flex rounded-full border pl-1 items-center">
                    <IconValue
                        value={load.comparative}
                        itemName={
                            load.comparative === 1 ? 'comparative flight' : 'comparative flights'
                        }
                        icon={<TrophyIcon />}
                    />
                    <div
                        className={`flex rounded-full border px-2 ml-2 transition-colors ${
                            load.comparative_active > 0
                                ? 'border-indigo-600 text-indigo-600 bg-indigo-100'
                                : ''
                        }`}
                    >
                        <IconValue
                            value={load.comparative_active}
                            itemName={
                                load.scoring === 1
                                    ? 'comparative flight active'
                                    : 'comparative flights active'
                            }
                            icon={<BoltIcon />}
                        />
                    </div>
                </div>
            )}

            {judges.length > 0 && (
                <IconValue
                    value={judges.length}
                    itemName={judges.length === 1 ? 'judge' : 'judges'}
                    icon={<UserIcon />}
                />
            )}
        </div>
    );
};

export default SessionLoad;
