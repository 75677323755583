import React from 'react';
import { IJudgeSnippet } from '@flight-cap/shared';

export const OtherJudges: React.FunctionComponent<{ otherJudges: IJudgeSnippet[] }> = ({
    otherJudges,
}) => {
    return (
        <div className="text-sm text-gray-500">
            {otherJudges.length > 0 ? (
                <span className="text-xs hidden sm:block md:inline md:ml-2">
                    Judging with:{' '}
                    {otherJudges.map((j, index) => (
                        <span key={j.uid}>
                            {j.display_name}
                            <span>{j.judging?.bjcp_rank ? ` (${j.judging?.bjcp_rank})` : ''}</span>
                            <span>{index < otherJudges.length - 1 ? ', ' : ''}</span>
                        </span>
                    ))}
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};
