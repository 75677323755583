import React from 'react';

const FlightDetailBoundaryContent: React.FunctionComponent = () => {
    return (
        <div className="flex justify-center mt-8 max-w-md">
            <div className="p-8 text-red-800 border border-red-800 border-dashed ">
                Whoops, we encountered an error! Don't worry, we auto-save your changes so your
                input won't be lost. Please refresh the page to continue!
            </div>
        </div>
    );
};

export default FlightDetailBoundaryContent;
