import React from 'react';
import AllergensBadge from '../../../components/EntryDetails/AllergensBadge';
import EntryDetails from '../../../components/EntryDetails/EntryDetails';
import { TableRow } from './TablesAdmin';

const TablePullSheetPrintLayout: React.FunctionComponent<{ table: TableRow }> = ({ table }) => {
    return table ? (
        <div className="hidden print:block">
            <h1 className="text-xl my-8">Table: {table.title} - Pull Sheet</h1>
            <ul>
                {table.entries
                    .sort((a, b) => (a.judging_number > b.judging_number ? 1 : -1))
                    .map((entry) => (
                        <li key={entry.judging_number} className="page-break border p-2 mb-2">
                            <div className="grid grid-cols-12 gap-8">
                                <div className="col-span-2 flex items-center">
                                    {entry.judging_number}
                                </div>
                                <div className="col-span-2 flex items-center">
                                    {entry.style_snippet.category}
                                    {entry.style_snippet.sub_category}: {entry.style_snippet.name}
                                </div>
                                <div className="col-span-6 flex items-center">
                                    <EntryDetails entry={entry} condensed />
                                </div>
                                <div className="col-span-2 flex items-center">
                                    <AllergensBadge entry={entry} />
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    ) : (
        <></>
    );
};

export default TablePullSheetPrintLayout;
