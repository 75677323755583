import React from 'react';
import { firebaseAuth, getUserProfileDocument } from '../firebase';
import { IUserProfile } from '@flight-cap/shared';

type IUserContext = { user: IUserProfile; resolved: boolean };

export const UserContext: React.Context<IUserContext> = React.createContext<IUserContext>({
    user: null,
    resolved: false,
});

const UserProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = React.useState<IUserProfile>(null);
    const [resolved, setResolved] = React.useState(false);

    React.useEffect(() => {
        return firebaseAuth.onAuthStateChanged(async (userAuth: firebase.default.User) => {
            if (userAuth) {
                const userRef = getUserProfileDocument(userAuth.uid);

                return userRef.onSnapshot((snapshot) => {
                    console.log('User Provider - onSnapshot');
                    const profile = { uid: snapshot.id, ...(snapshot.data() as IUserProfile) };
                    setUser(profile);
                    setResolved(true);
                });
            } else {
                setUser(null);
                setResolved(true);
            }
        });
    }, []);

    return <UserContext.Provider value={{ user, resolved }}>{children}</UserContext.Provider>;
};

export default UserProvider;
