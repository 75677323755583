import React from 'react';
import { RouteComponentProps, Redirect, useMatch } from '@reach/router';
import { UserContext } from '../../providers/UserProvider';
import { ApplicationRoutes } from '../../routes';
import { parameterizeEndpoint } from '@flight-cap/shared';
import { CompetitionContext } from '../../providers/CompetitionProvider';

const CompetitionStewardingRoute: React.FunctionComponent<
    {
        as:
            | React.ComponentClass<{ children?: React.ReactNode }>
            | React.FunctionComponent<{ children?: React.ReactNode }>;
    } & RouteComponentProps & { children?: React.ReactNode }
> = ({ as: Comp, ...rest }) => {
    const { user } = React.useContext(UserContext);
    const { competition } = React.useContext(CompetitionContext);

    const match = useMatch(`${ApplicationRoutes.Competition}/*`);
    const competitionSlug = match?.competitionSlug;

    const competitionRoles = competition && user && user.competitions?.[competition.uid]?.roles;
    const isSteward = competitionRoles?.includes('steward');

    console.log('stewarding route!', !!competitionRoles, isSteward);

    return isSteward ? (
        <Comp {...rest} />
    ) : (
        <Redirect
            to={parameterizeEndpoint(
                !!competitionRoles
                    ? ApplicationRoutes.CompetitionInfo
                    : ApplicationRoutes.JoinCompetition,
                { competitionSlug }
            )}
            noThrow
        />
    );
};

export default CompetitionStewardingRoute;
