import { IEntry, IScoreSheetData } from '@flight-cap/shared';
import { Link, RouteComponentProps } from '@reach/router';
import React from 'react';
import { CellProps } from 'react-table';
import ChevronRightIcon from '../../../components/Icon/ChevronRightIcon';
import SheetNeedsAttention from '../../../components/SheetNeedsAttention/SheetNeedsAttention';
import { MyColumn } from '../../../components/Table/react-table-config';
import Table from '../../../components/Table/Table';
import { EntriesContext } from '../providers/EntriesProvider';
import { FlightsContext } from '../providers/FlightsProvider';
import { ScoresheetsContext } from '../providers/ScoresheetsProvider';

type IFlightSummaryRow = IEntry & {
    scoresheets: IScoreSheetData[];
};

export const flightSummaryColumns: MyColumn<IFlightSummaryRow>[] = [
    {
        Header: 'Judging Number',
        accessor: 'judging_number',
        sticky: 'left',
    },
    {
        Header: 'Score: Jugde',
        width: 400,
        accessor: (d) => (
            <div>
                {Object.values(d.scoresheets || {}).map((score) => (
                    <React.Fragment key={`${d.uid}-${score.judge_snippet.uid}`}>
                        {score?.values?.final_score && (
                            <div className="flex items-center py-1">
                                <p>
                                    {score.values.final_score}: {score.judge_snippet.display_name}
                                </p>
                                {score.needs_attention ? (
                                    <SheetNeedsAttention
                                        reasons={score.needs_attention}
                                        condensed={false}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        ),
    },
    {
        Header: 'Score',
        accessor: 'consensus_score',
    },
    {
        Header: 'Mini Bos',
        sortType: (a, b) => (a.original.mini_bos > b.original.mini_bos ? 1 : -1),
        accessor: (d) =>
            d.mini_bos ? (
                <span className="badge-green badge-rounded">Yes</span>
            ) : (
                <span className="badge-red badge-rounded">No</span>
            ),
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<IFlightSummaryRow>) => (
            <Link to={`./entries/${original.uid}`} className="btn-outline btn-gray">
                <ChevronRightIcon />
            </Link>
        ),
        disableSortBy: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        sticky: 'right',
        className: 'unstick-large',
    },
];

const FlightAdminDetail: React.FunctionComponent<RouteComponentProps<{ flightId: string }>> = ({
    flightId,
}) => {
    const { flights } = React.useContext(FlightsContext);
    const { entries } = React.useContext(EntriesContext);
    const { scoresheets } = React.useContext(ScoresheetsContext);

    const flight = flights?.find((f) => f.uid === flightId);

    const rows: IFlightSummaryRow[] = React.useMemo(
        () =>
            (entries?.filter((e) => e.flight_snippet?.uid === flightId) || [])
                .sort((a, b) =>
                    a.flight_snippet.claimed_at > b.flight_snippet.claimed_at ? 1 : -1
                )
                .map((entry) => ({
                    ...entry,
                    scoresheets: scoresheets
                        .filter((s) => s.entry_id === entry.uid)
                        .map((sheet) => ({
                            ...sheet,
                        })),
                })),
        [entries, flightId, scoresheets]
    );

    return (
        <>
            <h1 className="text-2xl my-8">{flight?.name()}</h1>
            <h3 className="text-xl mb-4 text-gray-500">Flight Summary</h3>
            <Table columns={flightSummaryColumns} data={rows}></Table>
        </>
    );
};

export default FlightAdminDetail;
