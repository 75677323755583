import { ISession } from '@flight-cap/shared';
import { Menu } from '@headlessui/react';
import React from 'react';
import Confirm, { ConfirmTheme } from '../../../../components/Confirm/Confirm';
import VerticalDotsIcon from '../../../../components/Icon/VerticalDotsIcon';
import SelectMenu from '../../../../components/SelectMenu/SelectMenu';
import { SessionsContext } from '../../../../providers/SessionsProvider';
import { FlightsContext } from '../../providers/FlightsProvider';

const SessionMeatballMenu: React.FunctionComponent<{ session: ISession }> = ({ session }) => {
    const menuButton = (
        <Menu.Button>
            <VerticalDotsIcon />
        </Menu.Button>
    );

    const { flights, updateFlight } = React.useContext(FlightsContext);
    const { deleteSession } = React.useContext(SessionsContext);

    const flightsInSession = flights.filter((f) => f.session_id === session.uid);
    const activeFlights = flightsInSession.filter((f) => f.active);
    const inactiveFlights = flightsInSession.filter((f) => !f.active);
    const allActive = flightsInSession.length && activeFlights.length === flightsInSession.length;

    const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

    const handleToggleActive = (active: boolean) => () => {
        if (active) {
            inactiveFlights.forEach((f) => {
                updateFlight(f.uid, { active });
            });
            return;
        }

        activeFlights.forEach((f) => {
            updateFlight(f.uid, { active });
        });
    };

    const handleSessionDeleteClicked = () => {
        setShowDeleteConfirm(true);
    };

    const handleConfirmDelete = async () => {
        await deleteSession(session.uid);
        setShowDeleteConfirm(false);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirm(false);
    };

    const activateFlights = !allActive && flightsInSession.length > 0 && (
        <Menu.Item>
            <li>
                <button
                    className="menu-item cursor-pointer primary w-full text-left"
                    onClick={handleToggleActive(true)}
                >
                    Activate all flights
                </button>
            </li>
        </Menu.Item>
    );

    const deactivateFlights = activeFlights.length > 0 && (
        <Menu.Item>
            <li>
                <button
                    className="menu-item cursor-pointer primary w-full text-left"
                    onClick={handleToggleActive(false)}
                >
                    Dectivate all flights
                </button>
            </li>
        </Menu.Item>
    );

    return (
        <>
            <SelectMenu button={menuButton} className="z-10">
                <ul>
                    {activateFlights}
                    {deactivateFlights}
                    <hr />
                    <Menu.Item>
                        <li>
                            <button
                                className="menu-item cursor-pointer primary w-full text-left danger"
                                onClick={handleSessionDeleteClicked}
                            >
                                Delete Session
                            </button>
                        </li>
                    </Menu.Item>
                </ul>
            </SelectMenu>
            <Confirm
                theme={ConfirmTheme.Danger}
                title={`Are you sure want to delete ${session?.name()}`}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
                show={showDeleteConfirm}
                confirmLabel="Delete Session"
            >
                <div className="text-sm text-gray-500">
                    <p className="my-2">
                        Deleting the session will unassign the flights from the session, but will
                        not affect the their active status or judging assignments.
                    </p>
                    <p className="my-2">
                        Any participants who have registered for this session will no longer be able
                        to see this session.
                    </p>
                </div>
            </Confirm>
        </>
    );
};

export default SessionMeatballMenu;
