import { IPdfScoresheetWindow } from '@flight-cap/shared';
import { RouteComponentProps, useLocation } from '@reach/router';
import React from 'react';
import { SheetSchemasContext } from '../../Competition/providers/SheetSchemasProvider';
import PrintableFooter from './PrintableFooter';
import PrintableScoresheet from './PrintableScoresheet';

const win = window as unknown as IPdfScoresheetWindow;

// win.data = {
//     entry: {
//         disqualified: true,
//         disqualified_reason: 'The entry arrived after judging began',
//         table_place: 1,
//         entrant_snippet: {
//             bcoem_id: null,
//         },
//         notes: '',
//         entry_number: '512',
//         optional_info: null,
//         strength: 'Standard',
//         table_snippet: {
//             title: '21 - Specialty Mead',
//             uid: '3O9YRSw4phQvXj04uKKy',
//             entry_count: 11,
//             allow_awards: true,
//             categories: {},
//             category_ids: [],
//             scoring_flight_count: 1,
//         },
//         mini_bos: false,
//         sweetness: 'Medium Sweet',
//         recieved: false,
//         bcoem_id: 512,
//         sheet_schema_id: null,
//         allergens: [],
//         carbonation: 'Petillant',
//         consensus_score: 42,
//         specific_info: null,
//         style_snippet: {
//             sub_category: 'C',
//             type: 'mead',
//             uid: 'M4C',
//             name: 'Experimental Mead',
//             category: 'M4',
//         },
//         paid: false,
//         judging_number: '179',
//         required_info: 'smoked peaches, wildflower',
//         flight_snippet: {
//             title: '21 - Specialty Mead [A]',
//             uid: 'sJXgRd0yVrecVN0BwHa0',
//             claimed_at: {
//                 _seconds: 1644810028,
//                 _nanoseconds: 897000000,
//             },
//             judges: {
//                 yjrsrkk3VSWFI8dEKlV23gd2oE2i: {
//                     display_name: 'Grass Olive',
//                     uid: 'yjrsrkk3VSWFI8dEKlV23gd2oE2i',
//                     judging: null,
//                     email: 'grass.olive.105@example.com',
//                 },
//                 uTVy6TaranvsSenv8dwmS27avnXh: {
//                     display_name: 'Mountain Otter',
//                     uid: 'uTVy6TaranvsSenv8dwmS27avnXh',
//                     judging: {
//                         other_status: {
//                             cider_judge: true,
//                             mead_judge: true,
//                             professional_brewer: false,
//                         } as any,
//                         bjcp_id: 'A10102',
//                         bjcp_rank: 'Certified',
//                     },
//                     email: 'mountain.otter.266@example.com',
//                 },
//                 WQnHV8QYg70PPUvYn1b0G0ZB2GEA: {
//                     display_name: 'Otter Olive',
//                     uid: 'WQnHV8QYg70PPUvYn1b0G0ZB2GEA',
//                     judging: {
//                         other_status: {
//                             cider_judge: true,
//                             mead_judge: true,
//                             professional_brewer: false,
//                         } as any,
//                         bjcp_id: 'A10101',
//                         bjcp_rank: 'National',
//                     },
//                     email: 'otter.olive.539@example.com',
//                 },
//             },
//         },
//         score_snippets: {
//             WQnHV8QYg70PPUvYn1b0G0ZB2GEA: {
//                 score: 12,
//                 judge_snippet: {
//                     display_name: 'Otter Olive',
//                     uid: 'WQnHV8QYg70PPUvYn1b0G0ZB2GEA',
//                     judging: {
//                         other_status: {
//                             cider_judge: true,
//                             mead_judge: true,
//                             professional_brewer: false,
//                         } as any,
//                         bjcp_id: 'A10101',
//                         bjcp_rank: 'National',
//                     },
//                     email: 'otter.olive.539@example.com',
//                 },
//                 is_valid: true,
//             },
//         },
//     },
//     competition: {
//         created_at: new Date(),
//         style_classification: 'bjcp_2021',
//         title: 'Midwinter 2022',
//         created_by: 'WQnHV8QYg70PPUvYn1b0G0ZB2GEA',
//         slug: 'midwinter-2022',
//         default_sheet_schema: 'midwinter_2022',
//         logo_url:
//             'https://firebasestorage.googleapis.com/v0/b/comp-companion-99bfc.appspot.com/o/oCKEuYMqgnhzeecgyOsf%2Fcompetition-logo.svg?alt=media&token=c23dcdb6-179f-4ff1-85de-346b42bcd7a0',
//     },
//     scores: [
//         {
//             uid: 'XQMYsJnzdzRrRZsRrnBx',
//             judge_snippet: {
//                 uid: 'WQnHV8QYg70PPUvYn1b0G0ZB2GEA',
//                 judging: {
//                     bjcp_rank: 'National',
//                     other_status: {
//                         cider_judge: true,
//                         mead_judge: true,
//                         professional_brewer: false,
//                     } as any,
//                     bjcp_id: 'A10101',
//                 },
//                 display_name: 'Otter Olive',
//                 email: 'otter.olive.539@example.com',
//             },
//             competition_id: 'ykyZM0Xb2HeZsl5xTCP2',
//             entry_id: 'uviFoAerdO8DH8pnOrrg',
//             is_valid: true,
//             values: {
//                 aroma_flavor_notes: '',
//                 balance_drink_notes: '',
//                 appearance_notes: 'Heya these are some notes',
//                 final_score: 42,
//                 balance_slider: 12,
//                 appearance_slider: 10,
//                 finish_slider: 13,
//                 style_slider: 12,
//                 technical_slider: 14,
//                 aroma_slider: 8,
//                 flavor_slider: 13,
//                 overall_assessment: 'Displayasdasdasd asd a sda d asd ads asd',
//             },
//             auto_saved_at: {
//                 _seconds: 1644810903,
//                 _nanoseconds: 594000000,
//             },
//             saved_at: {
//                 _seconds: 1644811184,
//                 _nanoseconds: 704000000,
//             },
//             descriptors: {
//                 alcoholic: true,
//             },
//         },
//         {
//             uid: 'XQMYsJnzdzRrRZsRrnBx',
//             judge_snippet: {
//                 uid: 'WQnHV8QYg70PPUvYn1b0G0ZB2GEA',
//                 judging: {
//                     bjcp_rank: 'National',
//                     other_status: {
//                         cider_judge: true,
//                         mead_judge: true,
//                         professional_brewer: false,
//                     } as any,
//                     bjcp_id: 'A10101',
//                 },
//                 display_name: 'Some Fool',
//                 email: 'some.fool.539@example.com',
//             },
//             competition_id: 'ykyZM0Xb2HeZsl5xTCP2',
//             entry_id: 'uviFoAerdO8DH8pnOrrg',
//             is_valid: true,
//             values: {
//                 aroma_flavor_notes: '',
//                 balance_drink_notes: '',
//                 appearance_notes: 'Heya these are some notes',
//                 final_score: 22,
//                 balance_slider: 12,
//                 appearance_slider: 10,
//                 finish_slider: 13,
//                 style_slider: 12,
//                 technical_slider: 14,
//                 aroma_slider: 8,
//                 flavor_slider: 13,
//                 overall_assessment: 'Displayasdasdasd asd a sda d asd ads asd',
//             },
//             auto_saved_at: {
//                 _seconds: 1644810903,
//                 _nanoseconds: 594000000,
//             },
//             saved_at: {
//                 _seconds: 1644811184,
//                 _nanoseconds: 704000000,
//             },
//             descriptors: {
//                 alcoholic: true,
//                 estery: true,
//                 musty: true,
//                 sovent: false,
//                 sulfur: true,
//             },
//         },
//     ],
// };

const ScoresheetWrapper: React.FunctionComponent<RouteComponentProps> = () => {
    const { pathname } = useLocation();

    console.log('using pathname to re-render', pathname);

    const entry = win.data?.entry;
    const competition = win.data?.competition;
    const scores = win.data?.scores;

    // this is going to need to come from the window data, and not be hardcoded to the default
    // const schema = getSheetSchema(
    //     entry?.sheet_schema_id || (competition?.default_sheet_schema as SheetSchemaId)
    // );

    const { getSchemaForEntry } = React.useContext(SheetSchemasContext);
    const schema = getSchemaForEntry(competition, entry);

    console.log('schema', schema);

    if (!schema) {
        return (
            <>
                <div className="container-dashed-gray mt-24 mx-8">No sheet schema available</div>
                <PrintableFooter />
            </>
        );
    }

    if (!scores.length) {
        return (
            <>
                <div className="container-dashed-gray mt-24 mx-8">No scores available</div>
                <PrintableFooter />
            </>
        );
    }

    return (
        <>
            {schema &&
                scores?.map((s) => (
                    <PrintableScoresheet
                        key={s.uid}
                        entry={entry}
                        competition={competition}
                        schema={schema}
                        score={s}
                        className="pdf-page"
                    />
                ))}
        </>
    );
};

export default ScoresheetWrapper;
