import React from 'react';
import Drawer from '../../../components/Drawer/Drawer';
import { ITable, ITableRecord } from '@flight-cap/shared';
import { useForm, FormProvider } from 'react-hook-form';
import { TablesContext } from '../providers/TablesProvider';
import StyleSelection from './StyleSelection';

interface IProps {
    table: ITable;
    onCancel: React.MouseEventHandler;
}

interface IFormData {
    title: string;
    disableAwards: boolean;
    categoriesFormMap: {
        [key: string]: boolean;
    };
}
const TableEditDrawer: React.FunctionComponent<IProps> = ({ table, onCancel }) => {
    const methods = useForm<IFormData>();
    const {
        handleSubmit,
        formState: { errors },
        register,
        reset,
    } = methods;
    const { updateTable, updateTableStylesMap } = React.useContext(TablesContext);

    React.useEffect(() => {
        const categoriesFormMap = Object.fromEntries(
            table?.category_ids.map((catId) => [catId, true]) || []
        );

        reset({
            title: table?.title,
            categoriesFormMap,
            disableAwards: !!!table?.allow_awards,
        });
    }, [reset, table]);

    const onSubmit = async (data: IFormData) => {
        if (!table) {
            return;
        }

        const { categoriesFormMap, disableAwards, ...tableData } = data;

        const updatedAllowAwards = !disableAwards;

        const updates: Partial<ITableRecord> = {};

        const titleChanged = tableData.title !== table.title;
        const allowAwardsChanged = updatedAllowAwards !== table.allow_awards;

        if (titleChanged) {
            updates.title = tableData.title;
        }

        if (allowAwardsChanged) {
            updates.allow_awards = updatedAllowAwards;
        }

        if (Object.keys(updates).length > 0) {
            await updateTable(table.uid, updates);
        }

        await updateTableStylesMap(table.uid, categoriesFormMap);

        onCancel(null); // close it
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Drawer
                    title="Edit Table"
                    maxWidth="max-w-6xl"
                    show={table ? true : false}
                    onCancel={onCancel}
                    actions={
                        <>
                            <button
                                onClick={onCancel}
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Save
                            </button>
                        </>
                    }
                >
                    <div className="p-4">
                        <div className="mb-8 flex flex-col">
                            <label htmlFor="table-title">Table Name</label>
                            <input
                                className="max-w-xs"
                                id="table-title"
                                type="text"
                                placeholder="Table"
                                aria-invalid={errors.title ? 'true' : 'false'}
                                {...register('title', { required: true })}
                            ></input>
                        </div>
                        <div className="mb-8">
                            <label className="flex items-center space-x-2">
                                <input type="checkbox" {...register('disableAwards')} />
                                <span>Disable awards for table</span>
                            </label>
                        </div>
                        <div className="mb-8 flex flex-col">
                            <label>Styles</label>
                            <StyleSelection table={table} />
                        </div>
                    </div>
                </Drawer>
            </form>
        </FormProvider>
    );
};

export default TableEditDrawer;
