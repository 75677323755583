import React from 'react';
import { Transition } from '@headlessui/react';
import DangerIcon from '../Icon/DangerIcon';
import ReactDOM from 'react-dom';

export enum ConfirmTheme {
    Danger = 'danger',
    Neutral = 'neutral',
    Warning = 'warning',
}

interface IConfirmProps {
    show?: boolean;
    onConfirm?: React.MouseEventHandler;
    onCancel: React.MouseEventHandler;
    title: string;
    confirmLabel?: string;
    cancelLabel?: string;
    theme?: ConfirmTheme;
    disableConfirm?: boolean;
    children: React.ReactNode;
}

interface IThemeProps {
    buttonClasses: string;
    icon?: React.ReactChild;
    iconClasses?: string;
}

const getThemeProps = (theme: ConfirmTheme): IThemeProps => {
    return (
        {
            [ConfirmTheme.Danger]: {
                icon: <DangerIcon className="w-6 h-6 text-red-600" />,
                buttonClasses: 'btn-red',
                iconClasses: 'bg-red-100',
            },
            [ConfirmTheme.Warning]: {
                icon: <DangerIcon className="w-6 h-6 text-yellow-600" />,
                buttonClasses: 'btn-yellow',
                iconClasses: 'bg-yellow-100',
            },
            [ConfirmTheme.Neutral]: {
                buttonClasses: 'btn-indigo',
            },
        }[theme] || {
            icon: null,
            buttonClasses: 'btn-indigo',
            iconClasses: '',
        }
    );
};

const Modal: React.FunctionComponent<IConfirmProps> = ({
    show,
    title,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    theme,
    children,
    disableConfirm,
}) => {
    const themeProps = React.useMemo(() => getThemeProps(theme), [theme]);

    React.useEffect(() => {
        const handleKeyPress = (evt: KeyboardEvent) => {
            if (!show) {
                return;
            }

            if (evt.code === 'Escape') {
                return onCancel(null);
            }

            if (!disableConfirm && evt.key === 'Enter') {
                return onConfirm && onConfirm(null);
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => document.removeEventListener('keydown', handleKeyPress);
    });

    return ReactDOM.createPortal(
        <Transition show={show}>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        enter="transition-opacity ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed opacity-50 inset-0" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                    </Transition.Child>

                    <Transition.Child
                        enter="transform transition-all ease-out duration-200"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="transform transition-all ease-in duration-100"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className="sm:flex sm:items-start">
                                {themeProps?.icon ? (
                                    <div
                                        className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${themeProps.iconClasses}`}
                                    >
                                        {themeProps.icon}
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left sm:pr-8 w-full">
                                    <h3
                                        className="text-lg leading-6 font-medium text-gray-900"
                                        id="modal-headline"
                                    >
                                        {title}
                                    </h3>
                                    <div className="mt-2">{children}</div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:pr-8 w-full">
                                {!!onConfirm && (
                                    <button
                                        onClick={onConfirm}
                                        type="button"
                                        disabled={disableConfirm}
                                        className={`w-full inline-flex justify-center shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm ${themeProps?.buttonClasses}`}
                                    >
                                        {confirmLabel}
                                    </button>
                                )}

                                <button
                                    onClick={onCancel}
                                    type="button"
                                    className="btn-gray btn-outline mt-3 w-full inline-flex justify-center sm:mt-0 sm:w-auto sm:text-sm"
                                >
                                    {cancelLabel}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition>,
        window.document.body
    );
};

Modal.defaultProps = {
    show: false,
    disableConfirm: false,
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
};

export default Modal;
