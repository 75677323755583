import React from 'react';
import { TablesContext } from '../../providers/TablesProvider';
import FormMulitSelect from './QueryMulitSelect';
import { IValueInputProps } from './QueryFormRow';

const QueryTableSelect: React.FunctionComponent<IValueInputProps> = ({ name }) => {
    const { tables } = React.useContext(TablesContext);
    const items = tables.map((t) => ({ label: t.title, value: t.uid }));
    return <FormMulitSelect name={name} items={items} buttonLabel="Choose Tables" />;
};

export default QueryTableSelect;
