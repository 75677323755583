import React from 'react';
import AllergensBadge from '../../../components/EntryDetails/AllergensBadge';
import EntryDetails from '../../../components/EntryDetails/EntryDetails';
import { TableRow } from './TablesAdmin';

const TableMiniBosPrintLayout: React.FunctionComponent<{ table: TableRow }> = ({ table }) => {
    return table ? (
        <div className="hidden print:block">
            <h1 className="text-l my-2">{table.title}</h1>
            <div className="grid grid-cols-4 gap-4 my-2 text-gray-400">
                <div className="border p-2 h-12 text-xs">1st:</div>
                <div className="border p-2 h-12 text-xs">2nd:</div>
                <div className="border p-2 h-12 text-xs">3rd:</div>
                <div className="border p-2 h-12 text-xs">HM:</div>
            </div>
            <div>
                {table.entries
                    .filter((entry) => entry.mini_bos)
                    .sort((a, b) => (a.style_snippet.uid > b.style_snippet.uid ? 1 : -1))
                    .map((entry) => (
                        <div className="text-xs inline-flex text-left flex-col justify-between border border-dashed border-gray-300 p-4 m-2 h-72 w-72">
                            <div>
                                <p>
                                    {entry.style_snippet.category}
                                    {entry.style_snippet.sub_category}: {entry.style_snippet.name}
                                </p>
                                <p>{entry.judging_number}</p>
                                <span className="my-2">
                                    <EntryDetails entry={entry} />
                                </span>
                            </div>
                            <AllergensBadge entry={entry} className="relative bottom-0" />
                        </div>
                    ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default TableMiniBosPrintLayout;
