import { IFlight } from '@flight-cap/shared';
import React from 'react';
import PaperAirplaneIcon from '../Icon/PaperAirplaneIcon';
import TrophyIcon from '../Icon/TrophyIcon';

const FlightIcon: React.FunctionComponent<{ flightType: IFlight['type'] }> = ({ flightType }) => {
    if (flightType === 'table_comparative' || flightType === 'custom_comparative') {
        return <TrophyIcon />;
    }
    return <PaperAirplaneIcon />;
};

export default FlightIcon;
