import React from 'react';
import { Link, useLocation } from '@reach/router';
import { ApplicationRoutes } from '../../routes';

const SignInUpButton: React.FunctionComponent = () => {
    const { pathname } = useLocation();
    const isOnSignUp = pathname === ApplicationRoutes.SignUp;

    return (
        <Link
            to={`${isOnSignUp ? ApplicationRoutes.SignIn : ApplicationRoutes.SignUp}${
                window.location.search
            }`}
        >
            <button className="btn-white btn-outline">{isOnSignUp ? 'Sign In' : 'Sign Up'}</button>
        </Link>
    );
};

export default SignInUpButton;
