import React from 'react';
import { IScoreSheetData, ISliderProps } from '@flight-cap/shared';
import { useFormContext } from 'react-hook-form';
import { useLocation } from '@reach/router';

const SheetSlider: React.FunctionComponent<ISliderProps & { scoreSheetData: IScoreSheetData }> = (
    props
) => {
    const { setValue, register } = useFormContext();

    // in the case where we are moving from an entry with a null scoreSheetData to another null scoreSheetData, we want to reset the values
    const { pathname } = useLocation();

    React.useEffect(() => {
        setValue(props.element_id, Number(props.scoreSheetData?.values?.[props.element_id] || -1));
    }, [props.scoreSheetData, props.element_id, setValue, pathname]);

    const listId = `${props.element_id}_data_list`;

    return (
        <>
            <input
                {...register(props.element_id, {
                    required: !!props.required,
                    validate: (data: any) => (props.required && data > 0 ? true : props.required),
                    setValueAs: (v: string) => parseInt(v),
                })}
                type="range"
                className="w-full outline-none py-1 mt-1"
                list={listId}
                min={0}
                max={props.steps}
            />
            <div id={listId} className={'flex w-full justify-between text-sm text-gray-500'}>
                <span>{props.min_label}</span>
                <span>{props.max_label}</span>
            </div>
        </>
    );
};

export default SheetSlider;
