import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FlightsContext } from '../../CompetitionAdmin/providers/FlightsProvider';
import { EntriesContext } from '../../CompetitionAdmin/providers/EntriesProvider';
import { PullSheetEntryRow, customFlightPullSheetEntryColumns } from './TableEntryPullSheetColumns';
import { containsClass, getAttribute, ICustomComparativeFlight } from '@flight-cap/shared';
import CustomComparativeFlightEntriesProvider, {
    CustomComparativeFlightEntriesContext,
} from '../providers/FlightEntries/CustomComparativeFlightEntriesProvider';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import Table from '../../../components/Table/Table';
import {
    fieldLabelMap,
    FieldValue,
} from '../../CompetitionAdmin/flights/QueryFormList/QueryFormRow';

const FlightPullSheetDetail: React.FunctionComponent<{}> = () => {
    const { flightEntries, flight } = React.useContext(CustomComparativeFlightEntriesContext);
    const { entriesPulled, updateEntryPulledStatus } = React.useContext(EntriesContext);

    const rows: PullSheetEntryRow[] = React.useMemo(() => {
        if (!flight?.uid) {
            return [];
        }

        return flightEntries.map((e) => ({
            ...e,
            pullKey: flight.uid,
            isPulled: entriesPulled?.[e.uid]?.[flight.uid],
        }));
    }, [entriesPulled, flightEntries, flight?.uid]);

    const handleTableClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            if (containsClass(evt.target, 'toggle-pulled')) {
                const entryId = String(getAttribute(evt.target, 'data-entry-id'));
                const pullKey = String(getAttribute(evt.target, 'data-pull-key'));

                updateEntryPulledStatus(entryId, pullKey, !entriesPulled?.[entryId]?.[pullKey]);
            }
        },
        [updateEntryPulledStatus, entriesPulled]
    );

    return flight ? (
        <div onClick={handleTableClicked} className="mb-8">
            <div className="flex items-center my-4 space-x-2">
                <h3>Entries for {flight.title}</h3>
                <div className="flex space-x-1">
                    {(flight as ICustomComparativeFlight).where.map(
                        ({ field, comparator, value }) => (
                            <pre
                                key={`${field}-${comparator}-${value}`}
                                className="text-xs rounded bg-gray-200 p-2 "
                            >
                                {fieldLabelMap[field as FieldValue]}{' '}
                                {value instanceof Array ? value.join(', ') : value}
                            </pre>
                        )
                    )}
                </div>
            </div>
            <Table columns={customFlightPullSheetEntryColumns} data={rows}></Table>
        </div>
    ) : null;
};

const CustomCompatativeFlightPullSheet: React.FunctionComponent<
    RouteComponentProps<{ flightId: string }>
> = ({ flightId }) => {
    const { competition } = React.useContext(CompetitionContext);
    const { flights } = React.useContext(FlightsContext);

    const flight = flights.find((t) => t.uid === flightId);

    return (
        <CustomComparativeFlightEntriesProvider
            flight={flight as ICustomComparativeFlight}
            competitionId={competition.uid}
        >
            <FlightPullSheetDetail />
        </CustomComparativeFlightEntriesProvider>
    );
};

export default CustomCompatativeFlightPullSheet;
