import React from 'react';
import ReactDOM from 'react-dom';

const PrintPortal: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) =>
    children ? (
        ReactDOM.createPortal(
            <div className="hidden print:block absolute top-0 bottom-0 left-0 right-0 bg-white ">
                {children}
            </div>,
            window.document.body
        )
    ) : (
        <></>
    );

export const usePrinter = (): [
    React.FunctionComponent<{ children: React.ReactNode }>,
    (pageTitle: string) => void
] => {
    const print = (pageTitle: string) =>
        setTimeout(() => {
            const originalTitle = window.document.title;
            window.document.title = pageTitle;
            window.document.body.querySelector('.root-wrapper')?.classList.add('hidden');
            window.document.body
                .querySelector('.firebase-emulator-warning')
                ?.classList.add('hidden');
            window.print();
            window.document.body
                .querySelector('.firebase-emulator-warning')
                ?.classList.remove('hidden');
            window.document.body.querySelector('.root-wrapper')?.classList.remove('hidden');
            window.document.title = originalTitle;
        }, 10);

    return [PrintPortal, print];
};
