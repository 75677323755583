import { calculateJudgingLoad, IFlight, IParticipantSnippet } from '@flight-cap/shared';
import React from 'react';
import { FlightsContext } from '../../providers/FlightsProvider';
import CategoryList from '../CategoryList';
import SessionJudgeLoad from './SessionJudgeLoad';
import { DraggableStatusVariant } from './SessionEditor';
import MinusCircleIcon from '../../../../components/Icon/MinusCircleIcon';
import JudgeCertifications from '../../../../components/JudgeInfo/JudgeCertifications';
import JudgeRankBagde from '../../../../components/JudgeInfo/JudgeRankBagde';

const SessionJudge: React.FunctionComponent<{
    participant: IParticipantSnippet;
    sessionId: string;
    condensed?: boolean;
    showRemove?: boolean;
    className?: string;
    variant?: DraggableStatusVariant;
    flight?: IFlight;
}> = ({
    participant,
    sessionId,
    condensed = false,
    showRemove = false,
    className = '',
    variant = 'success',
    flight,
}) => {
    const variantClasses = (
        {
            success: 'border-gray-500',
            warning: 'bg-yellow-stripes text-yellow-700 border-yellow-700',
            error: 'bg-redder-stripes text-red-800 border-red-800',
        } as Record<DraggableStatusVariant, string>
    )[variant];

    const { flights } = React.useContext(FlightsContext);

    const judgeLoad = React.useMemo(() => {
        const participantFlights = flights.filter(
            (f) => sessionId && f.session_id === sessionId && f.judge_ids.includes(participant.uid)
        );
        return calculateJudgingLoad(participantFlights);
    }, [flights, participant.uid, sessionId]);

    return (
        <div
            className={`${className} border ${variantClasses} h-full flex items-center rounded bg-white p-2 text-sm`}
        >
            <div className="flex flex-col justify-between grow">
                <header className="flex items-start ">
                    <div className="w-full">
                        <div className="flex items-baseline justify-between">
                            <div className="">{participant.display_name}</div>
                            <div
                                className={`flex justify-between ${
                                    condensed ? 'items-center' : 'items-baseline'
                                }`}
                            >
                                <JudgeCertifications
                                    judging={participant.judging}
                                    asDots={condensed}
                                />
                                <JudgeRankBagde judging={participant.judging} />
                            </div>
                        </div>
                    </div>
                </header>

                {!condensed && (
                    <div className="flex justify-between items-end mt-2">
                        <section>
                            {participant.categories_entered_ids.length > 0 ? (
                                <CategoryList categoryIds={participant.categories_entered_ids} />
                            ) : (
                                <span className="italic text-gray-400">No entries</span>
                            )}
                        </section>
                        <SessionJudgeLoad load={judgeLoad} />
                    </div>
                )}
            </div>
            {showRemove && (
                <button
                    className="remove-participant-from-flight user-none hover:text-red-700"
                    data-participant-id={participant.uid}
                    data-flight-id={flight?.uid}
                    data-no-dnd="true"
                    title="Remove judge from session"
                >
                    <MinusCircleIcon className="remove-participant-from-flight user-none ml-4 pointer-events-none" />
                </button>
            )}
        </div>
    );
};

export default SessionJudge;
