import { IEntry } from '@flight-cap/shared';
import React from 'react';
import DangerIcon from '../Icon/DangerIcon';

const AllergensBadge: React.FunctionComponent<{ entry: IEntry; className?: string }> = ({
    entry,
    className,
}) => {
    const allergens = entry?.allergens.join(', ') || '';
    return allergens ? (
        <div className={`${className} truncate w-full`}>
            <p className="badge-yellow badge-rounded">
                <DangerIcon className="mr-1" />
                <span className="text-xs whitespace-normal w-full">{allergens}</span>
            </p>
        </div>
    ) : (
        <></>
    );
};

AllergensBadge.defaultProps = {
    className: '',
};

export default AllergensBadge;
