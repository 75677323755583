import React from 'react';
import Drawer from '../../../components/Drawer/Drawer';
import {
    ICustomComparativeFlightRecord,
    IFlight,
    IJudgeSnippet,
    IParticipantSnippet,
    ValidQuery,
} from '@flight-cap/shared';
import { useForm, FormProvider } from 'react-hook-form';
import { FlightsContext } from '../providers/FlightsProvider';
import { ParticipantsContext } from '../providers/ParticipantsProvider';
import QueryFormList from './QueryFormList/QueryFormList';
import { FieldValue } from './QueryFormList/QueryFormRow';

interface IProps {
    flight: IFlight;
    onCancel: React.MouseEventHandler;
}

const FlightEditDrawer: React.FunctionComponent<IProps> = ({ flight, onCancel }) => {
    const methods = useForm({ shouldUnregister: false });
    const {
        handleSubmit,
        reset,
        register,
        formState: { errors },
    } = methods;
    const { updateFlight } = React.useContext(FlightsContext);
    const { judges } = React.useContext(ParticipantsContext);

    React.useEffect(() => {
        const resetData: Partial<ICustomComparativeFlightRecord> = {
            judge_ids: flight?.judge_ids?.length ? flight?.judge_ids : null,
        };

        if (flight?.type === 'custom_comparative') {
            resetData.title = flight?.title;
            resetData.where = flight?.where;
        }

        reset(resetData);
    }, [flight, reset]);

    const onSubmit = async (data: Partial<ICustomComparativeFlightRecord>) => {
        console.log('submitting data in:', data);
        if (!flight) {
            return;
        }

        // need to convert to array since when a single judge is selected, a string (not an array of strings) will be supplied
        if (!(data.judge_ids instanceof Array)) {
            data.judge_ids = data.judge_ids ? [data.judge_ids] : [];
        }

        data.judges = data.judge_ids.reduce((obj, judgeId) => {
            const judge = judges.find((judge) => judge.uid === judgeId);

            const snippet: IParticipantSnippet = {
                uid: judge.uid,
                display_name: judge.display_name,
                email: judge.email,
                judging: judge.judging || null,
                categories_entered: judge.categories_entered,
                categories_entered_ids: judge.categories_entered_ids,
                session_snippets: judge.session_snippets,
                session_ids: judge.session_ids,
                roles: judge.roles,
            };

            obj[judgeId] = snippet;
            return obj;
        }, {} as Record<string, IJudgeSnippet>);

        if (data.where) {
            data.where = data.where
                ?.filter(
                    (query) =>
                        query.comparator &&
                        query.field &&
                        query.value &&
                        !(query.value instanceof Array && query.value.length === 0)
                )
                .map(
                    ({ field, comparator, value }) =>
                        ({
                            field,
                            value: field.includes(FieldValue.Place) ? Number(value) : value,
                            comparator:
                                comparator === 'in' && !(value instanceof Array)
                                    ? '=='
                                    : comparator,
                        } as ValidQuery)
                );
        }

        console.log(data);

        await updateFlight(flight.uid, data);
        onCancel(null);
    };

    return (
        flight && (
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Drawer
                        title={flight ? flight.name() : ''}
                        show={flight ? true : false}
                        onCancel={onCancel}
                        actions={
                            <>
                                <button
                                    onClick={onCancel}
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Save
                                </button>
                            </>
                        }
                    >
                        <div className="p-4">
                            {flight.type === 'custom_comparative' && (
                                <>
                                    <div className="mb-4">
                                        <label>
                                            Flight Name
                                            <input
                                                id="flight-title"
                                                type="text"
                                                placeholder="Flight Name"
                                                aria-invalid={errors.title ? 'true' : 'false'}
                                                {...register('title', { required: true })}
                                            ></input>
                                        </label>
                                    </div>
                                    <div className="mb-4">
                                        <label>Include entries where:</label>
                                        <QueryFormList />
                                    </div>
                                </>
                            )}

                            <h1>Assign judges for this flight</h1>
                            <div className="my-4 grid grid-cols-2 gap-2">
                                {judges?.map((judge) => (
                                    <label key={judge.uid} className="p-4 items-center flex">
                                        <input
                                            {...register('judge_ids')}
                                            className="mr-2"
                                            type="checkbox"
                                            value={judge.uid}
                                        ></input>
                                        {judge.display_name}
                                    </label>
                                ))}
                            </div>
                        </div>
                    </Drawer>
                </form>
            </FormProvider>
        )
    );
};

export default FlightEditDrawer;
