import React from 'react';
import ReactDOM from 'react-dom';
import './assets/tailwind-output.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import AppProviders from './providers/Providers';
import Pdfs from './modules/Pdfs/Pdfs';
import { RouteComponentProps, Router } from '@reach/router';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const AppWrapper: React.FunctionComponent<RouteComponentProps> = () => <App />;

const changeFavicon = (imageUrl: string) => {
    const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (link) {
        link.href = imageUrl;
    }
};

const favicon =
    (
        {
            local: '/favicon_yellow.ico',
            dev: '/favicon_green.ico',
        } as Record<string, string>
    )[process.env.REACT_APP_ENVIRONMENT] || '';

if (favicon) {
    changeFavicon(favicon);
}

ReactDOM.render(
    <React.StrictMode>
        <div className="root-wrapper">
            <ErrorBoundary>
                <AppProviders>
                    <Router>
                        <Pdfs path="/pdfs/*" />
                        <AppWrapper path="/*" />
                    </Router>
                </AppProviders>
            </ErrorBoundary>
        </div>
    </React.StrictMode>,
    document.getElementById('root')
);

document.addEventListener('wheel', function (event) {
    if ((document.activeElement as any).type === 'number') {
        (document.activeElement as any).blur();
    }
});

reportWebVitals();
