import React from 'react';
import UserProvider from './UserProvider';
import { LocationProvider } from '@reach/router';
import CompetitionProvider from './CompetitionProvider';
import FlagsProvider from './FlagsProvider';
import { ToastProvider } from 'react-toast-notifications';
import ToastMessage from '../components/Toasts/ToastMessage';
import DefaultToastContainer from '../components/Toasts/ToastContainer';

const AppProviders: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
    <ToastProvider
        autoDismiss={true}
        autoDismissTimeout={4000}
        components={{ Toast: ToastMessage, ToastContainer: DefaultToastContainer }}
    >
        <FlagsProvider>
            <LocationProvider>
                <UserProvider>
                    <CompetitionProvider>{children}</CompetitionProvider>
                </UserProvider>
            </LocationProvider>
        </FlagsProvider>
    </ToastProvider>
);

export default AppProviders;
