import React from 'react';
import { ICompetitionStyle, MetaStyleType } from '@flight-cap/shared';
import { MyColumn } from '../../../components/Table/react-table-config';
import DangerIcon from '../../../components/Icon/DangerIcon';
import ToggleSwitch from '../../../components/Toggle/ToggleSwitch';
import { CellProps } from 'react-table';
import EditDeleteCellRenderer from '../../../components/Table/renderers/EditDeleteCellRenderer';

export const stylesColumns: MyColumn<ICompetitionStyle>[] = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Style Id',
        accessor: 'uid',
        sortType: (a, b) => {
            const titleA = a.original.uid;
            const titleB = b.original.uid;
            return titleA > titleB ? 1 : -1;
        },
    },
    {
        Header: 'Style Type',
        accessor: (d) =>
            (({ beer: 'Beer', cider: 'Cider', mead: 'Mead' } as Record<MetaStyleType, string>)[
                d.type
            ]),
    },
    {
        Header: 'Table',
        accessor: (d) =>
            d.table_snippet?.uid ? (
                <div className="badge-gray badge-rounded">{d.table_snippet.title}</div>
            ) : (
                <div className="badge-yellow badge-rounded">
                    <DangerIcon className="w-2 h-2 mr-2" />
                    Not Assigned
                </div>
            ),
        sortType: (a, b) => {
            const titleA = a.original.table_snippet?.title || 'zzz';
            const titleB = b.original.table_snippet?.title || 'zzz';
            return titleA > titleB ? 1 : -1;
        },
    },
    {
        Header: 'Enabled',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-style-enabled"
                data-style-id={d.uid}
                checked={d.enabled}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
];

export const deleteStyleColumn: MyColumn<ICompetitionStyle> = {
    Header: 'Actions',
    accessor: null,
    Cell: ({ row: { original } }: CellProps<ICompetitionStyle>) => (
        <>
            <EditDeleteCellRenderer value={original.uid} />
        </>
    ),
    disableSortBy: true,
    width: 200,
    minWidth: 200,
    maxWidth: 300,
    sticky: 'right',
    className: 'unstick-large',
};
