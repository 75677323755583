import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import ScoresheetWrapper from './scoresheet/ScoresheetWrapper';
import SheetSchemasProvider from '../Competition/providers/SheetSchemasProvider';

const Pdfs: React.FunctionComponent<RouteComponentProps> = () => {
    return (
        <SheetSchemasProvider>
            <Router>
                <ScoresheetWrapper path="/scoresheet" />
            </Router>
        </SheetSchemasProvider>
    );
};

export default Pdfs;
