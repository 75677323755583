import { IJudgingLoad } from '@flight-cap/shared';
import React from 'react';
import DocumentTextIcon from '../../../../components/Icon/DocumentTextIcon';
import PaperAirplaneIcon from '../../../../components/Icon/PaperAirplaneIcon';
import TrophyIcon from '../../../../components/Icon/TrophyIcon';
import IconValue from '../IconValue';

const SessionJudgeLoad: React.FunctionComponent<{
    load: IJudgingLoad;
}> = ({ load }) => {
    const displayScoring = Math.ceil(load.approximate_scoring_entries);

    return (
        <div className="flex justify-end items-center space-x-1 sm:space-x-2">
            {load.scoring > 0 && (
                <IconValue
                    value={load.scoring}
                    itemName={load.scoring === 1 ? 'scoring flight' : 'scoring flights'}
                    icon={<PaperAirplaneIcon />}
                />
            )}
            {load.scoring > 0 && (
                <span className="flex items-center">
                    {load.approximate && <span className="text-xs mr-0.5">~</span>}
                    <IconValue
                        value={displayScoring}
                        itemName={`${load.approximate ? 'About' : ''} ${
                            displayScoring === 1 ? 'entry' : 'entries'
                        }`}
                        icon={<DocumentTextIcon />}
                    />
                </span>
            )}

            {load.comparative > 0 && (
                <IconValue
                    value={load.comparative}
                    itemName={load.comparative === 1 ? 'comparative flight' : 'comparative flights'}
                    icon={<TrophyIcon />}
                />
            )}
        </div>
    );
};

export default SessionJudgeLoad;
