import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { TablesContext } from '../../CompetitionAdmin/providers/TablesProvider';
import { EntriesContext } from '../../CompetitionAdmin/providers/EntriesProvider';
import Table from '../../../components/Table/Table';
import { PullSheetEntryRow, pullSheetEntryColumns } from './TableEntryPullSheetColumns';
import { containsClass, getAttribute } from '@flight-cap/shared';

const TablePullSheets: React.FunctionComponent<RouteComponentProps<{ tableId: string }>> = ({
    tableId,
}) => {
    const { tables } = React.useContext(TablesContext);
    const { entries, entriesPulled, updateEntryPulledStatus } = React.useContext(EntriesContext);

    const table = tables.find((t) => t.uid === tableId);

    const handleTableClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            if (containsClass(evt.target, 'toggle-pulled')) {
                const entryId = String(getAttribute(evt.target, 'data-entry-id'));
                const pullKey = String(getAttribute(evt.target, 'data-pull-key'));

                updateEntryPulledStatus(entryId, pullKey, !entriesPulled?.[entryId]?.[pullKey]);
            }
        },
        [updateEntryPulledStatus, entriesPulled]
    );

    /**
     * Load the map of 'pulled' status (will have both the regualt entries and the minibos entries).
     * Then find the table entries and make the two arrays
     */
    const [tableRows, miniBosRows]: [PullSheetEntryRow[], PullSheetEntryRow[]] =
        React.useMemo(() => {
            const tableEntries = entries?.filter((e) => e.table_snippet?.uid === tableId) || [];

            const tableRows: PullSheetEntryRow[] = tableEntries.map((e) => ({
                ...e,
                pullKey: 'scoring',
                isPulled: entriesPulled?.[e.uid]?.scoring,
            }));

            const miniBosRows: PullSheetEntryRow[] = tableEntries
                .filter((e) => e.mini_bos)
                .map((e) => ({
                    ...e,
                    pullKey: 'mini_bos',
                    isPulled: entriesPulled?.[e.uid]?.mini_bos,
                }));

            return [tableRows, miniBosRows];
        }, [entries, tableId, entriesPulled]) || [[], []];

    if (!tables || !entries) {
        return <div className="container-dashed-gray">Loading...</div>;
    }

    return (
        <>
            <h1 className="text-xl mb-8">Pull Sheets for Table {table?.title}</h1>
            <div onClick={handleTableClicked}>
                <div className="mb-8">
                    <h3 className="mb-2">Entries for flight scoring</h3>
                    <Table columns={pullSheetEntryColumns} data={tableRows}></Table>
                </div>
                <div className="mb-8">
                    <h3 className="mb-2">Entries for Mini-BOS</h3>
                    <Table columns={pullSheetEntryColumns} data={miniBosRows}></Table>
                </div>
            </div>
        </>
    );
};

export default TablePullSheets;
