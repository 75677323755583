import React from 'react';

interface ICardProps {
    className?: string;
    headingText?: string;
    children: React.ReactNode;
}

const Card: React.FunctionComponent<ICardProps> = ({ className, headingText, children }) => {
    return (
        <div className={`bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 ${className}`}>
            {headingText ? (
                <div className="py-4 mb-2 text-indigo-7000 text-xl">{headingText}</div>
            ) : (
                <></>
            )}
            <div>{children}</div>
        </div>
    );
};

export default Card;
