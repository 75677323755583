import React from 'react';
import AppHeader from '../components/AppHeader/AppHeader';
import { UserContext } from '../providers/UserProvider';
import Auth from '../modules/Auth/Auth';
import { Router } from '@reach/router';
import { ApplicationRoutes } from '../routes';
import UserProfile from '../modules/UserProfile/UserProfile';
import Competition from '../modules/Competition/Competition';
import Splash from '../modules/Splash/Splash';
import ProtectedRoute from '../modules/Auth/ProtectedRoute';
import DashboardWrapper from '../modules/Dashboard/DashboardWrapper';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

const App: React.FunctionComponent = () => {
    const { resolved: userPending } = React.useContext(UserContext);

    return (
        <div>
            {!userPending ? (
                <p>Loading...</p>
            ) : (
                <>
                    <AppHeader />
                    <main>
                        <div className="mx-auto relative">
                            <ErrorBoundary>
                                <Router>
                                    <Splash path="/" />
                                    <Auth path="/auth/*" />
                                    <ProtectedRoute as={DashboardWrapper} path="/dashboard/*" />
                                    <ProtectedRoute
                                        as={UserProfile}
                                        path={ApplicationRoutes.UserProfile}
                                    />
                                    <ProtectedRoute
                                        as={Competition}
                                        path={`${ApplicationRoutes.Competition}/*`}
                                    />
                                </Router>
                            </ErrorBoundary>
                        </div>
                    </main>
                </>
            )}
        </div>
    );
};

export default App;
