import React from 'react';
import { RouteComponentProps, Redirect } from '@reach/router';
import { UserContext } from '../../providers/UserProvider';
import { ApplicationRoutes } from '../../routes';

const ProtectedRoute: React.FunctionComponent<
    {
        as:
            | React.ComponentClass<{ children?: React.ReactNode }>
            | React.FunctionComponent<{ children?: React.ReactNode }>;
    } & RouteComponentProps & { children?: React.ReactNode }
> = ({ as: Comp, ...rest }) => {
    const { user, resolved } = React.useContext(UserContext);

    return resolved ? (
        user ? (
            <Comp {...rest} />
        ) : (
            <Redirect
                to={`${ApplicationRoutes.SignIn}?redirect=${encodeURIComponent(
                    rest.location.pathname
                )}`}
                noThrow
            />
        )
    ) : (
        <></>
    );
};

export default ProtectedRoute;
