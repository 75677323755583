import React from 'react';
import { RouteComponentProps, Redirect, useMatch } from '@reach/router';
import { UserContext } from '../../providers/UserProvider';
import { ApplicationRoutes } from '../../routes';
import { parameterizeEndpoint } from '@flight-cap/shared';
import { CompetitionContext } from '../../providers/CompetitionProvider';

const CompetitionJudgingRoute: React.FunctionComponent<
    {
        as:
            | React.ComponentClass<{ children?: React.ReactNode }>
            | React.FunctionComponent<{ children?: React.ReactNode }>;
    } & RouteComponentProps & { children?: React.ReactNode }
> = ({ as: Comp, ...rest }) => {
    const { user } = React.useContext(UserContext);
    const { competition } = React.useContext(CompetitionContext);

    const match = useMatch(`${ApplicationRoutes.Competition}/*`);
    const competitionSlug = match?.competitionSlug;

    const inCompetition = competition && user && user.competitions?.[competition.uid]?.roles;
    const isJudge = inCompetition?.includes('judge');

    return isJudge ? (
        <Comp {...rest} />
    ) : (
        <Redirect
            to={parameterizeEndpoint(
                inCompetition
                    ? ApplicationRoutes.CompetitionInfo
                    : ApplicationRoutes.JoinCompetition,
                { competitionSlug }
            )}
            noThrow
        />
    );
};

export default CompetitionJudgingRoute;
