import { AwardRank, IEntryRecord } from '@flight-cap/shared';
import React from 'react';

const SummaryInformation: React.FunctionComponent<{ entry: IEntryRecord }> = ({ entry }) => {
    return (
        <>
            {/* <label className="inline-block border-b border-gray-500 mb-3">Scoring Summary</label> */}
            <div className="space-y-4">
                <div className="flex items-center">
                    <span className="badge-blue badge-outline badge-rounded">
                        <span className="text-6xl px-1">{entry.consensus_score}</span>
                    </span>
                    <span className="ml-3 text-xl leading-6">
                        Consensus
                        <br />
                        Score
                    </span>
                </div>
                <div>
                    {' '}
                    {entry.mini_bos ? (
                        <div className="badge-green badge-rounded badge-outline">
                            <span className="text-lg">Advanced to Mini-BOS</span>
                        </div>
                    ) : (
                        <div className="badge-gray badge-rounded badge-outline">
                            <span className="text-lg">Did not advanced to Mini-BOS</span>
                        </div>
                    )}
                </div>
            </div>
            {entry.table_place && (
                <>
                    <label className="inline-block border-b text-lg uppercase border-gray-500 mt-8 mb-2">
                        Awards
                    </label>
                    <div className="my-2">
                        <p>
                            <span className="badge badge-yellow badge-rounded badge-outline">
                                <span className="text-base">
                                    {
                                        (
                                            {
                                                [AwardRank.First]: '1st Place',
                                                [AwardRank.Second]: '2nd Place',
                                                [AwardRank.Third]: '3rd Place',
                                                [AwardRank.HonorableMention]: 'Honorable Mention',
                                            } as Record<AwardRank, string>
                                        )[entry.table_place]
                                    }{' '}
                                    in {entry.table_snippet.title}!
                                </span>
                            </span>
                        </p>
                    </div>
                </>
            )}
        </>
    );
};

export default SummaryInformation;
