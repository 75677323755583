import { IFlight } from '@flight-cap/shared';
import React from 'react';
import DocumentTextIcon from '../../../../components/Icon/DocumentTextIcon';
import TrophyIcon from '../../../../components/Icon/TrophyIcon';
import IconValue from '../IconValue';

const FlightLoad: React.FunctionComponent<{ flight: IFlight }> = ({ flight }) => {
    if (flight.type === 'table_comparative' || flight.type === 'custom_comparative') {
        return <TrophyIcon />;
    }

    const entryCount = Math.ceil(
        flight.table_snippet.entry_count / flight.table_snippet.scoring_flight_count
    );

    let value: string | number = entryCount;

    return (
        <span className="flex items-center">
            {flight.table_snippet.scoring_flight_count > 1 && (
                <span className="text-xs mr-0.5">~</span>
            )}
            <IconValue
                value={value}
                itemName={entryCount === 1 ? 'entry' : 'entries'}
                icon={<DocumentTextIcon />}
            />
        </span>
    );
};

export default FlightLoad;
