import React from 'react';
import { useTable, useSortBy, useFlexLayout, useRowSelect, Row } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { MyColumn } from './react-table-config';

export type TableProps<T extends object = any> = {
    columns: MyColumn<T>[];
    data: T[];
    height?: string;
    maxHeight?: string;
    rowSelection?: boolean;
    Row?: React.FunctionComponent;
    className?: string;
    onSelectedChange?: (rowIds: T[]) => void;
};

export function useSelectedRows<T extends {}>(): [T[], (selected: Row<T>[]) => void] {
    const [selectedRows, setSelectedRows] = React.useState<T[]>([]);

    const handleSelectedRowsChange = React.useCallback((selected: Row<T>[]) => {
        setSelectedRows(selected.map((row) => row.original));
    }, []);

    return [selectedRows, handleSelectedRowsChange];
}

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, { indeterminate?: boolean }>(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef<HTMLInputElement>();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            (resolvedRef as any).current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const addSelectionColumn = (hooks: any) => {
    hooks.visibleColumns.push((columns: MyColumn[]) => [
        {
            id: 'selection',
            sticky: 'left',
            width: 45,
            minWidth: 45,
            maxWidth: 45,
            className: 'selection-column',
            Header: (props) => {
                return (
                    <div>
                        <IndeterminateCheckbox {...props.getToggleAllRowsSelectedProps()} />
                    </div>
                );
            },
            Cell: ({ row }) => (
                <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
            ),
        } as MyColumn,
        ...columns,
    ]);
};

const DivRow: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = ({
    children,
    ...rest
}) => <div {...rest}>{children}</div>;

const Table: React.FunctionComponent<TableProps> = ({
    columns,
    data,
    height,
    maxHeight,
    rowSelection,
    onSelectedChange,
    Row,
    className,
}) => {
    const plugins: any = [useSortBy, useSticky, useFlexLayout];

    const Div = Row || DivRow;

    if (rowSelection) {
        plugins.push(useRowSelect, addSelectionColumn);
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } =
        useTable(
            {
                columns,
                data,
                autoResetPage: false,
                autoResetExpanded: false,
                autoResetGroupBy: false,
                autoResetSelectedRows: false,
                autoResetSortBy: false,
                autoResetFilters: false,
                autoResetRowState: false,
            },
            ...plugins
        );

    React.useEffect(() => {
        if (onSelectedChange) {
            onSelectedChange(selectedFlatRows);
        }
    }, [selectedFlatRows, onSelectedChange]);

    return (
        <div
            className={`overflow-scroll border border-gray-200 sm:rounded-md ${className}`}
            style={{ height, maxHeight }}
        >
            <div
                className="min-w-full divide-y divide-gray-200 sticky table w-full -mt-1"
                {...getTableProps()}
            >
                <div className="bg-gray-50 header sticky border-b border-gray-200 top-0 z-20">
                    {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column) => (
                                <div
                                    className={`px-6 py-3 truncate text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider th ${
                                        (column as MyColumn).className || ''
                                    }`}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    <div className="flex h-8 items-center">
                                        {column.render('Header')}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            width={20}
                                            height={20}
                                            className={`inline-flex ml-2 text-gray-400 ${
                                                column.isSorted ? '' : 'invisible'
                                            }`}
                                        >
                                            {column.isSortedDesc ? (
                                                <path
                                                    fillRule="evenodd"
                                                    d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            ) : (
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                                                    clipRule="evenodd"
                                                />
                                            )}
                                        </svg>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                <div
                    className="bg-white divide-y divide-gray-200 relative z-0 body"
                    {...getTableBodyProps()}
                >
                    {rows.map((row, index) => {
                        prepareRow(row);
                        const bgColor = index % 2 ? 'bg-gray-50' : 'bg-white';
                        return (
                            <Div {...row.getRowProps()} className={`${bgColor} tr text-sm`}>
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            className={`px-6 py-3 flex items-center td ${bgColor} ${
                                                (cell.column as MyColumn).className || ''
                                            }`}
                                            {...cell.getCellProps({})}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </Div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

Table.defaultProps = {
    height: 'unset',
    maxHeight: 'unset',
    rowSelection: false,
};

export default Table;
