import React from 'react';
import { MyColumn } from '../../../components/Table/react-table-config';
import EditDeleteCellRenderer from '../../../components/Table/renderers/EditDeleteCellRenderer';
import { CellProps } from 'react-table';
import { TableScoringFlightRow, MiniBosFlightRow, CustomCompareFlightRow } from './FlightsAdmin';
import ToggleSwitch from '../../../components/Toggle/ToggleSwitch';
import ChevronRightIcon from '../../../components/Icon/ChevronRightIcon';
import { Link } from '@reach/router';
import AwardRankingBadgeList from '../../../components/AwardRanking/AwardRankingBadgeList';
import CustomComparativeFlightEntriesProvider, {
    CustomComparativeFlightEntriesContext,
} from '../../Competition/providers/FlightEntries/CustomComparativeFlightEntriesProvider';
import { fieldLabelMap, FieldValue } from './QueryFormList/QueryFormRow';

const CustomComparativeEntryRenderer: React.FunctionComponent = () => {
    const { flightEntries } = React.useContext(CustomComparativeFlightEntriesContext);
    return flightEntries.length > 0 ? (
        <p className="badge badge-green badge-rounded badge-outline">
            {flightEntries.length === 1 && '1 Entry'}
            {flightEntries.length > 1 && `${flightEntries.length} Entries`}
        </p>
    ) : (
        <p className="border-dashed-gray p-2">No Entries yet</p>
    );
};

export const tableScoringFlightColumns: MyColumn<TableScoringFlightRow>[] = [
    {
        Header: 'Name',
        accessor: (d) => d.name(),
        minWidth: 100,
        maxWidth: 300,
        sticky: 'left',
    },
    {
        Header: 'Table',
        accessor: (d) => {
            const tableSnip = d.table_snippet;
            return tableSnip ? (
                <div>
                    <Link to={`../tables/${tableSnip.uid}`}>{tableSnip.title}</Link>
                    <p className="text-sm">{d.table_entries.length} Entries </p>
                </div>
            ) : (
                <></>
            );
        },
        width: 150,
        minWidth: 150,
        maxWidth: 400,
    },
    {
        Header: 'Session',
        accessor: (d) => {
            const sessionSnip = d.session_snippet;
            return sessionSnip ? (
                <div>
                    <p className="text-sm">{sessionSnip.title} </p>
                </div>
            ) : (
                <></>
            );
        },
        sortType: (a, b) => {
            const aSnip = a.original.session_snippet;
            const bSnip = b.original.session_snippet;

            if (aSnip && !bSnip) {
                return 1;
            } else if (bSnip && !aSnip) {
                return -1;
            } else {
                return a.original.session_snippet?.title > b.original.session_snippet?.title
                    ? -1
                    : 1;
            }
        },
        width: 150,
        minWidth: 150,
        maxWidth: 400,
    },
    {
        Header: 'Entries',
        accessor: (d) => (
            <div className="flex flex-col">
                <div className="badge-blue badge-outline badge-rounded mb-2 w-min">
                    {d.claimed_entries.length || '0'} Claimed Entries
                </div>

                {d.mini_bos_entries.length > 0 ? (
                    <div className="badge-green badge-outline badge-rounded mb-2 w-min">
                        {d.mini_bos_entries.length} Sent to Mini-BOS
                    </div>
                ) : (
                    <></>
                )}
            </div>
        ),
        width: 150,
        sortType: (a, b) => {
            return a.original.claimed_entries > b.original.claimed_entries ? 1 : -1;
        },
    },
    {
        Header: 'Judges',
        accessor: (d) => (
            <div>
                {Object.values(d.judges || [])
                    .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
                    .map((judge) => (
                        <span key={judge.uid} className="badge-yellow">
                            {judge.display_name}
                        </span>
                    ))}
            </div>
        ),
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Active',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-flight-active"
                data-flight-id={d.uid}
                checked={d.active}
            />
        ),
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<TableScoringFlightRow>) => (
            <>
                <EditDeleteCellRenderer value={original.uid} />
                <Link to={`./${original.uid}`} className="btn-outline btn-gray">
                    <ChevronRightIcon />
                </Link>
            </>
        ),
        disableSortBy: true,
        width: 200,
        minWidth: 200,
        maxWidth: 300,
        sticky: 'right',
        className: 'unstick-large',
    },
];

export const miniBosFlightColumns: MyColumn<MiniBosFlightRow>[] = [
    {
        Header: 'Name',
        accessor: (d) => d.name(),
        minWidth: 100,
        maxWidth: 300,
        sticky: 'left',
    },
    {
        Header: 'Table',
        accessor: (d) => {
            const tableSnip = d.table_snippet;
            return tableSnip ? (
                <div>
                    <Link to={`../tables/${tableSnip.uid}`}>{tableSnip.title}</Link>
                    <p className="text-sm">{d.table_entries.length} Entries </p>
                </div>
            ) : (
                <></>
            );
        },
        width: 150,
        minWidth: 150,
        maxWidth: 400,
    },
    {
        Header: 'Entries',
        accessor: (d) => (
            <div className="flex flex-col">
                <div className="badge-green badge-outline badge-rounded mb-2 w-min">
                    {d.mini_bos_entries.length || '0'} Entries
                </div>
            </div>
        ),
        width: 150,
        sortType: (a, b) => {
            return a.original.mini_bos_entries > b.original.mini_bos_entries ? 1 : -1;
        },
    },
    {
        Header: 'Winners',
        accessor: (d) => {
            return <AwardRankingBadgeList rankings={d.award_rankings} />;
        },
    },
    {
        Header: 'Judges',
        accessor: (d) => (
            <div>
                {Object.values(d.judges || [])
                    .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
                    .map((judge) => (
                        <span key={judge.uid} className="badge-yellow">
                            {judge.display_name}
                        </span>
                    ))}
            </div>
        ),
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Active',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-flight-active"
                data-flight-id={d.uid}
                checked={d.active}
            />
        ),
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<MiniBosFlightRow>) => (
            <>
                <EditDeleteCellRenderer hideDelete value={original.uid} />
            </>
        ),
        disableSortBy: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        sticky: 'right',
        className: 'unstick-large',
    },
];

export const customFlightColumns: MyColumn<CustomCompareFlightRow>[] = [
    {
        Header: 'Name',
        accessor: (d) => d.name(),
        minWidth: 100,
        maxWidth: 300,
        sticky: 'left',
    },
    {
        Header: 'Query',
        accessor: (d) => (
            <div className="flex flex-col space-y-2">
                {d.where.map(({ field, comparator, value }) => (
                    <pre className="text-xs rounded bg-gray-200 p-2 ">
                        {fieldLabelMap[field as FieldValue]}{' '}
                        {value instanceof Array ? value.join(', ') : value}
                    </pre>
                ))}
            </div>
        ),
        minWidth: 200,
        maxWidth: 500,
    },
    {
        Header: 'Entries',
        accessor: (d) => (
            <CustomComparativeFlightEntriesProvider flight={d} competitionId={d.competitionId}>
                <CustomComparativeEntryRenderer />
            </CustomComparativeFlightEntriesProvider>
        ),
        width: 150,
    },
    {
        Header: 'Winners',
        accessor: (d) => {
            return <AwardRankingBadgeList rankings={d.award_rankings} />;
        },
    },
    {
        Header: 'Judges',
        accessor: (d) => (
            <div>
                {Object.values(d.judges || [])
                    .sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
                    .map((judge) => (
                        <span key={judge.uid} className="badge-yellow">
                            {judge.display_name}
                        </span>
                    ))}
            </div>
        ),
        minWidth: 150,
        maxWidth: 500,
    },
    {
        Header: 'Active',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-flight-active"
                data-flight-id={d.uid}
                checked={d.active}
            />
        ),
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<CustomCompareFlightRow>) => (
            <>
                <EditDeleteCellRenderer value={original.uid} />
            </>
        ),
        disableSortBy: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        sticky: 'right',
        className: 'unstick-large',
    },
];
