import { IEntry, IJudgeSnippet, ISheetSchema } from '@flight-cap/shared';
import React from 'react';
import { UserContext } from '../providers/UserProvider';

interface IFlightSummaryData {
    myScore: number;
    isValid: boolean;
    otherScores: { score: number; judge_snippet: IJudgeSnippet }[];
    minAllowedScore: ISheetSchema['minAllowedScore'];
    maxAllowedScore: ISheetSchema['maxAllowedScore'];
}

export const useFlightSummaryData = (entry: IEntry): IFlightSummaryData => {
    const { user } = React.useContext(UserContext);
    const myScore = entry.score_snippets?.[user.uid]?.score;
    const isValid = entry.score_snippets?.[user.uid]?.is_valid;
    const minAllowedScore = entry.sheet_schema?.minAllowedScore || 0;
    const maxAllowedScore = entry.sheet_schema?.maxAllowedScore || 50;

    const otherScores = Object.values(entry?.score_snippets || {}).filter(
        (snip) => snip.judge_snippet.uid !== user.uid
    );

    return { myScore, otherScores, minAllowedScore, maxAllowedScore, isValid };
};
