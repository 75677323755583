/**
 * This component is for the competition specific information for a participant. Right now,
 * it's only for judges to see some general info and the session information.
 */

import { RouteComponentProps } from '@reach/router';
import React from 'react';
import ToggleSwitch from '../../../components/Toggle/ToggleSwitch';
import { addParticipantRole, removeParticipantRole } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { UserContext } from '../../../providers/UserProvider';
import CatergoriesEntered from './CatergoriesEntered';
import SessionAvailablity from './SessionAvailability';

const CompetitionInformation: React.FunctionComponent<RouteComponentProps> = () => {
    const { user } = React.useContext(UserContext);
    const { competition } = React.useContext(CompetitionContext);

    const isCompetitionJudge = user?.competitions?.[competition?.uid]?.roles?.includes('judge');
    const isCompetitionSteward = user?.competitions?.[competition?.uid]?.roles?.includes('steward');

    const handleToggleJudge = () => {
        isCompetitionJudge
            ? removeParticipantRole(competition.uid, user.uid, 'judge')
            : addParticipantRole(competition.uid, user.uid, 'judge');
    };

    // const handleToggleSteward = () => {
    //     isCompetitionSteward
    //         ? removeParticipantRole(competition.uid, user.uid, 'steward')
    //         : addParticipantRole(competition.uid, user.uid, 'steward');
    // };

    return (
        <>
            <div className="p-4 m-2 mb-8 container-dashed-gray">
                <span className="badge-gray badge-rounded">
                    <span className="text-xl">{competition.title}</span>
                </span>
            </div>

            <h3 className="font-semibold mb-4">Your Roles</h3>
            <p>These are the ways you're involved in the competition.</p>
            <p>
                Being a judge allows the organizer to assign you to flights for judging the entries
                and determining the winners of the competition categories.
            </p>
            {/* <p>Being a steward will give you some tools to help pull entries for judging.</p> */}
            <div className="py-4 w-72 space-y-2">
                <div className="flex space-x-1 justify-between">
                    <span>I am willing to be a Judge: </span>
                    <ToggleSwitch
                        onClick={handleToggleJudge}
                        className="toggle-role toggle-is-judge"
                        checked={isCompetitionJudge}
                    />
                </div>
                {/* <div className="flex space-x-1 justify-between">
                    <span>I am willing to be a Steward: </span>
                    <ToggleSwitch
                        onClick={handleToggleSteward}
                        className="toggle-role toggle-is-stwerd"
                        checked={isCompetitionSteward}
                    />
                </div> */}
            </div>
            {/* <hr /> */}
            {isCompetitionJudge || isCompetitionSteward ? <SessionAvailablity /> : null}
            {/* <hr /> */}
            {isCompetitionJudge && <CatergoriesEntered />}
        </>
    );
};

export default CompetitionInformation;
