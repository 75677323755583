import { ICustomComparativeFlightRecord, ValidQuery } from '@flight-cap/shared';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import MinusCircleIcon from '../../../../components/Icon/MinusCircleIcon';
import QueryMetaTypeSelect from './QueryMetaTypeSelect';
import QueryMiniBosInput from './QueryMiniBosInput';
import QueryPlaceSelect from './QueryPlaceSelect';
import QueryScoreInput from './QueryScoreInput';
import QueryStyleSelect from './QueryStyleSelect';
import QueryTableSelect from './QueryTableSelect';

export enum FieldValue {
    Type = 'style_snippet.type',
    Table = 'table_snippet.uid',
    Style = 'style_snippet.uid',
    Score = 'consensus_score',
    Place = 'table_place',
    MiniBos = 'mini_bos',
}

const comparatorMap: Record<FieldValue, ValidQuery['comparator']> = {
    [FieldValue.Type]: 'in',
    [FieldValue.Table]: 'in',
    [FieldValue.Style]: 'in',
    [FieldValue.Score]: '>=',
    [FieldValue.Place]: '<=',
    [FieldValue.MiniBos]: '==',
};

const inputMap: Record<FieldValue, React.FunctionComponent<IValueInputProps>> = {
    [FieldValue.Type]: QueryMetaTypeSelect,
    [FieldValue.Table]: QueryTableSelect,
    [FieldValue.Style]: QueryStyleSelect,
    [FieldValue.Score]: QueryScoreInput,
    [FieldValue.Place]: QueryPlaceSelect,
    [FieldValue.MiniBos]: QueryMiniBosInput,
};

export const fieldLabelMap: Record<FieldValue, string> = {
    [FieldValue.Type]: 'Type is one of',
    [FieldValue.Table]: 'Table is one of',
    [FieldValue.Style]: 'Category is one of',
    [FieldValue.Score]: 'Score is or above',
    [FieldValue.Place]: 'Place is or above',
    [FieldValue.MiniBos]: 'Made Mini-BOS',
};

export interface IValueInputProps {
    name: string;
    items?: { label: string; value: string }[];
    buttonLabel?: string;
    required?: boolean;
}

const QueryFormRow: React.FunctionComponent<{
    index: number;
    onRemoveClick: (index: number) => void;
    showRemove: boolean;
}> = ({ index, onRemoveClick, showRemove }) => {
    const { register, watch, setValue } = useFormContext<ICustomComparativeFlightRecord>();

    const watchField: FieldValue = watch(`where.${index}.field`) as FieldValue;
    const ValueInput = inputMap[watchField];

    const handleFieldChanged = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(`where.${index}.value`, null);
        setValue(`where.${index}.comparator`, comparatorMap[evt.target.value as FieldValue]);
    };

    const handleRemoveClicked = () => {
        onRemoveClick(index);
    };

    return (
        <div className="mb-4 grid grid-cols-12 gap-y-1 gap-x-3">
            <select
                className="col-span-5"
                {...register(`where.${index}.field`, { onChange: handleFieldChanged })}
            >
                <option></option>
                <option value={FieldValue.Type}>{fieldLabelMap[FieldValue.Type]}</option>
                <option value={FieldValue.Table}>{fieldLabelMap[FieldValue.Table]}</option>
                <option value={FieldValue.Style}>{fieldLabelMap[FieldValue.Style]}</option>
                <option value={FieldValue.Score}>{fieldLabelMap[FieldValue.Score]}</option>
                <option value={FieldValue.Place}>{fieldLabelMap[FieldValue.Place]}</option>
                <option value={FieldValue.MiniBos}>{fieldLabelMap[FieldValue.MiniBos]}</option>
            </select>

            {watchField && (
                <input hidden type="text" readOnly {...register(`where.${index}.comparator`)} />
            )}

            {ValueInput && (
                <div className="col-span-6">
                    <ValueInput name={`where.${index}.value`} />
                </div>
            )}

            {showRemove && (
                <button
                    type="button"
                    onClick={handleRemoveClicked}
                    className="text-gray-300 hover:text-red-700"
                >
                    <MinusCircleIcon className="w-5 h-5 pointer-events-none" />
                </button>
            )}
        </div>
    );
};

export default QueryFormRow;
