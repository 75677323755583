import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { UserContext } from '../../providers/UserProvider';
import { firebaseAuth } from '../../firebase';
import {
    IUserProfile,
    JudgingOtherQualifications,
    judgingQualificationsMap,
} from '@flight-cap/shared';
import TwoColumnCard, {
    LeftColumnTitle,
    RightColumn,
    LeftColumn,
} from '../../components/Card/TwoColumnCard';
import { useFirebaseAuthError } from '../../hooks/useFirebaseAuthError';
// @TODO remove the use of this hook
import { updateDocImperative } from '../../hooks/useFirestoreUpdateDoc';
import { useForm } from 'react-hook-form';
import { SimpleToastContent } from '../../components/Toasts/ToastMessage';
import { useToasts } from 'react-toast-notifications';

interface IProfileFormData {
    email: string;
    name: string;
}

interface IPasswordFormData {
    password: string;
}

interface IBJCPFormData {
    'judging.bjcp_id': string;
    'judging.bjcp_rank': string;
    'judging.other_status.mead_judge': boolean;
    'judging.other_status.cider_judge': boolean;
    'judging.other_status.professional_brewer': boolean;
    'judging.other_status.professional_cidermaker': boolean;
    'judging.other_status.professional_meadmaker': boolean;
    'judging.other_status.cicerone': boolean;
    'judging.other_status.sommelier': boolean;
    'judging.other_status.sensory_training': boolean;
}

const UserProfile: React.FunctionComponent<
    RouteComponentProps & { children?: React.ReactNode }
> = () => {
    const { user } = React.useContext(UserContext);
    const [showPassword, setShowPassword] = React.useState(false);

    const [ProfileAuthErrorDisplay, setProfileAuthError] = useFirebaseAuthError();
    const [PasswordAuthErrorDisplay, setPasswordAuthError] = useFirebaseAuthError();

    const { addToast } = useToasts();

    const {
        register: registerProfileForm,
        handleSubmit: handleProfileSubmit,
        formState: { errors: profileFormErrors },
    } = useForm<IProfileFormData>({
        defaultValues: {
            email: user.email,
            name: user.display_name,
        },
    });

    const {
        register: registerBJCPForm,
        handleSubmit: handleJudgeInfoSubmit,
        formState: { errors: judgeInfoFormErrors },
    } = useForm<IBJCPFormData>({
        defaultValues: {
            'judging.bjcp_id': user.judging?.bjcp_id || '',
            'judging.bjcp_rank': user.judging?.bjcp_rank || '',
            'judging.other_status.mead_judge': user.judging?.other_status?.mead_judge || false,
            'judging.other_status.cider_judge': user.judging?.other_status?.cider_judge || false,
            'judging.other_status.professional_brewer':
                user.judging?.other_status?.professional_brewer || false,
            'judging.other_status.professional_cidermaker':
                user.judging?.other_status?.professional_cidermaker || false,
            'judging.other_status.professional_meadmaker':
                user.judging?.other_status?.professional_meadmaker || false,
            'judging.other_status.cicerone': user.judging?.other_status?.cicerone || false,
            'judging.other_status.sommelier': user.judging?.other_status?.sommelier || false,
            'judging.other_status.sensory_training':
                user.judging?.other_status?.sensory_training || false,
        },
    });

    const {
        register: registerPasswordForm,
        handleSubmit: handlePasswordSubmit,
        reset: resetPasswordForm,
        formState: { errors: passwordFormErrors },
    } = useForm<IPasswordFormData>();

    const onProfileSubmit = async ({ email, name }: IProfileFormData) => {
        const changes: Partial<IUserProfile> = {};

        if (user.email !== email) {
            firebaseAuth.currentUser.updateEmail(email).catch(setProfileAuthError);
            changes.email = email;
        }

        if (user.display_name !== name) {
            changes.display_name = name;
        }

        if (Object.keys(changes)) {
            try {
                await updateDocImperative(`users/${user.uid}`, changes);
                addToast(
                    <SimpleToastContent
                        title="Updated successfully!"
                        subTitle="You profile info has been updated"
                    />,
                    {
                        appearance: 'success',
                    }
                );
            } catch (err) {
                addToast(
                    <SimpleToastContent
                        title="Failed to update"
                        subTitle="Sorry, we could not update your profile."
                    />,
                    {
                        appearance: 'error',
                    }
                );
            }
        }
    };

    const onPasswordSubmit = async ({ password }: IPasswordFormData) => {
        if (password) {
            try {
                await firebaseAuth.currentUser.updatePassword(password).catch(setPasswordAuthError);
                resetPasswordForm({ password: '' });
                addToast(
                    <SimpleToastContent
                        title="Updated successfully!"
                        subTitle="You password has been updated"
                    />,
                    {
                        appearance: 'success',
                    }
                );
            } catch (err) {
                addToast(
                    <SimpleToastContent
                        title="Failed to update"
                        subTitle="Sorry, we could not update your password"
                    />,
                    {
                        appearance: 'error',
                    }
                );
            }
        }
    };

    const onJudgeInfoSubmit = async (data: IBJCPFormData) => {
        try {
            await updateDocImperative(`users/${user.uid}`, data);
            addToast(
                <SimpleToastContent
                    title="Updated successfully!"
                    subTitle="You judging info has been updated"
                />,
                {
                    appearance: 'success',
                }
            );
        } catch (err) {
            addToast(
                <SimpleToastContent
                    title="Failed to update"
                    subTitle="Sorry, we could not update your judging info."
                />,
                {
                    appearance: 'error',
                }
            );
        }
    };

    const handleShowPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowPassword(event.target.checked);
    };

    return (
        <div className="main-content-wrapper space-y-12">
            <TwoColumnCard>
                {/* left column */}
                <LeftColumn>
                    <LeftColumnTitle
                        title="Profile"
                        description="General account and contact information"
                    />
                </LeftColumn>

                {/* right column */}
                <RightColumn>
                    <form onSubmit={handleProfileSubmit(onProfileSubmit)} className="space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div className="mb-4">
                                <label htmlFor="email">Name</label>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    aria-invalid={profileFormErrors.name ? 'true' : 'false'}
                                    {...registerProfileForm('name', { required: true })}
                                ></input>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    aria-invalid={profileFormErrors.email ? 'true' : 'false'}
                                    {...registerProfileForm('email', { required: true })}
                                ></input>
                            </div>
                        </div>

                        <div className="px-4 py-3 text-right sm:px-6">
                            <button type="submit" className="btn-indigo">
                                Update Profile{' '}
                            </button>
                        </div>
                    </form>
                    <ProfileAuthErrorDisplay />
                </RightColumn>
            </TwoColumnCard>

            <TwoColumnCard>
                <LeftColumn>
                    <LeftColumnTitle
                        title="Judging Information"
                        description="Details about your judging background"
                    />
                </LeftColumn>
                <RightColumn>
                    <form onSubmit={handleJudgeInfoSubmit(onJudgeInfoSubmit)} className="space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div className="mb-4">
                                <label htmlFor="judging.bjcp_id">BJCP ID</label>
                                <input
                                    type="text"
                                    placeholder="BJCP ID"
                                    aria-invalid={
                                        judgeInfoFormErrors['judging.bjcp_id'] ? 'true' : 'false'
                                    }
                                    {...registerBJCPForm('judging.bjcp_id', { required: false })}
                                ></input>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="judging.bjcp_rank">BJCP Rank</label>
                                <select
                                    aria-invalid={
                                        judgeInfoFormErrors['judging.bjcp_rank'] ? 'true' : 'false'
                                    }
                                    {...registerBJCPForm('judging.bjcp_rank', { required: true })}
                                >
                                    <option disabled value="">
                                        Choose a Rank
                                    </option>
                                    <option value="Non-BJCP">Non-BJCP</option>
                                    <option value="Rank Pending">Rank Pending</option>
                                    <option value="Provisional">Provisional</option>
                                    <option value="Apprentice">Apprentice</option>
                                    <option value="Recognized">Recognized</option>
                                    <option value="Certified">Certified</option>
                                    <option value="National">National</option>
                                    <option value="Master">Master</option>
                                    <option value="Grand Master">Grand Master</option>
                                    <option value="Grand Master+">Grand Master ++</option>
                                    <option value="Honorary Master">Honorary Master</option>
                                </select>
                            </div>

                            <div className="mb-4">
                                <label>Other Qualifications</label>

                                {(
                                    Object.keys(
                                        judgingQualificationsMap
                                    ) as JudgingOtherQualifications[]
                                ).map((k) => (
                                    <label key={k} className="block">
                                        <input
                                            type="checkbox"
                                            className="m-2"
                                            {...registerBJCPForm(`judging.other_status.${k}`)}
                                        />
                                        {judgingQualificationsMap[k]}
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div className="px-4 py-3 text-right sm:px-6">
                            <button type="submit" className="btn-indigo">
                                Update Juding Information
                            </button>
                        </div>
                    </form>
                </RightColumn>
            </TwoColumnCard>

            <TwoColumnCard>
                {/* left column */}
                <LeftColumn>
                    <LeftColumnTitle title="Password" description="Change your password" />
                </LeftColumn>

                {/* right column */}

                <RightColumn>
                    <form onSubmit={handlePasswordSubmit(onPasswordSubmit)} className="space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            <div className="mb-4">
                                <label htmlFor="email">New Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    autoComplete="new-password"
                                    aria-invalid={passwordFormErrors.password ? 'true' : 'false'}
                                    {...registerPasswordForm('password', {
                                        required: 'A new password is required',
                                        minLength: {
                                            value: 8,
                                            message: 'New password be at least 8 characters',
                                        },
                                    })}
                                />
                                {passwordFormErrors?.password?.message && (
                                    <p className="text-sm mt-1 absolute text-red-800">
                                        {passwordFormErrors.password.message}
                                    </p>
                                )}
                            </div>
                            <div className="relative flex mt-2 items-center">
                                <div className="flex items-center h-5">
                                    <input
                                        name="show-password"
                                        type="checkbox"
                                        className="h-4 w-4"
                                        onChange={handleShowPasswordChange}
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label
                                        htmlFor="show-password"
                                        className="font-medium text-gray-700"
                                    >
                                        show-password
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 text-right sm:px-6">
                            <button type="submit" className="btn-indigo">
                                Update Password
                            </button>
                        </div>
                    </form>
                    <PasswordAuthErrorDisplay />
                </RightColumn>
            </TwoColumnCard>
        </div>
    );
};

export default UserProfile;
