import React from 'react';
import { firestore, addParticipantRole, removeParticipantRole } from '../../../firebase';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import {
    ICompetitionParticipant,
    ParticipantEnteredCategoriesMap,
    UserCompetitionRole,
} from '@flight-cap/shared';
import { StylesContext } from './StylesProvider';

type IParticipantsContext = {
    participants: ICompetitionParticipant[];
    judges: ICompetitionParticipant[];
    addRole: (userId: string, role: UserCompetitionRole) => Promise<void>;
    removeRole: (userId: string, role: UserCompetitionRole) => Promise<void>;
    enteredCategoriesMap: ParticipantEnteredCategoriesMap;
    updateCategoryEnteredIn: (
        userId: string,
        categoryId: string,
        entered: boolean
    ) => Promise<void>;
};

export const ParticipantsContext: React.Context<IParticipantsContext> =
    React.createContext<IParticipantsContext>({
        participants: null,
        judges: null,
        addRole: null,
        removeRole: null,
        enteredCategoriesMap: null,
        updateCategoryEnteredIn: null,
    });

/**
 * Provides the all the participants corresponding to the currently selected competition.
 */
const ParticipantsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [judges, setJudges] = React.useState<ICompetitionParticipant[]>(null);
    const [participants, setParticipants] = React.useState<ICompetitionParticipant[]>(null);

    const { competition } = React.useContext(CompetitionContext);

    const { styleSnippets } = React.useContext(StylesContext);

    const [enteredCategoriesMap, setEnteredCategoriesMap] =
        React.useState<ParticipantEnteredCategoriesMap>({});

    React.useEffect(() => {
        if (competition?.uid) {
            const enteredCategoriesUnsubscribe = firestore
                .doc(`competitions/${competition.uid}/indexes/participants_entered_categories`)
                .onSnapshot((result) => {
                    console.log('enteredCategoriessMap snapshot', result.data());
                    setEnteredCategoriesMap(result.data() as ParticipantEnteredCategoriesMap);
                });

            const participantsUnsubscribe = firestore
                .collection(`competitions/${competition.uid}/participants`)
                .onSnapshot((participants) => {
                    const participantObjects = participants.docs
                        .map((participant) => ({
                            uid: participant.id,
                            ...(participant.data() as ICompetitionParticipant),
                        }))
                        .sort((a, b) =>
                            a.display_name?.toLocaleLowerCase() >
                            b.display_name?.toLocaleLowerCase()
                                ? 1
                                : -1
                        );

                    setParticipants(participantObjects);

                    const judgeObjects = participantObjects.filter((p) =>
                        p.roles.includes('judge')
                    );

                    setJudges(judgeObjects);
                });

            return () => {
                participantsUnsubscribe();
                enteredCategoriesUnsubscribe();
            };
        } else {
            setJudges([]);
            setParticipants([]);
        }
    }, [competition]);

    const addRole = async (participantId: string, role: UserCompetitionRole) => {
        return addParticipantRole(competition.uid, participantId, role);
    };

    const removeRole = async (participantId: string, role: UserCompetitionRole) => {
        return removeParticipantRole(competition.uid, participantId, role);
    };

    const updateCategoryEnteredIn = async (
        userId: string,
        categoryId: string,
        isEntered: boolean
    ) => {
        // update the index
        const indexRef = firestore.doc(
            `competitions/${competition.uid}/indexes/participants_entered_categories`
        );
        const indexUpdate = {
            [userId]: {
                ...(enteredCategoriesMap?.[categoryId] || {}),
                [categoryId]: isEntered,
            },
        };

        await indexRef.set(indexUpdate, { merge: true });

        const style = styleSnippets.get(categoryId);

        // update the participant
        const particpantRef = firestore.doc(
            `competitions/${competition.uid}/participants/${userId}`
        );
        const participantDoc = await particpantRef.get();
        const participantData = participantDoc.data() as ICompetitionParticipant;
        const categories_entered = participantData.categories_entered || {};

        if (isEntered) {
            categories_entered[categoryId] = style;
        } else {
            delete categories_entered[categoryId];
        }

        return particpantRef.update({
            categories_entered,
            categories_entered_ids: Object.keys(categories_entered),
        });
    };

    return (
        <ParticipantsContext.Provider
            value={{
                participants,
                judges,
                addRole,
                removeRole,
                enteredCategoriesMap,
                updateCategoryEnteredIn,
            }}
        >
            {children}
        </ParticipantsContext.Provider>
    );
};

export default ParticipantsProvider;
