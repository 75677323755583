import React from 'react';
// import Tooltip from '../../../components/Tooltip/Tooltip';

const IconValue: React.FunctionComponent<{
    value: string | number;
    icon: React.ReactElement;
    itemName: string;
}> = ({ value, icon, itemName }) => {
    return (
        // <Tooltip
        //     className="flex"
        //     content={`${value} ${itemName}`}
        //     hoverToToggle={false}
        //     buttonClasses="cursor-default"
        // >
        <span title={`${value} ${itemName}`}>
            <span className="flex items-center space-x-1 relative">
                <span>{value}</span> {icon}
            </span>
        </span>
    );
};

export default IconValue;
