import { IFlight, IParticipantSnippet } from '@flight-cap/shared';
import React from 'react';

type ISessionInspectionContext = {
    inspectedFlight: IFlight;
    inspectFlight: (flight: IFlight) => void;
    inspectedParticipant: IParticipantSnippet;
    inspectParticipant: (session: IParticipantSnippet) => void;
    clearInspection: () => void;
};

export const SessionInspectionContext: React.Context<ISessionInspectionContext> =
    React.createContext<ISessionInspectionContext>({
        inspectedFlight: null,
        inspectedParticipant: null,
        inspectFlight: (flight: IFlight) => {},
        inspectParticipant: (session: IParticipantSnippet) => {},
        clearInspection: () => [],
    });

/**
 * Provides the all the tables corresponding to the currently selected competition.
 */
const SessionInspectionProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [inspectedState, setInspectedState] = React.useState<{
        participant: IParticipantSnippet;
        flight: IFlight;
    }>({
        participant: null,
        flight: null,
    });

    function inspectFlight(flight: IFlight) {
        setInspectedState({
            participant: null,
            flight,
        });
    }

    function inspectParticipant(participant: IParticipantSnippet) {
        setInspectedState({
            participant,
            flight: null,
        });
    }

    function clearInspection() {
        setInspectedState({
            participant: null,
            flight: null,
        });
    }

    return (
        <SessionInspectionContext.Provider
            value={{
                inspectedParticipant: inspectedState.participant,
                inspectedFlight: inspectedState.flight,
                inspectFlight,
                inspectParticipant,
                clearInspection,
            }}
        >
            {children}
        </SessionInspectionContext.Provider>
    );
};

export default SessionInspectionProvider;
