import React from 'react';
import { ToastContainerProps } from 'react-toast-notifications';

const ToastContainer: React.FunctionComponent<ToastContainerProps> = ({
    hasToasts,
    placement,
    ...props
}) => {
    return (
        <div
            className="
                react-toast-container 
                inset-0 
                fixed 
                flex 
                flex-col 
                items-center 
                justify-end 
                px-4 
                py-12 
                pointer-events-none 
                sm:p-6 
                sm:items-end 
                sm:justify-start"
            {...props}
        />
    );
};

export default ToastContainer;
