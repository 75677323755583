import React from 'react';
import { IEntry, MetaStyleType } from '@flight-cap/shared';
import { MyColumn } from '../../../components/Table/react-table-config';
import ToggleSwitch from '../../../components/Toggle/ToggleSwitch';

export type PullSheetEntryRow = IEntry & {
    isPulled: boolean;
    pullKey: string;
};

export const pullSheetEntryColumns: MyColumn<PullSheetEntryRow>[] = [
    {
        Header: 'Judging',
        accessor: 'judging_number',
        sticky: 'left',
    },
    {
        Header: 'Style',
        accessor: (d) => {
            const badgeClass = (
                {
                    beer: 'badge-yellow',
                    mead: 'badge-blue',
                    cider: 'badge-green',
                } as Record<MetaStyleType, string>
            )[d.style_snippet?.type];
            return (
                <span className={`${badgeClass} badge-rounded m-1`}>
                    {d.style_snippet?.category}
                    {d.style_snippet?.sub_category}: {d.style_snippet?.name}
                </span>
            );
        },
    },
    {
        Header: 'Pulled',
        accessor: (d) => (
            <ToggleSwitch
                className="toggle-pulled"
                data-entry-id={d.uid}
                data-pull-key={d.pullKey}
                checked={d.isPulled}
            />
        ),
        minWidth: 70,
        maxWidth: 100,
    },
];

export const customFlightPullSheetEntryColumns: MyColumn<PullSheetEntryRow>[] = [
    pullSheetEntryColumns[0], // number
    pullSheetEntryColumns[1], // style
    {
        Header: 'Table',
        accessor: (d) => d.table_snippet.title,
    },
    {
        Header: 'Score',
        accessor: (d) => d.consensus_score,
    },
    {
        Header: 'Place',
        accessor: (d) => d.table_place,
    },
    pullSheetEntryColumns[2], // pulled
];
