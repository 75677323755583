import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { TablesContext } from '../providers/TablesProvider';
import { SubmitHandler, useForm } from 'react-hook-form';
import Table from '../../../components/Table/Table';
import {
    getAttribute,
    IEntry,
    ITable,
    containsClass,
    ITableScoringFlight,
    parseCSV,
} from '@flight-cap/shared';
import Confirm, { ConfirmTheme } from '../../../components/Confirm/Confirm';
import TableEditDrawer from './TableEditDrawer';
import { useEditDeleteCell } from '../../../components/Table/renderers/EditDeleteCellRenderer';
import { awardTableColumns, nonAwardTableColumns } from './TablesTableColumns';
import { EntriesContext } from '../providers/EntriesProvider';
import { usePrinter } from '../../../hooks/usePrinter';
import TableMiniBosPrintLayout from './TableMiniBosPrintLayout';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { FlightsContext } from '../providers/FlightsProvider';
import UploadIcon from '../../../components/Icon/UploadIcon';
import { StylesContext } from '../providers/StylesProvider';
import TablePullSheetPrintLayout from './TablePullSheetPrintLayout';

interface IFormData {
    title: string;
}

export type TableRow = ITable & {
    entries: IEntry[];
    downloadSheetsUrl: string;
    scoringFlights: ITableScoringFlight[];
};

const TablesAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const { tables, addTable, deleteTable, updateTableStylesMap } = React.useContext(TablesContext);
    const { entries } = React.useContext(EntriesContext);
    const { competition } = React.useContext(CompetitionContext);
    const { flights, addTableScoringFlight } = React.useContext(FlightsContext);
    const { styleMigrations } = React.useContext(StylesContext);

    const [PrintPortal, printPage] = usePrinter();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IFormData>();

    const [tableForPrint, setTableForPrint] = React.useState<{
        table: TableRow;
        type: 'mini-bos' | 'pull-sheet';
    }>(null);

    const [handleEditDeleteTableClicked, tableForDeletion, tableForUpdate] =
        useEditDeleteCell<ITable>(tables);

    const rows: TableRow[] = React.useMemo(
        () =>
            tables
                ? tables.map((t) => ({
                      ...t,
                      entries: entries ? entries.filter((e) => e.table_snippet?.uid === t.uid) : [],
                      downloadSheetsUrl: `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}pdfs/competition/${competition?.uid}/table/${t.uid}`,
                      scoringFlights: (flights
                          ? flights.filter(
                                (f) => f.type === 'table_scoring' && f.table_snippet?.uid === t.uid
                            )
                          : []) as ITableScoringFlight[],
                  }))
                : [],
        [competition, entries, tables, flights]
    );

    const awardRows = rows.filter((t) => t.allow_awards) || [];
    const nonAwardRows = rows.filter((t) => !t.allow_awards) || [];

    const handleTableClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            handleEditDeleteTableClicked(evt);

            if (containsClass(evt.target, 'btn-add-scoring-flight')) {
                const tableId = getAttribute(evt.target, 'data-table-uid');
                console.log('add scoring flight to table', tableId);
                addTableScoringFlight(String(tableId));
            }

            if (containsClass(evt.target, 'btn-pull-sheet-print-table')) {
                const tableId = getAttribute(evt.target, 'data-table-uid');
                const table = rows.find((t) => t.uid === tableId);
                setTableForPrint({ table, type: 'pull-sheet' });
                printPage(`${table.title} Pull Sheet`);
            }

            if (containsClass(evt.target, 'btn-pull-sheet-print-mini-bos')) {
                const tableId = getAttribute(evt.target, 'data-table-uid');
                const table = rows.find((t) => t.uid === tableId);
                setTableForPrint({ table, type: 'mini-bos' });
                printPage(`${table.title} Mini-Bos`);
            }
        },
        [handleEditDeleteTableClicked, rows, printPage, addTableScoringFlight]
    );

    const onSubmit: SubmitHandler<IFormData> = async (data) => {
        await addTable({
            ...data,
            categories: {},
            category_ids: [],
            entry_count: 0,
            scoring_flight_count: 0,
            allow_awards: true,
        });
        reset();
    };

    const handleDeleteConfirm = () => {
        deleteTable(tableForDeletion.get().uid);
        tableForDeletion.set(null);
    };

    const handleDeleteCancel = () => {
        tableForDeletion.set(null);
    };

    const handleDrawerClose = () => {
        tableForUpdate.set(null);
    };

    /**
     * Note(jgreene): this is a bit of hack to make pulling tables from BCOEM easier
     */
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        const file = files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', async ({ target: { result } }) => {
                event.target.value = null;
                const entryRows = parseCSV(String(result));
                const tableMap = new Map<string, Set<string>>();

                for (const row of entryRows) {
                    if (tableMap.has(row.Table)) {
                    } else {
                        tableMap.set(row.Table, new Set());
                    }

                    const paddedCategory =
                        row.Category.length === 1 ? `0${row.Category}` : row.Category;
                    const set = tableMap.get(row.Table);
                    const styleUid = `${paddedCategory}${row.SubCategory}`;
                    set.add(styleMigrations[styleUid] || styleUid);
                }

                for (const [title, styleSet] of Object.entries(Object.fromEntries(tableMap))) {
                    console.log('create table: ', title, 'with categories', Array.from(styleSet));
                    const categoryMap: Record<string, boolean> = {};
                    styleSet.forEach((styleId) => {
                        categoryMap[styleId] = true;
                    });

                    const tableData = await addTable({
                        title,
                        categories: {},
                        category_ids: [],
                        entry_count: 0,
                        scoring_flight_count: 0,
                        allow_awards: true,
                    });
                    await updateTableStylesMap(tableData.uid, categoryMap);
                }
            });
            reader.readAsText(file, 'utf-8');
        }
    };

    return (
        <>
            {tables?.length === 0 && (
                <div className="flex justify-end">
                    <label
                        className="btn btn-indigo btn-outline flex items-center px-4 py-6 m-0 cursor-pointer w-auto"
                        title="import entries from a BCOEM CVS file"
                    >
                        <UploadIcon className="w-2 h-2" />
                        <span className="ml-2">Import tables from BCOEM entries export</span>
                        <input
                            type="file"
                            className="hidden"
                            accept="text/csv"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
            )}
            <div className="print:hidden">
                <div className="px-4 sm:px-0 grid grid-cols-2">
                    <div className="col-span-full md:col-span-1">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Add a table</h3>
                        <div className="mt-2 text-sm text-gray-500">
                            <p>A table is an award grouping for entries.</p>
                        </div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="mt-5 sm:flex sm:items-center"
                        >
                            <div className="w-full sm:w-3/5">
                                <label htmlFor="title" className="sr-only">
                                    Table Name
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    autoComplete="off"
                                    {...register('title', { required: true })}
                                    aria-invalid={errors.title ? 'true' : 'false'}
                                    placeholder="Table Name"
                                />
                            </div>
                            <button
                                type="submit"
                                className="mt-3 w-full btn-indigo items-center justify-center sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                Add Table
                            </button>
                        </form>
                    </div>
                </div>
                <hr className="my-8" />
                <div onClick={handleTableClicked} className="space-y-12 mb-12">
                    <div>
                        {awardRows.length > 0 ? (
                            <Table columns={awardTableColumns} data={awardRows} />
                        ) : (
                            <div className="container-dashed-gray">
                                There are no Award Tables yet!
                            </div>
                        )}
                    </div>
                    {nonAwardRows.length > 0 && (
                        <div>
                            <p className="mb-4 block">Non-Award Tables</p>
                            <Table columns={nonAwardTableColumns} data={nonAwardRows} />
                        </div>
                    )}
                </div>
                <Confirm
                    theme={ConfirmTheme.Danger}
                    title={`Delete Table`}
                    onConfirm={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                    show={tableForDeletion.get() ? true : false}
                >
                    <p className="text-sm text-gray-500">
                        Are you sure you want to delete the table:{' '}
                        <span className="text-red-700">{tableForDeletion.get()?.title}</span>? All
                        the flights and awards associated with this table will also be deleted.
                    </p>
                </Confirm>
                <TableEditDrawer onCancel={handleDrawerClose} table={tableForUpdate.get()} />
            </div>
            {tableForPrint && (
                <PrintPortal>
                    {tableForPrint.type === 'mini-bos' ? (
                        <TableMiniBosPrintLayout table={tableForPrint.table} />
                    ) : null}
                    {tableForPrint.type === 'pull-sheet' ? (
                        <TablePullSheetPrintLayout table={tableForPrint.table} />
                    ) : null}
                </PrintPortal>
            )}
        </>
    );
};

export default TablesAdmin;
