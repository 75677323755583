import React, { Fragment } from 'react';
import { StylesContext } from '../providers/StylesProvider';
import { ITable } from '@flight-cap/shared';
import { useFormContext } from 'react-hook-form';

enum DisplayFlag {
    Taken = 'taken',
    Empty = 'empty',
    Disabled = 'disabled',
}
type DisplayFlagMap = Record<DisplayFlag, boolean>;

const StyleSelection: React.FunctionComponent<{ table: ITable }> = ({ table }) => {
    const { styles } = React.useContext(StylesContext);
    const { register } = useFormContext();

    let category = '';

    const [displayFlags, setDisplayFlags] = React.useState<DisplayFlagMap>({
        taken: false,
        empty: false,
        disabled: false,
    });

    const getDisplayedItems = React.useCallback(
        (flags: DisplayFlagMap) => {
            let displayMe = styles;

            for (const [key, value] of Object.entries(flags)) {
                if (value === false) {
                    if (key === DisplayFlag.Taken) {
                        displayMe = displayMe.filter(
                            (item) =>
                                (flags.disabled || item.enabled) &&
                                (!item.table_snippet?.uid || item.table_snippet.uid === table?.uid)
                        );
                    }

                    if (key === DisplayFlag.Empty) {
                        displayMe = displayMe.filter(
                            (item) =>
                                (flags.disabled || item.enabled) &&
                                item.enabled &&
                                item.entry_count > 0
                        );
                    }
                }
            }

            return displayMe;
        },
        [styles, table?.uid]
    );

    const [displayedItems, setDisplayedItems] = React.useState(getDisplayedItems(displayFlags));

    const handleDisplayFlagChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const flags = { ...displayFlags };
        flags[evt.target.value as DisplayFlag] = evt.target.checked;
        setDisplayFlags(flags);
    };

    const hiddenCount = styles.length - displayedItems.length;

    React.useEffect(() => {
        setDisplayedItems(getDisplayedItems(displayFlags));
    }, [styles, displayFlags, setDisplayedItems, getDisplayedItems]);

    return (
        <div>
            <div
                className="flex items-center space-x-4 mt-4 ml-2"
                onChange={handleDisplayFlagChange}
            >
                <label className="flex items-center m-0">
                    <input
                        className="mr-1"
                        type="checkbox"
                        value={DisplayFlag.Taken}
                        checked={displayFlags.taken}
                        readOnly
                    />
                    <span className="bg-red-100 rounded px-1">Show Taken</span>
                </label>
                <label className="flex items-center m-0">
                    <input
                        className="mr-1"
                        type="checkbox"
                        value={DisplayFlag.Empty}
                        checked={displayFlags.empty}
                        readOnly
                    />
                    <span className="bg-yellow-100 rounded px-1">Show Empty</span>
                </label>
                <label className="flex items-center m-0">
                    <input
                        className="mr-1"
                        type="checkbox"
                        value={DisplayFlag.Disabled}
                        checked={displayFlags.disabled}
                        readOnly
                    />
                    <span className="bg-gray-100 text-red-800 rounded px-1">Include Disabled</span>
                </label>
                {hiddenCount > 0 && (
                    <span className="text-gray-500 text-sm">({hiddenCount} styles hidden)</span>
                )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-2 text-xs">
                {displayedItems.map((style) => {
                    const tableName = style.table_snippet?.uid ? style.table_snippet.title : null;
                    let showHeader = false;
                    if (style.category !== category) {
                        category = style.category;
                        showHeader = true;
                    }

                    const taken = tableName && style.table_snippet.uid !== table?.uid;
                    const empty = style.entry_count === 0;
                    let bgStyle = '';

                    if (!style.enabled) {
                        bgStyle = 'bg-gray-100';
                    } else if (taken) {
                        bgStyle = 'bg-red-100';
                    } else if (empty) {
                        bgStyle = 'bg-yellow-100';
                    }

                    return (
                        <Fragment key={style.uid}>
                            {showHeader && (
                                <h1
                                    key={`header-${style.uid}`}
                                    className="col-start-1 col-span-4 text-base text-gray-800 border-b pb-1 mb-1 mt-8"
                                    style={{ gridColumn: '1/-1' }}
                                >
                                    {style.category}{' '}
                                    {style.category_name && <>: {style.category_name}</>}
                                </h1>
                            )}
                            <label
                                key={style.uid}
                                className={`flex items-center p-2 rounded ${bgStyle}`}
                            >
                                <input
                                    type="checkbox"
                                    className={`mr-2 ${!style.enabled ? 'cursor-not-allowed' : ''}`}
                                    disabled={!style.enabled}
                                    {...register(`categoriesFormMap.${style.uid}`)}
                                />
                                <div className={`flex flex-col`}>
                                    <span className="text-gray-500 text-sm mr-2">
                                        {style.category}
                                        {style.sub_category}{' '}
                                        <span className="truncate">{style.name}</span>
                                    </span>

                                    <span className="text-xs text-gray-500">
                                        {style.entry_count || 0}{' '}
                                        {style.entry_count === 1 ? 'Entry' : 'Entries'}
                                    </span>

                                    <span className="text-xs text-gray-500">
                                        Table: {tableName ? tableName : 'Not Assigned'}
                                    </span>

                                    {!style.enabled && (
                                        <span className="text-xs text-red-800 mt-1">
                                            Style Disabled
                                        </span>
                                    )}
                                </div>
                            </label>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default StyleSelection;
