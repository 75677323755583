import React from 'react';
import { firestore } from '../firebase';
import { IFlags } from '@flight-cap/shared';

interface IFlagsContext {
    flags: IFlags;
    resolved: boolean;
}

export const FlagsContext: React.Context<IFlagsContext> = React.createContext<IFlagsContext>({
    flags: null,
    resolved: false,
});

const FlagsProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [context, setContext] = React.useState<IFlagsContext>(null);

    React.useEffect(() => {
        return firestore.doc('indexes/flags').onSnapshot((snap) => {
            if (snap.exists) {
                const flagData = snap.data() as IFlags;
                setContext({
                    flags: flagData,
                    resolved: true,
                });
            } else {
                console.warn('Could not load flags');
                setContext({
                    flags: {} as IFlags,
                    resolved: true,
                });
            }
        });
    }, []);

    return <FlagsContext.Provider value={{ ...context }}>{children}</FlagsContext.Provider>;
};

export default FlagsProvider;
