import React from 'react';
import { MyColumn } from '../../../components/Table/react-table-config';
import { IEntry, IScoreSheetData } from '@flight-cap/shared';
import DangerIcon from '../../../components/Icon/DangerIcon';
import { CellProps } from 'react-table';
import EditDeleteCellRenderer from '../../../components/Table/renderers/EditDeleteCellRenderer';
import DownloadIcon from '../../../components/Icon/DownloadIcon';
import ChevronRightIcon from '../../../components/Icon/ChevronRightIcon';
import { Link } from '@reach/router';
import EntryDetails from '../../../components/EntryDetails/EntryDetails';
import SheetNeedsAttention from '../../../components/SheetNeedsAttention/SheetNeedsAttention';

export interface IEntryRow extends IEntry {
    unrecognizedStyle: boolean;
    downloadSheetsUrl: string;
    sheetCount: number;
    sheetsNeedingAttention: IScoreSheetData['needs_attention'][];
}

export const entryColumns: MyColumn<IEntryRow>[] = [
    {
        Header: 'Judging',
        accessor: (d) => (
            <span className={`${d.disqualified ? 'bg-yellow-300 text-red-800' : ''} p-1 rounded`}>
                {d.judging_number}
            </span>
        ),
        sticky: 'left',
        width: 120,
        minWidth: 120,
        maxWidth: 200,
    },
    {
        Header: 'Entry',
        accessor: 'entry_number',
        width: 120,
        minWidth: 120,
        maxWidth: 200,
    },
    {
        Header: 'Style',
        accessor: (d) => (
            <span className={d.unrecognizedStyle ? 'badge-red' : ''}>
                {d.style_snippet.uid.toUpperCase()}
                {': '}
                {d.style_snippet?.name}
                {d.unrecognizedStyle ? <DangerIcon className="ml-2" /> : <></>}
            </span>
        ),
        sortType: (a, b) => {
            const titleA = a.original.style_snippet.uid;
            const titleB = b.original.style_snippet.uid;
            return titleA > titleB ? 1 : -1;
        },
        width: 250,
        minWidth: 150,
        maxWidth: 400,
    },

    {
        Header: 'Table',
        accessor: (d) => {
            if (d.table_override_id) {
                return (
                    <div className="flex flex-col">
                        <div className="badge-blue badge-rounded">{d.table_snippet?.title}</div>
                    </div>
                );
            }

            if (d.table_snippet?.uid) {
                return <div className="badge-gray badge-rounded">{d.table_snippet.title}</div>;
            }

            return (
                <div className="badge-yellow badge-rounded">
                    <DangerIcon className="w-2 h-2 mr-2" />
                    Not Assigned
                </div>
            );
        },
        sortType: (a, b) => {
            const titleA = a.original.table_snippet?.title || 'zzz';
            const titleB = b.original.table_snippet?.title || 'zzz';
            return titleA > titleB ? 1 : -1;
        },
    },
    {
        Header: 'Score',
        accessor: (d) => d.consensus_score,
        width: 120,
        minWidth: 120,
        maxWidth: 200,
    },
    {
        Header: 'Mini-BOS',
        accessor: (d) =>
            d.mini_bos === true ? (
                <span className="badge-green badge-rounded">Yes</span>
            ) : d.mini_bos === false ? (
                <span className="badge-red badge-rounded">No</span>
            ) : (
                <></>
            ),
        sortType: (a, b) => {
            return a.original.mini_bos < b.original.mini_bos ? 1 : -1;
        },
        width: 130,
        minWidth: 130,
        maxWidth: 200,
    },
    {
        Header: 'Place',
        accessor: 'table_place',
    },
    {
        Header: 'Scoresheets',
        accessor: (d) =>
            d.sheetCount ? (
                <a
                    href={d.downloadSheetsUrl}
                    target="entry_pdf"
                    className={`badge badge-rounded ${
                        d.sheetsNeedingAttention.length ? 'badge-yellow' : 'badge-gray'
                    }`}
                >
                    <DownloadIcon className="mr-2" />
                    {d.sheetCount} scoresheet{d.sheetCount === 1 ? '' : 's'}
                    {d.sheetsNeedingAttention.length ? (
                        <SheetNeedsAttention
                            // combine the reasons of all suspect sheets into a single array
                            reasons={d.sheetsNeedingAttention.reduce((prev, current) => {
                                for (const reason of current) {
                                    if (!prev.includes(reason)) {
                                        prev.push(reason);
                                    }
                                }
                                return prev;
                            }, d.sheetsNeedingAttention[0])}
                        />
                    ) : (
                        <></>
                    )}
                </a>
            ) : (
                <></>
            ),
        sortType: (a, b) => {
            if (
                a.original.sheetsNeedingAttention.length ===
                b.original.sheetsNeedingAttention.length
            ) {
                if (a.original.sheetCount === b.original.sheetCount) {
                    return a.original.judging_number > b.original.judging_number ? -1 : 1;
                } else {
                    return a.original.sheetCount > b.original.sheetCount ? -1 : 1;
                }
            } else {
                return a.original.sheetsNeedingAttention.length >
                    b.original.sheetsNeedingAttention.length
                    ? -1
                    : 1;
            }
        },
        width: 300,
        maxWidth: 300,
    },
    {
        Header: 'Additional Information',
        accessor: (d) => {
            return (
                <span className="text-sm">
                    <EntryDetails entry={d} />
                </span>
            );
        },
        width: 300,
    },

    // {
    //     Header: 'Entrant',
    //     accessor: (d) => (
    //         <a href={`mailto:${d.entrant_snippet.email}`}>
    //             {d.entrant_snippet.last_name}, {d.entrant_snippet.first_name}
    //         </a>
    //     ),
    //     width: 200,
    //     minWidth: 200,
    //     maxWidth: 300,
    // },
    {
        Header: 'Disqualified',
        accessor: (d) =>
            d.disqualified === true && <span className="badge-red badge-rounded">Yes</span>,
        sortType: (a, b) => {
            return !!a.original.disqualified > !!b.original.disqualified ? 1 : -1;
        },
        width: 130,
        minWidth: 130,
        maxWidth: 200,
    },
    {
        Header: 'Actions',
        accessor: null,
        Cell: ({ row: { original } }: CellProps<IEntryRow>) => (
            <>
                <EditDeleteCellRenderer value={original.uid} />
                <Link to={`./${original.uid}`} className="btn-outline btn-gray">
                    <ChevronRightIcon />
                </Link>
            </>
        ),
        disableSortBy: true,
        width: 200,
        minWidth: 200,
        maxWidth: 300,
        sticky: 'right',
        className: 'unstick-large',
    },
];
