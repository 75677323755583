import React from 'react';
import { useFormContext } from 'react-hook-form';

const FormNumberInput: React.FunctionComponent<{
    name: string;
    min?: number;
    max?: number;
    required?: boolean | string;
    className?: string;
}> = ({ name, className, min, max, required }) => {
    const { register } = useFormContext();

    return (
        <div className={className}>
            <input
                autoComplete="off"
                type="number"
                min={min}
                max={max}
                required={required === true}
                {...register(name, {
                    validate: (data: any) => (required && data > 0 ? true : required),
                    pattern: {
                        value: /^[0-9]+$/,
                        message: 'Please enter a number',
                    },
                    setValueAs: (v: string) => parseInt(v) || null,
                })}
            />
        </div>
    );
};
export default FormNumberInput;
