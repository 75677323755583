import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { IFlight } from '@flight-cap/shared';
import { SessionEditorDragType } from '../sessions/SessionEditor';

export interface IDraggableFlightData {
    type: SessionEditorDragType.Flight;
    flight: IFlight;
    flightSessionId: string;
}

export const DraggableFlight: React.FunctionComponent<{
    flight: IFlight;
    children: React.ReactElement;
}> = ({ flight, children }) => {
    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
        id: `draggable-flight-${flight.uid}`,
        data: {
            type: SessionEditorDragType.Flight,
            flight: flight,
            flightSessionId: flight.session_id,
        } as IDraggableFlightData,
    });

    return (
        <span
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            className={`touch-manipulation inline-block transition-all !select-none ${
                isDragging ? 'opacity-30' : ''
            }`}
        >
            {children}
        </span>
    );
};
