import { useMatch } from '@reach/router';
import React from 'react';
import { FlightsContext } from '../FlightsProvider';
import CustomComparativeFlightEntriesProvider from './CustomComparativeFlightEntriesProvider';
import TableComparativeFlightEntriesProvider from './TableComparativeFlightEntriesProvider';
import TableScoringFlightEntriesProvider from './TableScoringFlightEntriesProvider';

const FlightEntriesProvider: React.FunctionComponent<{
    competitionId: string;
    children: React.ReactNode;
}> = ({ children, competitionId }) => {
    const routeParams = useMatch('/:competitionSlug/judging/flight/:flightId/*');
    const { flights } = React.useContext(FlightsContext);
    const flight = routeParams?.flightId
        ? flights?.find((f) => f.uid === routeParams.flightId)
        : null;

    if (flight && flight.type === 'table_scoring') {
        return (
            <TableScoringFlightEntriesProvider flight={flight} competitionId={competitionId}>
                {children}
            </TableScoringFlightEntriesProvider>
        );
    }

    if (flight && flight.type === 'table_comparative') {
        return (
            <TableComparativeFlightEntriesProvider flight={flight} competitionId={competitionId}>
                {children}
            </TableComparativeFlightEntriesProvider>
        );
    }

    if (flight && flight.type === 'custom_comparative') {
        return (
            <CustomComparativeFlightEntriesProvider flight={flight} competitionId={competitionId}>
                {children}
            </CustomComparativeFlightEntriesProvider>
        );
    }

    return <>{children}</>;
};

export default FlightEntriesProvider;
