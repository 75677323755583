import React from 'react';
import { Router, Redirect, RouteComponentProps, navigate } from '@reach/router';
import SignUp from './SignUp';
import { ApplicationRoutes } from '../../routes';
import SignIn from './SignIn';
import RecoverPassword from './RecoverPassword';
import RecoverPasswordProvider from '../../providers/RecoverPasswordProvider';
import { UserContext } from '../../providers/UserProvider';

const Auth: React.FunctionComponent<RouteComponentProps> = () => {
    const { user } = React.useContext(UserContext);

    React.useEffect(() => {
        if (user) {
            const queryParams = new URLSearchParams(window.location.search);
            const redirect = queryParams.get('redirect');
            const sendEm = redirect || ApplicationRoutes.Dashboard;
            navigate(sendEm);
        }
    }, [user]);

    return (
        <RecoverPasswordProvider>
            <section className="flex justify-center">
                <div className="flex flex-col w-full max-w-sm">
                    <Router>
                        <SignUp path="/sign-up" />
                        <SignIn path="/sign-in" />
                        <RecoverPassword path="/recover-password" />
                        <Redirect from="/" to={ApplicationRoutes.SignUp} default noThrow />
                    </Router>
                </div>
            </section>
        </RecoverPasswordProvider>
    );
};

export default Auth;
