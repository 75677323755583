import { IFlight } from '@flight-cap/shared';
import React from 'react';
import BoltIcon from '../../../../components/Icon/BoltIcon';
import UserIcon from '../../../../components/Icon/UserIcon';
import CategoryList from '../CategoryList';
import JudgeDropZone from '../dnd/DropZoneJudges';
import IconValue from '../IconValue';
import FlightLoad from './FlightLoad';
import FlightMeatballMenu from './FlightMeatballMenu';
import JudgeCoverage from '../JudgeCoverage';

const FlightThumbnail: React.FunctionComponent<{
    flight: IFlight;
    className?: string;
}> = ({ flight, className = '' }) => {
    const isTableFlight = flight.type === 'table_scoring' || flight.type === 'table_comparative';

    return (
        <JudgeDropZone
            flight={flight}
            className={`${className} border border-gray-500 h-full bg-white flex`}
        >
            <div className="flex border-r border-gray-150">
                <JudgeCoverage
                    coverage={flight.coverage}
                    error={flight.ineligible_judge_count > 0}
                />
            </div>

            <div className="p-2 grow">
                <header className="flex justify-between items-baseline space-x-5">
                    <div className="text-sm w-[100%-40px]">{flight.name()}</div>
                    <div className="text-sm flex space-x-2 items-center">
                        <FlightLoad flight={flight} />
                        {flight.judge_ids.length > 0 && (
                            <IconValue
                                value={flight.judge_ids.length}
                                itemName={flight.judge_ids.length === 1 ? 'judge' : 'judges'}
                                icon={<UserIcon />}
                            />
                        )}
                        <FlightMeatballMenu flight={flight} />
                    </div>
                </header>
                {isTableFlight && (
                    <section className="flex justify-between mt-2">
                        <CategoryList categoryIds={flight.table_snippet?.category_ids} />
                        <BoltIcon
                            className={
                                flight.active ? 'fill-indigo-500 border-indigo-800' : 'fill-white'
                            }
                        />
                    </section>
                )}
            </div>
        </JudgeDropZone>
    );
};

export default FlightThumbnail;
