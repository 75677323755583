import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { StylesContext } from '../providers/StylesProvider';
import { getAttribute, ICompetitionStyle, IStyleSnippet, MetaStyleType } from '@flight-cap/shared';
import { EntriesContext } from '../providers/EntriesProvider';

const UnrecognizedStyles: React.FunctionComponent<RouteComponentProps> = () => {
    const { styles, addStyle, addStyleMigration, styleMigrations } =
        React.useContext(StylesContext);
    const { entries } = React.useContext(EntriesContext);

    const [styleType, setStyleType] = React.useState<MetaStyleType>('beer');

    const styleIdSet = React.useMemo(() => {
        const ids = new Map<string, ICompetitionStyle>();
        styles && styles.forEach((s) => ids.set(s.uid, s));
        return ids;
    }, [styles]);

    const styleMap = React.useMemo(() => {
        const custom: ICompetitionStyle[] = [];
        const bjcp: ICompetitionStyle[] = [];

        if (styles) {
            for (const style of styles) {
                if (style.source === 'bjcp') {
                    bjcp.push(style);
                } else if (style.source === 'custom') {
                    custom.push(style);
                }
            }
        }

        return { custom, bjcp };
    }, [styles]);

    const unrecognizedStyles = React.useMemo(() => {
        const map = new Map<string, IStyleSnippet>();
        if (entries) {
            for (const entry of entries) {
                const styleId = entry.style_snippet.uid;
                if (!styleMigrations[styleId] && !styleIdSet.has(styleId)) {
                    map.set(styleId, entry.style_snippet);
                }
            }
        }
        return Array.from(map.values()).sort((a, b) => (a.uid > b.uid ? 1 : -1));
    }, [entries, styleIdSet, styleMigrations]);

    const handleStyleTypeChanged = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        setStyleType(evt.target.value as MetaStyleType);
    };

    const handleAssignStyleClicked = React.useCallback(
        async (evt: React.MouseEvent) => {
            const unrecognizedStyleId = String(getAttribute(evt.target, 'data-unrec-style-uid'));
            const unrecognizedStyleSelect = document.getElementById(
                `unrec-style-assign-select-${unrecognizedStyleId}`
            ) as HTMLSelectElement;
            const selectedStyleId = unrecognizedStyleSelect.value;

            if (selectedStyleId === 'create') {
                const unrecognizedStyle = unrecognizedStyles.find(
                    (s) => s.uid === unrecognizedStyleId
                );

                if (!unrecognizedStyle) {
                    console.warn('cannot find unrecognized style', unrecognizedStyleId);
                    return;
                }

                const newStyle = await addStyle({
                    name: unrecognizedStyle.name,
                    type: styleType || unrecognizedStyle.type,
                });

                addStyleMigration(unrecognizedStyleId, newStyle.uid);
            } else {
                addStyleMigration(unrecognizedStyleId, selectedStyleId);
            }
        },
        [addStyle, addStyleMigration, unrecognizedStyles, styleType]
    );

    return unrecognizedStyles.length > 0 ? (
        <div className="mb-4">
            <div className="mb-4">
                There are some entries that do not have a recognized style associated with them
            </div>
            <ul className="grid grid-cols-1 sm:grid-cols-2">
                {unrecognizedStyles.map(({ uid, name }) => (
                    <li key={uid} className="p-4 m4 border items-center justify-between">
                        <div>
                            {uid}: {name}
                        </div>
                        <div className="relative z-0 inline-flex shadow-sm rounded-md h-min w-full">
                            <select
                                className="rounded-r-none"
                                id={`unrec-style-assign-select-${uid}`}
                            >
                                <optgroup label="Create new style">
                                    <option value={'create'}>Create Style: {name}</option>
                                </optgroup>
                                {styleMap.custom.length > 0 && (
                                    <optgroup label="Assign custom style">
                                        {styleMap.custom.map(({ uid, name }) => (
                                            <option key={uid} value={uid}>
                                                {name}
                                            </option>
                                        ))}
                                    </optgroup>
                                )}
                                <optgroup label="Assign a BJCP style">
                                    {styleMap.bjcp.map(({ uid, name }) => (
                                        <option key={uid} value={uid}>
                                            {name}
                                        </option>
                                    ))}
                                </optgroup>
                            </select>

                            <select
                                className="-ml-px rounded-none w-36"
                                value={styleType}
                                onChange={handleStyleTypeChanged}
                            >
                                <option value={'beer'}>Beer</option>
                                <option value={'cider'}>Cider</option>
                                <option value={'mead'}>Mead</option>
                            </select>

                            <button
                                data-unrec-style-uid={uid}
                                onClick={handleAssignStyleClicked}
                                className="-ml-px text-indigo-700 relative inline-flex items-center justify-center px-2 py-2 rounded-none rounded-r-md border border-gray-300 bg-white w-48"
                            >
                                Assign Style
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
};

export default UnrecognizedStyles;
