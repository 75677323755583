import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Table from '../../../components/Table/Table';
import { StylesContext } from '../providers/StylesProvider';
import { deleteStyleColumn, stylesColumns } from './StylesTable.columns';
import { containsClass, getAttribute, ICompetitionStyle } from '@flight-cap/shared';
import AddCustomStyleQuick from './QuickAddCustomStyle';
import QuickEnable from './QuickEnable';
import { useEditDeleteCell } from '../../../components/Table/renderers/EditDeleteCellRenderer';
import DeleteStyleConfirm from './DeleteStyleConfirm';
import StylesEditDrawer from './StylesEditDrawer';
import UnrecognizedStyles from './UnrecognizedEntryStyles';

const customStyleColumns = [...stylesColumns, deleteStyleColumn];

const StylesAdmin: React.FunctionComponent<RouteComponentProps> = () => {
    const { styles, updateStyles } = React.useContext(StylesContext);

    const [showDisabled, setShowDisabeld] = React.useState(true);

    const allRows = React.useMemo(() => styles || [], [styles]);

    const bjcpRows = React.useMemo(() => {
        if (showDisabled) {
            return allRows.filter((s) => s.source === 'bjcp') || [];
        }
        return allRows.filter((s) => s.source === 'bjcp' && s.enabled) || [];
    }, [allRows, showDisabled]);

    const customRows = React.useMemo(() => {
        if (showDisabled) {
            return allRows.filter((s) => s.source === 'custom') || [];
        }
        return allRows.filter((s) => s.source === 'custom' && s.enabled) || [];
    }, [allRows, showDisabled]);

    const hiddenRowCount = allRows.length - bjcpRows.length - customRows.length;

    const handleDisplayFlagChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setShowDisabeld(evt.target.checked);
    };

    const [handleTableClickedEditDelete, itemForDeletion, itemForUpdate] =
        useEditDeleteCell<ICompetitionStyle>(customRows);

    const handleTableClicked = React.useCallback(
        (evt: React.MouseEvent) => {
            handleTableClickedEditDelete(evt);
            if (containsClass(evt.target, 'toggle-style-enabled')) {
                const styleId = String(getAttribute(evt.target, 'data-style-id'));
                const style = allRows.find((p) => p.uid === styleId);
                updateStyles([styleId], { enabled: !style.enabled });
            }
        },
        [allRows, handleTableClickedEditDelete, updateStyles]
    );

    const handleDrawerClose = () => {
        itemForUpdate.set(null);
    };

    return (
        <>
            <div onClick={handleTableClicked}>
                <div className="mb-2">
                    <QuickEnable />
                </div>

                <div className="flex my-4 justify-end" onChange={handleDisplayFlagChange}>
                    <label className="flex items-center m-0">
                        <input
                            className="mr-1"
                            type="checkbox"
                            value={'showDisabled'}
                            checked={showDisabled}
                            readOnly
                        />
                        <span className="rounded px-1">Show Disabled Styles</span>
                    </label>
                    {hiddenRowCount > 0 && (
                        <span className="text-gray-500 text-sm">
                            ({hiddenRowCount} styles hidden)
                        </span>
                    )}
                </div>

                <div className="mb-2">
                    <h3 className="my-4">BJCP Styles</h3>
                </div>

                <Table columns={stylesColumns} data={bjcpRows} rowSelection={false} />
                <hr className="my-8" />
                <UnrecognizedStyles />
                <div className="mb-4">
                    <AddCustomStyleQuick />
                </div>
                <div className="flex justify-between items-center mb-2">
                    <h3 className="my-4">Custom Styles</h3>
                </div>
                <Table columns={customStyleColumns} data={customRows} rowSelection={false} />
            </div>

            <DeleteStyleConfirm itemForDeletion={itemForDeletion} />
            <StylesEditDrawer
                style={itemForUpdate.get()}
                onCancel={handleDrawerClose}
            ></StylesEditDrawer>
        </>
    );
};

export default StylesAdmin;
