import { containsClass, getAttribute, IFlight } from '@flight-cap/shared';
import { navigate } from '@reach/router';
import React from 'react';
import DescriptorForm from '../DescriptorForm';
import { EntryContext } from '../providers/EntryProvider';
import StyleInformation from '../StyleInformation';
import { DescriptorChangedHandler } from './FlightDetail';
import FlightSummary from './FlightSummary';

enum TabType {
    FlightSummary = 'flight_summary',
    StyleGuide = 'style_guide',
    Descriptors = 'descriptors',
}

interface TabData {
    type: TabType;
    label: string;
}

const tabs: TabData[] = [
    {
        type: TabType.FlightSummary,
        label: 'Flight',
    },
    {
        type: TabType.StyleGuide,
        label: 'Style Guide',
    },
    {
        type: TabType.Descriptors,
        label: 'Descriptors',
    },
];

const DetailsMenu: React.FunctionComponent<{
    onTabClick: (tab: TabType) => void;
    selectedTab: TabType;
}> = ({ onTabClick, selectedTab }) => {
    const handleClick = (type: TabType) => () => {
        onTabClick(type);
    };

    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center">
                <span className="relative z-0 inline-flex  rounded-md -space-x-px">
                    <nav className="flex space-x-4 tab-group" aria-label="Tabs">
                        {tabs.map(({ type, label }) => (
                            <button
                                className={`bg-white border focus:ring-0 shadow-sm ${
                                    selectedTab === type ? 'active border-indigo-600' : ''
                                }`}
                                onClick={handleClick(type)}
                                key={type}
                            >
                                {label}
                            </button>
                        ))}
                    </nav>
                </span>
            </div>
        </div>
    );
};

export const DetailsSidebar: React.FunctionComponent<{
    flight: IFlight;
    navigateToEntry: (entryId: string) => void;
    openDrawer: VoidFunction;
    onDescriptorChanged: DescriptorChangedHandler;
}> = ({ flight, navigateToEntry, openDrawer, onDescriptorChanged }) => {
    const { entry } = React.useContext(EntryContext);

    const handleFlightSummaryClicked = (evt: React.MouseEvent) => {
        if (containsClass(evt.target, 'flight-summary__entry-cell')) {
            const entryId = getAttribute(evt.target, 'data-entry-id');
            if (entryId) {
                navigateToEntry(String(entryId));
            }
        }
    };

    const handleEntryRemoved = () => {
        navigate(`./${flight.uid}`);
    };

    const [selectedTab, setSelectedTab] = React.useState<TabType>(TabType.FlightSummary);

    const handleTabClick = (type: TabType) => {
        setSelectedTab(type);
    };

    React.useEffect(() => {
        setSelectedTab(TabType.FlightSummary);
    }, [flight]);

    return (
        <>
            <div className="sticky top-0 bg-white pb-4">
                <DetailsMenu selectedTab={selectedTab} onTabClick={handleTabClick} />
            </div>
            <div className="mb-12">
                {selectedTab === TabType.FlightSummary && (
                    <FlightSummary
                        onClick={handleFlightSummaryClicked}
                        flight={flight}
                        onEntryRemoved={handleEntryRemoved}
                        onAddEntryClicked={openDrawer}
                    />
                )}
                {selectedTab === TabType.StyleGuide && (
                    <div className="mx-4 md:mx-0">
                        <StyleInformation
                            category={entry?.style_snippet.category}
                            subCategory={entry?.style_snippet.sub_category}
                            type={entry?.style_snippet.type}
                        />
                    </div>
                )}
                {selectedTab === TabType.Descriptors && (
                    <div className="mx-4 md:mx-0">
                        <DescriptorForm onDescriptorChanged={onDescriptorChanged} />
                    </div>
                )}
            </div>
        </>
    );
};
