import React from 'react';
import { Active, Over } from '@dnd-kit/core';
import { isJudgeAllowedInFlight } from '@flight-cap/shared';
import FlightThumbnail from '../flights/FlightThumbnail';
import { SessionEditorDragType } from '../sessions/SessionEditor';
import SessionJudge from '../sessions/SessionJudge';
import { IDraggableFlightData } from './DraggableFlight';
import { IDraggableJudgeData } from './DraggableJudge';
import { IJudgeDropZoneData } from './DropZoneJudges';

type DraggableData = IDraggableFlightData | IDraggableJudgeData;

const DragGhost: React.FunctionComponent<{ active: Active; over: Over }> = ({ active, over }) => {
    const type: SessionEditorDragType = (active.data.current as DraggableData).type;

    switch (type) {
        case SessionEditorDragType.Flight: {
            const data = active.data.current as IDraggableFlightData;

            return <FlightThumbnail flight={data.flight} />;
        }
        case SessionEditorDragType.Participant: {
            const data = active.data.current as IDraggableJudgeData;
            const overData = over?.data.current as IJudgeDropZoneData;

            const isJudgeAllowed =
                data.participant && overData?.flight
                    ? isJudgeAllowedInFlight(data.participant, overData.flight)
                    : true;

            return (
                <div className="">
                    <SessionJudge
                        participant={data.participant}
                        sessionId={data.sessionId}
                        condensed={!!data.flight}
                        showRemove={!!data.flight}
                        variant={isJudgeAllowed ? 'success' : 'error'}
                    />
                </div>
            );
        }
    }
};

export default DragGhost;
