import React from 'react';
import { firestore, timestamp } from '../../../firebase';
import { ApplicationRoutes } from '../../../routes';
import { useMatch } from '@reach/router';
import { IEntry, IScoreSheetData, decorateWithEntryInfoGetters } from '@flight-cap/shared';
import { SheetSchemasContext } from './SheetSchemasProvider';
import { CompetitionContext } from '../../../providers/CompetitionProvider';
import { UserContext } from '../../../providers/UserProvider';

interface IUpdateScoreParams {
    values?: IScoreSheetData['values'];
    descriptors?: IScoreSheetData['descriptors'];
    options?: { autosave?: boolean };
    isValid: boolean;
}

type IEntryContext = {
    entry: IEntry;
    scoreSheetData: IScoreSheetData;
    updateScore: ({ values, descriptors, options }: IUpdateScoreParams) => Promise<void>;
};

export const EntryContext: React.Context<IEntryContext> = React.createContext<IEntryContext>({
    entry: null,
    scoreSheetData: null,
    updateScore: null,
});

const EntryProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const match = useMatch(`${ApplicationRoutes.JudgingEntry}/*`);

    const { schemas, getSchemaForEntry } = React.useContext(SheetSchemasContext);
    const { competition } = React.useContext(CompetitionContext);
    const { user } = React.useContext(UserContext);

    const [entry, setEntry] = React.useState<IEntryContext['entry']>(null);
    const [scoreSheetData, setScoreSheetData] =
        React.useState<IEntryContext['scoreSheetData']>(null);
    const [scoreSheetDoc, setScoreSheetDoc] =
        React.useState<firebase.default.firestore.DocumentReference>(null);

    React.useEffect(() => {
        const unsubscribes: VoidFunction[] = [];
        if (match?.entryId) {
            unsubscribes.push(
                firestore
                    .doc(`competitions/${competition.uid}/entries/${match.entryId}`)
                    .onSnapshot((doc) => {
                        console.log('Entry Provider - onSnapshot', doc.data());
                        const entryData = doc.data() as IEntry;

                        setEntry(
                            decorateWithEntryInfoGetters({
                                uid: doc.id,
                                ...entryData,
                                sheet_schema: getSchemaForEntry(competition, entryData),
                            })
                        );
                    })
            );

            // to simplify validation messaging and re-renders, we don't need to subscribe to sheet changes, we can just get them
            firestore
                .collection(`competitions/${competition.uid}/entries/${match.entryId}/scoresheets`)
                .where('judge_snippet.uid', '==', user.uid)
                .get()
                .then((sheets) => {
                    if (sheets.docs.length) {
                        const sheet = sheets.docs.shift();
                        console.log('Entry Provider - Scoresheet - get', sheet.data());
                        setScoreSheetDoc(sheet.ref);
                        setScoreSheetData({
                            uid: sheet.id,
                            ...(sheet.data() as IScoreSheetData),
                        });
                    } else {
                        const doc = firestore
                            .collection(
                                `competitions/${competition.uid}/entries/${match.entryId}/scoresheets`
                            )
                            .doc();

                        setScoreSheetDoc(doc);
                        setScoreSheetData({
                            uid: doc.id,
                            descriptors: {},
                            values: null,
                            judge_snippet: null,
                            competition_id: competition.uid,
                            is_valid: false,
                            entry_id: match.entryId,
                        });
                    }
                });

            return () => unsubscribes.forEach((func) => func());
        } else {
            setEntry(null);
        }
    }, [
        competition,
        competition?.uid,
        competition?.default_sheet_schema,
        match?.entryId,
        schemas,
        user?.uid,
        user?.email,
        user?.judging,
        user?.display_name,
        getSchemaForEntry,
    ]);

    const updateScore = async ({ values, descriptors, options, isValid }: IUpdateScoreParams) => {
        const hasValues = values && Object.keys(values).length;
        const hasDescriptors = descriptors && Object.keys(descriptors).length;

        if (!hasValues && !hasDescriptors) {
            return Promise.reject('No values or descriptors to update');
        }

        const sheetData: Omit<IScoreSheetData, 'uid'> = {
            values: {
                ...(scoreSheetData?.values || { final_score: undefined }),
                ...(values || {}),
            },
            descriptors: {
                ...(scoreSheetData?.descriptors || {}),
                ...(descriptors || {}),
            },
            competition_id: competition.uid,
            entry_id: entry.uid,
            judge_snippet: {
                uid: user.uid,
                display_name: user.display_name,
                email: user.email,
                judging: user.judging || null,
            },
            is_valid: isValid,
        };

        if (options?.autosave) {
            sheetData.auto_saved_at = timestamp();
        } else {
            sheetData.saved_at = timestamp();
        }

        return scoreSheetDoc.set(sheetData, { merge: true });
    };

    return (
        <EntryContext.Provider value={{ entry, scoreSheetData, updateScore }}>
            {children}
        </EntryContext.Provider>
    );
};

export default EntryProvider;
